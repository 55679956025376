import {FormGroup, ValidatorFn} from "@angular/forms";

export type FieldValidator = {
  field: string,
  validators: ValidatorFn[]
}

export function conditionalValidationOnCheckedValue(form: FormGroup, controlName: string, fieldsAndValidators: FieldValidator[]) {
  form.get(controlName).valueChanges.subscribe((value) => {
    fieldsAndValidators.forEach(({field, validators}) => {
      if (value) {
        form.get(field).setValidators(validators);
      } else {
        form.get(field).clearValidators();
      }
      form.get(field).updateValueAndValidity();
    });
  });
  form.get(controlName).updateValueAndValidity();
}

export function conditionalValidation(form: FormGroup, controlName: string, predicate: (c: any) => boolean, fieldsAndValidators: FieldValidator[]) {
  form.get(controlName).valueChanges.subscribe((value) => {
    fieldsAndValidators.forEach(({field, validators}) => {
      if (predicate(value)) {
        form.get(field).setValidators(validators);
      } else {
        form.get(field).clearValidators();
      }
      form.get(field).updateValueAndValidity();
    });
  });
}