import {Pipe, PipeTransform} from '@angular/core';
import {InlinableAddress} from "../../models/address/InlinableAddress";
import {AddressInfoDto} from "../../../generated/api";
import {nullToEmpty} from "../../utils/stringUtils";

@Pipe({
  standalone: true,
  name: 'inlineAddress'
})
export class InlineAddressPipe implements PipeTransform {

  transform(address: InlinableAddress | AddressInfoDto, ...args: unknown[]): unknown {
    if (address == null) return "";
    let firstPart = this.getFirstPart(address).trim();
    let secondPart = this.getSecondPart(address).trim();
    const separator = (firstPart === "" || secondPart === "") ? " " : ", ";
    return (firstPart + separator + secondPart).trim();
  }

  private getFirstPart(address: InlinableAddress | AddressInfoDto) {
    return nullToEmpty(address.streetName) + " "
      + nullToEmpty(address.streetNumber);
  }

  private getSecondPart(address: InlinableAddress | AddressInfoDto) {
    return nullToEmpty(address.zipCode) + " "
      + nullToEmpty(address.city);
  }
}
