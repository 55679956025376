import {NumberByKeyData} from "../number-by-key-data";

export class BeneficiaryStatistics {

  beneficiaries: NumberByKeyData[] = [];
  beneficiariesByBr: NumberByKeyData[] = [];
  refusedInscriptionsByMotive: NumberByKeyData[] = [];


  constructor(beneficiaries: NumberByKeyData[], beneficiariesByBr: NumberByKeyData[], refusedInscriptionByMotives: NumberByKeyData[]) {
    this.beneficiaries = beneficiaries;
    this.beneficiariesByBr = beneficiariesByBr;
    this.refusedInscriptionsByMotive = refusedInscriptionByMotives;
  }
}
