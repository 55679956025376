<div class="m-3">
    <h1 mat-dialog-title>Supprimer la prestation</h1>
    <mat-dialog-content>
        <div class="vstack gap-2">
            <div>Attention, cette action est irréversible.</div>
            <div>La suppression de la prestation peut avoir un impact sur</div>
            <mat-list>
                <mat-list-item>la facturation</mat-list-item>
                <mat-list-item>le planning</mat-list-item>
                <mat-list-item>d'autres documents (indemnités km...)</mat-list-item>
                <mat-list-item>...</mat-list-item>
            </mat-list>
            <div class="fw-bold">Êtes-vous sûr de vouloir supprimer cette prestation ?</div>
        </div>
    </mat-dialog-content>


    <mat-dialog-actions class="d-flex gap-2 justify-content-end">
        <button mat-dialog-close class="btn btn-secondary">Annuler</button>
        <button class="btn btn-danger" id="confirm" (click)="confirmDeletion()">Confirmer</button>
    </mat-dialog-actions>
</div>

