import {FirstContactApplicationRefusalMotive} from "./RefusalMotive";
import RefusalMotive = FirstContactApplicationRefusalMotive.RefusalMotive;


export class RefusalInformation {
  private _motive: RefusalMotive
  private _note: string


  get motive(): FirstContactApplicationRefusalMotive.RefusalMotive {
    return this._motive;
  }

  get note(): string {
    return this._note;
  }


  set motive(value: FirstContactApplicationRefusalMotive.RefusalMotive) {
    this._motive = value;
  }

  set note(value: string) {
    this._note = value;
  }
}
