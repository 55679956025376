import {NumberByKeyData} from "../number-by-key-data";

export class PrestationStatistics {
  prestations: number;
  prestationsByMonth: NumberByKeyData[];
  prestationPointsByMonth: NumberByKeyData[];


  constructor(prestations: number, prestationsByMonth: NumberByKeyData[], prestationPointsByMonth: NumberByKeyData[]) {
    this.prestations = prestations;
    this.prestationsByMonth = prestationsByMonth;
    this.prestationPointsByMonth = prestationPointsByMonth;
  }
}
