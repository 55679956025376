<h1>Créer un bénéficiaire</h1>
<form [formGroup]="form" class="vstack gap-2">
    <div class="general-bg bg-white">
        <h2>Informations générales</h2>
        <app-create-person-fields formGroupName="beneficiary"></app-create-person-fields>
    </div>

    <div class="general-bg bg-white">
        <h2>Situation</h2>
        <mat-form-field appearance="fill">
            <mat-label>Bureau régional</mat-label>
            <mat-select formControlName="br" name="br">
                <mat-option *ngFor="let br of brs" [value]="br">
                    {{ br.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Numéro AVIQ</mat-label>
            <input matInput type="text" formControlName="aviqNumber" name="aviqNumber">
            <button *ngIf="form.get('aviqNumber').value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="form.patchValue({aviqNumber:''})" tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <mat-checkbox formControlName="bap" name="bap">BAP</mat-checkbox>
    </div>

    <div class="general-bg bg-white vstack gap-2">
        <h2>Contact</h2>

        <app-create-person-fields formGroupName="contact"></app-create-person-fields>

        <app-create-address-fields formGroupName="contactAddress"></app-create-address-fields>

        <div>
            <mat-form-field>
                <mat-label>Adresse email</mat-label>
                <input matInput type="text" formControlName="contactEmail" name="contactEmail">
                <button *ngIf="form.get('contactEmail').value" matSuffix mat-icon-button aria-label="Clear"
                        (click)="form.patchValue({contactEmail:''})" tabindex="-1">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Téléphone</mat-label>
                <input matInput type="text" formControlName="contactPhone" name="contactPhone">
                <button *ngIf="form.get('contactPhone').value" matSuffix mat-icon-button aria-label="Clear"
                        (click)="form.patchValue({contactPhone:''})" tabindex="-1">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Type de contact</mat-label>
                <mat-select formControlName="contactType" name="contactType">
                    <mat-option *ngFor="let type of contactTypes" [value]="type">
                        {{ type.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Lien avec le bénéficiaire</mat-label>
                <mat-select formControlName="contactLinkType" name="contactLinkType">
                    <mat-option *ngFor="let linkType of contactLinkTypes" [value]="linkType">
                        {{ linkType.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="general-bg bg-white">
        <mat-form-field>
            <mat-label>Date d'inscription</mat-label>
            <input matInput [matDatepicker]="registerDatePicker" formControlName="registeredOn" name="registeredOn">
            <mat-hint>JJ/MM/AAAA</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="registerDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #registerDatePicker></mat-datepicker>
        </mat-form-field>
    </div>

    <div class="general-bg bg-white d-flex gap-2">
        <button class="btn btn-secondary" [routerLink]="['/beneficiary']">Retour</button>
        <button type="submit" class="btn btn-primary" [disabled]="form.invalid" (click)="create()">
            Créer le bénéficiaire
        </button>
    </div>
</form>