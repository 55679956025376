<h1 mat-dialog-title>Modifier le contact</h1>
<div mat-dialog-content>

    <form [formGroup]="form" class="general-bg bg-white">
        <app-create-person-fields formGroupName="contact"></app-create-person-fields>
        <br/>
        <mat-form-field>
            <mat-label>Adresse email</mat-label>
            <input matInput type="text" formControlName="contactEmail" name="contactEmail">
            <button *ngIf="form.get('contactEmail').value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="form.patchValue({contactEmail:''})" tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Téléphone</mat-label>
            <input matInput required type="text" formControlName="contactPhone" name="contactPhone">
            <button *ngIf="form.get('contactPhone').value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="form.patchValue({contactPhone:''})" tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Type de contact</mat-label>
            <mat-select required [compareWith]="compareFn" formControlName="contactType" name="contactType">
                <mat-option *ngFor="let type of contactTypes" [value]="type">
                    {{ type.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Lien avec le bénéficiaire</mat-label>
            <mat-select required [compareWith]="compareFn" formControlName="contactLinkType" name="contactLinkType">
                <mat-option *ngFor="let linkType of contactLinkTypes" [value]="linkType">
                    {{ linkType.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>


    <div mat-dialog-actions class="d-flex gap-2">
        <button class="btn btn-secondary" mat-dialog-close="">Annuler</button>
        <button class="btn btn-primary" mat-dialog-close="" (click)="confirm()">Confirmer</button>
    </div>
</div>
