import {addHours, addMinutes, setHours, setMinutes, startOfDay} from "date-fns";
import {SchedulerTimePeriod} from "./SchedulerTimePeriod";
import {SchedulerTime} from "./SchedulerTime";

export class SchedulerDay {
  private readonly _date: Date;

  constructor(date: Date) {
    this._date = startOfDay(date);
  }

  get date(): Date {
    return this._date;
  }

  atEndOfPeriod(timePeriod:SchedulerTimePeriod): SchedulerDay {
    return new SchedulerDay(this.dateTimeAtEndOfPeriod(timePeriod));
  }

  dateTimeAtEndOfPeriod(timePeriod: SchedulerTimePeriod): Date {
    let newDate = new Date(this._date);
    newDate = setHours(newDate, timePeriod.startTime.hour)
    newDate = setMinutes(newDate, timePeriod.startTime.minute)
    newDate = addHours(newDate, timePeriod.durationHour)
    newDate = addMinutes(newDate, timePeriod.durationMinute)
    return newDate;
  }

  dateTimeAtStartOfPeriod(timePeriod: SchedulerTimePeriod): Date {
    let newDate = new Date(this._date);
    newDate = setHours(newDate, timePeriod.startTime.hour)
    newDate = setMinutes(newDate, timePeriod.startTime.minute)
    return newDate;
  }

  dateTimeAt(time: SchedulerTime): Date {
    let newDate = new Date(this._date);
    newDate = setHours(newDate, time.hour)
    newDate = setMinutes(newDate, time.minute)
    return newDate;
  }

  static today(): SchedulerDay {
    return new SchedulerDay(new Date());
  }

}
