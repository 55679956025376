import {Component, OnInit} from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import {formatDate, NgForOf, NgIf} from "@angular/common";
import {
  BeneficiaryContactLinkTypeDto,
  BeneficiaryContactTypeDto,
  BureauRegionalDto,
  CreateBeneficiaryRequest
} from "../../../../generated/api";
import {BeneficiaryService} from "../../../models/beneficiary/beneficiary.service";
import {BrService} from "../../../models/br/br.service";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatInput} from "@angular/material/input";
import {MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {CreatePersonFieldsComponent} from "../../components/create-person-fields/create-person-fields.component";
import {CreateAddressFieldsComponent} from "../../address/create-address-fields/create-address-fields.component";


@Component({
  selector: 'app-create-beneficiary',
  standalone: true,
  templateUrl: './create-beneficiary.component.html',
  imports: [
    FormsModule,
    MatLabel,
    MatHint,
    MatFormField,
    MatSelect,
    MatOption,
    MatInput,
    MatIconButton,
    MatIcon,
    MatSuffix,
    MatCheckbox,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDatepicker,
    RouterLink,
    CreatePersonFieldsComponent,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    CreateAddressFieldsComponent
  ],
  styleUrls: ['./create-beneficiary.component.scss']
})
export class CreateBeneficiaryComponent implements OnInit {

  form: FormGroup = new FormGroup({
    beneficiary: new FormGroup({
      firstName: new FormControl("", Validators.required),
      lastName: new FormControl("", Validators.required),
      birthDate: new FormControl("", Validators.required),
      gender: new FormControl(null, Validators.required)
    }),
    aviqNumber: new FormControl(""),
    bap: new FormControl(false),
    registeredOn: new FormControl(formatDate(new Date(), "YYYY-MM-dd", "fr-BE"), Validators.required),
    br: new FormControl(null, Validators.required),
    contact: new FormGroup({
      firstName: new FormControl("", Validators.required),
      lastName: new FormControl("", Validators.required),
      birthDate: new FormControl(""),
      gender: new FormControl(null, Validators.required)
    }),
    contactAddress: new FormGroup({
      streetName: new FormControl("", Validators.required),
      streetNumber: new FormControl("", Validators.required),
      zipCode: new FormControl("", Validators.required),
      city: new FormControl("", Validators.required)
    }),
    contactEmail: new FormControl(""),
    contactPhone: new FormControl("", Validators.required),
    contactType: new FormControl(null, Validators.required),
    contactLinkType: new FormControl(null, Validators.required)
  });

  brs: BureauRegionalDto[] = []
  contactTypes: BeneficiaryContactTypeDto[] = [];
  contactLinkTypes: BeneficiaryContactLinkTypeDto[] = [];
  dd = null;

  constructor(private router: Router, private beneficiaryService: BeneficiaryService, private brService: BrService) {
  }

  ngOnInit(): void {
    this.brService.allBr().subscribe(brs => this.brs = brs.sort((a, b) => a.name > b.name ? 1 : -1));
    this.beneficiaryService.contactTypes().subscribe(types => this.contactTypes = types);
    this.beneficiaryService.contactLinkTypes().subscribe(linkTypes => this.contactLinkTypes = linkTypes);
  }

  create() {
    let request: CreateBeneficiaryRequest = {
      personInfo: {
        firstName: this.form.get("beneficiary.firstName").value,
        lastName: this.form.get("beneficiary.lastName").value,
        birthDate: this.form.get("beneficiary.birthDate").value,
        gender: this.form.get("beneficiary.gender").value
      },
      aviqFileNumber: this.form.get("aviqNumber").value,
      bap: this.form.get("bap").value,
      registeredOn: this.form.get("registeredOn").value,
      brId: this.form.get("br").value.id,
      contactInfo: {
        firstName: this.form.get("contact.firstName").value,
        lastName: this.form.get("contact.lastName").value,
        birthDate: this.form.get("contact.birthDate").value,
        gender: this.form.get("contact.gender").value
      },
      contactPhoneNumber: this.form.get("contactPhone").value,
      contactEmailAddress: this.form.get("contactEmail").value,
      contactType: this.form.get("contactType").value.value,
      contactLinkType: this.form.get("contactLinkType").value.value,
      contactAddress: {
        streetName: this.form.get("contactAddress.streetName").value,
        streetNumber: this.form.get("contactAddress.streetNumber").value,
        zipCode: this.form.get("contactAddress.zipCode").value,
        city: this.form.get("contactAddress.city").value
      }
    };
    this.beneficiaryService.create(request).subscribe(
      beneficiaryDetail => this.router.navigate(["/beneficiary/detail/", beneficiaryDetail.id])
    )
  }
}
