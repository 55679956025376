import {Component, OnInit} from '@angular/core';
import {InvoicedBapBeneficiaryDto} from "../../../../generated/api";
import {Year} from "../../../models/calendar/Year";
import {Month} from "../../../models/calendar/Month";
import {InvoiceService} from "../../../models/document/invoice/invoice.service";
import {CalendarService} from "../../../models/calendar/calendar.service";

@Component({
  selector: 'app-generate-bap-invoice',
  templateUrl: './generate-bap-invoice.component.html',
  styleUrls: ['./generate-bap-invoice.component.scss']
})
export class GenerateBapInvoiceComponent implements OnInit {
  bapBeneficiaries: InvoicedBapBeneficiaryDto[] = []
  selectedYear: Year;
  selectedMonth: Month;
  months: Month[] = [];
  years: Year[] = [];


  constructor(private invoiceService: InvoiceService, private calendarService: CalendarService) {
  }

  ngOnInit(): void {
    this.months = this.calendarService.getMonths();
    this.years = this.calendarService.getYears();
    this.selectedYear = this.calendarService.getCurrentYear();
    this.selectedMonth = this.calendarService.getCurrentMonth();
    this.loadBeneficiariesToInvoice();
  }

  private loadBeneficiariesToInvoice() {
    this.invoiceService.fetchBapInvoiceableBeneficiaries(this.selectedMonth, this.selectedYear).subscribe((beneficiaries => {
      this.bapBeneficiaries = beneficiaries.sort((a, b) => a.lastName > b.lastName ? 1 : -1);
    }));
  }

  public updateBeneficiaries() {
    this.loadBeneficiariesToInvoice();
  }

  generateAndDownloadBapInvoice(beneficiary: InvoicedBapBeneficiaryDto) {
    this.invoiceService.generateBapInvoice(beneficiary, this.selectedMonth, this.selectedYear);
  }

  hasBeneficiaries(): boolean {
    return this.bapBeneficiaries.length > 0;
  }
}
