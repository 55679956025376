export class SchedulerTime {
  private readonly _hour: number;
  private readonly _minute: number;


  constructor(hour: number, minute: number) {
    this._hour = hour;
    this._minute = minute;
  }

  get hour(): number {
    return this._hour;
  }

  get minute(): number {
    return this._minute;
  }

  add(durationHour: number, durationMinute: number) {
    const totalMinutes = this.timeToMinutes(this) + durationHour * 60 + durationMinute;
    const newHour = Math.floor(totalMinutes / 60);
    const newMinute = totalMinutes % 60;

    return new SchedulerTime(newHour % 24, newMinute);
  }

  duration(other: SchedulerTime) {
    const startMinutes = this.timeToMinutes(this);
    let endMinutes = this.timeToMinutes(other);
    if (startMinutes > endMinutes) {
      endMinutes = endMinutes + 24 * 60;
    }
    const durationMinutes = endMinutes - startMinutes;

    if (durationMinutes < 0) {
      throw new Error('The start time must be before the end time.');
    }

    const hour = Math.floor(durationMinutes / 60);
    const minute = durationMinutes % 60;

    return {hour, minute};
  }

  isBefore(other: SchedulerTime): boolean {
    return this._hour < other._hour || (this._hour === other.hour && this._minute < other.minute);
  }

  asDate(): Date {
    const date = new Date();
    date.setHours(this._hour);
    date.setMinutes(this._minute);
    date.setSeconds(0);
    return date;
  }

  asString(): string {
    return `${this._hour.toString().padStart(2, '0')}:${this._minute.toString().padStart(2, '0')}`;
  }

  private timeToMinutes(time: SchedulerTime): number {
    return time.hour * 60 + time.minute;
  }

  public static fromString(time: string): SchedulerTime {
    if (time.includes(":") === false) {
      return new SchedulerTime(parseInt(time), 0);
    }
    return new SchedulerTime(parseInt(time.split(":")[0]), parseInt(time.split(":")[1]));
  }

  public static isParsable(timeAsString: string): boolean {
    if (timeAsString === null || timeAsString === undefined) {
      return false;
    }
    return /^([0-9]|0[0-9]|1[0-9]|2[0-3])(:([0-9]|[0-5][0-9]))?$/.test(timeAsString);
  }
}
