<div class="vstack gap-2" *ngIf="prestation()">
    <div class="d-flex gap-2 align-items-center">
        <app-prestation-status-icon [status]="prestation().status"/>
        <h1>Prestation pour {{ prestation().beneficiary | personInfo }}<span
                *ngIf="prestation().mainEducator"> par {{ prestation().mainEducator | personInfo }}</span>
        </h1>
    </div>

    <div class="repit-container-2">

        <div class="box general-bg bg-white">
            <div class="d-flex align-items-baseline">
                <h2>Informations générales</h2>
                <button class="ms-auto" mat-icon-button [matMenuTriggerFor]="generalInfoSubMenu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #generalInfoSubMenu="matMenu" class="no-max-width">
                    <button mat-menu-item (click)="editSchedule()">
                        <mat-icon>access_time</mat-icon>
                        Modifier l'horaire
                    </button>
                    <button mat-menu-item (click)="switchUrgent()">
                        <mat-icon>alarm</mat-icon>
                        {{ prestation().urgent ? 'Passer en non-urgent' : 'Passer en urgent' }}
                    </button>
                    <button mat-menu-item [disabled]="canSwitchBap()" (click)="switchBap()">
                        <mat-icon>accessible_forward</mat-icon>
                        {{ prestation().bap ? 'Passer en non-BAP' : 'Passer en BAP' }}
                    </button>
                    <button mat-menu-item (click)="editType()">
                        <mat-icon>edit</mat-icon>
                        Modifier le type
                    </button>
                </mat-menu>
            </div>

            <div class="repit-field">
                <div>Date :</div>
                <div>{{ prestation().schedule.day | schedulerDay }}</div>
            </div>
            <div class="repit-field">
                <div>Horaire :</div>
                <div>{{ prestation().schedule.timePeriod | schedulerTimePeriod:true }}</div>
            </div>
            <div class="repit-field">
                <div>Statut :</div>
                <div>{{ prestation().status.label }}</div>
                <div *ngIf="isRefused()">({{ prestation().refusalMotive }})</div>
                <div *ngIf="isCancelled()">({{ prestation().cancellationMotive }})</div>
            </div>
            <div class="repit-field" *ngIf="prestation().refusalNote">
                <div>Justification :</div>
                <div>{{ prestation().refusalNote }}</div>
            </div>
            <div class="repit-field" *ngIf="prestation().cancellationNote">
                <div>Justification :</div>
                <div>{{ prestation().cancellationNote }}</div>
            </div>
            <div class="repit-field">
                <div>Type :</div>
                <div>{{ prestation().type.label }}</div>
            </div>
            <div class="repit-field">
                <div>Urgent :</div>
                <div>{{ prestation().urgent | yesNo }}</div>
            </div>
            <div class="repit-field">
                <div>Bap :</div>
                <div>{{ prestation().bap | yesNo }}</div>
            </div>
        </div>

        <div class="box general-bg bg-white">
            <h2>Educateur</h2>
            <div *ngIf="prestation().mainEducator; else noEducatorBlock">
                <div class="repit-field">
                    <div>Educateur principal :</div>
                    <div>{{ prestation().mainEducator | personInfo }}</div>
                </div>
                <div class="repit-field" *ngIf="prestation().secondaryEducator">
                    <div>Educateur accompagnant :</div>
                    <div>{{ prestation().secondaryEducator | personInfo }}</div>
                </div>

                <div class="repit-field" *ngIf="prestation().educatorValidation?.note">
                    <div>Note de l'éducateur :</div>
                    <div>{{ prestation().educatorValidation?.note }}</div>
                </div>
            </div>
            <ng-template #noEducatorBlock>
                <div>
                    <div>Pas d'éducateur assigné</div>
                </div>
            </ng-template>

            <div *ngIf="canBeAssigned()">
                <button class="btn btn-primary" (click)="assign()">
                    {{ prestation().mainEducator ? 'Modifier' : 'Assigner' }}
                </button>
            </div>
        </div>

        <div class="box general-bg bg-white">
            <h2>Bénéficiaire</h2>
            <div class="repit-field">
                <div>Bénéficiaire :</div>
                <div>{{ prestation().beneficiary | personInfo }}</div>
            </div>
            <div class="repit-field">
                <div>Lieu :</div>
                <div>{{ prestation().location | inlineAddress }}</div>
                <div *ngIf="isLocationAtHome(); else notAtHomeBlock " id="atHome"> (à domicile)</div>
                <ng-template #notAtHomeBlock>
                    <div id="outOfHome"> (hors domicile)</div>
                </ng-template>
            </div>
            <div class="repit-field">
                <div>Demandeur :</div>
                <div>{{ prestation().requestContact | personInfo }} ({{ prestation().requestContact.linkType }})
                </div>
            </div>
            <div class="repit-field">
                <div>Facturation :</div>
                <div>{{ prestation().invoiceContact | personInfo }} ({{ prestation().invoiceContact.linkType }})
                </div>
            </div>
            <div *ngIf="canUpdateLocationAndContacts()">
                <button class="btn btn-primary" id="updateLocationAndContacts" (click)="updateLocationAndContacts()">
                    Modifier
                </button>
            </div>
        </div>

        <div class="box general-bg bg-white" *ngIf="isClosed()">
            <h2>Trajets</h2>
            <div *ngIf="hasTravels(); else noTravelBlock">
                <div class="repit-field" *ngIf="prestation().travelBefore">
                    <div>Trajet avant :</div>
                    <div>{{ prestation().travelBefore.motive }} ({{ prestation().travelBefore.distance }}km)</div>
                </div>
                <div class="repit-field" *ngFor="let travel of prestation().travelDurings">
                    <div>Trajet pendant :</div>
                    <div>{{ travel.motive }} ({{ travel.distance }}km)</div>
                </div>
                <div class="repit-field" *ngIf="prestation().travelAfter">
                    <div>Trajet après :</div>
                    <div>{{ prestation().travelAfter.motive }} ({{ prestation().travelAfter.distance }}km)</div>
                </div>
            </div>
            <ng-template #noTravelBlock>
                <div>
                    <div>Pas de trajet pendant la prestation</div>
                </div>
            </ng-template>
            <div *ngIf="canUpdateTravels()">
                <button class="btn btn-primary" id="updateTravels" (click)="updateTravels()">
                    {{ hasTravels() ? 'Modifier' : 'Ajouter' }}
                </button>
            </div>
        </div>

        <div class="box general-bg bg-white" *ngIf="prestation().note">
            <h2>Note</h2>
            <div>
                {{ prestation().note }}
            </div>
        </div>
    </div>

    <div class="general-bg bg-white d-flex gap-2">
        <button [routerLink]="['/prestation']" class="btn btn-secondary">Retour</button>
        <button class="btn btn-primary" (click)="refuse()" *ngIf="canBeRefused()">
            Refuser
        </button>
        <button class="btn btn-primary" (click)="cancel()" *ngIf="canBeCancelled()">
            Annuler la prestation
        </button>
        <button class="btn btn-danger" (click)="delete()" *ngIf="canBeDeleted()">
            Supprimer la prestation
        </button>
    </div>

</div>