import {Observable} from "rxjs";
import {PrestationToValidate} from "../models/prestation/PrestationToValidate";
import {PrestationValidationRequest} from "../models/prestation/PrestationValidationRequest";
import {PrestationToClose} from "../models/prestation/PrestationToClose";
import {PrestationClosureRequest} from "../models/prestation/PrestationClosureRequest";
import {PrestationSummary} from "../models/prestation/PrestationSummary";
import {DateRange} from "../models/calendar/DateRange";
import {PrestationDetail} from "../models/prestation/prestationDetail";
import {PrestationCreationRequest} from "../models/prestation/PrestationCreationRequest";
import {PrestationRefusalMotiveEnum} from "../models/prestation/PrestationRefusalMotive";
import {PrestationCancellationMotiveEnum} from "../models/prestation/PrestationCancellationMotive";
import {PrestationTravelCreationRequest} from "../models/prestation/PrestationTravelCreationRequest";
import {InlinableAddress} from "../models/address/InlinableAddress";
import {PrestationTypeEnum} from "../models/prestation/PrestationType";
import {SchedulerTime} from "../models/scheduler/SchedulerTime";
import PrestationRefusalMotive = PrestationRefusalMotiveEnum.PrestationRefusalMotive;
import PrestationCancellationMotive = PrestationCancellationMotiveEnum.PrestationCancellationMotive;
import PrestationType = PrestationTypeEnum.PrestationType;

export abstract class PrestationGateway {

  abstract retrieveAllSummaries(dateRange: DateRange): Observable<PrestationSummary[]>;

  abstract retrieveAllToValidate(): Observable<PrestationToValidate[]>;

  abstract retrieveAllToClose(): Observable<PrestationToClose[]>;

  abstract validatePrestation(request: PrestationValidationRequest): Observable<void>;

  abstract assignPrestation(prestationId: number, mainEducatorId: number, secondaryEducatorId?: number | null): Observable<void>;

  abstract closePrestation(prestation: PrestationClosureRequest): Observable<void>;

  abstract createPrestation(request: PrestationCreationRequest): Observable<number>;

  abstract retrieveDetail(prestationId: number): Observable<PrestationDetail>;

  abstract refusePrestation(prestationId: number, refusalMotive: PrestationRefusalMotive, note: string): Observable<void>;

  abstract cancelPrestation(prestationId: number, cancellationMotive: PrestationCancellationMotive, note: string): Observable<void>;

  abstract updateTravels(prestationId: number,
                         travelDuringDescription: string,
                         travelDuringDistance: number,
                         travelBefore: PrestationTravelCreationRequest | null,
                         travelAfter: PrestationTravelCreationRequest | null): Observable<void>;

  abstract updateLocationAndContacts(prestationId: number, requestContactId: number, invoiceContactId: number, location: InlinableAddress): Observable<void>;

  abstract switchBap(prestationId: number): Observable<void>;

  abstract switchUrgent(prestationId: number): Observable<void>;

  abstract updateSchedule(prestationId: number, startTime: SchedulerTime, endTime: SchedulerTime): Observable<void>;

  abstract updateType(prestationId: number, type: PrestationType): Observable<void>;

  abstract deletePrestation(prestationId: number): Observable<void>;
}