import {Component, computed, inject, Inject, OnInit, signal} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from "@angular/material/dialog";
import {SimpleEducator} from "../../../models/educator/simpleEducator";
import {Store} from "@ngxs/store";
import {StaffSelectors} from "../../../stores/staff/staff.selectors";
import {toSignal} from "@angular/core/rxjs-interop";
import {RetrieveActiveEducators} from "../../../stores/staff/staff.actions";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {NgForOf, NgIf} from "@angular/common";
import {PersonInfoPipe} from "../../pipe/person-info.pipe";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {fullName, sortByFullName} from "../../../models/person/PersonUtils";
import {byId} from "../../../models/common/compareUtils";
import {MatCheckbox} from "@angular/material/checkbox";
import {AssignPrestation} from "../../../stores/prestation/prestation.actions";

export interface Data {
  prestationId: number;
  mainEducator?: SimpleEducator | null;
  secondaryEducator?: SimpleEducator | null;
}

@Component({
  selector: 'app-assign-prestation-dialog',
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
    PersonInfoPipe,
    ReactiveFormsModule,
    FormsModule,
    MatDialogContent,
    MatDialogTitle,
    MatDialogActions,
    MatDialogClose,
    MatCheckbox,
    NgIf
  ],
  templateUrl: './assign-prestation-dialog.component.html',
  styleUrl: './assign-prestation-dialog.component.scss'
})
export class AssignPrestationDialogComponent implements OnInit {
  store = inject(Store);

  educators = toSignal(this.store.select(StaffSelectors.activeEducators()));
  mainEducator = signal<SimpleEducator>(null);
  secondaryEducator = signal<SimpleEducator>(null);
  mainEducatorFilter = signal("");
  secondaryEducatorFilter = signal("");
  doubledPrestation = signal(false);

  constructor(@Inject(MAT_DIALOG_DATA) public input: Data) {
    this.mainEducator.set(input.mainEducator || null)
    this.secondaryEducator.set(input.secondaryEducator || null)
    this.doubledPrestation.set(!!input.secondaryEducator)
  }

  ngOnInit() {
    this.store.dispatch(new RetrieveActiveEducators());
  }

  filteredMainEducators = computed(() => {
    return this.educators()
      .filter(educator => this.secondaryEducator() ? educator.id !== this.secondaryEducator().id : true)
      .filter(educator => fullName(educator).toLowerCase().includes(this.mainEducatorFilter().toLowerCase()))
      .sort(sortByFullName);
  });

  filteredSecondaryEducators = computed(() => {
    return this.educators()
      .filter(educator => this.mainEducator() ? educator.id !== this.mainEducator().id : true)
      .filter(educator => fullName(educator).toLowerCase().includes(this.secondaryEducatorFilter().toLowerCase()))
      .sort(sortByFullName);
  });

  assign() {
    if (this.isValid()) {
      this.store.dispatch(new AssignPrestation(this.input.prestationId, this.mainEducator().id, this.doubledPrestation() ? this.secondaryEducator().id : null));
    }
  }

  isValid = computed(() => {
    return this.mainEducator() && (this.doubledPrestation() ? this.secondaryEducator() : true);
  })

  protected readonly byId = byId;
}
