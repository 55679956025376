<h1>Historique des adresses</h1>

<table>
    <thead>
    <tr>
        <th>Adresse</th>
        <th>Date de début</th>
        <th>Date de fin</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let address of addresses()">
        <td>{{address | inlineAddress}}</td>
        <td>{{address.startDate | date:"dd/MM/YYYY"}}</td>
        <td>{{address.endDate | date:"dd/MM/YYYY"}}</td>
    </tr>
    </tbody>
</table>