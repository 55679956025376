/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {AddressWithCoordinatesDto} from './addressWithCoordinatesDto';


export interface FirstContactApplicationResponse {
  id?: number;
  contactedOn?: string;
  firstName?: string;
  lastName?: string;
  age?: number;
  gender?: string;
  status?: string;
  note?: string;
  address?: AddressWithCoordinatesDto;
  refusalMotive?: string;
  refusalNote?: string;
  refusedOn?: string;
  acceptedOn?: string;
  canBeEdited?: boolean;
}

