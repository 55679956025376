export class SchedulerEventType {
  public static readonly PRESTATION_TYPE = new SchedulerEventType("PRESTATION")
  public static readonly TIME_OFF_TYPE = new SchedulerEventType("TIME_OFF")
  private readonly _value:String;

  private constructor(value: String) {
    this._value = value;
  }

  get value(): String {
    return this._value;
  }
}
