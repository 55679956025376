/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {
  HttpClient,
  HttpContext,
  HttpEvent,
  HttpHeaders,
  HttpParameterCodec,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import {CustomHttpParameterCodec} from '../encoder';
import {Observable} from 'rxjs';

// @ts-ignore
import {LocationAndContactsDto} from '../model/locationAndContactsDto';
// @ts-ignore
import {PrestationAssignRequestDto} from '../model/prestationAssignRequestDto';
// @ts-ignore
import {PrestationCancellationRequestDto} from '../model/prestationCancellationRequestDto';
// @ts-ignore
import {PrestationClosureRequestDto} from '../model/prestationClosureRequestDto';
// @ts-ignore
import {PrestationCreationRequestDto} from '../model/prestationCreationRequestDto';
// @ts-ignore
import {PrestationDetailDto} from '../model/prestationDetailDto';
// @ts-ignore
import {PrestationDto} from '../model/prestationDto';
// @ts-ignore
import {PrestationRefusalRequestDto} from '../model/prestationRefusalRequestDto';
// @ts-ignore
import {PrestationSummaryDto} from '../model/prestationSummaryDto';
// @ts-ignore
import {PrestationToCloseDto} from '../model/prestationToCloseDto';
// @ts-ignore
import {PrestationTravelMutationRequestDto} from '../model/prestationTravelMutationRequestDto';
// @ts-ignore
import {PrestationValidationRequestDto} from '../model/prestationValidationRequestDto';
// @ts-ignore
import {QuickCreationRequestDto} from '../model/quickCreationRequestDto';
// @ts-ignore
import {SchedulablePrestationDto} from '../model/schedulablePrestationDto';
// @ts-ignore
import {UpdateScheduleDto} from '../model/updateScheduleDto';

// @ts-ignore
import {BASE_PATH, COLLECTION_FORMATS} from '../variables';
import {Configuration} from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class PrestationControllerService {

  protected basePath = 'http://localhost:8880';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string | string[], @Optional() configuration: Configuration) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }


  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === "object") {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
          httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }

  /**
   * @param prestationId
   * @param educatorId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public assignMainEducator(prestationId: number, educatorId: number, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<SchedulablePrestationDto>;
  public assignMainEducator(prestationId: number, educatorId: number, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<SchedulablePrestationDto>>;
  public assignMainEducator(prestationId: number, educatorId: number, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<SchedulablePrestationDto>>;
  public assignMainEducator(prestationId: number, educatorId: number, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {
    if (prestationId === null || prestationId === undefined) {
      throw new Error('Required parameter prestationId was null or undefined when calling assignMainEducator.');
    }
    if (educatorId === null || educatorId === undefined) {
      throw new Error('Required parameter educatorId was null or undefined when calling assignMainEducator.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/prestation/${this.configuration.encodeParam({
      name: "prestationId",
      value: prestationId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int64"
    })}/assign/${this.configuration.encodeParam({
      name: "educatorId",
      value: educatorId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int64"
    })}`;
    return this.httpClient.request<SchedulablePrestationDto>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param prestationId
   * @param prestationAssignRequestDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public assignPrestation(prestationId: number, prestationAssignRequestDto: PrestationAssignRequestDto, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<SchedulablePrestationDto>;
  public assignPrestation(prestationId: number, prestationAssignRequestDto: PrestationAssignRequestDto, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<SchedulablePrestationDto>>;
  public assignPrestation(prestationId: number, prestationAssignRequestDto: PrestationAssignRequestDto, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<SchedulablePrestationDto>>;
  public assignPrestation(prestationId: number, prestationAssignRequestDto: PrestationAssignRequestDto, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {
    if (prestationId === null || prestationId === undefined) {
      throw new Error('Required parameter prestationId was null or undefined when calling assignPrestation.');
    }
    if (prestationAssignRequestDto === null || prestationAssignRequestDto === undefined) {
      throw new Error('Required parameter prestationAssignRequestDto was null or undefined when calling assignPrestation.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/prestation/${this.configuration.encodeParam({
      name: "prestationId",
      value: prestationId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int64"
    })}/assign`;
    return this.httpClient.request<SchedulablePrestationDto>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: prestationAssignRequestDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param prestationId
   * @param prestationCancellationRequestDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cancelPrestation(prestationId: number, prestationCancellationRequestDto: PrestationCancellationRequestDto, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any>;
  public cancelPrestation(prestationId: number, prestationCancellationRequestDto: PrestationCancellationRequestDto, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpResponse<any>>;
  public cancelPrestation(prestationId: number, prestationCancellationRequestDto: PrestationCancellationRequestDto, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpEvent<any>>;
  public cancelPrestation(prestationId: number, prestationCancellationRequestDto: PrestationCancellationRequestDto, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any> {
    if (prestationId === null || prestationId === undefined) {
      throw new Error('Required parameter prestationId was null or undefined when calling cancelPrestation.');
    }
    if (prestationCancellationRequestDto === null || prestationCancellationRequestDto === undefined) {
      throw new Error('Required parameter prestationCancellationRequestDto was null or undefined when calling cancelPrestation.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/prestation/${this.configuration.encodeParam({
      name: "prestationId",
      value: prestationId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int64"
    })}/cancel`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: prestationCancellationRequestDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param prestationId
   * @param prestationClosureRequestDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public close(prestationId: number, prestationClosureRequestDto: PrestationClosureRequestDto, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any>;
  public close(prestationId: number, prestationClosureRequestDto: PrestationClosureRequestDto, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpResponse<any>>;
  public close(prestationId: number, prestationClosureRequestDto: PrestationClosureRequestDto, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpEvent<any>>;
  public close(prestationId: number, prestationClosureRequestDto: PrestationClosureRequestDto, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any> {
    if (prestationId === null || prestationId === undefined) {
      throw new Error('Required parameter prestationId was null or undefined when calling close.');
    }
    if (prestationClosureRequestDto === null || prestationClosureRequestDto === undefined) {
      throw new Error('Required parameter prestationClosureRequestDto was null or undefined when calling close.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/prestation/${this.configuration.encodeParam({
      name: "prestationId",
      value: prestationId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int64"
    })}/close`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: prestationClosureRequestDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param prestationCreationRequestDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createPrestation(prestationCreationRequestDto: PrestationCreationRequestDto, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<PrestationDto>;
  public createPrestation(prestationCreationRequestDto: PrestationCreationRequestDto, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<PrestationDto>>;
  public createPrestation(prestationCreationRequestDto: PrestationCreationRequestDto, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<PrestationDto>>;
  public createPrestation(prestationCreationRequestDto: PrestationCreationRequestDto, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {
    if (prestationCreationRequestDto === null || prestationCreationRequestDto === undefined) {
      throw new Error('Required parameter prestationCreationRequestDto was null or undefined when calling createPrestation.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/prestation/create`;
    return this.httpClient.request<PrestationDto>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: prestationCreationRequestDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param quickCreationRequestDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createPrestationWithDefault(quickCreationRequestDto: QuickCreationRequestDto, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<PrestationDto>;
  public createPrestationWithDefault(quickCreationRequestDto: QuickCreationRequestDto, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<PrestationDto>>;
  public createPrestationWithDefault(quickCreationRequestDto: QuickCreationRequestDto, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<PrestationDto>>;
  public createPrestationWithDefault(quickCreationRequestDto: QuickCreationRequestDto, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {
    if (quickCreationRequestDto === null || quickCreationRequestDto === undefined) {
      throw new Error('Required parameter quickCreationRequestDto was null or undefined when calling createPrestationWithDefault.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/prestation/create-default`;
    return this.httpClient.request<PrestationDto>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: quickCreationRequestDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param prestationId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deletePrestation(prestationId: number, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any>;
  public deletePrestation(prestationId: number, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpResponse<any>>;
  public deletePrestation(prestationId: number, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpEvent<any>>;
  public deletePrestation(prestationId: number, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any> {
    if (prestationId === null || prestationId === undefined) {
      throw new Error('Required parameter prestationId was null or undefined when calling deletePrestation.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/prestation/${this.configuration.encodeParam({
      name: "prestationId",
      value: prestationId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int64"
    })}`;
    return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public detail(id: number, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<PrestationDetailDto>;
  public detail(id: number, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<PrestationDetailDto>>;
  public detail(id: number, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<PrestationDetailDto>>;
  public detail(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling detail.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/prestation/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int64"
    })}`;
    return this.httpClient.request<PrestationDetailDto>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public prestationsToClose(observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<Array<PrestationToCloseDto>>;
  public prestationsToClose(observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<Array<PrestationToCloseDto>>>;
  public prestationsToClose(observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<Array<PrestationToCloseDto>>>;
  public prestationsToClose(observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/prestation/toClose`;
    return this.httpClient.request<Array<PrestationToCloseDto>>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public prestationsToValidate(observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<Array<SchedulablePrestationDto>>;
  public prestationsToValidate(observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<Array<SchedulablePrestationDto>>>;
  public prestationsToValidate(observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<Array<SchedulablePrestationDto>>>;
  public prestationsToValidate(observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/prestation/toValidate`;
    return this.httpClient.request<Array<SchedulablePrestationDto>>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param prestationId
   * @param prestationRefusalRequestDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public refusePrestation(prestationId: number, prestationRefusalRequestDto: PrestationRefusalRequestDto, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any>;
  public refusePrestation(prestationId: number, prestationRefusalRequestDto: PrestationRefusalRequestDto, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpResponse<any>>;
  public refusePrestation(prestationId: number, prestationRefusalRequestDto: PrestationRefusalRequestDto, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpEvent<any>>;
  public refusePrestation(prestationId: number, prestationRefusalRequestDto: PrestationRefusalRequestDto, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any> {
    if (prestationId === null || prestationId === undefined) {
      throw new Error('Required parameter prestationId was null or undefined when calling refusePrestation.');
    }
    if (prestationRefusalRequestDto === null || prestationRefusalRequestDto === undefined) {
      throw new Error('Required parameter prestationRefusalRequestDto was null or undefined when calling refusePrestation.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/prestation/${this.configuration.encodeParam({
      name: "prestationId",
      value: prestationId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int64"
    })}/refuse`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: prestationRefusalRequestDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param start
   * @param end
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public schedulablePrestations(start: string, end: string, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<Array<SchedulablePrestationDto>>;
  public schedulablePrestations(start: string, end: string, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<Array<SchedulablePrestationDto>>>;
  public schedulablePrestations(start: string, end: string, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<Array<SchedulablePrestationDto>>>;
  public schedulablePrestations(start: string, end: string, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {
    if (start === null || start === undefined) {
      throw new Error('Required parameter start was null or undefined when calling schedulablePrestations.');
    }
    if (end === null || end === undefined) {
      throw new Error('Required parameter end was null or undefined when calling schedulablePrestations.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/prestation/schedulable/from/${this.configuration.encodeParam({
      name: "start",
      value: start,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "date"
    })}/to/${this.configuration.encodeParam({
      name: "end",
      value: end,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "date"
    })}`;
    return this.httpClient.request<Array<SchedulablePrestationDto>>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param start
   * @param end
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public summaries(start: string, end: string, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<Array<PrestationSummaryDto>>;
  public summaries(start: string, end: string, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<Array<PrestationSummaryDto>>>;
  public summaries(start: string, end: string, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<Array<PrestationSummaryDto>>>;
  public summaries(start: string, end: string, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {
    if (start === null || start === undefined) {
      throw new Error('Required parameter start was null or undefined when calling summaries.');
    }
    if (end === null || end === undefined) {
      throw new Error('Required parameter end was null or undefined when calling summaries.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/prestation/summaries/from/${this.configuration.encodeParam({
      name: "start",
      value: start,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "date"
    })}/to/${this.configuration.encodeParam({
      name: "end",
      value: end,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: "date"
    })}`;
    return this.httpClient.request<Array<PrestationSummaryDto>>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param prestationId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public switchBap(prestationId: number, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any>;
  public switchBap(prestationId: number, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpResponse<any>>;
  public switchBap(prestationId: number, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpEvent<any>>;
  public switchBap(prestationId: number, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any> {
    if (prestationId === null || prestationId === undefined) {
      throw new Error('Required parameter prestationId was null or undefined when calling switchBap.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/prestation/${this.configuration.encodeParam({
      name: "prestationId",
      value: prestationId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int64"
    })}/switchBap`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param prestationId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public switchUrgent(prestationId: number, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any>;
  public switchUrgent(prestationId: number, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpResponse<any>>;
  public switchUrgent(prestationId: number, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpEvent<any>>;
  public switchUrgent(prestationId: number, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any> {
    if (prestationId === null || prestationId === undefined) {
      throw new Error('Required parameter prestationId was null or undefined when calling switchUrgent.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/prestation/${this.configuration.encodeParam({
      name: "prestationId",
      value: prestationId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int64"
    })}/switchUrgent`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param prestationId
   * @param locationAndContactsDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateLocationAndContacts(prestationId: number, locationAndContactsDto: LocationAndContactsDto, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any>;
  public updateLocationAndContacts(prestationId: number, locationAndContactsDto: LocationAndContactsDto, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpResponse<any>>;
  public updateLocationAndContacts(prestationId: number, locationAndContactsDto: LocationAndContactsDto, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpEvent<any>>;
  public updateLocationAndContacts(prestationId: number, locationAndContactsDto: LocationAndContactsDto, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any> {
    if (prestationId === null || prestationId === undefined) {
      throw new Error('Required parameter prestationId was null or undefined when calling updateLocationAndContacts.');
    }
    if (locationAndContactsDto === null || locationAndContactsDto === undefined) {
      throw new Error('Required parameter locationAndContactsDto was null or undefined when calling updateLocationAndContacts.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/prestation/${this.configuration.encodeParam({
      name: "prestationId",
      value: prestationId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int64"
    })}/locationAndContacts`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: locationAndContactsDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param prestationId
   * @param updateScheduleDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updatePrestationSchedule(prestationId: number, updateScheduleDto: UpdateScheduleDto, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any>;
  public updatePrestationSchedule(prestationId: number, updateScheduleDto: UpdateScheduleDto, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpResponse<any>>;
  public updatePrestationSchedule(prestationId: number, updateScheduleDto: UpdateScheduleDto, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpEvent<any>>;
  public updatePrestationSchedule(prestationId: number, updateScheduleDto: UpdateScheduleDto, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any> {
    if (prestationId === null || prestationId === undefined) {
      throw new Error('Required parameter prestationId was null or undefined when calling updatePrestationSchedule.');
    }
    if (updateScheduleDto === null || updateScheduleDto === undefined) {
      throw new Error('Required parameter updateScheduleDto was null or undefined when calling updatePrestationSchedule.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/prestation/${this.configuration.encodeParam({
      name: "prestationId",
      value: prestationId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int64"
    })}/schedule`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: updateScheduleDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param prestationId
   * @param type
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updatePrestationType(prestationId: number, type: string, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any>;
  public updatePrestationType(prestationId: number, type: string, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpResponse<any>>;
  public updatePrestationType(prestationId: number, type: string, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpEvent<any>>;
  public updatePrestationType(prestationId: number, type: string, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any> {
    if (prestationId === null || prestationId === undefined) {
      throw new Error('Required parameter prestationId was null or undefined when calling updatePrestationType.');
    }
    if (type === null || type === undefined) {
      throw new Error('Required parameter type was null or undefined when calling updatePrestationType.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/prestation/${this.configuration.encodeParam({
      name: "prestationId",
      value: prestationId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int64"
    })}/type/${this.configuration.encodeParam({
      name: "type",
      value: type,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param prestationId
   * @param prestationTravelMutationRequestDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateTravels(prestationId: number, prestationTravelMutationRequestDto: PrestationTravelMutationRequestDto, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any>;
  public updateTravels(prestationId: number, prestationTravelMutationRequestDto: PrestationTravelMutationRequestDto, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpResponse<any>>;
  public updateTravels(prestationId: number, prestationTravelMutationRequestDto: PrestationTravelMutationRequestDto, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpEvent<any>>;
  public updateTravels(prestationId: number, prestationTravelMutationRequestDto: PrestationTravelMutationRequestDto, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any> {
    if (prestationId === null || prestationId === undefined) {
      throw new Error('Required parameter prestationId was null or undefined when calling updateTravels.');
    }
    if (prestationTravelMutationRequestDto === null || prestationTravelMutationRequestDto === undefined) {
      throw new Error('Required parameter prestationTravelMutationRequestDto was null or undefined when calling updateTravels.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/prestation/${this.configuration.encodeParam({
      name: "prestationId",
      value: prestationId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int64"
    })}/travels`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: prestationTravelMutationRequestDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param prestationId
   * @param prestationValidationRequestDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public validate(prestationId: number, prestationValidationRequestDto: PrestationValidationRequestDto, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any>;
  public validate(prestationId: number, prestationValidationRequestDto: PrestationValidationRequestDto, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpResponse<any>>;
  public validate(prestationId: number, prestationValidationRequestDto: PrestationValidationRequestDto, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpEvent<any>>;
  public validate(prestationId: number, prestationValidationRequestDto: PrestationValidationRequestDto, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any> {
    if (prestationId === null || prestationId === undefined) {
      throw new Error('Required parameter prestationId was null or undefined when calling validate.');
    }
    if (prestationValidationRequestDto === null || prestationValidationRequestDto === undefined) {
      throw new Error('Required parameter prestationValidationRequestDto was null or undefined when calling validate.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/prestation/${this.configuration.encodeParam({
      name: "prestationId",
      value: prestationId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int64"
    })}/validate`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: prestationValidationRequestDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
