import {RepitEnum} from "../common/RepitEnum";

export namespace PrestationTypeEnum {
  export class PrestationType extends RepitEnum {
    constructor(value: string, label: string) {
      super(value, label);
    }
  }

  export const CARE = new PrestationType("CARE", "Garde");
  export const HOSPITAL_CARE = new PrestationType("HOSPITAL_CARE", "Garde hôpital");
  export const CARNET_DE_BORD = new PrestationType("CARNET_DE_BORD", "Carnet de bord");
  export const URGENT_CARE = new PrestationType("URGENT_CARE", "Garde urgence");
  export const URGENT_HOSPITAL_CARE = new PrestationType("URGENT_HOSPITAL_CARE", "Garde hôpital urgence");
  export const URGENT_CARNET_DE_BORD = new PrestationType("URGENT_CARNET_DE_BORD", "Carnet de bord urgence");


  export const VALUES: PrestationType[] = [
    CARE,
    HOSPITAL_CARE,
    CARNET_DE_BORD,
    URGENT_CARE,
    URGENT_HOSPITAL_CARE,
    URGENT_CARNET_DE_BORD,
  ];

  const BY_VALUE = new Map<string, PrestationType>();
  VALUES.forEach(item => BY_VALUE.set(item.value, item));

  export function fromValue(value: string): PrestationType {
    return BY_VALUE.get(value);
  }
}