import {Component, OnInit} from '@angular/core';
import {PageEvent} from "@angular/material/paginator";
import {BeneficiarySummaryDto} from "../../../../generated/api";
import {BeneficiaryService} from "../../../models/beneficiary/beneficiary.service";
import {sortByLastNameAndFirstName} from "../../../models/person/PersonUtils";

@Component({
  selector: 'app-list-beneficiary',
  templateUrl: './list-beneficiary.component.html',
  styleUrls: ['./list-beneficiary.component.scss']
})
export class ListBeneficiaryComponent implements OnInit {

  beneficiaries: BeneficiarySummaryDto[] = [];

  textFilter: string = "";
  bapFilter: boolean = true;
  notBapFilter: boolean = true;
  archivedFilter: boolean = false;


  pageIndex = 0;
  pageSize = 20;

  constructor(private findBeneficiaryService: BeneficiaryService) {
  }

  ngOnInit(): void {
    this.findBeneficiaryService.summaries().subscribe(
      beneficiaries => this.beneficiaries = beneficiaries
    );
  }

  filteredBeneficiaries(): BeneficiarySummaryDto[] {
    return this.beneficiaries
      .filter(beneficiary => this.filterName(beneficiary))
      .filter(beneficiary => this.filterBap(beneficiary))
      .filter(beneficiary => this.filterArchived(beneficiary))
      .sort(sortByLastNameAndFirstName)
  }

  private filterName(beneficiary: BeneficiarySummaryDto) {
    return (beneficiary.firstName + " " + beneficiary.lastName).toLowerCase()
      .includes(this.textFilter.toLowerCase());
  }

  private filterBap(beneficiary: BeneficiarySummaryDto) {
    return this.bapFilter && beneficiary.bap ||
      this.notBapFilter && !beneficiary.bap
  }

  private filterArchived(beneficiary: BeneficiarySummaryDto) {
    return this.archivedFilter || beneficiary.active;
  }

  refreshPagination($event: PageEvent) {
    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
  }

  paginatedBeneficiaries(): BeneficiarySummaryDto[] {
    const end = (this.pageIndex + 1) * this.pageSize;
    const start = this.pageIndex * this.pageSize;
    return this.filteredBeneficiaries().slice(start, end)
  }

  hasLastPrestation(beneficiary: BeneficiarySummaryDto) {
    return !!beneficiary.lastPrestation
  }

  hasNextPrestation(beneficiary: BeneficiarySummaryDto) {
    return !!beneficiary.nextPrestation
  }

  toggleBap() {
    this.bapFilter = !this.bapFilter
  }

  toggleNotBap() {
    this.notBapFilter = !this.notBapFilter
  }

}
