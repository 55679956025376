import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {PageEvent} from "@angular/material/paginator";
import {MatDialog} from "@angular/material/dialog";
import {ArchiveBeneficiaryDialogComponent} from "../archive-beneficiary-dialog/archive-beneficiary-dialog.component";
import {EditContactComponent} from "../edit-contact/edit-contact.component";
import {BeneficiaryService} from "../../../models/beneficiary/beneficiary.service";
import {BeneficiaryContactDto, BeneficiaryDetailDto, BeneficiaryPrestationSummaryDto} from "../../../../generated/api";

@Component({
  selector: 'app-detail-beneficiary',
  templateUrl: './detail-beneficiary.component.html',
  styleUrls: ['./detail-beneficiary.component.scss']
})
export class DetailBeneficiaryComponent implements OnInit {

  id: number;
  beneficiary: BeneficiaryDetailDto = null;
  prestations: BeneficiaryPrestationSummaryDto[] = null;
  contacts: BeneficiaryContactDto[] = null;

  prestationsPageIndex = 0;
  prestationsPageSize = 20;

  isEditingSummary = false;
  text: string;

  constructor(private route: ActivatedRoute, private beneficiaryService: BeneficiaryService, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.beneficiaryService.detail(this.id)
      .subscribe(response => this.beneficiary = response)
    // this might be lazily loaded
    this.beneficiaryService.fetchPrestations(this.id)
      .subscribe(prestations => this.prestations = prestations)
    // this might be lazily loaded
    this.beneficiaryService.fetchContacts(this.id)
      .subscribe(contacts => this.contacts = contacts)
  }

  isBeneficiaryLoaded() {
    return this.beneficiary != null;
  }

  arePrestationsLoaded() {
    return this.prestations != null;
  }

  areContactsLoaded() {
    return this.contacts != null;
  }

  paginatedPrestations() {
    const end = (this.prestationsPageIndex + 1) * this.prestationsPageSize;
    const start = this.prestationsPageIndex * this.prestationsPageSize;
    return this.prestations
      .sort((a, b) => a.schedule.startTime > b.schedule.startTime ? -1 : 1)
      .slice(start, end)
  }

  refreshPagination($event: PageEvent) {
    this.prestationsPageIndex = $event.pageIndex;
    this.prestationsPageSize = $event.pageSize;
  }

  prestationScheduleStatus(prestation: BeneficiaryPrestationSummaryDto) {
    let now = new Date();
    if (new Date(prestation.schedule.startTime) > now) {
      return "A venir - ";
    }
    if (new Date(prestation.schedule.endTime) < now) {
      return "Passée - "
    }
    return "En cours - "
  }

  sortedContacts(): BeneficiaryContactDto[] {
    return this.contacts
      .sort((a, b) => {
        if (a.prestationPrimaryContact) return -1
        if (b.prestationPrimaryContact) return 1
        return 0
      })
  }

  makePrestationPrimary(contact: BeneficiaryContactDto) {
    this.beneficiaryService.makePrestationPrimaryContact(this.id, contact.id).subscribe(
      contacts => this.contacts = contacts
    );
  }

  editContact(contact: BeneficiaryContactDto): void {
    const dialogRef = this.dialog.open(EditContactComponent, {data: contact})
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // TODO RHA add me
      }
    });

  }

  cancelEdition() {
    this.isEditingSummary = false;
  }

  startEditing() {
    this.isEditingSummary = true;
    this.text = this.beneficiary.generalInfo.summary
  }

  confirmEdition() {
    this.beneficiary.generalInfo.summary = this.text;
    this.isEditingSummary = false;
    this.beneficiaryService.updateSummary(this.id, this.text).subscribe(
      response => this.beneficiary = response
    )
  }

  openArchivePopup() {
    return this.beneficiaryService.canBeArchived(this.id).subscribe(
      canBeArchived => {
        const dialogRef = this.dialog.open(ArchiveBeneficiaryDialogComponent, {data: {canBeArchived: canBeArchived}})
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.beneficiaryService.archive(this.id)
              .subscribe(response => this.beneficiary = response);
          }
        });
      }
    );
  }

  reactivate() {
    this.beneficiaryService.reactivate(this.id)
      .subscribe(response => this.beneficiary = response);
  }

  makeInvoicePrimary(contact: BeneficiaryContactDto) {
    this.beneficiaryService.makeInvoicePrimaryContact(this.id, contact.id).subscribe(
      contacts => this.contacts = contacts
    );
  }
}
