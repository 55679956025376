import {PrestationEvent} from "../models/scheduler/PrestationEvent";
import {RepitWorker} from "../models/scheduler/RepitWorker";
import {SchedulerBeneficiary} from "../models/scheduler/SchedulerBeneficiary";
import {SchedulerDateAndTime} from "../models/scheduler/SchedulerDateAndTime";
import {Week} from "../models/scheduler/Week";
import {TimeOffEvent} from "../models/scheduler/TimeOffEvent";
import {SchedulerEvent} from "../models/scheduler/SchedulerEvent";


const A_WORKER: RepitWorker = {id: 1, firstName: "John", lastName: "Doe"}
const A_BENEFICIARY: SchedulerBeneficiary = new SchedulerBeneficiary(1, "Roger", "Bonpain")
const ANOTHER_BENEFICIARY: SchedulerBeneficiary = new SchedulerBeneficiary(2, "Hannah", "Belle")

let day = Week.current().getDaysOfPeriod()[0];
let oneWeekLater = Week.current().getNextPeriod().getDaysOfPeriod()[0];

let aPrestation = PrestationEvent.scheduled(1, A_BENEFICIARY, SchedulerDateAndTime.timeRangeBetween(day, "09:00", "12:00"), A_WORKER.id);
let anotherPrestation = PrestationEvent.scheduled(2, ANOTHER_BENEFICIARY, SchedulerDateAndTime.timeRangeBetween(oneWeekLater, "09:00", "12:00"), A_WORKER.id);
let aTimeOff = TimeOffEvent.scheduled(1, SchedulerDateAndTime.timeRangeBetween(day, "13:00", "17:00"), A_WORKER.id)

export const WORK_EVENTS: SchedulerEvent[] = [
  aPrestation,
  anotherPrestation,
  aTimeOff,
]