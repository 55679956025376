<div>
    <div>
        <span style="font-weight: bold">Educateur : </span><span>{{ prestation().educator | personInfo }}</span>
    </div>
    <div>
        <span style="font-weight: bold">Bénéficiaire : </span><span>{{ prestation().beneficiary | personInfo }}</span>
    </div>
    <div>
        <span style="font-weight: bold">Validé à : </span><span>{{ prestation().prestationValidation.validatedAt | date:"dd/MM/YYYY HH:mm" }}</span>
    </div>

    <form [formGroup]="form">
        <div class="border border-2 rounded m-2 p-2">
            <div class="d-flex gap-2">
                <h2>Horaire</h2>
                <mat-icon *ngIf="prestation().prestationValidation.hasCorrectSchedule; else correctScheduleBlock"
                          class="success-icon" matTooltip="L'éducateur a confirmé l'horaire">check_circle
                </mat-icon>
                <ng-template #correctScheduleBlock>
                    <mat-icon class="warning-icon" matTooltip="L'éducateur a modifié l'horaire">warning</mat-icon>
                </ng-template>
            </div>
            <div *ngIf="!updatingSchedule(); else writableScheduleBlock">
                <div class="d-flex gap-1 align-items-center">
                    <span style="font-weight: bold">Date : </span>
                    <span>{{ prestation().schedule.day | schedulerDay }}</span>
                    <mat-icon *ngIf="isOriginalScheduleCoveringTwoDays()" class="warning-icon"
                              matTooltip="La prestation couvre 2 jours">
                        warning
                    </mat-icon>
                    <div *ngIf="!prestation().prestationValidation.hasCorrectSchedule"
                         class="d-flex gap-1 align-items-center">
                        <mat-icon>arrow_forward</mat-icon>
                        <span class="validated-by-educator">{{ prestation().prestationValidation.schedule.start() | date:"E dd/MM/yyyy" }}</span>
                        <mat-icon *ngIf="isPrestationValidationCoveringTwoDays()" class="warning-icon"
                                  matTooltip="La prestation couvre 2 jours">
                            warning
                        </mat-icon>
                    </div>
                </div>
                <div class="d-flex gap-1 align-items-center">
                    <span style="font-weight: bold">Horaire : </span>
                    <span>{{ prestation().schedule.timePeriod | schedulerTimePeriod: true }}</span>
                    <div *ngIf="!prestation().prestationValidation.hasCorrectSchedule"
                         class="d-flex gap-1 align-items-center">
                        <mat-icon>arrow_forward</mat-icon>
                        <span class="validated-by-educator">{{ prestation().prestationValidation.schedule.timePeriod | schedulerTimePeriod: true }}</span>
                    </div>
                </div>
                <div class="btn btn-secondary d-flex" (click)="updatingSchedule.set(true)">
                    <mat-icon>edit</mat-icon>
                    Modifier l'horaire
                </div>
            </div>
            <ng-template #writableScheduleBlock>
                <div class="d-flex flex-column gap-2">
                    <div class="d-flex gap-2 align-items-center">
                        <div class="flex-grow-1 d-flex gap-2">
                            <span style="font-weight: bold">Début : </span><span>{{ prestation().schedule.day | schedulerDay }}</span>
                        </div>
                        <app-clearable-input-text label="Début" inputClass="small-input" controlName="startTime"
                                                  hint="HH:mm (10:00)"></app-clearable-input-text>
                    </div>
                    <div class="d-flex gap-2 align-items-center">
                        <div class="flex-grow-1 d-flex gap-2">
                            <span style="font-weight: bold">Fin : </span><span>{{ computeEndDay() | schedulerDay }}</span>
                            <mat-icon *ngIf="isCoveringTwoDays()" class="warning-icon"
                                      matTooltip="La prestation couvre 2 jours">
                                warning
                            </mat-icon>
                        </div>
                        <app-clearable-input-text label="Fin" inputClass="small-input" controlName="endTime"
                                                  hint="HH:mm (13:00)"></app-clearable-input-text>
                    </div>
                </div>
            </ng-template>
        </div>
        <div class="border border-2 rounded m-2 p-2">
            <div class="d-flex gap-2">
                <h2>Déplacements</h2>
                <mat-icon
                        *ngIf="!prestation().prestationValidation.hasTravelDuringPrestation; else travelDuringPrestationBlock"
                        class="success-icon" matTooltip="L'éducateur n'a pas renseigné de déplacement">check_circle
                </mat-icon>
                <ng-template #travelDuringPrestationBlock>
                    <mat-icon class="warning-icon" matTooltip="L'éducateur a renseigné des déplacements">warning
                    </mat-icon>
                </ng-template>
            </div>
            <app-update-prestation-travels formGroupName="travels"
                                           [educatorDescription]="this.prestation().prestationValidation.travelDescription"></app-update-prestation-travels>
        </div>

        <div *ngIf="prestation().prestationValidation.note">
            <span style="font-weight: bold">Note : </span><span>{{ prestation().prestationValidation.note }}</span>
        </div>

        <div class="d-flex gap-2 mt-lg-2 justify-content-end">
            <button class="btn btn-secondary" *ngIf="showCancel()" (click)="cancel()">{{ cancelText() }}</button>
            <button class="btn btn-primary" [disabled]="!isValid()" (click)="closePrestation()">Clôturer</button>
        </div>
    </form>

</div>