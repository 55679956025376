export class User {
  private readonly _id: number;
  private readonly _personId: number;
  private readonly _staffId: number;
  private readonly _educatorId: number;
  private readonly _firstName: string;
  private readonly _lastName: string;
  private readonly _email: string;
  private readonly _roles: string[];


  constructor(id: number, personId: number, staffId: number, educatorId: number, firstName: string, lastName: string, email: string, roles: string[]) {
    this._id = id;
    this._personId = personId;
    this._staffId = staffId;
    this._educatorId = educatorId;
    this._firstName = firstName;
    this._lastName = lastName;
    this._email = email;
    this._roles = roles;
  }

  get id(): number {
    return this._id;
  }

  get firstName(): string {
    return this._firstName;
  }

  get lastName(): string {
    return this._lastName;
  }

  get email(): string {
    return this._email;
  }

  get personId(): number {
    return this._personId;
  }

  get staffId(): number {
    return this._staffId;
  }

  get educatorId(): number {
    return this._educatorId;
  }

  get roles(): string[] {
    return this._roles;
  }

  isAdmin(): boolean {
    return this._roles.includes('repit_admin');
  }

  isEducator(): boolean {
    return this._roles.includes('repit_educator');
  }

  isAS(): boolean {
    return this._roles.includes('repit_as');
  }
}
