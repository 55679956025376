<h1>Mon compte</h1>

<div class="general-bg bg-white" *ngIf="loaded()">
  <h2>Informations Générales</h2>
  <div>
      <span style="font-weight: bold">Prénom : </span><span>{{ user().firstName }}</span>
  </div>
  <div>
      <span style="font-weight: bold">Nom : </span><span>{{ user().lastName }}</span>
  </div>
  <div>
      <span style="font-weight: bold">Email : </span><span>{{ user().email }}</span>
  </div>
</div>


<div class="general-bg bg-white d-flex gap-2">
  <button class="btn btn-primary">
    Modifier mon mot de passe
  </button>
</div>
