import {Component, computed, inject, Signal, signal} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {MatDialog} from "@angular/material/dialog";
import {
  CreateStaffActivityRequestDialogComponent
} from "../create-staff-activity-request-dialog/create-staff-activity-request-dialog.component";
import {StaffActivityTypeEnum} from "../../../models/staff/activity/StaffActivityType";
import {StaffActivityRequestStatusEnum} from "../../../models/staff/activity/StaffActivityRequestStatus";
import {StaffActivityRequestFilter, StaffState} from "../../../stores/staff/staff.state";
import {StaffActivityRequest} from "../../../models/staff/activity/StaffActivityRequest";
import {toObservable, toSignal} from "@angular/core/rxjs-interop";
import {Store} from "@ngxs/store";
import {FindMyStaffActivityRequests} from "../../../stores/staff/staff.actions";
import {map} from "rxjs";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {PrestationStatusIconComponent} from "../../prestation/prestation-status-icon/prestation-status-icon.component";
import {PrestationStatusEnum} from "../../../models/prestation/PrestationStatusEnum";
import {FormsModule} from "@angular/forms";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {PersonInfoPipe} from "../../pipe/person-info.pipe";
import {SchedulerDayPipe} from "../../pipe/scheduler-day.pipe";
import {SchedulerTimePeriodPipe} from "../../pipe/scheduler-time-period.pipe";
import {RouterLink} from "@angular/router";
import StaffActivityType = StaffActivityTypeEnum.StaffActivityType;
import StaffActivityRequestStatus = StaffActivityRequestStatusEnum.StaffActivityRequestStatus;

@Component({
  selector: 'app-list-staff-activity-request',
  standalone: true,
  imports: [
    MatIcon,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
    PrestationStatusIconComponent,
    FormsModule,
    MatPaginator,
    NgIf,
    PersonInfoPipe,
    SchedulerDayPipe,
    SchedulerTimePeriodPipe,
    DatePipe,
    RouterLink
  ],
  templateUrl: './list-staff-activity-request.component.html',
  styleUrl: './list-staff-activity-request.component.scss'
})
export class ListStaffActivityRequestComponent {
  store = inject(Store);

  typeFilter = signal<StaffActivityType[]>([]);
  statusFilter = signal<StaffActivityRequestStatus[]>([]);

  filter = computed(() => ({
    type: this.typeFilter(),
    status: this.statusFilter()
  } as StaffActivityRequestFilter))

  pageIndex = signal(0);
  pageSize = signal(20);

  requests: Signal<StaffActivityRequest[]> = toSignal(this.store.select(StaffState.myActivityRequests)
    .pipe(
      map((requests) => requests.sort((a, b) => a.startDate.getTime() - b.startDate.getTime()))
    )
  )
  pagedRequests = computed(() => {
    return this.requests().slice(this.pageIndex() * this.pageSize(), (this.pageIndex() + 1) * this.pageSize())
  })

  constructor(public dialog: MatDialog) {
    const filterFromState = this.store.selectSnapshot(StaffState.myActivityRequestFilter);
    this.typeFilter.set(filterFromState.type);
    this.statusFilter.set(filterFromState.status);
    toObservable(this.filter).subscribe(filter => {
      this.store.dispatch(new FindMyStaffActivityRequests(this.filter()));
    })
  }

  create() {
    this.dialog.open(CreateStaffActivityRequestDialogComponent);
  }

  refreshPagination($event: PageEvent) {
    this.pageIndex.set($event.pageIndex);
    this.pageSize.set($event.pageSize);
  }

  protected readonly PrestationStatusEnum = PrestationStatusEnum;
  protected readonly StaffActivityRequestStatusEnum = StaffActivityRequestStatusEnum;
  protected readonly StaffActivityTypeEnum = StaffActivityTypeEnum;
}
