import {SchedulerTimePeriod} from "./SchedulerTimePeriod";
import {SchedulerDay} from "./SchedulerDay";
import {addHours, addMinutes} from "date-fns";

export class SchedulerDateAndTime {
  private readonly _day: SchedulerDay;
  private readonly _timePeriod: SchedulerTimePeriod;

  constructor(day: SchedulerDay, timePeriod: SchedulerTimePeriod) {
    this._day = day;
    this._timePeriod = timePeriod;
  }


  get day(): SchedulerDay {
    return this._day;
  }

  get timePeriod(): SchedulerTimePeriod {
    return this._timePeriod;
  }

  public start(): Date {
    let date = new Date(this.day.date);
    date.setHours(this.timePeriod.startTime.hour)
    date.setMinutes(this.timePeriod.startTime.minute)
    return date;
  }

  public end(): Date {
    let startDate = this.start();
    let end = addHours(startDate, this.timePeriod.durationHour)
    return addMinutes(end, this.timePeriod.durationMinute)
  }

  isCoveringTwoDays(): boolean {
    return this.start().getDay() !== this.end().getDay();
  }

  includesDay(day: SchedulerDay): boolean {
    return this._day.date <= day.date
      && this._day.atEndOfPeriod(this._timePeriod).date >= day.date
  }

  public static timeRangeBetween(day: SchedulerDay, start: string, end: string): SchedulerDateAndTime {
    return new SchedulerDateAndTime(day, SchedulerTimePeriod.betweenHoursAsString(start, end));
  }

  public static between(start: Date, end: Date): SchedulerDateAndTime {
    return new SchedulerDateAndTime(new SchedulerDay(start),
      SchedulerTimePeriod.betweenDates(start, end))
  }
}
