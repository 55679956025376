<h1>Horaire</h1>

<div class="mb-4">
  <div class="general-bg bg-white">

    <div class="d-flex gap-2 align-content-center align-items-center justify-content-center">

      <button class="btn btn-secondary arrow-button d-inline" (click)="previousWeek()">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <h2>Semaine: {{this.scheduler.getPeriod().getFirstDayOfPeriod().date | date:"dd/MM/YYYY"}}
        - {{this.scheduler.getPeriod().getLastDayOfPeriod().date | date:"dd/MM/YYYY"}}</h2>
      <button class="btn btn-secondary arrow-button d-inline" (click)="nextWeek()">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>

    </div>
  </div>

  <div class="table-scheduler">
    <table>
      <thead>
      <tr>
        <th>Educateur \ Jour</th>
        <th *ngFor="let day of scheduler.getPeriod().getDaysOfPeriod()">{{day.date | date:"E dd/MM/YYYY"}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let repitWorker of scheduler.getWorkers()">
        <td>{{repitWorker.firstName}} {{repitWorker.lastName}}</td>
        <td *ngFor="let day of scheduler.getPeriod().getDaysOfPeriod()" dndDropzone
            (dndDrop)="schedule($event, day, repitWorker)">
          <div *ngFor="let event of scheduler.getWorkDay(day, repitWorker).events"
               [dndDraggable]="event.id" [dndDisableIf]="!event.isAssignable()">
            <app-prestation-event-card *ngIf="isPrestationEvent(event)"
                                       [prestationEvent]="castToPrestationEvent(event)"></app-prestation-event-card>
          </div>
        </td>
      </tr>
      <tr>
        <td>Prestations non assignées</td>
        <td *ngFor="let day of scheduler.getPeriod().getDaysOfPeriod()" dndDropzone
            (dndDrop)="removeAssignee($event)">
          <div *ngFor="let event of scheduler.getUnscheduledEventsOn(day)"
               [dndDraggable]="event.id">
            <app-prestation-event-card *ngIf="isPrestationEvent(event)"
                                       [prestationEvent]="castToPrestationEvent(event)"></app-prestation-event-card>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

  </div>

</div>

<div class="general-bg bg-white">
  <h2 class="mb-4">Créer une prestation</h2>
  <mat-form-field appearance="fill">
    <mat-label>Bénéficiaire</mat-label>
    <mat-select [(ngModel)]="newPrestation.beneficiary" required>
        <input type="search" class="select-filter" [(ngModel)]="beneficiaryFilter" placeholder="Rechercher">
      <mat-option *ngFor="let beneficiary of getFilteredBeneficiaries()" [value]="beneficiary">
        {{beneficiary.personInfo | personInfo}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Date</mat-label>
    <input matInput required [matDatepicker]="picker" [(ngModel)]="newPrestation.startDate">
    <mat-hint>JJ/MM/AAAA</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Début</mat-label>
    <input matInput required type="text" [(ngModel)]="newPrestation.startTime">
    <mat-hint>HH:mm (10:00)</mat-hint>
    <button *ngIf="newPrestation.startTime" matSuffix mat-icon-button aria-label="Clear"
            (click)="newPrestation.startTime=''" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Fin</mat-label>
    <input matInput required type="text" [(ngModel)]="newPrestation.endTime">
    <mat-hint>HH:mm (13:00)</mat-hint>
    <button *ngIf="newPrestation.endTime" matSuffix mat-icon-button aria-label="Clear"
            (click)="newPrestation.endTime=''" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <div>
    <button class="btn btn-primary" type="submit" (click)="createNewPrestation()">
      Créer la prestation
    </button>
  </div>
</div>
