import {ActivityReportGateway} from "../../ports/ActivityReportGateway";
import {Year} from "../../models/calendar/Year";
import {map, Observable, of} from "rxjs";
import {
  DailyReport,
  DailyReportDetail,
  WeeklyReport,
  WeeklyReportDetail,
  YearlyReport
} from "../../models/activity-report/activity-report";
import {StaffActivityReportControllerService} from "../../../generated/api";
import {inject} from "@angular/core";
import {fd} from "../../models/time/TimeUtils";
import {StaffTypeEnum} from "../../models/staff/StaffType.enum";
import {ActivityReportTypeEnum} from "../../models/activity-report/ActivityReportType.enum";

export class HttpActivityReportGateway extends ActivityReportGateway {

  staffActivityReportController = inject(StaffActivityReportControllerService)

  findActivityReport(staffMemberId: number, year: Year): Observable<YearlyReport> {
    return of({
      year: year,
      weeks: WEEKS_DATA
    });
  }

  findWeeklyReport(staffMemberId: number, weekNumber: number): Observable<WeeklyReportDetail> {
    return of(WEEK_DETAIL);
  }

  findDailyReport(staffMemberId: number, date: Date): Observable<DailyReportDetail> {
    return this.staffActivityReportController.findDailyReport(staffMemberId, fd(date))
      .pipe(map(dailyReport => {
        return {
          date: new Date(dailyReport.date),
          workedHours: this.durationFromMinutes(dailyReport.workedMinutes),
          otherValuedHours: this.durationFromMinutes(dailyReport.otherValuedMinutes),
          totalValuedHours: this.durationFromMinutes(dailyReport.totalValuedMinutes),
          expectedHours: this.durationFromMinutes(dailyReport.expectedMinutes),
          dailyDifference: this.durationFromMinutes(dailyReport.dailyDifferenceInMinutes),
          activities: dailyReport.lines.map(line => {
            return {
              id: line.id,
              index: line.index,
              staffMember: {
                id: line.staffMember.id,
                firstName: line.staffMember.personInfo.firstName,
                lastName: line.staffMember.personInfo.lastName,
                active: line.staffMember.active,
                type: StaffTypeEnum.fromValue(line.staffMember.type.value)
              },
              duration: this.durationFromMinutes(line.durationInMinutes),
              type: ActivityReportTypeEnum.fromValue(line.type),
              valued: line.valued,
              data: null
            }
          })
        }
      }));
  }

  private durationFromMinutes(minutes: number) {
    return {minutes: minutes};
  }
}

const WEEKS_DATA: WeeklyReport[] = [
  {
    weekNumber: 1,
    weekStartDate: new Date('2021-01-01'),
    weekEndDate: new Date('2021-01-07'),
    workedHours: 34,
    otherValuedHours: 3,
    totalValuedHours: 37,
    expectedHours: 38,
    weeklyDifference: -1,
    yearlyDifference: -1
  },
  {
    weekNumber: 2,
    weekStartDate: new Date('2021-01-08'),
    weekEndDate: new Date('2021-01-14'),
    workedHours: 40,
    otherValuedHours: 0,
    totalValuedHours: 40,
    expectedHours: 38,
    weeklyDifference: 2,
    yearlyDifference: 1
  }
]

const DAYS_DATA: DailyReport[] = [
  {
    date: new Date('2021-01-01'),
    workedHours: {minutes: 480},
    otherValuedHours: {minutes: 0},
    totalValuedHours: {minutes: 480},
    expectedHours: {minutes: 456},
    dailyDifference: {minutes: 24}
  },
  {
    date: new Date('2021-01-02'),
    workedHours: {minutes: 480},
    otherValuedHours: {minutes: 0},
    totalValuedHours: {minutes: 480},
    expectedHours: {minutes: 456},
    dailyDifference: {minutes: 24}
  },
  {
    date: new Date('2021-01-03'),
    workedHours: {minutes: 480},
    otherValuedHours: {minutes: 0},
    totalValuedHours: {minutes: 480},
    expectedHours: {minutes: 456},
    dailyDifference: {minutes: 24}
  },
  {
    date: new Date('2021-01-04'),
    workedHours: {minutes: 480},
    otherValuedHours: {minutes: 0},
    totalValuedHours: {minutes: 480},
    expectedHours: {minutes: 456},
    dailyDifference: {minutes: 24}
  },
  {
    date: new Date('2021-01-05'),
    workedHours: {minutes: 120},
    otherValuedHours: {minutes: 180},
    totalValuedHours: {minutes: 300},
    expectedHours: {minutes: 456},
    dailyDifference: {minutes: -156}
  },
  {
    date: new Date('2021-01-06'),
    workedHours: {minutes: 0},
    otherValuedHours: {minutes: 0},
    totalValuedHours: {minutes: 0},
    expectedHours: {minutes: 0},
    dailyDifference: {minutes: 0}
  },
  {
    date: new Date('2021-01-07'),
    workedHours: {minutes: 0},
    otherValuedHours: {minutes: 0},
    totalValuedHours: {minutes: 0},
    expectedHours: {minutes: 0},
    dailyDifference: {minutes: 0}
  }
]

const WEEK_DETAIL: WeeklyReportDetail = {
  weekNumber: 1,
  weekStartDate: new Date('2021-01-01'),
  weekEndDate: new Date('2021-01-07'),
  workedHours: 34,
  otherValuedHours: 3,
  totalValuedHours: 37,
  expectedHours: 38,
  weeklyDifference: -1,
  yearlyDifference: -1,
  days: DAYS_DATA
}
