import {Period} from "./Period";
import {Week} from "./Week";
import {WorkerFetcher} from "./WorkerFetcher";
import {RepitWorker} from "./RepitWorker";
import {SchedulerEventFetcher} from "./SchedulerEventFetcher";
import {SchedulerEvent} from "./SchedulerEvent";
import {SchedulerDay} from "./SchedulerDay";
import {SchedulerWorkDay} from "./SchedulerWorkDay";

export class Scheduler {
  private period: Period;
  private workerFetcher: WorkerFetcher;
  private eventFetcher:SchedulerEventFetcher;
  private repitWorkers: RepitWorker[] = [];
  private events:SchedulerEvent[] = [];


  constructor(period: Period, workerFetcher: WorkerFetcher, eventFetcher: SchedulerEventFetcher) {
    this.period = period;
    this.workerFetcher = workerFetcher;
    this.eventFetcher = eventFetcher;
    this.fetchData();
  }

  private fetchData() {
    this.workerFetcher.fetch(this.period).subscribe(workers => this.repitWorkers = workers)
    this.eventFetcher.fetch(this.period).subscribe(events => this.events = events)
  }

  static forCurrentWeek(workerFetcher: WorkerFetcher, eventFetcher: SchedulerEventFetcher): Scheduler {
    return new Scheduler(Week.current(), workerFetcher, eventFetcher);
  }

  getPeriod(): Period {
    return this.period;
  }

  nextPeriod() {
    this.period = this.period.getNextPeriod()
    this.fetchData();
  }

  previousPeriod() {
    this.period = this.period.getPreviousPeriod()
    this.fetchData();
  }

  getWorkers(): RepitWorker[] {
    return this.repitWorkers;
  }

  getWorkDay(day: SchedulerDay, repitWorker: RepitWorker) : SchedulerWorkDay{
    let filteredEvents = this.events.filter(event => {
      return event.isConcerning(repitWorker) && event.isHappeningOn(day);
    })
    return new SchedulerWorkDay(day, repitWorker, filteredEvents)
  }

  getUnscheduledEvents() {
    return this.events.filter(event => {
      return event.isNotScheduled();
    });
  }

  getUnscheduledEventsOn(day: SchedulerDay) {
    return this.events.filter(event => {
      return event.isHappeningOn(day) && !event.isAssigned();
    });
  }

  schedule(id: number, day: SchedulerDay, worker: RepitWorker) {
    this.findEvent(id)
      .scheduleDay(day, worker)
  }

  private findEvent(id: number) {
    return this.events
      .find(event => event.id === id);
  }

  removeAssignee(id: number) {
    this.findEvent(id).removeAssignee()
  }

  addEvent(event: SchedulerEvent) {
    this.events.push(event)
  }
}
