import {Component, OnInit} from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import {formatDate, NgForOf, NgIf} from "@angular/common";
import {GenderEnum} from "../../../models/person/GenderEnum";
import {FirstContactApplicationMutationRequest} from "../../../../generated/api";
import {FirstContactService} from "../../../models/first-contact/first-contact.service";
import {InlinableAddress} from "../../../models/address/InlinableAddress";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatOption, MatSelect} from "@angular/material/select";
import {CreateAddressFieldsComponent} from "../../address/create-address-fields/create-address-fields.component";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {ClearableInputTextComponent} from "../../components/clearable-input-text/clearable-input-text.component";
import {DatePickerComponent} from "../../components/date-picker/date-picker.component";
import Gender = GenderEnum.Gender;

@Component({
  selector: 'app-create-first-contact',
  standalone: true,
  templateUrl: './create-first-contact.component.html',
  imports: [
    MatLabel,
    MatSuffix,
    MatHint,
    MatFormField,
    MatInput,
    MatIcon,
    ReactiveFormsModule,
    MatIconButton,
    NgIf,
    MatSelect,
    MatOption,
    NgForOf,
    CreateAddressFieldsComponent,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDatepicker,
    CdkTextareaAutosize,
    FormsModule,
    RouterLink,
    ClearableInputTextComponent,
    DatePickerComponent
  ],
  styleUrls: ['./create-first-contact.component.scss']
})
export class CreateFirstContactComponent implements OnInit {

  form: FormGroup = new FormGroup({
    gender: new FormControl(GenderEnum.UNSPECIFIED.value),
    contactedOn: new FormControl(formatDate(new Date(), "YYYY-MM-dd", "fr-BE"), Validators.required),
    firstName: new FormControl(""),
    lastName: new FormControl(""),
    age: new FormControl(0),
    note: new FormControl(""),
    address: new FormGroup({
      streetName: new FormControl(""),
      streetNumber: new FormControl(""),
      zipCode: new FormControl(""),
      city: new FormControl(""),
    }),
  });

  genders: Gender[] = GenderEnum.VALUES;

  constructor(private router: Router, private firstContactService: FirstContactService) {
  }

  ngOnInit(): void {
  }

  create() {
    const firstContact: FirstContactApplicationMutationRequest = {
      contactedOn: this.form.get("contactedOn").value,
      firstName: this.form.get("firstName").value,
      lastName: this.form.get("lastName").value,
      age: this.form.get("age").value,
      note: this.form.get("note").value,
      gender: this.form.get("gender").value,
      address: {
        streetName: this.form.get("address").get("streetName").value,
        streetNumber: this.form.get("address").get("streetNumber").value,
        zipCode: this.form.get("address").get("zipCode").value,
        city: this.form.get("address").get("city").value
      } as InlinableAddress,
    };

    this.firstContactService.create(firstContact).subscribe((firstContactResponse) => {
      this.router.navigate(["/first-contact/detail/", firstContactResponse.id]);
      }
    );
  }
}
