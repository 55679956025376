import {RepitEnum} from "../common/RepitEnum";

export namespace StaffTypeEnum {

  export class StaffType extends RepitEnum {
    constructor(value: string, label: string) {
      super(value, label);
    }
  }

  export const EDUCATOR = new StaffType("EDUCATOR", "Educateur");
  export const AS = new StaffType("AS", "AS");
  export const OFFICE = new StaffType("OFFICE", "Bureau");
  export const MANAGEMENT = new StaffType("MANAGEMENT", "Direction");

  export const VALUES: StaffType[] = [
    EDUCATOR,
    AS,
    OFFICE,
    MANAGEMENT
  ];

  const BY_VALUE = new Map<string, StaffType>();
  VALUES.forEach(type => BY_VALUE.set(type.value, type));

  export function fromValue(value: string): StaffType {
    return BY_VALUE.get(value);
  }
}




