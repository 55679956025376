<div *ngIf="staffMember(); else loadingBlock">
    <h1>{{staffMember() | personInfo}}</h1>

    <mat-tab-group>
        <mat-tab label="Informations générales">
            <h2>Informations générales</h2>
            <div class="d-flex content-tab">
                <div class="staff staff-face">
                    <img ngSrc="./assets/avatar.png" alt="picture" height="398" width="287">
                </div>

                <div class="staff staff-infos">
                    <div class="d-flex align-items-baseline">
                        <h3>Contact</h3>
                        <button class="ms-auto" mat-icon-button [matMenuTriggerFor]="contactSubMenu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #contactSubMenu="matMenu" class="no-max-width">
                            <button mat-menu-item>
                                <mat-icon>edit</mat-icon>
                                Modifier
                            </button>
                            <button mat-menu-item>
                                <mat-icon>phone</mat-icon>
                                Modifier les informations de contact urgent
                            </button>
                        </mat-menu>
                    </div>
                    <div>
                        <span style="font-weight: bold">Email : </span><span>{{staffMember().email}}</span>
                    </div>
                    <div>
                        <span style="font-weight: bold">Tél. pro : </span><span>{{staffMember().professionalPhoneNumber}}</span>
                    </div>
                    <div>
                        <span style="font-weight: bold">Tél. privé : </span><span>{{staffMember().privatePhoneNumber}}</span>
                    </div>
                    <div>
                        <span style="font-weight: bold">Nom contact urg. : </span><span>{{staffMember().urgentContactIdentification}}</span>
                    </div>
                    <div>
                        <span style="font-weight: bold">Tél. contact urg. : </span><span>{{staffMember().urgentContactPhoneNumber}}</span>
                    </div>

                    <mat-divider></mat-divider>

                    <br/>
                    <div class="d-flex align-items-baseline">
                        <h3>Adresse</h3>
                        <button class="ms-auto" mat-icon-button [matMenuTriggerFor]="addressSubMenu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #addressSubMenu="matMenu" class="no-max-width">
                            <button mat-menu-item>
                                <mat-icon>add</mat-icon>
                                Ajouter une nouvelle adresse
                            </button>
                            <button mat-menu-item (click)="modifyCurrentAddress()">
                                <mat-icon>edit</mat-icon>
                                Corriger l'adresse
                            </button>
                            <button mat-menu-item (click)="viewAddressHistory()">
                                <mat-icon>history</mat-icon>
                                <div>Voir toutes les adresses</div>
                            </button>
                        </mat-menu>
                    </div>

                    <div>
                        <span style="font-weight: bold">Adresse : </span><span>{{staffMember().address | inlineAddress}}</span>
                    </div>
                </div>
                <div class="staff staff-infos">
                    <div class="d-flex align-items-baseline">
                        <h3>Contrat</h3>
                        <button class="ms-auto" mat-icon-button [matMenuTriggerFor]="contractSubMenu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #contractSubMenu="matMenu" class="no-max-width">
                            <button mat-menu-item>
                                <mat-icon>add</mat-icon>
                                Créer un nouveau contrat
                            </button>
                            <button mat-menu-item>
                                <mat-icon>close</mat-icon>
                                Clore le contrat
                            </button>
                            <button mat-menu-item>
                                <mat-icon>edit</mat-icon>
                                Ajuster le nombre d'heures
                            </button>
                            <button mat-menu-item>
                                <mat-icon>history</mat-icon>
                                <div>Voir tous les contrats</div>
                            </button>
                        </mat-menu>
                    </div>
                    <div>
                        <span style="font-weight: bold">Fonction : </span><span>{{staffMember().type.label}}</span>
                    </div>
                    <div>
                        <span style="font-weight: bold">Type : </span><span>{{staffMember().currentContract?.type}}</span>
                    </div>
                    <div>
                        <span style="font-weight: bold">Date de début : </span><span>{{staffMember().currentContract?.startDate | date:"dd/MM/YYYY"}}</span>
                    </div>
                    <div>
                        <span style="font-weight: bold">Date de fin : </span><span>{{staffMember().currentContract?.endDate | date:"dd/MM/YYYY"}}</span>
                    </div>
                    <div>
                        <span style="font-weight: bold">Heures/semaine : </span><span>{{staffMember().currentContract?.numberOfHours}}</span>
                    </div>

                    <div *ngIf="isEducator()">
                        <mat-divider></mat-divider>
                        <br/>
                        <h3>Educateur</h3>
                        <div>
                            <span style="font-weight: bold">Bureau régional : </span><span>{{staffMember().br}}</span>
                        </div>
                    </div>
                </div>
            </div>

        </mat-tab>
        <mat-tab label="Prestations">
            <h2>Prestations</h2>

        </mat-tab>
    </mat-tab-group>


    <div class="general-bg bg-white d-flex gap-2">
        <button [routerLink]="['/staff']" class="btn btn-secondary">Retour</button>
        <button class="btn btn-danger">
            Archiver
        </button>
        <button class="btn btn-primary">
            Réactiver
        </button>
    </div>
</div>
<ng-template #loadingBlock>En cours de chargement</ng-template>
