import {SchedulerTime} from "./SchedulerTime";

const timeRegex = /^(0?[0-9]|1[0-9]|2[0-3]):(0?[0-9]|[0-5][0-9])$/;

export class SchedulerTimePeriod {
  private readonly _startTime: SchedulerTime;
  private readonly _durationHour: number;
  private readonly _durationMinute: number;

  constructor(schedulerTime: SchedulerTime, durationHour: number, durationMinute: number) {
    this._startTime = schedulerTime;
    this._durationHour = durationHour;
    this._durationMinute = durationMinute;
  }

  get startTime(): SchedulerTime {
    return this._startTime;
  }

  get endTime(): SchedulerTime {
    return this._startTime.add(this._durationHour, this._durationMinute)
  }

  get durationHour(): number {
    return this._durationHour;
  }

  get durationMinute(): number {
    return this._durationMinute;
  }

  public static betweenHoursAsString(start: string, end: string): SchedulerTimePeriod {
    if (!timeRegex.test(start) || !timeRegex.test(end)) {
      return new SchedulerTimePeriod(new SchedulerTime(0, 0), 0, 0);
    }

    return SchedulerTimePeriod.between(
      parseInt(start.split(":")[0]),
      parseInt(start.split(":")[1]),
      parseInt(end.split(":")[0]),
      parseInt(end.split(":")[1]),
    );
  }

  public static betweenDates(start: Date, end: Date): SchedulerTimePeriod {
    return SchedulerTimePeriod.between(
      start.getHours(),
      start.getMinutes(),
      end.getHours(),
      end.getMinutes(),
    );
  }

  public static between(startHour: number, startMinute: number, endHour: number, endMinute: number): SchedulerTimePeriod {
    let startTime = new SchedulerTime(startHour, startMinute);
    let endTime = new SchedulerTime(endHour, endMinute);
    let duration = startTime.duration(endTime);
    return new SchedulerTimePeriod(startTime, duration.hour, duration.minute);
  }

}
