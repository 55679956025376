<div class="row text-center">
    <div class="col-md-4">
        <div class="btn-group">
            <div class="btn btn-secondary"
                 mwlCalendarPreviousView
                 [view]="view"
                 [(viewDate)]="viewDate">
                <
            </div>
            <div class="btn btn-outline-secondary"
                 mwlCalendarToday
                 [(viewDate)]="viewDate">
                Aujourd'hui
            </div>
            <div class="btn btn-secondary"
                 mwlCalendarNextView
                 [view]="view"
                 [(viewDate)]="viewDate">
                >
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <h3>
            {{ viewDate | calendarDate:(view + 'ViewTitle'):locale:weekStartsOn }}
        </h3>
    </div>
    <div class="col-md-4">
        <div class="btn-group">
            <div class="btn btn-secondary"
                 (click)="setView(CalendarView.Month)"
                 [class.active]="view === CalendarView.Month">
                Mois
            </div>
            <div class="btn btn-secondary"
                 (click)="setView(CalendarView.Week)"
                 [class.active]="view === CalendarView.Week">
                Semaine
            </div>
            <div class="btn btn-secondary"
                 (click)="setView(CalendarView.Day)"
                 [class.active]="view === CalendarView.Day">
                Jour
            </div>
        </div>
    </div>
</div>
<br/>

<div [ngSwitch]="view">
    <mwl-calendar-month-view *ngSwitchCase="'month'"
                             [viewDate]="viewDate"
                             [events]="events()"
                             [locale]="locale"
                             [weekStartsOn]="weekStartsOn"
                             [weekendDays]="weekendDays">
    </mwl-calendar-month-view>
    <mwl-calendar-week-view *ngSwitchCase="'week'"
                            [viewDate]="viewDate"
                            [events]="events()"
                            [locale]="locale"
                            [weekStartsOn]="weekStartsOn"
                            [weekendDays]="weekendDays">
    </mwl-calendar-week-view>
    <mwl-calendar-day-view *ngSwitchCase="'day'"
                           [viewDate]="viewDate"
                           [events]="events()"
                           [locale]="locale">
    </mwl-calendar-day-view>
</div>
