import {SchedulerDay} from "./SchedulerDay";
import {RepitWorker} from "./RepitWorker";
import {SchedulerEvent} from "./SchedulerEvent";

export class SchedulerWorkDay {
  private readonly _day: SchedulerDay;
  private readonly _worker: RepitWorker;
  private readonly _events: SchedulerEvent[];


  constructor(day: SchedulerDay, worker: RepitWorker, events: SchedulerEvent[]) {
    this._day = day;
    this._worker = worker;
    this._events = events.sort((a, b) => {
      if (a.time.startTime.hour !== b.time.startTime.hour) {
        return a.time.startTime.hour - b.time.startTime.hour;
      } else {
        return a.time.startTime.minute - b.time.startTime.minute;
      }
    });
  }

  get day(): SchedulerDay {
    return this._day;
  }

  get worker(): RepitWorker {
    return this._worker;
  }

  get events(): SchedulerEvent[] {
    return this._events;
  }
}
