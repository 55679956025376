/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {AddressInfoDto} from './addressInfoDto';
import {PersonInfoDto} from './personInfoDto';


export interface CreateBeneficiaryRequest {
  personInfo: PersonInfoDto;
  aviqFileNumber?: string;
  bap?: boolean;
  registeredOn: string;
  brId?: number;
  contactInfo: PersonInfoDto;
  contactPhoneNumber: string;
  contactEmailAddress?: string;
  contactType: string;
  contactLinkType: string;
  contactAddress: AddressInfoDto;
}

