import {Component, Inject, inject, OnInit} from '@angular/core';
import {PrestationTypeEnum} from "../../../models/prestation/PrestationType";
import {Store} from "@ngxs/store";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {NgForOf} from "@angular/common";
import {UpdatePrestationType} from "../../../stores/prestation/prestation.actions";
import PrestationType = PrestationTypeEnum.PrestationType;


export interface PrestationUpdateTypeData {
  prestationId: number;
  type: PrestationType;
}

@Component({
  selector: 'app-update-prestation-type-dialog',
  standalone: true,
  imports: [
    FormsModule,
    MatDialogActions,
    MatDialogClose,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
    ReactiveFormsModule,
    MatDialogContent,
    MatDialogTitle
  ],
  templateUrl: './update-prestation-type-dialog.component.html',
  styleUrl: './update-prestation-type-dialog.component.scss'
})
export class UpdatePrestationTypeDialogComponent implements OnInit {

  store = inject(Store);
  form = new FormGroup({
    type: new FormControl(null, Validators.required),
  })

  constructor(@Inject(MAT_DIALOG_DATA) public input: PrestationUpdateTypeData) {
  }

  ngOnInit() {
    this.form.get("type").reset(this.input.type);
  }

  isValid() {
    return this.form.valid;
  }

  update() {
    this.store.dispatch(new UpdatePrestationType(this.input.prestationId, this.form.get("type").value));
  }

  protected readonly PrestationTypeEnum = PrestationTypeEnum;
}
