import {Component, Inject, inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Store} from "@ngxs/store";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {NgForOf, NgIf} from "@angular/common";
import {SchedulerDateAndTime} from "../../../models/scheduler/SchedulerDateAndTime";
import {SchedulerTimePipe} from "../../pipe/scheduler-time.pipe";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {UpdatePrestationSchedule} from "../../../stores/prestation/prestation.actions";
import {SchedulerTimePeriod} from "../../../models/scheduler/SchedulerTimePeriod";

export class PrestationUpdateScheduleData {
  prestationId: number;
  schedule: SchedulerDateAndTime;
}

@Component({
  selector: 'app-update-prestation-schedule-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    MatHint,
    MatIcon,
    MatIconButton,
    MatInput,
    MatSuffix,
    NgIf
  ],
  templateUrl: './update-prestation-schedule-dialog.component.html',
  styleUrl: './update-prestation-schedule-dialog.component.scss'
})
export class UpdatePrestationScheduleDialogComponent implements OnInit {

  store = inject(Store);
  schedulerTimePipe = inject(SchedulerTimePipe);

  form = new FormGroup({
    startTime: new FormControl("", Validators.required),
    endTime: new FormControl("", Validators.required),
  })

  constructor(@Inject(MAT_DIALOG_DATA) public input: PrestationUpdateScheduleData) {
  }

  ngOnInit() {
    this.form.get("startTime").reset(this.schedulerTimePipe.transform(this.input.schedule.timePeriod.startTime));
    this.form.get("endTime").reset(this.schedulerTimePipe.transform(this.input.schedule.timePeriod.endTime));
  }

  isValid() {
    return this.form.valid;
  }

  update() {
    let startTime = this.form.get("startTime").value;
    let endTime = this.form.get("endTime").value;
    let schedule = SchedulerTimePeriod.betweenHoursAsString(startTime, endTime);
    this.store.dispatch(new UpdatePrestationSchedule(this.input.prestationId, schedule));
  }

}
