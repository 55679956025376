import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {
  BeneficiaryContactDto,
  BeneficiaryContactLinkTypeDto,
  BeneficiaryContactTypeDto,
  BeneficiaryControllerService,
  BeneficiaryDetailDto,
  BeneficiaryPrestationSummaryDto,
  BeneficiarySummaryDto,
  ContactControllerService,
  CreateBeneficiaryRequest,
  SimpleBeneficiaryDto,
  UpdateSummaryRequest
} from "../../../generated/api";

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryService {

  constructor(private beneficiaryController: BeneficiaryControllerService, private contactController: ContactControllerService) {
  }

  summaries(): Observable<BeneficiarySummaryDto[]> {
    return this.beneficiaryController.allBeneficiarySummaries()
  }

  detail(id: number): Observable<BeneficiaryDetailDto> {
    return this.beneficiaryController.fetchBeneficiaryDetail(id);
  }

  simpleBeneficiaries(): Observable<SimpleBeneficiaryDto[]> {
    return this.beneficiaryController.allSimpleBeneficiaries();
  }

  fetchPrestations(beneficiaryId: number): Observable<BeneficiaryPrestationSummaryDto[]> {
    return this.beneficiaryController.fetchPrestationsForBeneficiary(beneficiaryId);
  }

  fetchContacts(beneficiaryId: number): Observable<BeneficiaryContactDto[]> {
    return this.beneficiaryController.fetchContactsForBeneficiary(beneficiaryId);
  }

  makePrestationPrimaryContact(beneficiaryId: number, contactId: number): Observable<BeneficiaryContactDto[]> {
    return this.beneficiaryController.makePrestationPrimaryContact(beneficiaryId, contactId);
  }

  makeInvoicePrimaryContact(beneficiaryId: number, contactId: number): Observable<BeneficiaryContactDto[]> {
    return this.beneficiaryController.makeInvoicePrimaryContact(beneficiaryId, contactId);
  }

  updateSummary(beneficiaryId: number, newSummary: string): Observable<BeneficiaryDetailDto> {
    let request: UpdateSummaryRequest = {
      newSummary: newSummary
    }
    return this.beneficiaryController.updateSummary(beneficiaryId, request);
  }

  archive(beneficiaryId: number): Observable<BeneficiaryDetailDto> {
    return this.beneficiaryController.archiveBeneficiary(beneficiaryId);
  }

  reactivate(beneficiaryId: number): Observable<BeneficiaryDetailDto> {
    return this.beneficiaryController.reactivateBeneficiary(beneficiaryId);
  }

  canBeArchived(beneficiaryId: number): Observable<Boolean> {
    return this.beneficiaryController.checkCanBeArchived(beneficiaryId)
  }

  create(request: CreateBeneficiaryRequest): Observable<BeneficiaryDetailDto> {
    return this.beneficiaryController.createBeneficiary(request);
  }

  contactTypes(): Observable<BeneficiaryContactTypeDto[]> {
    return this.contactController.contactTypes();
  }

  contactLinkTypes(): Observable<BeneficiaryContactLinkTypeDto[]> {
    return this.contactController.contactLinkTypes();
  }
}
