import {Component, Inject, Signal} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {toSignal} from "@angular/core/rxjs-interop";
import {AddressWithHistory} from "../../../models/address/AddressWithHistory";
import {AddressGateway} from "../../../ports/AddressGateway";


export interface ViewAddressHistoryDialogData {
  id: number;
}

@Component({
  selector: 'app-view-address-history-dialog',
  templateUrl: './view-address-history-dialog.component.html',
  styleUrls: ['./view-address-history-dialog.component.scss']
})
export class ViewAddressHistoryDialogComponent {

  addresses: Signal<AddressWithHistory[]> = toSignal(this.addressGateway.getAllForPerson(this.data.id));

  constructor(public dialogRef: MatDialogRef<ViewAddressHistoryDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ViewAddressHistoryDialogData,
              public addressGateway: AddressGateway) {
  }
}
