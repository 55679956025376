import {FormControl, FormGroup, Validators} from "@angular/forms";
import {InlinableAddress} from "../../models/address/InlinableAddress";

export class AddressForm {
  form: FormGroup;

  private constructor(form: FormGroup) {
    this.form = form;
  }

  address() {
    return {
      streetName: this.form.get("streetName").value,
      streetNumber: this.form.get("streetNumber").value,
      zipCode: this.form.get("zipCode").value,
      city: this.form.get("city").value,
    } as InlinableAddress
  }

  formGroup() {
    return this.form;
  }

  setValue(address: InlinableAddress) {
    this.form.get("streetName").setValue(address.streetName);
    this.form.get("streetNumber").setValue(address.streetNumber);
    this.form.get("zipCode").setValue(address.zipCode);
    this.form.get("city").setValue(address.city);
  }

  enable() {
    this.form.get("streetName").enable();
    this.form.get("streetNumber").enable();
    this.form.get("zipCode").enable();
    this.form.get("city").enable();
  }

  disable() {
    this.form.get("streetName").disable();
    this.form.get("streetNumber").disable();
    this.form.get("zipCode").disable();
    this.form.get("city").disable();
  }

  reset() {
    this.form.get("streetName").reset(null);
    this.form.get("streetNumber").reset(null);
    this.form.get("zipCode").reset(null);
    this.form.get("city").reset(null);
  }

  static initialize(option: { required?: boolean, disabled?: boolean } = {required: true, disabled: false}) {
    const validator = option.required ? Validators.required : Validators.nullValidator;
    return new AddressForm(new FormGroup({
      streetName: new FormControl({value: null, disabled: option.disabled}, validator),
      streetNumber: new FormControl({value: null, disabled: option.disabled}, validator),
      zipCode: new FormControl({value: null, disabled: option.disabled}, validator),
      city: new FormControl({value: null, disabled: option.disabled}, validator),
    }));
  }

}