import {InlinableAddress} from "./InlinableAddress";

export class AddressWithHistory implements InlinableAddress {
  id: number;
  streetNumber: string;
  streetName: string;
  zipCode: string;
  city: string;
  latitude?: number;
  longitude?: number;
  startDate: Date;
  endDate?: Date;

  constructor(id: number, streetNumber: string, streetName: string, zipCode: string, city: string, latitude: number, longitude: number, startDate: Date, endDate: Date) {
    this.id = id;
    this.streetNumber = streetNumber;
    this.streetName = streetName;
    this.zipCode = zipCode;
    this.city = city;
    this.latitude = latitude;
    this.longitude = longitude;
    this.startDate = startDate;
    this.endDate = endDate;
  }
}