import {Component, inject, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from "@angular/material/dialog";
import {PrestationTravel} from "../../../models/prestation/PrestationTravel";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {UpdatePrestationTravelsComponent} from "../update-prestation-travels/update-prestation-travels.component";
import {Store} from "@ngxs/store";
import {UpdatePrestationTravels} from "../../../stores/prestation/prestation.actions";
import {TravelForm} from "../TravelForm";

export interface UpdatePreparationTravelsDialogData {
  prestationId: number;
  educatorTravelDescription?: string;
  travelBefore?: PrestationTravel;
  travelDurings: Array<PrestationTravel>;
  travelAfter?: PrestationTravel;
}

@Component({
  selector: 'app-update-prestation-travels-dialog',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogTitle,
    MatDialogActions,
    MatDialogClose,
    FormsModule,
    ReactiveFormsModule,
    UpdatePrestationTravelsComponent
  ],
  templateUrl: './update-prestation-travels-dialog.component.html',
  styleUrl: './update-prestation-travels-dialog.component.scss'
})
export class UpdatePrestationTravelsDialogComponent {
  store = inject(Store);

  form: FormGroup = new FormGroup({
    travels: new FormGroup({
      addingTravelDuring: new FormControl(false),
      travelDescription: new FormControl(""),
      travelDistance: new FormControl(null),
      addingTravelBefore: new FormControl(false),
      travelBeforeDescription: new FormControl(""),
      addingTravelAfter: new FormControl(false),
      travelAfterDescription: new FormControl(""),
      addressBefore: new FormGroup({
        streetName: new FormControl(null),
        streetNumber: new FormControl(null),
        zipCode: new FormControl(null),
        city: new FormControl(null),
      }),
      addressAfter: new FormGroup({
        streetName: new FormControl(null),
        streetNumber: new FormControl(null),
        zipCode: new FormControl(null),
        city: new FormControl(null),
      })
    }),
  });

  travelForm: TravelForm = new TravelForm(this.form.get("travels") as FormGroup);

  constructor(@Inject(MAT_DIALOG_DATA) public input: UpdatePreparationTravelsDialogData) {
    this.form.reset({
      travels: {
        travelDescription: input.travelDurings[0]?.motive,
        travelDistance: input.travelDurings[0]?.distance,
        addingTravelDuring: !!input.travelDurings[0],
        addingTravelBefore: !!input.travelBefore,
        addingTravelAfter: !!input.travelAfter,
        travelBeforeDescription: input.travelBefore?.motive,
        addressBefore: {
          streetName: input.travelBefore?.from.streetName,
          streetNumber: input.travelBefore?.from.streetNumber,
          zipCode: input.travelBefore?.from.zipCode,
          city: input.travelBefore?.from.city,
        },
        travelAfterDescription: input.travelAfter?.motive,
        addressAfter: {
          streetName: input.travelAfter?.to.streetName,
          streetNumber: input.travelAfter?.to.streetNumber,
          zipCode: input.travelAfter?.to.zipCode,
          city: input.travelAfter?.to.city,
        }
      }
    })
  }

  update() {
    if (this.form.valid) {

      this.store.dispatch(new UpdatePrestationTravels(
        this.input.prestationId,
        this.travelForm.travelDuringDescription(),
        this.travelForm.travelDuringDistance(),
        this.travelForm.travelBeforeRequest(),
        this.travelForm.travelAfterRequest(),
      ))
    }
  }

  isValid() {
    return this.form.valid;
  }

}
