import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {BeneficiaryStatistics} from "./beneficiary/beneficiary-statistics";
import {PrestationStatistics} from "./prestation/prestation-statistics";
import {Year} from "../calendar/Year";

@Injectable({
  providedIn: 'root'
})
export class ManagementService {

  private readonly BENEFICIARY_STATISTICS: Map<number, BeneficiaryStatistics> = new Map<number, BeneficiaryStatistics>();
  private readonly PRESTATION_STATISTICS: Map<number, PrestationStatistics> = new Map<number, PrestationStatistics>();

  constructor() {
    this.BENEFICIARY_STATISTICS.set(2024, new BeneficiaryStatistics(
      [
        {name: "Inscrits", number: 161},
        {name: "Actifs", number: 45}
      ], [
        {name: "Charleroi", number: 44},
        {name: "Dinant", number: 4},
        {name: "Liège", number: 33},
        {name: "Luxembourg", number: 2},
        {name: "Namur", number: 34},
        {name: "Mons", number: 13},
        {name: "Brabant Wallon", number: 31},
      ], [
        {name: "Hors public cible", number: 2},
        {name: "Hors territoire", number: 0},
        {name: "Hors offre du service", number: 0},
        {name: "Hors public du répit", number: 0},

      ]))
    this.BENEFICIARY_STATISTICS.set(2023, new BeneficiaryStatistics(
      [
        {name: "Inscrits", number: 165},
        {name: "Actifs", number: 45}
      ], [
        {name: "Charleroi", number: 43},
        {name: "Dinant", number: 4},
        {name: "Liège", number: 30},
        {name: "Namur", number: 36},
        {name: "Mons", number: 15},
        {name: "Brabant Wallon", number: 37},
      ], [
        {name: "Hors public cible", number: 39},
        {name: "Hors territoire", number: 2},
        {name: "Hors offre du service", number: 1},
        {name: "Hors public du répit", number: 2},

      ]))
    this.BENEFICIARY_STATISTICS.set(2022, new BeneficiaryStatistics([
      {name: "Inscrits", number: 155},
      {name: "Actifs", number: 35}
    ], [
      {name: "Charleroi", number: 41},
      {name: "Dinant", number: 4},
      {name: "Liège", number: 28},
      {name: "Namur", number: 34},
      {name: "Mons", number: 13},
      {name: "Brabant Wallon", number: 35},
    ], [
      {name: "Hors public cible", number: 34},
      {name: "Hors territoire", number: 8},
      {name: "Hors offre du service", number: 5},
      {name: "Hors public du répit", number: 1},
    ]))
    this.BENEFICIARY_STATISTICS.set(2021, new BeneficiaryStatistics([
      {name: "Inscrits", number: 145},
      {name: "Actifs", number: 30}
    ], [
      {name: "Charleroi", number: 39},
      {name: "Dinant", number: 3},
      {name: "Liège", number: 27},
      {name: "Namur", number: 32},
      {name: "Mons", number: 11},
      {name: "Brabant Wallon", number: 34},
    ], [
      {name: "Hors public cible", number: 14},
      {name: "Hors territoire", number: 3},
      {name: "Hors offre du service", number: 5},
      {name: "Hors public du répit", number: 4}]))
    this.PRESTATION_STATISTICS.set(2024, new PrestationStatistics(285, [
      {name: "Janvier", number: 203},
      {name: "Février", number: 82},
      {name: "Mars", number: 0},
      {name: "Avril", number: 0},
      {name: "Mai", number: 0},
      {name: "Juin", number: 0},
      {name: "Juillet", number: 0},
      {name: "Aout", number: 0},
      {name: "Septembre", number: 0},
      {name: "Octobre", number: 0},
      {name: "Novembre", number: 0},
      {name: "Décembre", number: 0},
    ], [
      {name: "Janvier", number: 203},
      {name: "Février", number: 82},
      {name: "Mars", number: 0},
      {name: "Avril", number: 0},
      {name: "Mai", number: 0},
      {name: "Juin", number: 0},
      {name: "Juillet", number: 0},
      {name: "Aout", number: 0},
      {name: "Septembre", number: 0},
      {name: "Octobre", number: 0},
      {name: "Novembre", number: 0},
      {name: "Décembre", number: 0},
    ]))
    this.PRESTATION_STATISTICS.set(2023, new PrestationStatistics(2474, [
      {name: "Janvier", number: 204},
      {name: "Février", number: 215},
      {name: "Mars", number: 210},
      {name: "Avril", number: 216},
      {name: "Mai", number: 217},
      {name: "Juin", number: 210},
      {name: "Juillet", number: 197},
      {name: "Aout", number: 209},
      {name: "Septembre", number: 198},
      {name: "Octobre", number: 198},
      {name: "Novembre", number: 218},
      {name: "Décembre", number: 182},
    ], [
      {name: "Janvier", number: 204},
      {name: "Février", number: 215},
      {name: "Mars", number: 210},
      {name: "Avril", number: 216},
      {name: "Mai", number: 217},
      {name: "Juin", number: 210},
      {name: "Juillet", number: 197},
      {name: "Aout", number: 209},
      {name: "Septembre", number: 198},
      {name: "Octobre", number: 198},
      {name: "Novembre", number: 218},
      {name: "Décembre", number: 182},
    ]))
    this.PRESTATION_STATISTICS.set(2022, new PrestationStatistics(2430, [
      {name: "Janvier", number: 170},
      {name: "Février", number: 199},
      {name: "Mars", number: 267},
      {name: "Avril", number: 218},
      {name: "Mai", number: 217},
      {name: "Juin", number: 241},
      {name: "Juillet", number: 196},
      {name: "Aout", number: 191},
      {name: "Septembre", number: 161},
      {name: "Octobre", number: 193},
      {name: "Novembre", number: 197},
      {name: "Décembre", number: 180},
    ], [
      {name: "Janvier", number: 170},
      {name: "Février", number: 199},
      {name: "Mars", number: 267},
      {name: "Avril", number: 218},
      {name: "Mai", number: 217},
      {name: "Juin", number: 241},
      {name: "Juillet", number: 196},
      {name: "Aout", number: 191},
      {name: "Septembre", number: 161},
      {name: "Octobre", number: 193},
      {name: "Novembre", number: 197},
      {name: "Décembre", number: 180},
    ]))
    this.PRESTATION_STATISTICS.set(2021, new PrestationStatistics(2283, [
      {name: "Janvier", number: 174},
      {name: "Février", number: 174},
      {name: "Mars", number: 181},
      {name: "Avril", number: 184},
      {name: "Mai", number: 197},
      {name: "Juin", number: 207},
      {name: "Juillet", number: 199},
      {name: "Aout", number: 191},
      {name: "Septembre", number: 209},
      {name: "Octobre", number: 214},
      {name: "Novembre", number: 201},
      {name: "Décembre", number: 152},
    ], [
      {name: "Janvier", number: 174},
      {name: "Février", number: 174},
      {name: "Mars", number: 181},
      {name: "Avril", number: 184},
      {name: "Mai", number: 197},
      {name: "Juin", number: 207},
      {name: "Juillet", number: 199},
      {name: "Aout", number: 191},
      {name: "Septembre", number: 209},
      {name: "Octobre", number: 214},
      {name: "Novembre", number: 201},
      {name: "Décembre", number: 152},
    ]))
  }

  public fetchBeneficiaryStatistics(year: Year): Observable<BeneficiaryStatistics> {
    return new Observable<BeneficiaryStatistics>(observer => {
      observer.next(this.BENEFICIARY_STATISTICS.get(year.value))
    })
  }

  fetchPrestationStatistics(year: Year): Observable<PrestationStatistics> {
    return new Observable<PrestationStatistics>(observer => {
      observer.next(this.PRESTATION_STATISTICS.get(year.value))
    })
  }
}
