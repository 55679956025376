export class User {
  private _id: number;
  private _personId: number;
  private _staffId: number;
  private _educatorId: number;
  private _firstName: string;
  private _lastName: string;
  private _email: string;
  private _roles: string[];

  get id(): number {
    return this._id;
  }

  get firstName(): string {
    return this._firstName;
  }

  get lastName(): string {
    return this._lastName;
  }

  get email(): string {
    return this._email;
  }

  get personId(): number {
    return this._personId;
  }

  get staffId(): number {
    return this._staffId;
  }

  get educatorId(): number {
    return this._educatorId;
  }

  get roles(): string[] {
    return this._roles;
  }


  set id(value: number) {
    this._id = value;
  }

  set personId(value: number) {
    this._personId = value;
  }

  set staffId(value: number) {
    this._staffId = value;
  }

  set educatorId(value: number) {
    this._educatorId = value;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  set email(value: string) {
    this._email = value;
  }

  set roles(value: string[]) {
    this._roles = value;
  }

  isAdmin(): boolean {
    return this._roles.includes('repit_admin');
  }

  isEducator(): boolean {
    return this._roles.includes('repit_educator');
  }

  isAS(): boolean {
    return this._roles.includes('repit_as');
  }
}
