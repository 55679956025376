<div class="m-3">
    <h1 mat-dialog-title>Assigner un éducateur</h1>
    <mat-dialog-content>
        <div class="vstack">
            <mat-form-field appearance="fill">
                <mat-label>Educateur principal</mat-label>
                <mat-select [compareWith]="byId" [(ngModel)]="mainEducator" required>
                    <input type="search" class="select-filter" [(ngModel)]="mainEducatorFilter"
                           placeholder="Rechercher">
                    <mat-option *ngFor="let educator of filteredMainEducators()" [value]="educator">
                        {{ educator | personInfo }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-checkbox [(ngModel)]="doubledPrestation" name="bap">Prestation doublée?</mat-checkbox>

            <mat-form-field appearance="fill" *ngIf="doubledPrestation()">
                <mat-label>Educateur accompagnant</mat-label>
                <mat-select [compareWith]="byId" [(ngModel)]="secondaryEducator" required>
                    <input type="search" class="select-filter" [(ngModel)]="secondaryEducatorFilter"
                           placeholder="Rechercher">
                    <mat-option *ngFor="let educator of filteredSecondaryEducators()" [value]="educator">
                        {{ educator | personInfo }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </mat-dialog-content>


    <mat-dialog-actions class="d-flex gap-2 justify-content-end">
        <button mat-dialog-close class="btn btn-secondary">Annuler</button>
        <button class="btn btn-primary" [disabled]="!isValid()" (click)="assign()">Assigner</button>
    </mat-dialog-actions>
</div>

