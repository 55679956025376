import {Injectable} from '@angular/core';
import {BrControllerService, BureauRegionalDto} from "../../../generated/api";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BrService {

  constructor(private brController: BrControllerService) {
  }

  public allBr(): Observable<BureauRegionalDto[]> {
    return this.brController.allBrs();
  }
}
