<h1>Pilotage</h1>
<div class="sticky-top general-bg bg-white">
  <mat-form-field appearance="fill">
    <mat-label>Année</mat-label>
    <mat-select [(ngModel)]="selectedYear" (ngModelChange)="refreshData()">
      <mat-option *ngFor="let year of years" [value]="year">{{year.value}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<mat-tab-group>
  <mat-tab label="Bénéficiaires">
    <div class="general-bg bg-white d-flex flex-wrap justify-content-around gap-2 mt-3">
      <div class="general-bg bg-white d-flex flex-column align-items-center gap-2">
        <span style="font-weight: bold">Bénéficiaires</span>
        <canvas baseChart [data]="beneficiariesByActivity" [options]="doughnutAreaOptions" [type]="'doughnut'"
                width="500px"
                height="400px"></canvas>
      </div>
      <div class="general-bg bg-white d-flex flex-column align-items-center gap-2">
        <span style="font-weight: bold">Nombre de bénéficiaires par BR</span>
        <canvas baseChart [data]="beneficiariesByBrData" [options]="barChartOptions" [type]="'bar'" width="500px"
                height="400px"></canvas>
      </div>
      <div class="general-bg bg-white d-flex flex-wrap flex-column align-items-center gap-2">
        <span style="font-weight: bold">Inscriptions refusées</span>
        <canvas baseChart width="500px" height="400px"
                [data]="refusedInscriptionsByMotiveData"
                [options]="doughnutAreaOptions"
                [type]="'doughnut'">
        </canvas>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Prestations">
    <div class="general-bg bg-white d-flex flex-wrap justify-content-around gap-2 mt-3">
      <div class="general-bg bg-white d-flex flex-column align-items-center gap-2">
        <span style="font-weight: bold">Nombre de prestations acceptées : {{prestationStatistics.prestations}}</span>
        <canvas baseChart [data]="prestationsByMonthData" [options]="barChartOptions" [type]="'bar'" width="1200px"
                height="600px"></canvas>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Personnel">

  </mat-tab>
</mat-tab-group>
