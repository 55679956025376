import {APP_INITIALIZER, inject, Injectable, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DragDropModule} from "@angular/cdk/drag-drop";
import {HttpClientModule} from "@angular/common/http";
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {
  DateAdapter,
  ErrorStateMatcher,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatOptionModule,
  NativeDateAdapter
} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatTableModule} from "@angular/material/table";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {MatInputModule} from "@angular/material/input";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatExpansionModule} from "@angular/material/expansion";
import {ApiModule, Configuration, ConfigurationParameters} from "./generated/api";
import {environment} from "../environments/environment";
import localeFrBE from '@angular/common/locales/fr-BE';
import {LocationStrategy, NgOptimizedImage, registerLocaleData} from "@angular/common";
import {MatChipsModule} from "@angular/material/chips";
import {MatPaginatorIntl, MatPaginatorModule} from "@angular/material/paginator";
import {Subject} from "rxjs";
import {MatDialogModule} from "@angular/material/dialog";
import {MatTabsModule} from "@angular/material/tabs";
import {KeycloakAngularModule, KeycloakService} from "keycloak-angular";
import {AuthGuard} from "./AuthGuard";
import {DndModule} from "ngx-drag-drop";
import {NgChartsModule} from 'ng2-charts';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatMenuModule} from "@angular/material/menu";
import {StaffGateway} from "./core/ports/StaffGateway";
import {HttpStaffGateway} from "./core/adapters/staff/HttpStaffGateway";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {AddressGateway} from "./core/ports/AddressGateway";
import {InMemoryAddressGateway} from "./core/adapters/address/InMemoryAddressGateway";
import {NgxsModule, Store} from "@ngxs/store";
import {StaffState} from "./core/stores/staff/staff.state";
import {GenerateReportKmComponent} from "./core/views/document/generate-report-km/generate-report-km.component";
import {Dashboard} from "./core/views/dashboard/dashboard";
import {
  GenerateRepitInvoiceComponent
} from "./core/views/document/generate-repit-invoice/generate-repit-invoice.component";
import {SchedulerComponent} from "./core/views/scheduler/scheduler.component";
import {UserDetailComponent} from "./core/views/user/user-detail/user-detail.component";
import {UserGreeterComponent} from "./core/views/user/user-greeter/user-greeter.component";
import {ListFirstContactComponent} from "./core/views/first-contact/list-first-contact/list-first-contact.component";
import {
  DetailFirstContactComponent
} from "./core/views/first-contact/detail-first-contact/detail-first-contact.component";
import {GenderPipe} from "./core/views/pipe/gender.pipe";
import {FirstContactApplicationStatusPipe} from "./core/views/pipe/first-contact-application-status.pipe";
import {InlineAddressPipe} from "./core/views/pipe/inline-address.pipe";
import {
  PendingFirstContactWidgetComponent
} from "./core/views/first-contact/pending-first-contact-widget/pending-first-contact-widget.component";
import {EditFirstContactComponent} from "./core/views/first-contact/edit-first-contact/edit-first-contact.component";
import {
  RefuseFirstContactComponent
} from "./core/views/first-contact/refuse-first-contact/refuse-first-contact.component";
import {RefusalMotivePipe} from "./core/views/pipe/refusal-motive.pipe";
import {ListBeneficiaryComponent} from "./core/views/beneficiary/list-beneficiary/list-beneficiary.component";
import {DetailBeneficiaryComponent} from "./core/views/beneficiary/detail-beneficiary/detail-beneficiary.component";
import {
  ArchiveBeneficiaryDialogComponent
} from "./core/views/beneficiary/archive-beneficiary-dialog/archive-beneficiary-dialog.component";
import {
  PrestationEventCardComponent
} from "./core/views/scheduler/prestation-event-card/prestation-event-card.component";
import {PersonInfoPipe} from "./core/views/pipe/person-info.pipe";
import {
  ManagementDashboardComponent
} from "./core/views/management/management-dashboard/management-dashboard.component";
import {
  PrestationsToValidateWidgetComponent
} from "./core/views/prestation/prestations-to-validate-widget/prestations-to-validate-widget.component";
import {GenerateBapInvoiceComponent} from "./core/views/document/generate-bap-invoice/generate-bap-invoice.component";
import {DetailStaffComponent} from "./core/views/staff/detail-staff/detail-staff.component";
import {
  ViewAddressHistoryDialogComponent
} from "./core/views/address/view-address-history-dialog/view-address-history-dialog.component";
import {ModifyAddressDialogComponent} from "./core/views/address/modify-address-dialog/modify-address-dialog.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {PrestationService} from "./core/models/prestation/prestation.service";
import {ADDRESSES_WITH_HISTORY} from "./core/dummy/Address.dummy";
import {MyWorkScheduleComponent} from './core/views/my-work/my-work-schedule/my-work-schedule.component';
import {CalendarModule, DateAdapter as CalendarDateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {WorkEventGateway} from "./core/ports/WorkEventGateway";
import {InMemoryWorkEventGateway} from "./core/adapters/work-event/InMemoryWorkEventGateway";
import {WORK_EVENTS} from "./core/dummy/SchedulerEvent.dummy";
import {WorkEventState} from "./core/stores/work-event/work-event.state";
import {
  PrestationToValidateFormComponent
} from './core/views/prestation/prestation-to-validate-form/prestation-to-validate-form.component';
import {SchedulerDayPipe} from "./core/views/pipe/scheduler-day.pipe";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {SchedulerTimePipe} from "./core/views/pipe/scheduler-time.pipe";
import {PrestationGateway} from "./core/ports/PrestationGateway";
import {PrestationState} from "./core/stores/prestation/prestation.state";
import {UserState} from "./core/stores/user/user.state";
import {UserGateway} from "./core/ports/UserGateway";
import {HttpUserGateway} from "./core/adapters/user/HttpUserGateway";
import {HttpPrestationGateway} from "./core/adapters/prestation/HttpPrestationGateway";
import {MatTooltip} from "@angular/material/tooltip";
import {SchedulePipe} from "./core/views/pipe/schedule.pipe";
import {SchedulerTimePeriodPipe} from "./core/views/pipe/scheduler-time-period.pipe";
import {AmountPipe} from "./core/views/pipe/amount.pipe";
import {BeneficiaryGateway} from "./core/ports/BeneficiaryGateway";
import {HttpBeneficiaryGateway} from "./core/adapters/beneficiary/HttpBeneficiaryGateway";
import {BeneficiaryState} from "./core/stores/beneficiary/beneficiary.state";
import {
  PrestationsToCloseWidgetComponent
} from "./core/views/prestation/prestations-to-close-widget/prestations-to-close-widget.component";
import {NotificationState} from "./core/stores/notification/notification.state";
import {FetchCurrentUser} from "./core/stores/user/user.actions";

registerLocaleData(localeFrBE);

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.apiUrl,
  };
  return new Configuration(params);
}

function initializeApp(keycloak: KeycloakService, store: Store) {
  const locationStrategy = inject(LocationStrategy);
  return async () => {
    await initializeKeycloak(keycloak, locationStrategy);
    initializeUser(store)
  };

}

function initializeKeycloak(keycloak: KeycloakService, locationStrategy: LocationStrategy) {
  return keycloak.init({
    config: {
      url: environment.keycloakUrl,
      realm: environment.keycloakRealm,
      clientId: environment.keycloakClientId
    },
    initOptions: {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri:
        window.location.origin + locationStrategy.getBaseHref() + '/assets/silent-check-sso.html'
    }
  });
}

function initializeUser(store: Store) {
  return store.dispatch(new FetchCurrentUser());
}

export class CustomMaterialFormsMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}

@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();

  firstPageLabel = "Première page";
  itemsPerPageLabel = "Eléments par page";
  lastPageLabel = "Dernière page";
  nextPageLabel = 'Page suivante';
  previousPageLabel = 'Page précédente';

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return `Page 1/1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return `Page ${page + 1}/${amountPages}`;
  }
}

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  override getFirstDayOfWeek(): number {
    return 1;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    GenerateReportKmComponent,
    Dashboard,
    GenerateRepitInvoiceComponent,
    SchedulerComponent,
    UserDetailComponent,
    UserGreeterComponent,
    ListFirstContactComponent,
    DetailFirstContactComponent,
    GenderPipe,
    FirstContactApplicationStatusPipe,
    PendingFirstContactWidgetComponent,
    EditFirstContactComponent,
    RefuseFirstContactComponent,
    RefusalMotivePipe,
    ListBeneficiaryComponent,
    DetailBeneficiaryComponent,
    ArchiveBeneficiaryDialogComponent,
    PrestationEventCardComponent,
    ManagementDashboardComponent,
    PrestationsToValidateWidgetComponent,
    GenerateBapInvoiceComponent,
    DetailStaffComponent,
    ViewAddressHistoryDialogComponent,
    ModifyAddressDialogComponent,
    MyWorkScheduleComponent,
    PrestationToValidateFormComponent,
  ],
  imports: [
    BrowserModule,
    ApiModule.forRoot(apiConfigFactory),
    AppRoutingModule,
    BrowserAnimationsModule,
    DragDropModule,
    HttpClientModule,
    FormsModule,
    KeycloakAngularModule,
    NgxsModule.forRoot([
      UserState,
      StaffState,
      WorkEventState,
      PrestationState,
      BeneficiaryState,
      NotificationState
    ]),
    MatButtonToggleModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatInputModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatChipsModule,
    MatPaginatorModule,
    MatDialogModule,
    MatCheckboxModule,
    MatTabsModule,
    DndModule,
    NgChartsModule,
    NgOptimizedImage,
    MatAutocompleteModule,
    MatMenuModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    CalendarModule.forRoot({provide: CalendarDateAdapter, useFactory: adapterFactory}),
    MatRadioButton,
    MatRadioGroup,
    MatTooltip,
    SchedulerDayPipe,
    PersonInfoPipe,
    SchedulePipe,
    SchedulerTimePeriodPipe,
    AmountPipe,
    InlineAddressPipe,
    PrestationsToCloseWidgetComponent,

  ],
  providers: [
    {provide: ErrorStateMatcher, useClass: CustomMaterialFormsMatcher},
    {provide: DateAdapter, useClass: CustomDateAdapter},
    {provide: MAT_DATE_LOCALE, useValue: 'fr-BE'},
    {provide: LOCALE_ID, useValue: 'fr-BE'},
    {provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl},
    PrestationService,
    AuthGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [KeycloakService, Store]
    },
    PersonInfoPipe,
    SchedulerTimePipe,
    {provide: UserGateway, useClass: HttpUserGateway},
    {provide: StaffGateway, useClass: HttpStaffGateway},
    {provide: AddressGateway, useFactory: () => new InMemoryAddressGateway().addAddresses(ADDRESSES_WITH_HISTORY)},
    {provide: WorkEventGateway, useFactory: () => new InMemoryWorkEventGateway().setWorkEvents(WORK_EVENTS)},
    {provide: PrestationGateway, useClass: HttpPrestationGateway},
    {provide: BeneficiaryGateway, useClass: HttpBeneficiaryGateway},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 10000}},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
