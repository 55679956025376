import {inject, Injectable} from "@angular/core";
import {Action, Selector, State, StateContext, Store} from "@ngxs/store";
import {PrestationGateway} from "../../ports/PrestationGateway";
import {tap} from "rxjs/operators";
import {
  AssignPrestation,
  CancelPrestation,
  ClosePrestation,
  CreatePrestation,
  DeletePrestation,
  FindPrestationDetail,
  FindPrestationsToClose,
  FindPrestationsToValidateForCurrentUser,
  FindPrestationSummaries,
  PrestationDetailFound,
  PrestationsToCloseFound,
  PrestationsToValidateFound,
  PrestationSummariesFound,
  RefusePrestation,
  SwitchBap,
  SwitchUrgent,
  UpdatePrestationLocationAndContacts,
  UpdatePrestationSchedule,
  UpdatePrestationTravels,
  UpdatePrestationType,
  ValidatePrestation,
  ViewPrestation,
  ViewPrestations
} from "./prestation.actions";
import {Observable, of} from "rxjs";
import {PrestationToValidate} from "../../models/prestation/PrestationToValidate";
import {PrestationToClose} from "../../models/prestation/PrestationToClose";
import {PrestationSummary} from "../../models/prestation/PrestationSummary";
import {PrestationDetail} from "../../models/prestation/prestationDetail";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {DateRange} from "../../models/calendar/DateRange";
import {Week} from "../../models/scheduler/Week";
import {PrestationStatusEnum} from "../../models/prestation/PrestationStatusEnum";
import {fullName} from "../../models/person/PersonUtils";
import {ShowInfo} from "../notification/notification.state";
import PrestationStatus = PrestationStatusEnum.PrestationStatus;


export type PrestationListFilter = {
  dateRange: DateRange;
  educator: string;
  beneficiary: string;
  statuses: PrestationStatus[];
  bap: boolean;
  nonBap: boolean;
}

export interface PrestationStateModel {
  prestationSummaries: PrestationSummary[]
  prestationsToValidate: PrestationToValidate[];
  prestationToClose: PrestationToClose[];
  prestationDetail: PrestationDetail;
  listFilter: PrestationListFilter;
}

@State<PrestationStateModel>({
  name: 'prestation',
  defaults: {
    prestationSummaries: [],
    prestationsToValidate: [],
    prestationToClose: [],
    prestationDetail: null,
    listFilter: {
      dateRange: new DateRange(Week.current().getFirstDayOfPeriod().date, Week.current().getLastDayOfPeriod().date),
      educator: "",
      beneficiary: "",
      statuses: [],
      bap: true,
      nonBap: true
    }
  }
})

@Injectable()
export class PrestationState {

  prestationGateway = inject(PrestationGateway);
  store = inject(Store);
  router = inject(Router);
  dialog = inject(MatDialog);

  @Action(FindPrestationSummaries)
  public findPrestationSummaries(ctx: StateContext<PrestationStateModel>, action: FindPrestationSummaries): Observable<PrestationSummary[]> {
    let shouldFetchAgain = true;
    if (action.filter) {
      shouldFetchAgain = action.filter.dateRange !== ctx.getState().listFilter.dateRange
      ctx.patchState({
        listFilter: action.filter
      })
    }
    if (shouldFetchAgain) {
      return this.prestationGateway.retrieveAllSummaries(ctx.getState().listFilter.dateRange).pipe(
        tap(prestations => {
          ctx.dispatch(new PrestationSummariesFound(prestations));
        })
      )
    } else {
      return of(ctx.getState().prestationSummaries);
    }
  }

  @Action(PrestationSummariesFound)
  public prestationSummariesFound(ctx: StateContext<PrestationStateModel>, action: PrestationSummariesFound) {
    ctx.patchState({
      prestationSummaries: action.prestations
    })
  }

  @Action(FindPrestationsToValidateForCurrentUser)
  public findPrestationsToValidateForCurrentUser(ctx: StateContext<PrestationStateModel>): Observable<PrestationToValidate[]> {
    return this.prestationGateway.retrieveAllToValidate().pipe(
      tap(prestations => {
        ctx.dispatch(new PrestationsToValidateFound(prestations));
      })
    )
  }

  @Action(PrestationsToValidateFound)
  public prestationsToValidateFound(ctx: StateContext<PrestationStateModel>, action: PrestationsToValidateFound) {
    ctx.patchState({
      prestationsToValidate: action.prestations
    })
  }

  @Action(ValidatePrestation)
  public validatePrestation(ctx: StateContext<PrestationStateModel>, action: ValidatePrestation) {
    return this.prestationGateway.validatePrestation(action.request).pipe(
      tap(() => {
        ctx.dispatch(new FindPrestationsToValidateForCurrentUser());
      })
    )
  }

  @Action(AssignPrestation)
  public assignPrestation(ctx: StateContext<PrestationStateModel>, action: AssignPrestation) {
    return this.prestationGateway.assignPrestation(action.prestationId, action.maindEducatorId, action.secondaryEducatorId).pipe(
      tap(() => {
        this.dialog.closeAll();
        ctx.dispatch(new FindPrestationDetail(action.prestationId));
      })
    )
  }

  @Action(RefusePrestation)
  public refusePrestation(ctx: StateContext<PrestationStateModel>, action: RefusePrestation) {
    return this.prestationGateway.refusePrestation(action.prestationId, action.refusalMotive, action.note).pipe(
      tap(() => {
        this.dialog.closeAll();
        ctx.dispatch(new FindPrestationDetail(action.prestationId));
      })
    )
  }

  @Action(CancelPrestation)
  public cancelPrestation(ctx: StateContext<PrestationStateModel>, action: CancelPrestation) {
    return this.prestationGateway.cancelPrestation(action.prestationId, action.cancellationMotive, action.note).pipe(
      tap(() => {
        this.dialog.closeAll();
        ctx.dispatch(new FindPrestationDetail(action.prestationId));
      })
    )
  }

  @Action(FindPrestationsToClose)
  public findPrestationsToClose(ctx: StateContext<PrestationStateModel>): Observable<PrestationToClose[]> {
    return this.prestationGateway.retrieveAllToClose().pipe(
      tap(prestations => {
        ctx.dispatch(new PrestationsToCloseFound(prestations));
      })
    )
  }

  @Action(PrestationsToCloseFound)
  public prestationsToCloseFound(ctx: StateContext<PrestationStateModel>, action: PrestationsToCloseFound) {
    ctx.patchState({
      prestationToClose: action.prestations
    })
  }

  @Action(ClosePrestation)
  public closePrestation(ctx: StateContext<PrestationStateModel>, action: ClosePrestation) {
    return this.prestationGateway.closePrestation(action.request).pipe(
      tap(() => {
        ctx.dispatch(new FindPrestationsToClose());
      })
    )
  }

  @Action(CreatePrestation)
  public createPrestation(ctx: StateContext<PrestationStateModel>, action: CreatePrestation) {
    return this.prestationGateway.createPrestation(action.request).pipe(
      tap(prestationId => {
        if (action.view) {
          ctx.dispatch(new ViewPrestation(prestationId));
        } else {
          ctx.dispatch(new FindPrestationSummaries());
          ctx.dispatch(new ShowInfo("La prestation a bien été créée", new ViewPrestation(prestationId)))
        }
      })
    )
  }

  @Action(ViewPrestation)
  public viewPrestation(ctx: StateContext<PrestationStateModel>, action: ViewPrestation) {
    this.router.navigate(['/prestation/detail/' + action.prestationId]);
    this.dialog.closeAll();
  }

  @Action(ViewPrestations)
  public viewPrestations() {
    this.router.navigate(['/prestation/list']);
  }

  @Action(FindPrestationDetail)
  public findPrestationDetail(ctx: StateContext<PrestationStateModel>, action: FindPrestationDetail) {
    return this.prestationGateway.retrieveDetail(action.prestationId).pipe(
      tap(prestationDetail => {
        ctx.dispatch(new PrestationDetailFound(prestationDetail));
      }))
  }

  @Action(PrestationDetailFound)
  public prestationDetailFound(ctx: StateContext<PrestationStateModel>, action: PrestationDetailFound) {
    ctx.patchState({
      prestationDetail: action.prestationDetail
    })
  }

  @Action(UpdatePrestationTravels)
  public updatePrestationTravels(ctx: StateContext<PrestationStateModel>, action: UpdatePrestationTravels) {
    return this.prestationGateway.updateTravels(action.prestationId, action.travelDuringDescription, action.travelDuringDistance, action.travelBefore, action.travelAfter).pipe(
      tap(() => {
        ctx.dispatch(new FindPrestationDetail(action.prestationId));
        ctx.dispatch(new ShowInfo("Les déplacements ont bien été mis à jour"));
        this.dialog.closeAll();
      })
    )
  }

  @Action(UpdatePrestationLocationAndContacts)
  public updatePrestationLocationAndContacts(ctx: StateContext<PrestationStateModel>, action: UpdatePrestationLocationAndContacts) {
    return this.prestationGateway.updateLocationAndContacts(action.prestationId, action.requestContactId, action.invoiceContactId, action.address).pipe(
      tap(() => {
        ctx.dispatch(new FindPrestationDetail(action.prestationId));
        ctx.dispatch(new ShowInfo("Les informations ont bien été mises à jour"));
        this.dialog.closeAll();
      })
    )
  }

  @Action(SwitchBap)
  public switchBap(ctx: StateContext<PrestationStateModel>, action: SwitchBap) {
    return this.prestationGateway.switchBap(action.prestationId).pipe(
      tap(() => {
        ctx.dispatch(new FindPrestationDetail(action.prestationId));
        ctx.dispatch(new ShowInfo("Le BAP a bien été modifié"));
      })
    )
  }

  @Action(UpdatePrestationSchedule)
  public updatePrestationSchedule(ctx: StateContext<PrestationStateModel>, action: UpdatePrestationSchedule) {
    return this.prestationGateway.updateSchedule(action.prestationId, action.schedule.startTime, action.schedule.endTime).pipe(
      tap(() => {
        ctx.dispatch(new FindPrestationDetail(action.prestationId));
        ctx.dispatch(new ShowInfo("Le planning a bien été modifié"));
        this.dialog.closeAll();
      })
    )
  }

  @Action(UpdatePrestationType)
  public updatePrestationType(ctx: StateContext<PrestationStateModel>, action: UpdatePrestationType) {
    return this.prestationGateway.updateType(action.prestationId, action.type).pipe(
      tap(() => {
        ctx.dispatch(new FindPrestationDetail(action.prestationId));
        ctx.dispatch(new ShowInfo("Le type a bien été modifié"));
        this.dialog.closeAll();
      })
    )
  }

  @Action(SwitchUrgent)
  public switchUrgent(ctx: StateContext<PrestationStateModel>, action: SwitchUrgent) {
    return this.prestationGateway.switchUrgent(action.prestationId).pipe(
      tap(() => {
        ctx.dispatch(new FindPrestationDetail(action.prestationId));
        ctx.dispatch(new ShowInfo("L'urgence a bien été modifiée"));
      })
    )
  }

  @Action(DeletePrestation)
  public deletePrestation(ctx: StateContext<PrestationStateModel>, action: DeletePrestation) {
    return this.prestationGateway.deletePrestation(action.prestationId).pipe(
      tap(() => {
        this.dialog.closeAll();
        ctx.dispatch(new ViewPrestations());
        ctx.dispatch(new ShowInfo("La prestation a bien été supprimée"));
      })
    )
  }

  @Selector()
  static prestationFilter(state: PrestationStateModel) {
    return state.listFilter;
  }

  @Selector()
  static prestationSummaries(state: PrestationStateModel) {
    const filter = state.listFilter;
    return state.prestationSummaries.filter(prestation => fullName(prestation.educator).toLowerCase().includes(filter.educator.toLowerCase()))
      .filter(prestation => fullName(prestation.beneficiary).toLowerCase().includes(filter.beneficiary.toLowerCase()))
      .filter(prestation => filter.statuses.length ? filter.statuses.includes(prestation.status) : true)
      .filter(prestation => (filter.bap && prestation.bap) || (filter.nonBap && !prestation.bap) || (!filter.bap && !filter.nonBap));
  }

}