<h1>Inscription : {{original.status | firstContactApplicationStatus}}</h1>
<div *ngIf="original.canBeEdited" class="vstack gap-2">
  <div class="general-bg bg-white">
    <h2>Informations à propos de la personne</h2>

    <mat-form-field>
      <mat-label>Prénom</mat-label>
      <input matInput type="text" [(ngModel)]="firstContact.firstName">
      <button *ngIf="firstContact.firstName" matSuffix mat-icon-button aria-label="Clear"
              (click)="firstContact.firstName=''" tabindex="-1">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Nom</mat-label>
      <input matInput type="text" [(ngModel)]="firstContact.lastName">
      <button *ngIf="firstContact.lastName" matSuffix mat-icon-button aria-label="Clear"
              (click)="firstContact.lastName=''" tabindex="-1">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Age</mat-label>
      <input matInput type="number" min="1" step="1" [(ngModel)]="firstContact.age">
      <button *ngIf="firstContact.age" matSuffix mat-icon-button aria-label="Clear" (click)="firstContact.age=null"
              tabindex="-1">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Genre</mat-label>
      <mat-select [(ngModel)]="firstContact.gender">
        <mat-option *ngFor="let gender of genders" [value]="gender.value">
          {{gender.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="general-bg bg-white">
    <h2>Adresse</h2>

    <mat-form-field>
      <mat-label>Numéro</mat-label>
      <input matInput type="text" [(ngModel)]="firstContact.address.streetNumber">
      <button *ngIf="firstContact.address.streetNumber" matSuffix mat-icon-button aria-label="Clear"
              (click)="firstContact.address.streetNumber=''" tabindex="-1">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Rue</mat-label>
      <input matInput type="text" [(ngModel)]="firstContact.address.streetName">
      <button *ngIf="firstContact.address.streetName" matSuffix mat-icon-button aria-label="Clear"
              (click)="firstContact.address.streetName=''" tabindex="-1">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Code postal</mat-label>
      <input matInput type="text" [(ngModel)]="firstContact.address.zipCode">
      <button *ngIf="firstContact.address.zipCode" matSuffix mat-icon-button aria-label="Clear"
              (click)="firstContact.address.zipCode=''" tabindex="-1">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Localité</mat-label>
      <input matInput type="text" [(ngModel)]="firstContact.address.city">
      <button *ngIf="firstContact.address.city" matSuffix mat-icon-button aria-label="Clear"
              (click)="firstContact.address.city=''" tabindex="-1">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="general-bg bg-white">
    <h2>Autres informations</h2>

    <mat-form-field>
      <mat-label>Date de contact</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="firstContact.contactedOn">
      <mat-hint>JJ/MM/AAAA</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="input-note">
      <mat-label>Note</mat-label>
      <textarea matInput cdkTextareaAutosize [(ngModel)]="firstContact.note"></textarea>
    </mat-form-field>
  </div>

  <div class="general-bg bg-white d-flex gap-2">
    <button class="btn btn-secondary" [routerLink]="['/first-contact/detail/', id]">Annuler</button>
    <button class="btn btn-primary" (click)="save()">Sauvegarder</button>
  </div>
</div>

<div *ngIf="!original.canBeEdited">
  L'inscription ne peut pas être modifiée.
  <div>
    <button class="btn btn-secondary" [routerLink]="['/first-contact/detail/', id]">Retour</button>
  </div>
</div>