import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface ArchiveDialogData {
  canBeArchived: boolean;
}

@Component({
  selector: 'app-archive-beneficiary-dialog',
  templateUrl: './archive-beneficiary-dialog.component.html',
  styleUrls: ['./archive-beneficiary-dialog.component.scss']
})
export class ArchiveBeneficiaryDialogComponent {


  constructor(
    public dialogRef: MatDialogRef<ArchiveBeneficiaryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ArchiveDialogData,
  ) {
  }

  confirm() {
    this.dialogRef.close(true)
  }

}
