import {RepitWorker} from "./RepitWorker";

export class EducatorWorker implements RepitWorker {
  private readonly _id: number;
  private readonly _firstName:string;
  private readonly _lastName:string;


  constructor(id: number, firstName: string, lastName: string) {
    this._id = id;
    this._firstName = firstName;
    this._lastName = lastName;
  }


  get id(): number {
    return this._id;
  }

  get firstName(): string {
    return this._firstName;
  }

  get lastName(): string {
    return this._lastName;
  }
}
