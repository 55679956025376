<form [formGroup]="form" class="d-flex gap-2 align-items-center">
    <mat-form-field>
        <mat-label>Prénom</mat-label>
        <input matInput type="text" formControlName="firstName" name="firstName">
        <button *ngIf="form.get('firstName').value" matSuffix mat-icon-button aria-label="Clear"
                (click)="form.patchValue({firstName:''})" tabindex="-1">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Nom</mat-label>
        <input matInput type="text" formControlName="lastName" name="lastName">
        <button *ngIf="form.get('lastName').value" matSuffix mat-icon-button aria-label="Clear"
                (click)="form.patchValue({lastName:''})" tabindex="-1">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Date de naissance</mat-label>
        <input matInput [matDatepicker]="birthDatePicker" formControlName="birthDate" name="birthDate">
        <mat-hint>JJ/MM/AAAA</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #birthDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Genre</mat-label>
        <mat-select formControlName="gender" name="gender">
            <mat-option *ngFor="let gender of genders" [value]="gender.value">
                {{ gender.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</form>
