<h1 mat-dialog-title>Refuser l'inscription</h1>
<div mat-dialog-content>
  <form #refusalForm="ngForm">
    <mat-form-field appearance="fill">
      <mat-label>Motif du refus</mat-label>
      <mat-select [(ngModel)]="refusal.motive" required name="motive">
        <mat-option *ngFor="let motive of motives" [value]="motive">
          {{motive.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="input-note">
      <mat-label>Note du refus</mat-label>
      <textarea matInput cdkTextareaAutosize [(ngModel)]="refusal.note" name="note"></textarea>
    </mat-form-field>
  </form>
  <div mat-dialog-actions class="d-flex gap-2">
    <button class="btn btn-secondary" mat-dialog-close="">Annuler</button>
    <button class="btn btn-danger" mat-dialog-close="" [disabled]="!refusalForm.form.valid" (click)="confirm()">
      Refuser
    </button>
  </div>
</div>
