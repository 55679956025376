import {BeneficiaryContactDto} from "../../../generated/api";
import {SimpleBeneficiaryContact} from "../../models/beneficiary/simpleBeneficiaryContact";
import {mapAddressWithCoordinates} from "../address/AddressMapper";


export function mapContact(contact: BeneficiaryContactDto): SimpleBeneficiaryContact {
  return {
    id: contact.id,
    firstName: contact.legalRepresentative.personInfo.firstName,
    lastName: contact.legalRepresentative.personInfo.lastName,
    linkType: contact.linkType.label,
    isPrestationPrimaryContact: contact.prestationPrimaryContact,
    isInvoicePrimaryContact: contact.invoicePrimaryContact,
    address: mapAddressWithCoordinates(contact.address.addressWithCoordinates)
  }

}
