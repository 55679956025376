import {Pipe, PipeTransform} from '@angular/core';
import {FirstContactApplicationStatusEnum} from "../../models/first-contact/FirstContactApplicationStatusEnum";
import fromValue = FirstContactApplicationStatusEnum.fromValue;

@Pipe({
  name: 'firstContactApplicationStatus'
})
export class FirstContactApplicationStatusPipe implements PipeTransform {

  constructor() {
  }

  transform(status: string, ...args: unknown[]): string {
    return status ? fromValue(status).label : "";
  }

}
