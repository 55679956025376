export namespace GenderEnum {
  export class Gender {
    private readonly _value: string;
    private readonly _label: string;

    constructor(value: string, label: string) {
      this._value = value;
      this._label = label;
    }

    get value(): string {
      return this._value;
    }

    get label(): string {
      return this._label;
    }

  }

  export const FEMALE = new Gender("FEMALE", "Féminin");
  export const MALE = new Gender("MALE", "Masculin");
  export const OTHER = new Gender("REFUSED", "Autre");
  export const UNSPECIFIED = new Gender("UNSPECIFIED", "Non spécifié");

  export const VALUES: Gender[] = [
    FEMALE,
    MALE,
    OTHER,
    UNSPECIFIED
  ];

  const BY_VALUE = new Map<string, Gender>();
  VALUES.forEach(gender => BY_VALUE.set(gender.value, gender));

  export function fromValue(value: string): Gender {
    return BY_VALUE.get(value);
  }
}




