import {Action, Selector, State, StateContext} from "@ngxs/store";
import {inject, Injectable} from "@angular/core";
import {User} from "../../models/user/User";
import {CurrentUserFetched, FetchCurrentUser} from "./user.actions";
import {Observable, of} from "rxjs";
import {UserGateway} from "../../ports/UserGateway";
import {tap} from "rxjs/operators";


export const ADMIN_ONLY = ['repit_admin'];
export const ADMIN_AND_AS = ['repit_admin', 'repit_as'];

export interface UserStateModel {
  currentUser: User | null;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    currentUser: null
  }
})
@Injectable()
export class UserState {

  userGateway = inject(UserGateway);

  @Selector()
  static getCurrentUser(state: UserStateModel) {
    return state.currentUser;
  }

  @Selector()
  static isEducator(state: UserStateModel) {
    return state.currentUser.isEducator();
  }

  @Selector()
  static isAdmin(state: UserStateModel) {
    return state.currentUser.isAdmin();
  }

  @Selector()
  static getCurrentStaffId(state: UserStateModel) {
    return state.currentUser.staffId;
  }

  @Action(FetchCurrentUser)
  public fetchCurrentUser(ctx: StateContext<UserStateModel>, action: FetchCurrentUser): Observable<User> {
    if (!ctx.getState().currentUser) {
      return this.userGateway.fetchCurrentUser().pipe(
        tap(currentUser => {
          ctx.dispatch(new CurrentUserFetched(currentUser));
        })
      )
    } else {
      return of(ctx.getState().currentUser);
    }
  }

  @Action(CurrentUserFetched)
  public currentUserFetched(ctx: StateContext<UserStateModel>, action: CurrentUserFetched) {
    ctx.patchState({
      currentUser: action.user
    });
  }
}