import {Component, input, OnInit, signal} from '@angular/core';
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {CreateAddressFieldsComponent} from "../../address/create-address-fields/create-address-fields.component";
import {FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {InlineAddressPipe} from "../../pipe/inline-address.pipe";
import {
  MatExpansionPanel,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from "@angular/material/expansion";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {NgIf} from "@angular/common";
import {InlinableAddress} from "../../../models/address/InlinableAddress";
import {conditionalValidationOnCheckedValue} from "../../formUtils";
import {TravelForm} from "../TravelForm";

@Component({
  selector: 'app-update-prestation-travels',
  standalone: true,
  imports: [
    CdkTextareaAutosize,
    CreateAddressFieldsComponent,
    FormsModule,
    InlineAddressPipe,
    MatExpansionPanel,
    MatExpansionPanelDescription,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    NgIf,
    ReactiveFormsModule
  ],
  templateUrl: './update-prestation-travels.component.html',
  styleUrl: './update-prestation-travels.component.scss'
})
export class UpdatePrestationTravelsComponent implements OnInit {

  formGroupName = input.required<string>()
  educatorDescription = input.required<string>()
  expandTravelsAtOpening = input<boolean>(true)
  form: FormGroup;
  travelForm: TravelForm;

  travelDuringExpanded = signal(true)
  travelBeforeExpanded = signal(true)
  travelAfterExpanded = signal(true)

  constructor(private rootFormGroup: FormGroupDirective) {
  }

  ngOnInit(): void {
    this.travelDuringExpanded.set(this.expandTravelsAtOpening())
    this.travelBeforeExpanded.set(this.expandTravelsAtOpening())
    this.travelAfterExpanded.set(this.expandTravelsAtOpening())
    this.form = this.rootFormGroup.control.get(this.formGroupName()) as FormGroup;
    this.travelForm = new TravelForm(this.form);
    conditionalValidationOnCheckedValue(this.form, "addingTravelBefore", [
      {field: "travelBeforeDescription", validators: [Validators.required]},
      {field: "addressBefore.streetName", validators: [Validators.required]},
      {field: "addressBefore.streetNumber", validators: [Validators.required]},
      {field: "addressBefore.zipCode", validators: [Validators.required]},
      {field: "addressBefore.city", validators: [Validators.required]}
    ]);
    conditionalValidationOnCheckedValue(this.form, "addingTravelDuring", [
      {field: "travelDescription", validators: [Validators.required]},
      {field: "travelDistance", validators: [Validators.required, Validators.min(1)]}
    ]);
    conditionalValidationOnCheckedValue(this.form, "addingTravelAfter", [
      {field: "travelAfterDescription", validators: [Validators.required]},
      {field: "addressAfter.streetName", validators: [Validators.required]},
      {field: "addressAfter.streetNumber", validators: [Validators.required]},
      {field: "addressAfter.zipCode", validators: [Validators.required]},
      {field: "addressAfter.city", validators: [Validators.required]}
    ]);
    this.form.updateValueAndValidity()
  }

  isUpdatingTravels(): boolean {
    return this.isAddingTravelBefore()
      || this.isAddingTravelDuring()
      || this.isAddingTravelAfter()
      || this.educatorDescription();
  }

  isAddingTravelBefore() {
    return this.form.get("addingTravelBefore").value
  }

  startAddingTravelBefore() {
    this.form.get("addingTravelBefore").setValue(true)
  }

  removeTravelBefore() {
    this.form.get("addingTravelBefore").setValue(false)
  }

  isAddingTravelAfter() {
    return this.form.get("addingTravelAfter").value
  }

  startAddingTravelAfter() {
    this.form.get("addingTravelAfter").setValue(true)
  }

  removeTravelAfter() {
    this.form.get("addingTravelAfter").setValue(false)
  }

  isAddingTravelDuring() {
    return this.form.get("addingTravelDuring").value
  }

  startAddingTravelDuring() {
    this.form.get("addingTravelDuring").setValue(true)
  }

  removeTravelDuring() {
    this.form.get("addingTravelDuring").setValue(false)
  }

  getDeclaredTravelDistanceOfTravelDuring() {
    const distance = this.form.get("travelDistance").value;
    return distance ? distance : 0;
  }

  travelBeforeAddress(): InlinableAddress {
    return this.travelForm.addressBefore();
  }

  travelAfterAddress(): InlinableAddress {
    return this.travelForm.addressAfter();
  }
}
