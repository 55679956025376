import {RepitEnum} from "../../common/RepitEnum";

export namespace StaffActivityTypeEnum {
  export class StaffActivityType extends RepitEnum {
    constructor(value: string, label: string) {
      super(value, label);
    }
  }

  export const TIME_OFF = new StaffActivityType("TIME_OFF", "Congé");
  export const SICK_LEAVE = new StaffActivityType("SICK_LEAVE", "Maladie");
  export const VA_OUT = new StaffActivityType("VA_OUT", "VA");

  export const VALUES: StaffActivityType[] = [
    TIME_OFF,
    SICK_LEAVE,
    VA_OUT,
  ];

  const BY_VALUE = new Map<string, StaffActivityType>();
  VALUES.forEach(item => BY_VALUE.set(item.value, item));

  export function fromValue(value: string): StaffActivityType {
    return BY_VALUE.get(value);
  }
}