<div class="m-3" [formGroup]="form">

    <h1 mat-dialog-title>Modifier l'horaire</h1>
    <mat-dialog-content>
        <mat-form-field class="small-input">
            <mat-label>Début</mat-label>
            <input matInput type="text" formControlName="startTime">
            <mat-hint>HH:mm (10:00)</mat-hint>
            <button *ngIf="form.value.startTime" matSuffix mat-icon-button aria-label="Clear"
                    (click)="form.patchValue({startTime:''})" tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field class="small-input">
            <mat-label>Fin</mat-label>
            <input matInput type="text" formControlName="endTime">
            <mat-hint>HH:mm (13:00)</mat-hint>
            <button *ngIf="form.value.endTime" matSuffix mat-icon-button aria-label="Clear"
                    (click)="form.patchValue({endTime:''})" tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </mat-dialog-content>


    <mat-dialog-actions class="d-flex gap-2 justify-content-end">
        <button mat-dialog-close class="btn btn-secondary">Annuler</button>
        <button class="btn btn-primary" id="confirm" [disabled]="!isValid()" (click)="update()">Modifier</button>
    </mat-dialog-actions>

</div>