import {Component, OnInit} from '@angular/core';
import {PageEvent} from "@angular/material/paginator";
import {InvoicedRepitBeneficiaryDto} from "../../../../generated/api";
import {Year} from "../../../models/calendar/Year";
import {Month} from "../../../models/calendar/Month";
import {InvoiceService} from "../../../models/document/invoice/invoice.service";
import {CalendarService} from "../../../models/calendar/calendar.service";


@Component({
  selector: 'app-generate-repit-invoice',
  templateUrl: './generate-repit-invoice.component.html',
  styleUrls: ['./generate-repit-invoice.component.scss']
})
export class GenerateRepitInvoiceComponent implements OnInit {
  repitBeneficiaries: InvoicedRepitBeneficiaryDto[] = []
  selectedYear: Year;
  selectedMonth: Month;
  months: Month[] = [];
  years: Year[] = [];
  pageIndex = 0;
  pageSize = 20;
  isLoading: boolean = true;

  constructor(private invoiceService: InvoiceService, private calendarService: CalendarService) {
  }

  ngOnInit(): void {
    this.months = this.calendarService.getMonths();
    this.years = this.calendarService.getYears();
    this.selectedYear = this.calendarService.getCurrentYear();
    this.selectedMonth = this.calendarService.getCurrentMonth();
    this.loadBeneficiariesToInvoice();
  }

  private loadBeneficiariesToInvoice() {
    this.isLoading = true;
    this.invoiceService.fetchRepitInvoiceableBeneficiaries(this.selectedMonth, this.selectedYear).subscribe((beneficiaries => {
      this.repitBeneficiaries = beneficiaries.sort((a, b) => a.lastName > b.lastName ? 1 : -1);
      this.isLoading = false;
    }));
  }

  public updateBeneficiaries() {
    this.loadBeneficiariesToInvoice();
  }

  generateAndDownloadRepitInvoice(beneficiary: InvoicedRepitBeneficiaryDto) {
    this.invoiceService.generateRepitInvoice(beneficiary, this.selectedMonth, this.selectedYear);
  }

  hasBeneficiaries(): boolean {
    return this.repitBeneficiaries.length > 0;
  }

  refreshPagination($event: PageEvent) {
    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
  }

  paginatedBeneficiaries(): InvoicedRepitBeneficiaryDto[] {
    const end = (this.pageIndex + 1) * this.pageSize;
    const start = this.pageIndex * this.pageSize;
    return this.repitBeneficiaries.slice(start, end);
  }
}
