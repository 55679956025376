import {AfterViewInit, Component, computed, inject, OnInit, signal, ViewChild} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource
} from "@angular/material/table";
import {MatSort, MatSortHeader} from "@angular/material/sort";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {Year} from "../../../models/calendar/Year";
import {CalendarService} from "../../../models/calendar/calendar.service";
import {Store} from "@ngxs/store";
import {
  ActivityReportState,
  FindMyActivityReport,
  SelectMyDailyReport,
  SelectMyWeeklyReport,
  UnselectMyDailyReport,
  UnselectMyWeeklyReport
} from "../../../stores/activity-report/activity-report.state";
import {toObservable, toSignal} from "@angular/core/rxjs-interop";
import {DailyReport, WeeklyReport} from "../../../models/activity-report/activity-report";
import {tap} from "rxjs/operators";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {DurationPipe} from "../../pipe/duration.pipe";

@Component({
  selector: 'app-my-activities',
  standalone: true,
  imports: [
    FormsModule,
    MatTable,
    MatHeaderRow,
    MatRow,
    MatRowDef,
    MatHeaderRowDef,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    MatCellDef,
    MatHeaderCellDef,
    MatSortHeader,
    MatSort,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
    MatIcon,
    NgIf,
    MatIconButton,
    DatePipe,
    DurationPipe,

  ],
  templateUrl: './my-activities.component.html',
  styleUrl: './my-activities.component.scss'
})
export class MyActivitiesComponent implements OnInit, AfterViewInit {
  calendarService = inject(CalendarService);
  store = inject(Store);

  yearlyReport = toSignal(this.store.select(ActivityReportState.myYearlyReport));
  weeklyReport = toSignal(this.store.select(ActivityReportState.myWeeklyReport));
  selectedWeek = toSignal(this.store.select(ActivityReportState.selectedWeek));
  dailyReport = toSignal(this.store.select(ActivityReportState.myDailyReport));
  selectedDay = toSignal(this.store.select(ActivityReportState.selectedDay));

  yearlyReportDisplayedColumns: string[] = ['weekNumber', 'workedHours', 'otherValuedHours', 'totalValuedHours', "expectedHours", "weeklyDifference", "yearlyDifference", "detail"];
  weeklyReportDisplayedColumns: string[] = ['date', 'workedHours', 'otherValuedHours', 'totalValuedHours', "expectedHours", "dailyDifference", "detail"];
  yearlyReportDataSource = new MatTableDataSource<WeeklyReport>([]);
  weeklyReportDataSource = new MatTableDataSource<DailyReport>([]);
  yearFilter = signal<Year>(new Year(new Date().getFullYear()));

  @ViewChild(MatSort) sort: MatSort;

  constructor() {
    toObservable(this.yearlyReport)
      .pipe(tap(report => this.yearlyReportDataSource.data = report?.weeks ?? []))
      .subscribe();
    toObservable(this.weeklyReport)
      .pipe(tap(report => this.weeklyReportDataSource.data = report?.days ?? []))
      .subscribe();
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.yearFilter.set(this.calendarService.getCurrentYear());
    this.store.dispatch(new FindMyActivityReport(this.yearFilter()));
    this.yearlyReportDataSource.sort = this.sort;
    this.weeklyReportDataSource.sort = this.sort;
  }

  years = computed(() => this.calendarService.getYears());

  resetReport() {
    this.store.dispatch(new FindMyActivityReport(this.yearFilter()));
  }

  selectWeek(week: WeeklyReport) {
    this.store.dispatch(new SelectMyWeeklyReport(week));
  }

  closeWeek() {
    this.store.dispatch(new UnselectMyWeeklyReport());
  }

  showWeekDetail = computed(() => {
    return this.selectedWeek() !== null;
  });

  showDayDetail = computed(() => {
    return this.selectedDay() !== null;
  });

  selectDay(day: DailyReport) {
    this.store.dispatch(new SelectMyDailyReport(day));
  }

  closeDay() {
    this.store.dispatch(new UnselectMyDailyReport());
  }

  isWeekSelected(row) {
    return this.selectedWeek() === row;
  }

  isDaySelected(row) {
    return this.selectedDay() === row;
  }
}
