import {Component, input, OnInit} from '@angular/core';
import {FormControl, FormGroupDirective, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-clearable-input-text',
  standalone: true,
  imports: [
    MatHint,
    FormsModule,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatSuffix,
    NgIf,
    ReactiveFormsModule
  ],
  templateUrl: './clearable-input-text.component.html',
  styleUrl: './clearable-input-text.component.scss'
})
export class ClearableInputTextComponent implements OnInit {

  controlName = input.required<string>()
  label = input.required<string>()
  hint = input<string>()
  inputClass = input<string>()
  formControl: FormControl;

  constructor(private rootFormGroup: FormGroupDirective) {
  }

  ngOnInit(): void {
    this.formControl = this.rootFormGroup.control.get(this.controlName()) as FormControl;
  }

}
