/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {
    HttpClient,
    HttpContext,
    HttpEvent,
    HttpHeaders,
    HttpParameterCodec,
    HttpParams,
    HttpResponse
} from '@angular/common/http';
import {CustomHttpParameterCodec} from '../encoder';
import {Observable} from 'rxjs';

// @ts-ignore
import {StaffActivityCancellationDto} from '../model/staffActivityCancellationDto';
// @ts-ignore
import {StaffActivityRefusalDto} from '../model/staffActivityRefusalDto';
// @ts-ignore
import {StaffActivityRequestDto} from '../model/staffActivityRequestDto';
// @ts-ignore
import {TimeOffAcceptationDto} from '../model/timeOffAcceptationDto';
// @ts-ignore
import {TimeOffRequestCreationDto} from '../model/timeOffRequestCreationDto';
// @ts-ignore
import {TimeOutAcceptationDto} from '../model/timeOutAcceptationDto';
// @ts-ignore
import {TimeOutRequestCreationDto} from '../model/timeOutRequestCreationDto';

// @ts-ignore
import {BASE_PATH, COLLECTION_FORMATS} from '../variables';
import {Configuration} from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class StaffActivityControllerService {

  protected basePath = 'http://localhost:8880';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string | string[], @Optional() configuration: Configuration) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }


  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === "object") {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
          httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }

  /**
   * @param timeOffAcceptationDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public acceptTimeOff(timeOffAcceptationDto: TimeOffAcceptationDto, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any>;
  public acceptTimeOff(timeOffAcceptationDto: TimeOffAcceptationDto, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpResponse<any>>;
  public acceptTimeOff(timeOffAcceptationDto: TimeOffAcceptationDto, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpEvent<any>>;
  public acceptTimeOff(timeOffAcceptationDto: TimeOffAcceptationDto, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any> {
    if (timeOffAcceptationDto === null || timeOffAcceptationDto === undefined) {
      throw new Error('Required parameter timeOffAcceptationDto was null or undefined when calling acceptTimeOff.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/staff/activity/request/accept/timeoff`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: timeOffAcceptationDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

    /**
     * @param timeOutAcceptationDto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public acceptTimeOut(timeOutAcceptationDto: TimeOutAcceptationDto, observe?: 'body', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<any>;
    public acceptTimeOut(timeOutAcceptationDto: TimeOutAcceptationDto, observe?: 'response', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<HttpResponse<any>>;
    public acceptTimeOut(timeOutAcceptationDto: TimeOutAcceptationDto, observe?: 'events', reportProgress?: boolean, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<HttpEvent<any>>;
    public acceptTimeOut(timeOutAcceptationDto: TimeOutAcceptationDto, observe: any = 'body', reportProgress: boolean = false, options?: {
        httpHeaderAccept?: undefined,
        context?: HttpContext
    }): Observable<any> {
        if (timeOutAcceptationDto === null || timeOutAcceptationDto === undefined) {
            throw new Error('Required parameter timeOutAcceptationDto was null or undefined when calling acceptTimeOut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/staff/activity/request/accept/timeout`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
          {
              context: localVarHttpContext,
              body: timeOutAcceptationDto,
              responseType: <any>responseType_,
              withCredentials: this.configuration.withCredentials,
              headers: localVarHeaders,
              observe: observe,
              reportProgress: reportProgress
      }
    );
  }

  /**
   * @param staffActivityCancellationDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cancelActivityRequest(staffActivityCancellationDto: StaffActivityCancellationDto, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any>;
  public cancelActivityRequest(staffActivityCancellationDto: StaffActivityCancellationDto, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpResponse<any>>;
  public cancelActivityRequest(staffActivityCancellationDto: StaffActivityCancellationDto, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpEvent<any>>;
  public cancelActivityRequest(staffActivityCancellationDto: StaffActivityCancellationDto, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any> {
    if (staffActivityCancellationDto === null || staffActivityCancellationDto === undefined) {
      throw new Error('Required parameter staffActivityCancellationDto was null or undefined when calling cancelActivityRequest.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/staff/activity/request/cancel`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: staffActivityCancellationDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findRequestById(id: number, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<StaffActivityRequestDto>;
  public findRequestById(id: number, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<StaffActivityRequestDto>>;
  public findRequestById(id: number, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<StaffActivityRequestDto>>;
  public findRequestById(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling findRequestById.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/staff/activity/request/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int64"
    })}`;
    return this.httpClient.request<StaffActivityRequestDto>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param staffId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findRequestsByStaffId(staffId: number, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<Array<StaffActivityRequestDto>>;
  public findRequestsByStaffId(staffId: number, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<Array<StaffActivityRequestDto>>>;
  public findRequestsByStaffId(staffId: number, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<Array<StaffActivityRequestDto>>>;
  public findRequestsByStaffId(staffId: number, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {
    if (staffId === null || staffId === undefined) {
      throw new Error('Required parameter staffId was null or undefined when calling findRequestsByStaffId.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/staff/activity/requests/staff/${this.configuration.encodeParam({
      name: "staffId",
      value: staffId,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int64"
    })}`;
    return this.httpClient.request<Array<StaffActivityRequestDto>>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param staffActivityRefusalDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public refuseActivityRequest(staffActivityRefusalDto: StaffActivityRefusalDto, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any>;
  public refuseActivityRequest(staffActivityRefusalDto: StaffActivityRefusalDto, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpResponse<any>>;
  public refuseActivityRequest(staffActivityRefusalDto: StaffActivityRefusalDto, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<HttpEvent<any>>;
  public refuseActivityRequest(staffActivityRefusalDto: StaffActivityRefusalDto, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext
  }): Observable<any> {
    if (staffActivityRefusalDto === null || staffActivityRefusalDto === undefined) {
      throw new Error('Required parameter staffActivityRefusalDto was null or undefined when calling refuseActivityRequest.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/staff/activity/request/refuse`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: staffActivityRefusalDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param timeOffRequestCreationDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public requestTimeOff(timeOffRequestCreationDto: TimeOffRequestCreationDto, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<number>;
  public requestTimeOff(timeOffRequestCreationDto: TimeOffRequestCreationDto, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<number>>;
  public requestTimeOff(timeOffRequestCreationDto: TimeOffRequestCreationDto, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<number>>;
  public requestTimeOff(timeOffRequestCreationDto: TimeOffRequestCreationDto, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {
    if (timeOffRequestCreationDto === null || timeOffRequestCreationDto === undefined) {
      throw new Error('Required parameter timeOffRequestCreationDto was null or undefined when calling requestTimeOff.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/staff/activity/request/create/timeoff`;
    return this.httpClient.request<number>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: timeOffRequestCreationDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param timeOutRequestCreationDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public requestTimeOut(timeOutRequestCreationDto: TimeOutRequestCreationDto, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<number>;
  public requestTimeOut(timeOutRequestCreationDto: TimeOutRequestCreationDto, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpResponse<number>>;
  public requestTimeOut(timeOutRequestCreationDto: TimeOutRequestCreationDto, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<HttpEvent<number>>;
  public requestTimeOut(timeOutRequestCreationDto: TimeOutRequestCreationDto, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: 'application/json',
    context?: HttpContext
  }): Observable<any> {
    if (timeOutRequestCreationDto === null || timeOutRequestCreationDto === undefined) {
      throw new Error('Required parameter timeOutRequestCreationDto was null or undefined when calling requestTimeOut.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json'
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/staff/activity/request/create/timeout`;
    return this.httpClient.request<number>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: timeOutRequestCreationDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
