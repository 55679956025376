<h1>Modifier l'adresse</h1>

<div mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="confirm()">
        <mat-form-field>
            <mat-label>Numéro</mat-label>
            <input matInput type="text" formControlName="streetNumber">
            <button *ngIf="address.streetNumber" type="button" matSuffix mat-icon-button aria-label="Clear"
                    (click)="form.patchValue({streetNumber: ''})" tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Rue</mat-label>
            <input matInput type="text" formControlName="streetName">
            <button *ngIf="address.streetName" type="button" matSuffix mat-icon-button aria-label="Clear"
                    (click)="form.patchValue({streetName: ''})" tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Code postal</mat-label>
            <input matInput type="text" formControlName="zipCode">
            <button *ngIf="address.zipCode" type="button" matSuffix mat-icon-button aria-label="Clear"
                    (click)="form.patchValue({zipCode: ''})" tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Localité</mat-label>
            <input matInput type="text" formControlName="city">
            <button *ngIf="address.city" type="button" matSuffix mat-icon-button aria-label="Clear"
                    (click)="form.patchValue({city: ''})" tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <div mat-dialog-actions class="d-flex gap-2">
            <button class="btn btn-secondary" mat-dialog-close="">Annuler</button>
            <button class="btn btn-danger" [disabled]="form.invalid" mat-dialog-close="" (click)="confirm()">Confirmer
            </button>
        </div>
    </form>
</div>