import {Component, inject, OnInit, Signal, signal} from '@angular/core';
import {CalendarEvent, CalendarView, DAYS_OF_WEEK,} from 'angular-calendar';
import {Store} from "@ngxs/store";
import {toSignal} from "@angular/core/rxjs-interop";
import {WorkEventSelectors} from "../../../stores/work-event/work-event.selectors";
import {FindWorkEventsForStaffMember} from "../../../stores/work-event/work-event.actions";

@Component({
  selector: 'app-my-work-schedule',
  templateUrl: './my-work-schedule.component.html',
  styleUrls: ['./my-work-schedule.component.scss']
})
export class MyWorkScheduleComponent implements OnInit {

  store = inject(Store);
  staffMemberId = signal(1);
  events: Signal<CalendarEvent[]> = toSignal(this.store.select(WorkEventSelectors.workEvents()));

  view: CalendarView = CalendarView.Month;
  viewDate = new Date();
  locale: string = 'fr-BE';
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];
  CalendarView = CalendarView;

  ngOnInit(): void {
    this.store.dispatch(new FindWorkEventsForStaffMember(this.staffMemberId()))
  }

  setView(view: CalendarView) {
    this.view = view;
  }


}
