import {Action, State, StateContext} from "@ngxs/store";
import {inject, Injectable} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {
  SnackBarWithActionDispatchComponent
} from "../../views/notification/snack-bar-with-action-dispatch/snack-bar-with-action-dispatch.component";

export interface NotificationStateModel {

}

export class ShowInfo {
  public static readonly type = '[Notification] Show info';

  constructor(public message: string, public action?: any) {
  }
}

@State<NotificationStateModel>({
  name: 'notification',
  defaults: {}
})
@Injectable()
export class NotificationState {

  _snackBar = inject(MatSnackBar);

  @Action(ShowInfo)
  public showInfo(ctx: StateContext<NotificationStateModel>, action: ShowInfo) {
    if (action.action) {
      let snackBarRef = this._snackBar.openFromComponent(SnackBarWithActionDispatchComponent, {
        data: action.message
      });
      snackBarRef.onAction().subscribe(() => {
        ctx.dispatch(action.action);
      });
    } else {
      this._snackBar.open(action.message, 'Fermer');
    }
  }

}
