<div class="m-3" [formGroup]="form">
    <h1 mat-dialog-title>Modifier les trajets</h1>
    <mat-dialog-content>
        <app-update-prestation-travels formGroupName="travels"
                                       [educatorDescription]="input.educatorTravelDescription"
                                       [expandTravelsAtOpening]="false">
        </app-update-prestation-travels>
    </mat-dialog-content>

    <mat-dialog-actions class="d-flex gap-2 justify-content-end">
        <button mat-dialog-close class="btn btn-secondary">Annuler</button>
        <button class="btn btn-primary" [disabled]="!isValid()" (click)="update()">Modifier</button>
    </mat-dialog-actions>
</div>