import {Pipe, PipeTransform} from '@angular/core';
import {FirstContactApplicationRefusalMotive} from "../../models/first-contact/RefusalMotive";

@Pipe({
  name: 'refusalMotive'
})
export class RefusalMotivePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    return value ? FirstContactApplicationRefusalMotive.fromValue(value).label : "";
  }

}
