import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {FirstContactService} from "../../../models/first-contact/first-contact.service";
import {CreateBeneficiaryService} from "../../../models/beneficiary/create-beneficiary.service";
import {RefuseFirstContactComponent} from "../refuse-first-contact/refuse-first-contact.component";
import {FirstContactApplicationStatusEnum} from "../../../models/first-contact/FirstContactApplicationStatusEnum";
import {FirstContactApplicationResponse} from "../../../../generated/api";

@Component({
  selector: 'app-detail-first-contact',
  templateUrl: './detail-first-contact.component.html',
  styleUrls: ['./detail-first-contact.component.scss']
})
export class DetailFirstContactComponent implements OnInit {

  firstContact: FirstContactApplicationResponse = {};

  constructor(private route: ActivatedRoute, private firstContactService: FirstContactService, public dialog: MatDialog, private createBeneficiaryService: CreateBeneficiaryService) {
  }

  ngOnInit(): void {
    const id = Number(this.route.snapshot.paramMap.get('id'));
    this.firstContactService.fetch(id).subscribe(response => this.firstContact = response);
  }

  refuse() {
    const dialogRef = this.dialog.open(RefuseFirstContactComponent)
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.firstContactService.refuse(this.firstContact.id, result.motive, result.note)
          .subscribe(response => this.firstContact = response);
      }
    });
  }

  isRefused() {
    return FirstContactApplicationStatusEnum.fromValue(this.firstContact.status) === FirstContactApplicationStatusEnum.REFUSED;
  }

  accept() {
    this.firstContactService.accept(this.firstContact.id)
      .subscribe(response => this.firstContact = response);
  }

  isAccepted() {
    return FirstContactApplicationStatusEnum.fromValue(this.firstContact.status) === FirstContactApplicationStatusEnum.ACCEPTED;
  }

  canCreateBeneficiary() {
    // TODO RHA make this button visible only if beneficiary has not been created yet
    return this.isAccepted();
  }

  createBeneficiary() {
    this.createBeneficiaryService.initiateFromFirstContact(this.firstContact);
  }
}
