import {InlinableAddress} from "./InlinableAddress";

export class AddressWithCoordinates implements InlinableAddress {
  id: number;
  streetNumber: string;
  streetName: string;
  zipCode: string;
  city: string;
  latitude?: number;
  longitude?: number;

  constructor(id: number, streetNumber: string, streetName: string, zipCode: string, city: string, latitude: number, longitude: number) {
    this.id = id;
    this.streetNumber = streetNumber;
    this.streetName = streetName;
    this.zipCode = zipCode;
    this.city = city;
    this.latitude = latitude;
    this.longitude = longitude;
  }

  isSameAs(address: InlinableAddress): boolean {
    return this.streetNumber === address.streetNumber
      && this.streetName === address.streetName
      && this.zipCode === address.zipCode
      && this.city === address.city;
  }

}