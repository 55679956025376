import {Component, input, OnInit} from '@angular/core';
import {FormGroup, FormGroupDirective, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatHint, MatLabel} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatIconButton} from "@angular/material/button";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-create-address-fields',
  standalone: true,
  templateUrl: './create-address-fields.component.html',
  imports: [
    ReactiveFormsModule,
    MatLabel,
    MatFormField,
    MatIcon,
    MatInput,
    MatIconButton,
    NgIf,
    MatHint
  ],
  styleUrls: ['./create-address-fields.component.scss']
})
export class CreateAddressFieldsComponent implements OnInit {

  formGroupName = input.required<string>()
  form: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective) {
  }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName()) as FormGroup;
  }
}
