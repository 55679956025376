import {Pipe, PipeTransform} from '@angular/core';
import {SchedulerDay} from "../../models/scheduler/SchedulerDay";
import {formatDate} from "@angular/common";

@Pipe({
  standalone: true,
  name: 'schedulerDay'
})
export class SchedulerDayPipe implements PipeTransform {


  transform(day: SchedulerDay): unknown {
    return formatDate(day.date, "E dd/MM/yyyy", "fr-BE");
  }

}
