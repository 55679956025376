import {FormGroup} from "@angular/forms";
import {InlinableAddress} from "../../models/address/InlinableAddress";
import {PrestationTravelTypeEnum} from "../../models/prestation/PrestationTravelType";
import {PrestationTravelCreationRequest} from "../../models/prestation/PrestationTravelCreationRequest";


export class TravelForm {
  form: FormGroup;

  constructor(form: FormGroup) {
    this.form = form;
  }

  travelBeforeRequest() {
    if (!this.isAddingTravelBefore()) return null;
    return {
      address: this.address("addressBefore"),
      type: PrestationTravelTypeEnum.BEFORE,
      description: this.form.get("travelBeforeDescription").value,
    } as PrestationTravelCreationRequest
  }

  travelAfterRequest() {
    if (!this.isAddingTravelAfter()) return null;
    return {
      address: this.address("addressAfter"),
      type: PrestationTravelTypeEnum.AFTER,
      description: this.form.get("travelAfterDescription").value,
    } as PrestationTravelCreationRequest
  }

  travelDuringDescription() {
    if (!this.isAddingTravelDuring()) return null;
    return this.form.get("travelDescription").value;
  }

  travelDuringDistance() {
    if (!this.isAddingTravelDuring()) return null;
    return this.form.get("travelDistance").value;
  }

  addressBefore() {
    return this.address("addressBefore");
  }

  addressAfter() {
    return this.address("addressAfter");
  }

  private isAddingTravelDuring() {
    return this.form.get("addingTravelDuring").value
  }

  private isAddingTravelBefore() {
    return this.form.get("addingTravelBefore").value
  }

  private isAddingTravelAfter() {
    return this.form.get("addingTravelAfter").value
  }

  private address(formGroupName: string): InlinableAddress {
    return {
      streetName: this.form.get(formGroupName + ".streetName").value,
      streetNumber: this.form.get(formGroupName + ".streetNumber").value,
      zipCode: this.form.get(formGroupName + ".zipCode").value,
      city: this.form.get(formGroupName + ".city").value,
    };
  }
}