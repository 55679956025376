/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {SimpleBeneficiaryDto} from './simpleBeneficiaryDto';
import {DateTimeRangeDto} from './dateTimeRangeDto';
import {PrestationStatusDto} from './prestationStatusDto';
import {PrestationTypeDto} from './prestationTypeDto';


export interface PrestationDto {
  id?: number;
  status?: PrestationStatusDto;
  type?: PrestationTypeDto;
  beneficiary?: SimpleBeneficiaryDto;
  schedule?: DateTimeRangeDto;
}

