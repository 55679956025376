import {Component, Inject, OnInit} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {
  BeneficiaryContactDto,
  BeneficiaryContactLinkTypeDto,
  BeneficiaryContactTypeDto
} from "../../../../generated/api";
import {BeneficiaryService} from "../../../models/beneficiary/beneficiary.service";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatIconButton} from "@angular/material/button";
import {MatOption, MatSelect} from "@angular/material/select";
import {CreatePersonFieldsComponent} from "../../components/create-person-fields/create-person-fields.component";


@Component({
  selector: 'app-edit-contact',
  standalone: true,
  templateUrl: './edit-contact.component.html',
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatIcon,
    MatInput,
    MatIconButton,
    ReactiveFormsModule,
    NgIf,
    MatSelect,
    MatOption,
    NgForOf,
    CreatePersonFieldsComponent,
    MatDialogActions,
    MatDialogClose
  ],
  styleUrls: ['./edit-contact.component.scss']
})
export class EditContactComponent implements OnInit {

  form: FormGroup = new FormGroup({
    contact: new FormGroup({
      firstName: new FormControl("", Validators.required),
      lastName: new FormControl("", Validators.required),
      birthDate: new FormControl(""),
      gender: new FormControl(null, Validators.required)
    }),
    contactEmail: new FormControl(""),
    contactPhone: new FormControl("", Validators.required),
    contactType: new FormControl(null, Validators.required),
    contactLinkType: new FormControl(null, Validators.required)
  })

  contactTypes: BeneficiaryContactTypeDto[] = [];
  contactLinkTypes: BeneficiaryContactLinkTypeDto[] = [];


  constructor(
    public dialogRef: MatDialogRef<EditContactComponent>,
    private beneficiaryService: BeneficiaryService,
    @Inject(MAT_DIALOG_DATA) private beneficiaryContact: BeneficiaryContactDto,
  ) {
  }

  ngOnInit(): void {
    this.beneficiaryService.contactTypes().subscribe(types => this.contactTypes = types);
    this.beneficiaryService.contactLinkTypes().subscribe(linkTypes => this.contactLinkTypes = linkTypes);
    this.form.get("contact.firstName").setValue(this.beneficiaryContact.legalRepresentative.personInfo.firstName);
    this.form.get("contact.lastName").setValue(this.beneficiaryContact.legalRepresentative.personInfo.lastName);
    this.form.get("contact.birthDate").setValue(this.beneficiaryContact.legalRepresentative.personInfo.birthDate);
    this.form.get("contact.gender").setValue(this.beneficiaryContact.legalRepresentative.personInfo.gender);
    this.form.get("contactEmail").setValue(this.beneficiaryContact.legalRepresentative.email);
    this.form.get("contactPhone").setValue(this.beneficiaryContact.legalRepresentative.phoneNumber);
    this.form.get("contactType").setValue(this.beneficiaryContact.type);
    this.form.get("contactLinkType").setValue(this.beneficiaryContact.linkType);
  }

  confirm() {
    this.beneficiaryContact.legalRepresentative.personInfo.firstName = this.form.get("contact.firstName").value;
    this.beneficiaryContact.legalRepresentative.personInfo.lastName = this.form.get("contact.lastName").value;
    this.beneficiaryContact.legalRepresentative.personInfo.birthDate = this.form.get("contact.birthDate").value;
    this.beneficiaryContact.legalRepresentative.personInfo.gender = this.form.get("contact.gender").value;
    this.beneficiaryContact.legalRepresentative.email = this.form.get("contactEmail").value;
    this.beneficiaryContact.legalRepresentative.phoneNumber = this.form.get("contactPhone").value;
    this.beneficiaryContact.type = this.form.get("contactType").value;
    this.beneficiaryContact.linkType = this.form.get("contactLinkType").value;

    this.dialogRef.close()
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.value === c2.value : c1 === c2;
  }
}
