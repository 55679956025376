import {Component, input, OnInit} from '@angular/core';
import {MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {NgIf} from "@angular/common";
import {FormControl, FormGroupDirective, ReactiveFormsModule} from "@angular/forms";
import {MatDatepickerInput} from "@angular/material/datepicker";

@Component({
  selector: 'app-time-picker',
  standalone: true,
  imports: [
    MatFormField,
    MatHint,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatSuffix,
    NgIf,
    ReactiveFormsModule,
    MatDatepickerInput
  ],
  templateUrl: './time-picker.component.html',
  styleUrl: './time-picker.component.scss'
})
export class TimePickerComponent implements OnInit {

  controlName = input.required<string>()
  label = input.required<string>()
  hint = input<string>("HH:mm (10:00)")
  formControl: FormControl;

  constructor(private rootFormGroup: FormGroupDirective) {
  }

  ngOnInit(): void {
    this.formControl = this.rootFormGroup.control.get(this.controlName()) as FormControl;
  }

}
