import {Action, NgxsOnInit, Selector, State, StateContext, Store} from '@ngxs/store';
import {inject, Injectable} from "@angular/core";
import {StaffMemberSummary} from "../../models/staff/StaffMemberSummary.model";
import {
  AcceptStaffActivityRequest,
  ActiveEducatorsRetrieved,
  CancelStaffActivityRequest,
  ConfirmSickLeaveCreation,
  ConfirmStaffActivityRequestCancellation,
  ConfirmStaffActivityRequestRefusal,
  ConfirmTimeOffRequestAcceptance,
  ConfirmTimeOutRequestAcceptance,
  CreateSickLeave,
  CreateTimeOffRequest,
  CreateTimeOutCompRequest,
  FindActivityRequest,
  FindMyStaffActivityRequests,
  FindStaffActivityRequests,
  RefuseStaffActivityRequest,
  RetrieveActiveEducators,
  RetrieveStaffMember,
  RetrieveStaffMembers,
  StaffMemberRetrieved,
  StaffMembersRetrieved,
  UpdateStaffMemberAddress
} from "./staff.actions";
import {StaffGateway} from "../../ports/StaffGateway";
import {tap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {StaffMemberDetail} from "../../models/staff/StaffMemberDetail";
import {MatDialog} from "@angular/material/dialog";
import {ShowInfo} from "../notification/notification.state";
import {StaffActivityTypeEnum} from "../../models/staff/activity/StaffActivityType";
import {StaffActivityRequestStatusEnum} from "../../models/staff/activity/StaffActivityRequestStatus";
import {StaffActivityRequest} from "../../models/staff/activity/StaffActivityRequest";
import {UserState} from "../user/user.state";
import {
  CancelStaffActivityRequestDialogComponent
} from "../../views/my-work/cancel-staff-activity-request-dialog/cancel-staff-activity-request-dialog.component";
import {
  RefuseStaffActivityRequestDialogComponent
} from "../../views/my-work/refuse-staff-activity-request-dialog/refuse-staff-activity-request-dialog.component";
import {
  AcceptStaffActivityRequestDialogComponentComponent
} from "../../views/my-work/accept-staff-activity-request-dialog-component/accept-staff-activity-request-dialog-component.component";
import {Year} from "../../models/calendar/Year";
import {CalendarService} from "../../models/calendar/calendar.service";
import {
  CreateSickLeaveDialogComponent
} from "../../views/activity/create-sick-leave-dialog/create-sick-leave-dialog.component";
import {waitForUser} from "../store-utils";
import StaffActivityType = StaffActivityTypeEnum.StaffActivityType;
import StaffActivityRequestStatus = StaffActivityRequestStatusEnum.StaffActivityRequestStatus;

export interface StaffStateModel {
  staffMembers: StaffMemberSummary[];
  activeEducators: StaffMemberSummary[];
  selectedStaffMember: StaffMemberDetail | null;
  selectedActivityRequest: StaffActivityRequest | null;
  myActivityRequests: StaffActivityRequest[];
  myActivityRequestFilter: StaffActivityRequestFilter;
  activityRequests: StaffActivityRequest[];
  activityRequestFilter: StaffActivityRequestFilter;
}

export type StaffActivityRequestFilter = {
  year?: Year,
  educator?: string,
  type: StaffActivityType[],
  status: StaffActivityRequestStatus[]
}

@State<StaffStateModel>({
  name: 'staff',
  defaults: {
    staffMembers: [],
    activeEducators: [],
    selectedStaffMember: null,
    selectedActivityRequest: null,
    myActivityRequests: [],
    myActivityRequestFilter: {
      educator: '',
      type: [],
      status: []
    },
    activityRequests: [],
    activityRequestFilter: {
      educator: '',
      type: [],
      status: []
    }
  }
})
@Injectable()
export class StaffState implements NgxsOnInit {

  staffGateway = inject(StaffGateway);
  store = inject(Store);
  dialog = inject(MatDialog);
  calendarService = inject(CalendarService);

  ngxsOnInit(ctx: StateContext<StaffStateModel>): void {
    const year = this.calendarService.getCurrentYear();
    const activityRequestFilter = ctx.getState().activityRequestFilter;
    activityRequestFilter.year = year;
    ctx.patchState({activityRequestFilter: activityRequestFilter});
  }

  @Action(RetrieveStaffMembers)
  public retrieveStaffMembers(ctx: StateContext<StaffStateModel>): Observable<StaffMemberSummary[]> {
    return this.staffGateway.retrieveAll().pipe(
      tap(staffMembers => {
        ctx.dispatch(new StaffMembersRetrieved(staffMembers));
      })
    )
  }

  @Action(StaffMembersRetrieved)
  public staffMembersRetrieved(ctx: StateContext<StaffStateModel>, action: StaffMembersRetrieved) {
    ctx.patchState({
      staffMembers: action.staffMembers
    });
  }

  @Action(RetrieveActiveEducators)
  public retrieveActiveEducators(ctx: StateContext<StaffStateModel>): Observable<StaffMemberSummary[]> {
    return this.staffGateway.retrieveActiveEducators().pipe(
      tap(activeEducators => {
        ctx.dispatch(new ActiveEducatorsRetrieved(activeEducators));
      })
    )
  }

  @Action(ActiveEducatorsRetrieved)
  public activeEducatorsRetrieved(ctx: StateContext<StaffStateModel>, action: ActiveEducatorsRetrieved) {
    ctx.patchState({
      activeEducators: action.educators
    });
  }

  @Action(RetrieveStaffMember)
  public retrieveStaffMember(ctx: StateContext<StaffStateModel>, action: RetrieveStaffMember) {
    return this.staffGateway.retrieve(action.staffMemberId).pipe(
      tap(staffMember => {
        ctx.dispatch(new StaffMemberRetrieved(staffMember));
      })
    )
  }

  @Action(StaffMemberRetrieved)
  public staffMemberRetrieved(ctx: StateContext<StaffStateModel>, action: StaffMemberRetrieved) {
    ctx.patchState({
      selectedStaffMember: action.staffMember
    });
  }

  @Action(UpdateStaffMemberAddress)
  public updateStaffMemberAddress(ctx: StateContext<StaffStateModel>, action: UpdateStaffMemberAddress) {
    return this.staffGateway.updateCurrentAddress(action.staffMemberId, action.addressToUpdate).pipe(
      tap((staffMemberDetail) => {
        ctx.patchState({
          selectedStaffMember: staffMemberDetail
        });
      })
    );
  }

  @Action(FindMyStaffActivityRequests)
  public findMyStaffActivityRequests(ctx: StateContext<StaffStateModel>, action: FindMyStaffActivityRequests) {
    const staffId = this.store.selectSnapshot(UserState.getCurrentStaffId)
    if (!staffId) {
      waitForUser(ctx, action);
      return of([])
    } else {
      if (action.filter) {
        ctx.patchState({
          myActivityRequestFilter: action.filter
        });
      }
      return this.staffGateway.findActivityRequestsForStaff(staffId).pipe(
        tap((requests) => {
          ctx.patchState({
            myActivityRequests: requests
          });
        })
      );
    }
  }

  @Action(FindStaffActivityRequests)
  public findStaffActivityRequests(ctx: StateContext<StaffStateModel>, action: FindStaffActivityRequests) {
    if (action.filter) {
      ctx.patchState({
        activityRequestFilter: action.filter
      });
    }
    if (!action.filter?.year) {
      return ctx.dispatch(new ShowInfo("Une période doit être spécifiée."));
    } else {
      return this.staffGateway.findActivityRequests(action.filter.year.value).pipe(
        tap((requests) => {
          ctx.patchState({
            activityRequests: requests
          });
        })
      );
    }
  }

  @Action(FindActivityRequest)
  public findActivityRequest(ctx: StateContext<StaffStateModel>, action: FindActivityRequest) {
    return this.staffGateway.findActivityRequest(action.activityRequestId).pipe(
      tap((request) => {
        ctx.patchState({
          selectedActivityRequest: request
        });
      })
    );
  }

  @Action(CreateSickLeave)
  public createSickLeave(ctx: StateContext<StaffStateModel>, action: CreateSickLeave) {
    return this.dialog.open(CreateSickLeaveDialogComponent);
  }

  @Action(ConfirmSickLeaveCreation)
  public confirmSickLeaveCreation(ctx: StateContext<StaffStateModel>, action: ConfirmSickLeaveCreation) {
    return this.staffGateway.createSickLeaveRequest(action.request.staffMemberId, action.request).pipe(
      tap(() => {
        if (action.close) {
          this.dialog.closeAll();
        }
        ctx.dispatch(new ShowInfo("La demande a bien été créée"));
        ctx.dispatch(new FindStaffActivityRequests(ctx.getState().activityRequestFilter));
      })
    );
  }

  @Action(CreateTimeOutCompRequest)
  public createTimeOutCompRequest(ctx: StateContext<StaffStateModel>, action: CreateTimeOutCompRequest) {
    return this.staffGateway.createTimeOutRequest(action.request.staffMemberId, action.request).pipe(
      tap(() => {
        if (action.close) {
          this.dialog.closeAll();
        }
        ctx.dispatch(new ShowInfo("La demande a bien été créée"))
        ctx.dispatch(new FindMyStaffActivityRequests(ctx.getState().myActivityRequestFilter));
      })
    );
  }

  @Action(CreateTimeOffRequest)
  public createTimeOffRequest(ctx: StateContext<StaffStateModel>, action: CreateTimeOffRequest) {
    return this.staffGateway.createTimeOffRequest(action.request.staffMemberId, action.request).pipe(
      tap(() => {
        if (action.close) {
          this.dialog.closeAll();
        }
        ctx.dispatch(new ShowInfo("La demande a bien été créée"));
        ctx.dispatch(new FindMyStaffActivityRequests(ctx.getState().myActivityRequestFilter));
      })
    );
  }

  @Action(CancelStaffActivityRequest)
  public cancelStaffActivityRequest(ctx: StateContext<StaffStateModel>, action: CancelStaffActivityRequest) {
    return this.dialog.open(CancelStaffActivityRequestDialogComponent, {
      data: {
        request: action.request
      }
    });
  }

  @Action(ConfirmStaffActivityRequestCancellation)
  public confirmStaffActivityRequestCancellation(ctx: StateContext<StaffStateModel>, action: ConfirmStaffActivityRequestCancellation) {
    return this.staffGateway.cancelActivityRequest(action.request.id).pipe(
      tap(() => {
        this.dialog.closeAll();
        ctx.dispatch(new FindActivityRequest(action.request.id));
        ctx.dispatch(new ShowInfo("La demande a bien été annulée"));
      })
    );
  }

  @Action(RefuseStaffActivityRequest)
  public refuseStaffActivityRequest(ctx: StateContext<StaffStateModel>, action: RefuseStaffActivityRequest) {
    return this.dialog.open(RefuseStaffActivityRequestDialogComponent, {
      data: {
        request: action.request
      }
    });
  }

  @Action(ConfirmStaffActivityRequestRefusal)
  public confirmStaffActivityRequestRefusal(ctx: StateContext<StaffStateModel>, action: ConfirmStaffActivityRequestRefusal) {
    return this.staffGateway.refuseActivityRequest(action.request.id, action.note).pipe(
      tap(() => {
        this.dialog.closeAll();
        ctx.dispatch(new FindActivityRequest(action.request.id));
        ctx.dispatch(new ShowInfo("La demande a bien été refusée"));
      })
    );
  }

  @Action(AcceptStaffActivityRequest)
  public acceptStaffActivityRequest(ctx: StateContext<StaffStateModel>, action: AcceptStaffActivityRequest) {
    return this.dialog.open(AcceptStaffActivityRequestDialogComponentComponent, {
      data: {
        request: action.request
      }
    });
  }

  @Action(ConfirmTimeOffRequestAcceptance)
  public ConfirmTimeOffRequestAcceptance(ctx: StateContext<StaffStateModel>, action: ConfirmTimeOffRequestAcceptance) {
    return this.staffGateway.acceptTimeOffRequest(action.request.id).pipe(
      tap(() => {
        this.dialog.closeAll();
        ctx.dispatch(new FindActivityRequest(action.request.id));
        ctx.dispatch(new ShowInfo("La demande a bien été acceptée"));
      })
    );
  }

  @Action(ConfirmTimeOutRequestAcceptance)
  public ConfirmTimeOutRequestAcceptance(ctx: StateContext<StaffStateModel>, action: ConfirmTimeOutRequestAcceptance) {
    return this.staffGateway.acceptTimeOutRequest(action.request.id).pipe(
      tap(() => {
        this.dialog.closeAll();
        ctx.dispatch(new FindActivityRequest(action.request.id));
        ctx.dispatch(new ShowInfo("La demande a bien été acceptée"));
      })
    );
  }

  @Selector()
  static activityRequestFilter(state: StaffStateModel) {
    return state.activityRequestFilter;
  }

  @Selector()
  static activityRequests(state: StaffStateModel) {
    return this.filterActivityRequest(state.activityRequests, state.activityRequestFilter);
  }

  @Selector()
  static myActivityRequestFilter(state: StaffStateModel) {
    return state.myActivityRequestFilter;
  }

  @Selector()
  static myActivityRequests(state: StaffStateModel) {
    return this.filterActivityRequest(state.myActivityRequests, state.myActivityRequestFilter);
  }

  private static filterActivityRequest(requests: StaffActivityRequest[], requestFilter: StaffActivityRequestFilter) {
    return requests
      .filter(request => !requestFilter.educator || request.staffMemberFullName.toLowerCase().includes(requestFilter.educator.toLowerCase()))
      .filter(request => requestFilter.type.length === 0 || requestFilter.type.includes(request.type))
      .filter(request => requestFilter.status.length === 0 || requestFilter.status.includes(request.status))
      .sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
  }

  @Selector()
  static selectedActivityRequest(state: StaffStateModel) {
    return state.selectedActivityRequest;
  }

}
