import {Component, computed, inject, OnInit} from '@angular/core';
import {Store} from "@ngxs/store";
import {toSignal} from "@angular/core/rxjs-interop";
import {UserState} from "../../../stores/user/user.state";

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

  store = inject(Store);
  user = toSignal(this.store.select(UserState.getCurrentUser), {initialValue: null});

  ngOnInit(): void {
  }

  loaded = computed(() => {
    return this.user();
  });

}
