import {PrestationToValidate} from "../../models/prestation/PrestationToValidate";
import {PrestationValidationRequest} from "../../models/prestation/PrestationValidationRequest";
import {PrestationToClose} from "../../models/prestation/PrestationToClose";
import {PrestationClosureRequest} from "../../models/prestation/PrestationClosureRequest";
import {PrestationSummary} from "../../models/prestation/PrestationSummary";
import {PrestationDetail} from "../../models/prestation/prestationDetail";
import {PrestationCreationRequest} from "../../models/prestation/PrestationCreationRequest";
import {PrestationRefusalMotiveEnum} from "../../models/prestation/PrestationRefusalMotive";
import {PrestationCancellationMotiveEnum} from "../../models/prestation/PrestationCancellationMotive";
import {PrestationListFilter} from "./prestation.state";
import {PrestationTravelCreationRequest} from "../../models/prestation/PrestationTravelCreationRequest";
import {InlinableAddress} from "../../models/address/InlinableAddress";
import {PrestationTypeEnum} from "../../models/prestation/PrestationType";
import {SchedulerTimePeriod} from "../../models/scheduler/SchedulerTimePeriod";
import PrestationRefusalMotive = PrestationRefusalMotiveEnum.PrestationRefusalMotive;
import PrestationCancellationMotive = PrestationCancellationMotiveEnum.PrestationCancellationMotive;
import PrestationType = PrestationTypeEnum.PrestationType;

export class FindPrestationSummaries {
  public static readonly type = '[Prestation list] List summaries';

  constructor(public filter?: PrestationListFilter) {
  }
}

export class PrestationSummariesFound {
  public static readonly type = '[Prestation API] Summaries found';

  constructor(public prestations: PrestationSummary[]) {
  }
}

export class FindPrestationsToValidateForCurrentUser {
  public static readonly type = '[Prestation list] List prestations to validate for current user';
}

export class PrestationsToValidateFound {
  public static readonly type = '[Prestation API] Prestations to validate found';

  constructor(public prestations: PrestationToValidate[]) {
  }
}

export class ValidatePrestation {
  public static readonly type = '[Prestation validate] Validate prestation';

  constructor(public request: PrestationValidationRequest) {
  }
}

export class AssignPrestation {
  public static readonly type = '[Prestation assign] Assign prestation';

  constructor(public prestationId: number, public maindEducatorId: number, public secondaryEducatorId?: number | null) {
  }

}

export class FindPrestationsToClose {
  public static readonly type = '[Prestation list] List prestations to close for current user';
}

export class PrestationsToCloseFound {
  public static readonly type = '[Prestation API] Prestations to close found';

  constructor(public prestations: PrestationToClose[]) {
  }
}

export class ClosePrestation {
  public static readonly type = '[Prestation close] Close prestation';

  constructor(public request: PrestationClosureRequest) {
  }
}

export class ViewPrestation {
  public static readonly type = '[Prestation detail] View prestation';

  constructor(public prestationId: number) {
  }
}

export class ViewPrestations {
  public static readonly type = '[Prestation list] View prestations';
}

export class FindPrestationDetail {
  public static readonly type = '[Prestation detail] Find prestation detail';

  constructor(public prestationId: number) {
  }
}

export class PrestationDetailFound {
  public static readonly type = '[Prestation API] Prestation detail found';

  constructor(public prestationDetail: PrestationDetail) {
  }
}

export class CreatePrestation {
  public static readonly type = '[Prestation create] Create prestation';

  constructor(public request: PrestationCreationRequest, public view: boolean) {
  }
}

export class RefusePrestation {
  public static readonly type = '[Prestation refuse] Refuse prestation';

  constructor(public prestationId: number, public refusalMotive: PrestationRefusalMotive, public note: string) {
  }
}

export class CancelPrestation {
  public static readonly type = '[Prestation cancel] Cancel prestation';

  constructor(public prestationId: number, public cancellationMotive: PrestationCancellationMotive, public note: string) {
  }
}

export class UpdatePrestationTravels {
  public static readonly type = '[Prestation update] Update prestation travels';

  constructor(public prestationId: number,
              public travelDuringDescription: string,
              public travelDuringDistance: number,
              public travelBefore: PrestationTravelCreationRequest | null,
              public travelAfter: PrestationTravelCreationRequest | null) {
  }
}

export class UpdatePrestationLocationAndContacts {
  public static readonly type = '[Prestation update] Update prestation location and contacts';

  constructor(public prestationId: number,
              public requestContactId: number,
              public invoiceContactId: number,
              public address: InlinableAddress) {
  }
}

export class SwitchBap {
  public static readonly type = '[Prestation switch] Switch BAP';

  constructor(public prestationId: number) {
  }
}

export class SwitchUrgent {
  public static readonly type = '[Prestation switch] Switch urgent';

  constructor(public prestationId: number) {
  }
}

export class UpdatePrestationSchedule {
  public static readonly type = '[Prestation update] Update prestation schedule';

  constructor(public prestationId: number, public schedule: SchedulerTimePeriod) {
  }
}

export class UpdatePrestationType {
  public static readonly type = '[Prestation update] Update prestation type';

  constructor(public prestationId: number, public type: PrestationType) {
  }
}

export class DeletePrestation {
  public static readonly type = '[Prestation delete] Delete prestation';

  constructor(public prestationId: number) {
  }
}