import {Component, computed, inject} from '@angular/core';
import {toSignal} from "@angular/core/rxjs-interop";
import {UserState} from "../../../stores/user/user.state";
import {Store} from "@ngxs/store";

@Component({
  selector: 'app-user-greeter',
  templateUrl: './user-greeter.component.html',
  styleUrls: ['./user-greeter.component.scss']
})
export class UserGreeterComponent {

  store = inject(Store);
  user = toSignal(this.store.select(UserState.getCurrentUser), {initialValue: null});

  greetings = computed(() => {
    return this.user() ? `${this.user().firstName}` : '';
  });

}
