import {RepitEnum} from "../common/RepitEnum";

export namespace PrestationCancellationMotiveEnum {
  export class PrestationCancellationMotive extends RepitEnum {
    constructor(value: string, label: string) {
      super(value, label);
    }
  }

  export const FAMILY = new PrestationCancellationMotive("FAMILY", "Famille");
  export const SICK_STAFF = new PrestationCancellationMotive("SICK_STAFF", "Educateur malade");
  export const OTHER = new PrestationCancellationMotive("OTHER", "Autre");

  export const VALUES: PrestationCancellationMotive[] = [
    FAMILY,
    SICK_STAFF,
    OTHER,
  ];

  const BY_VALUE = new Map<string, PrestationCancellationMotive>();
  VALUES.forEach(item => BY_VALUE.set(item.value, item));

  export function fromValue(value: string): PrestationCancellationMotive {
    return BY_VALUE.get(value);
  }
}