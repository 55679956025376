import {Component, computed, inject, OnInit, signal} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {formatDate, NgForOf, NgIf} from "@angular/common";
import {PersonInfoPipe} from "../../pipe/person-info.pipe";
import {fullName, sortByFullName} from "../../../models/person/PersonUtils";
import {MatCheckbox} from "@angular/material/checkbox";
import {PrestationTypeEnum} from "../../../models/prestation/PrestationType";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {MatIcon} from "@angular/material/icon";
import {MatButton, MatIconButton} from "@angular/material/button";
import {BeneficiaryForPrestationCreation} from "../../../models/beneficiary/beneficiaryForPrestationCreation";
import {MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {Store} from "@ngxs/store";
import {BeneficiaryState, FindBeneficiariesForPrestationCreation} from "../../../stores/beneficiary/beneficiary.state";
import {toSignal} from "@angular/core/rxjs-interop";
import {PrestationCreationRequest} from "../../../models/prestation/PrestationCreationRequest";
import {SchedulerTime} from "../../../models/scheduler/SchedulerTime";
import {SchedulerDay} from "../../../models/scheduler/SchedulerDay";
import {CreatePrestation} from "../../../stores/prestation/prestation.actions";
import {StaffSelectors} from "../../../stores/staff/staff.selectors";
import {RetrieveActiveEducators} from "../../../stores/staff/staff.actions";
import {byId} from "../../../models/common/compareUtils";
import {SimpleEducator} from "../../../models/educator/simpleEducator";
import {MatTooltip} from "@angular/material/tooltip";
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {CreateAddressFieldsComponent} from "../../address/create-address-fields/create-address-fields.component";
import {AddressForm} from "../../address/AddressForm";

@Component({
  selector: 'app-create-prestation-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
    PersonInfoPipe,
    FormsModule,
    MatCheckbox,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatHint,
    MatIcon,
    MatIconButton,
    MatSuffix,
    NgIf,
    MatDialogTitle,
    MatDialogContent,
    MatButton,
    MatDialogClose,
    MatDialogActions,
    MatTooltip,
    CdkTextareaAutosize,
    CreateAddressFieldsComponent
  ],
  templateUrl: './create-prestation-form.component.html',
  styleUrl: './create-prestation-form.component.scss'
})
export class CreatePrestationFormComponent implements OnInit {

  store = inject(Store);
  addressForm = AddressForm.initialize({disabled: true});
  form: FormGroup = new FormGroup({
    type: new FormControl(PrestationTypeEnum.CARE, Validators.required),
    urgent: new FormControl(false),
    doubledPrestation: new FormControl(false),
    beneficiary: new FormControl(null, Validators.required),
    requestContact: new FormControl({value: null, disabled: true}, Validators.required),
    invoiceContact: new FormControl({value: null, disabled: true}, Validators.required),
    bap: new FormControl({value: false, disabled: true}),
    manualAddress: new FormControl(false),
    address: this.addressForm.formGroup(),
    startDate: new FormControl(formatDate(new Date(), "YYYY-MM-dd", "fr-BE"), Validators.required),
    startTime: new FormControl("", Validators.required),
    endTime: new FormControl("", Validators.required),
    mainEducator: new FormControl(null),
    secondaryEducator: new FormControl(null),
    note: new FormControl(""),
  });

  activeBeneficiaries = toSignal<BeneficiaryForPrestationCreation[]>(this.store.select(BeneficiaryState.beneficiariesForPrestationCreation));
  selectedBeneficiary = signal<BeneficiaryForPrestationCreation | null>(null);
  beneficiaryFilter = signal("");
  activeEducators = toSignal(this.store.select(StaffSelectors.activeEducators()));
  mainEducator = toSignal<SimpleEducator>(this.form.get("mainEducator").valueChanges);
  mainEducatorFilter = signal("");
  secondaryEducatorFilter = signal("");
  secondaryEducator = toSignal<SimpleEducator>(this.form.get("secondaryEducator").valueChanges);

  ngOnInit(): void {
    this.store.dispatch(new FindBeneficiariesForPrestationCreation());
    this.store.dispatch(new RetrieveActiveEducators());
    this.form.get("beneficiary").valueChanges.subscribe((value) => {
      this.selectedBeneficiary.set(value);
      if (value) {
        this.form.get("requestContact").enable();
        this.form.get("requestContact").setValue(value.contacts.find(contact => contact.isPrestationPrimaryContact));
        this.form.get("invoiceContact").enable();
        this.form.get("invoiceContact").setValue(value.contacts.find(contact => contact.isInvoicePrimaryContact));
        if (value.bap) {
          this.form.get("bap").enable();
        } else {
          this.form.get("bap").disable();
        }
        this.form.get("bap").setValue(value.bap);
      } else {
        this.form.get("requestContact").disable();
        this.form.get("requestContact").setValue(null);
        this.form.get("invoiceContact").disable();
        this.form.get("invoiceContact").setValue(null);
        this.form.get("bap").disable();
        this.form.get("bap").setValue(false);
      }
    });
    this.form.get("requestContact").valueChanges.subscribe((value) => {
      if (value && value.address) {
        this.addressForm.setValue(value.address);
      } else {
        if (value) {
          this.form.get("manualAddress").setValue(true);
        }
      }
    });
    this.form.get("manualAddress").valueChanges.subscribe((value) => {
      if (value) {
        this.addressForm.enable();
      } else {
        this.addressForm.disable();
      }
    });
  }

  filteredBeneficiaries = computed(() => {
    return this.activeBeneficiaries().filter(beneficiary => {
      return fullName(beneficiary).toLowerCase().includes(this.beneficiaryFilter().toLowerCase());
    }).sort(sortByFullName);
  });

  filteredMainEducators = computed(() => {
    return this.activeEducators()
      .filter(educator => this.secondaryEducator() ? educator.id !== this.secondaryEducator().id : true)
      .filter(educator => fullName(educator).toLowerCase().includes(this.mainEducatorFilter().toLowerCase()))
      .sort(sortByFullName);
  })

  filteredSecondaryEducators = computed(() => {
    return this.activeEducators()
      .filter(educator => this.mainEducator() ? educator.id !== this.mainEducator().id : true)
      .filter(educator => fullName(educator).toLowerCase().includes(this.secondaryEducatorFilter().toLowerCase()))
      .sort(sortByFullName);
  })

  protected readonly PrestationTypeEnum = PrestationTypeEnum;

  createAndView() {
    this.create(true)
  }

  createAndContinue() {
    this.create(false)
  }

  createAndAccept() {
    this.create(false, true)
  }

  create(view: boolean, accepted: boolean = false) {
    if (!this.isFormValid()) {
      return;
    }

    const request = <PrestationCreationRequest>{
      type: this.form.value.type,
      beneficiaryId: this.form.value.beneficiary.id,
      requestContactId: this.form.value.requestContact.id,
      invoiceContactId: this.form.value.invoiceContact.id,
      address: this.addressForm.address(),
      urgent: this.form.value.urgent,
      bap: !!this.form.value.bap,
      startDay: new SchedulerDay(new Date(this.form.value.startDate)),
      startTime: SchedulerTime.fromString(this.form.value.startTime),
      endDay: this.computeEndDay(),
      endTime: SchedulerTime.fromString(this.form.value.endTime),
      accepted: accepted,
      mainEducatorId: this.form.value.mainEducator ? this.form.value.mainEducator.id : null,
      secondaryEducatorId: this.form.value.secondaryEducator ? this.form.value.secondaryEducator.id : null,
      note: this.form.value.note,
    }
    this.store.dispatch(new CreatePrestation(request, view));
    this.resetForm();
  }

  private resetForm() {
    this.form.get("type").reset(PrestationTypeEnum.CARE);
    this.form.get("urgent").reset(false);
    this.form.get("doubledPrestation").reset(false);
    this.form.get("beneficiary").reset(null);
    this.form.get("requestContact").reset(null);
    this.form.get("invoiceContact").reset(null);
    this.form.get("bap").reset(false);
    this.form.get("startTime").reset(null);
    this.form.get("endTime").reset(null);
    this.form.get("mainEducator").reset(null);
    this.form.get("secondaryEducator").reset(null);
    this.form.get("note").reset("");
    this.addressForm.reset();
  }

  computeEndDay() {
    if (this.isCoveringTwoDays()) {
      let nextDay = new Date(this.form.value.startDate);
      nextDay.setDate(nextDay.getDate() + 1);
      return new SchedulerDay(nextDay)
    }
    return new SchedulerDay(new Date(this.form.value.startDate));
  }

  isCoveringTwoDays() {
    if (SchedulerTime.isParsable(this.form.value.startTime) && SchedulerTime.isParsable(this.form.value.endTime)) {
      return SchedulerTime.fromString(this.form.value.endTime).isBefore(SchedulerTime.fromString(this.form.value.startTime))
    }
    return false;
  }

  protected readonly byId = byId;

  isDoubledPrestation() {
    return this.form.get("doubledPrestation").value;
  }

  canCreateAndValidate() {
    return this.isFormValid()
      && this.form.get("mainEducator").value
      && (!this.isDoubledPrestation() || this.form.get("secondaryEducator").value);
  }

  isFormValid() {
    return this.form.valid
      && this.form.get("address.streetName").value
      && this.form.get("address.streetNumber").value
      && this.form.get("address.zipCode").value
      && this.form.get("address.city").value;
  }
}
