import {Action, Selector, State, Store} from "@ngxs/store";
import {inject, Injectable} from "@angular/core";
import {ActivityReportGateway} from "../../ports/ActivityReportGateway";
import {Year} from "../../models/calendar/Year";
import {UserState} from "../user/user.state";
import {of} from "rxjs";
import {tap} from "rxjs/operators";
import {waitForUser} from "../store-utils";
import {
  DailyReport,
  DailyReportDetail,
  WeeklyReport,
  WeeklyReportDetail,
  YearlyReport
} from "../../models/activity-report/activity-report";

export type ActivityReportStateModel = {
  myYearlyReport: YearlyReport;
  selectedWeek: WeeklyReport;
  myWeeklyReport: WeeklyReportDetail;
  selectedDay: DailyReport;
  myDailyReport: DailyReportDetail;
}

export class FindMyActivityReport {
  static readonly type = '[ActivityReport] Find My Activity Report';

  constructor(public year: Year) {
  }
}

export class FindMyWeeklyReport {
  static readonly type = '[ActivityReport] Find My Weekly Report';

  constructor(public weekNumber: number) {
  }
}

export class SelectMyWeeklyReport {
  static readonly type = '[ActivityReport] Select My Weekly Report';

  constructor(public week: WeeklyReport) {
  }
}

export class UnselectMyWeeklyReport {
  static readonly type = '[ActivityReport] Unselect My Weekly Report';

  constructor() {
  }
}

export class SelectMyDailyReport {
  static readonly type = '[ActivityReport] Select My Day Report';

  constructor(public day: DailyReport) {
  }
}

export class UnselectMyDailyReport {
  static readonly type = '[ActivityReport] Unselect My Day Report';

  constructor() {
  }
}

export class FindMyDailyReport {
  static readonly type = '[ActivityReport] Find My Daily Report';

  constructor(public day: Date) {
  }
}


@State<ActivityReportStateModel>({
  name: 'activityReport',
  defaults: {
    myYearlyReport: null,
    selectedWeek: null,
    myWeeklyReport: null,
    selectedDay: null,
    myDailyReport: null
  }
})
@Injectable()
export class ActivityReportState {

  activityReportGateway = inject(ActivityReportGateway);
  store = inject(Store);

  @Selector()
  static myYearlyReport(state: ActivityReportStateModel) {
    return state.myYearlyReport;
  }

  @Selector()
  static selectedWeek(state: ActivityReportStateModel) {
    return state.selectedWeek;
  }

  @Selector()
  static myWeeklyReport(state: ActivityReportStateModel) {
    return state.myWeeklyReport;
  }

  @Selector()
  static selectedDay(state: ActivityReportStateModel) {
    return state.selectedDay;
  }

  @Selector()
  static myDailyReport(state: ActivityReportStateModel) {
    return state.myDailyReport;
  }


  @Action(FindMyActivityReport)
  public findMyActivityReport(ctx: any, action: FindMyActivityReport) {
    const staffId = this.store.selectSnapshot(UserState.getCurrentStaffId)
    if (!staffId) {
      waitForUser(ctx, action);
      return of([])
    } else {
      return this.activityReportGateway.findActivityReport(staffId, action.year)
        .pipe(tap(report => {
          ctx.patchState({myYearlyReport: report})
        }))
    }
  }

  @Action(SelectMyWeeklyReport)
  public selectMyWeeklyReport(ctx: any, action: SelectMyWeeklyReport) {
    ctx.patchState({
      selectedWeek: action.week,
      myWeeklyReport: null,
      selectedDay: null,
      myDailyReport: null
    })
    ctx.dispatch(new FindMyWeeklyReport(action.week.weekNumber))
  }

  @Action(UnselectMyWeeklyReport)
  public unselectMyWeeklyReport(ctx: any, action: UnselectMyWeeklyReport) {
    ctx.patchState({
      selectedWeek: null,
      myWeeklyReport: null,
      selectedDay: null,
      myDailyReport: null
    })
  }

  @Action(FindMyWeeklyReport)
  public findMyWeeklyReport(ctx: any, action: FindMyWeeklyReport) {
    const staffId = this.store.selectSnapshot(UserState.getCurrentStaffId)
    if (!staffId) {
      waitForUser(ctx, action);
      return of([])
    } else {
      return this.activityReportGateway.findWeeklyReport(staffId, action.weekNumber)
        .pipe(tap(report => {
          ctx.patchState({myWeeklyReport: report})
        }))
    }
  }

  @Action(SelectMyDailyReport)
  public selectMyDayReport(ctx: any, action: SelectMyDailyReport) {
    ctx.patchState({selectedDay: action.day})
    ctx.dispatch(new FindMyDailyReport(action.day.date))
  }

  @Action(UnselectMyDailyReport)
  public unselectMyDayReport(ctx: any, action: UnselectMyDailyReport) {
    ctx.patchState({
      selectedDay: null,
      myDailyReport: null
    })
  }

  @Action(FindMyDailyReport)
  public findMyDailyReport(ctx: any, action: FindMyDailyReport) {
    const staffId = this.store.selectSnapshot(UserState.getCurrentStaffId)
    if (!staffId) {
      waitForUser(ctx, action);
      return of([])
    } else {
      return this.activityReportGateway.findDailyReport(staffId, action.day)
        .pipe(tap(report => {
          ctx.patchState({myDailyReport: report})
        }))
    }
  }

}