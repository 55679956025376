<div class="m-3">
    <h1 mat-dialog-title>Créer une demande d'absence</h1>
    <mat-dialog-content>
        <div class="vstack gap-2 align-items-center">
            <mat-button-toggle-group [(ngModel)]="type">
                <mat-button-toggle [value]="TimeOffType.OUT">Out - Récup</mat-button-toggle>
                <mat-button-toggle [value]="TimeOffType.TIME_OFF">Congé</mat-button-toggle>
            </mat-button-toggle-group>
            <form *ngIf="isTimeOff()" [formGroup]="timeOffForm">
                <mat-form-field>
                    <mat-label>Période</mat-label>
                    <mat-date-range-input [rangePicker]="datePicker">
                        <input matStartDate placeholder="Début" formControlName="startDate">
                        <input matEndDate placeholder="Fin" formControlName="endDate">
                    </mat-date-range-input>
                    <mat-hint>JJ/MM/AAAA - JJ/MM/AAAA</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="datePicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #datePicker></mat-date-range-picker>
                </mat-form-field>
            </form>
            <form *ngIf="isOut()" [formGroup]="outCompForm">
                <app-date-picker controlName="date" label="Date l'absence"></app-date-picker>
                <app-time-picker controlName="startTime" label="Début" hint="HH:mm (10:00)"></app-time-picker>
                <app-time-picker controlName="endTime" label="Fin" hint="HH:mm (15:00)"></app-time-picker>
            </form>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex gap-2 justify-content-end">
        <button mat-dialog-close class="btn btn-secondary">Annuler</button>
        <button class="btn btn-primary" id="createAndAccept" [disabled]="!isFormValid()"
                (click)="create()">Créer
        </button>
        <button class="btn btn-primary" id="createAndView" [disabled]="!isFormValid()" (click)="createAndClose(true)">
            Créer
            et fermer
        </button>
    </mat-dialog-actions>
</div>