import {RepitEnum} from "../common/RepitEnum";

export namespace PrestationTravelTypeEnum {
  export class PrestationTravelType extends RepitEnum {
    constructor(value: string, label: string) {
      super(value, label);
    }
  }

  export const DURING = new PrestationTravelType("DURING", "Pendant");
  export const BEFORE = new PrestationTravelType("BEFORE", "Avant");
  export const AFTER = new PrestationTravelType("AFTER", "Après");

  export const VALUES: PrestationTravelType[] = [
    DURING,
    BEFORE,
    AFTER
  ]

  const BY_VALUE = new Map<string, PrestationTravelType>();
  VALUES.forEach(item => BY_VALUE.set(item.value, item));

  export function fromValue(value: string): PrestationTravelType {
    return BY_VALUE.get(value);
  }
}