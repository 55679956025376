export class TimeOffRequest {
  staffMemberId: number;
  startDate: Date;
  endDate: Date;
  note: string;


  constructor(staffMemberId: number, startDate: Date, endDate: Date, note: string) {
    this.staffMemberId = staffMemberId;
    this.startDate = startDate;
    this.endDate = endDate;
    this.note = note;
  }
}