/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {AddressWithCoordinatesDto} from './addressWithCoordinatesDto';
import {SimpleBeneficiaryDto} from './simpleBeneficiaryDto';
import {BeneficiaryContactDto} from './beneficiaryContactDto';
import {DateTimeRangeDto} from './dateTimeRangeDto';
import {EducatorSummaryDto} from './educatorSummaryDto';
import {PrestationCancellationMotiveDto} from './prestationCancellationMotiveDto';
import {PrestationStatusDto} from './prestationStatusDto';
import {PrestationTravelDto} from './prestationTravelDto';
import {PrestationRefusalMotiveDto} from './prestationRefusalMotiveDto';
import {PrestationTypeDto} from './prestationTypeDto';


export interface PrestationDetailDto {
  id?: number;
  schedule?: DateTimeRangeDto;
  status?: PrestationStatusDto;
  type?: PrestationTypeDto;
  urgent?: boolean;
  bap?: boolean;
  mainEducator?: EducatorSummaryDto;
  secondaryEducator?: EducatorSummaryDto;
  beneficiary?: SimpleBeneficiaryDto;
  requestContact?: BeneficiaryContactDto;
  invoiceContact?: BeneficiaryContactDto;
  location?: AddressWithCoordinatesDto;
  note?: string;
  refusalMotive?: PrestationRefusalMotiveDto;
  refusalNote?: string;
  cancellationMotive?: PrestationCancellationMotiveDto;
  cancellationNote?: string;
  validationNote?: string;
  travelDurings?: Array<PrestationTravelDto>;
  travelBefore?: PrestationTravelDto;
  travelAfter?: PrestationTravelDto;
}

