import {SimpleBeneficiaryDto} from "../../../generated/api";
import {SchedulerDateAndTime} from "./SchedulerDateAndTime";
import {SchedulerDay} from "./SchedulerDay";


export class NewPrestation {
  beneficiary: SimpleBeneficiaryDto;
  startDate: Date;
  startTime: string;
  endTime: string;

  public get schedule(): SchedulerDateAndTime {
    return SchedulerDateAndTime.timeRangeBetween(new SchedulerDay(this.startDate), this.startTime, this.endTime);
  }

}
