import {Pipe, PipeTransform} from '@angular/core';
import {SchedulerTimePeriod} from "../../models/scheduler/SchedulerTimePeriod";
import {padNumberWithLeadingZeros} from "../../models/time/TimeUtils";

@Pipe({
  standalone: true,
  name: 'schedulerTimePeriod'
})
export class SchedulerTimePeriodPipe implements PipeTransform {

  transform(value: SchedulerTimePeriod, withDuration: boolean = false): string {
    let transformed = this.transformSchedule(value);
    if (withDuration) {
      transformed += ` (${this.transformDuration(value)})`;
    }
    return transformed;
  }

  private transformSchedule(value: SchedulerTimePeriod) {
    return padNumberWithLeadingZeros(value.startTime.hour) + ":" + padNumberWithLeadingZeros(value.startTime.minute)
      + " - "
      + padNumberWithLeadingZeros(value.endTime.hour) + ":" + padNumberWithLeadingZeros(value.endTime.minute);
  }

  private transformDuration(value: SchedulerTimePeriod) {
    if (value.durationHour > 0 && value.durationMinute === 0) {
      return `${value.durationHour}h`;
    } else if (value.durationHour === 0) {
      return `${value.durationMinute}m`;
    } else {
      return `${value.durationHour}h ${value.durationMinute}m`;
    }
  }
}
