import {Component, computed, inject, OnInit, signal, Signal} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {NgForOf, NgIf} from "@angular/common";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {PrestationSummary} from 'src/app/core/models/prestation/PrestationSummary';
import {toObservable, toSignal} from "@angular/core/rxjs-interop";
import {Store} from "@ngxs/store";
import {map} from "rxjs";
import {
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker,
  MatEndDate,
  MatStartDate
} from "@angular/material/datepicker";
import {PageEvent} from "@angular/material/paginator";
import {SchedulerDayPipe} from "../../pipe/scheduler-day.pipe";
import {SchedulerTimePeriodPipe} from "../../pipe/scheduler-time-period.pipe";
import {PersonInfoPipe} from "../../pipe/person-info.pipe";
import {Week} from "../../../models/scheduler/Week";
import {DateRange} from "../../../models/calendar/DateRange";
import {FindPrestationSummaries} from "../../../stores/prestation/prestation.actions";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {MatChipListbox, MatChipOption} from "@angular/material/chips";
import {PrestationStatusEnum} from "../../../models/prestation/PrestationStatusEnum";
import {MatDialog} from "@angular/material/dialog";
import {CreatePrestationFormComponent} from "../create-prestation-form/create-prestation-form.component";
import {PrestationListFilter, PrestationState} from "../../../stores/prestation/prestation.state";
import {PrestationStatusIconComponent} from "../prestation-status-icon/prestation-status-icon.component";
import {RepitPagerComponent} from "../../components/repit-pager/repit-pager.component";
import PrestationStatus = PrestationStatusEnum.PrestationStatus;

@Component({
  selector: 'app-list-prestation',
  standalone: true,
  imports: [
    FormsModule,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    MatSuffix,
    NgForOf,
    NgIf,
    RouterLink,
    MatDatepickerToggle,
    MatHint,
    ReactiveFormsModule,
    MatStartDate,
    MatEndDate,
    MatDateRangeInput,
    MatDateRangePicker,
    SchedulerDayPipe,
    SchedulerTimePeriodPipe,
    PersonInfoPipe,
    MatOption,
    MatSelect,
    MatChipListbox,
    MatChipOption,
    PrestationStatusIconComponent,
    RepitPagerComponent
  ],
  templateUrl: './list-prestation.component.html',
  styleUrl: './list-prestation.component.scss'
})
export class ListPrestationComponent implements OnInit {
  store = inject(Store);
  route = inject(ActivatedRoute);

  startDate = signal(Week.current().getFirstDayOfPeriod().date);
  endDate = signal(Week.current().getLastDayOfPeriod().date);
  previousRange = signal(Week.current().toRange());
  dateRange = computed(() => {
    if (this.startDate() && this.endDate()) {
      return new DateRange(new Date(this.startDate()), new Date(this.endDate()));
    }
    return this.previousRange();
  });
  educatorFilter = signal('');
  beneficiaryFilter = signal('');
  prestationStatusFilter = signal<PrestationStatus[]>([]);
  bapFilter = signal(true);
  nonBapFilter = signal(true);

  filter = computed(() => ({
    dateRange: this.dateRange(),
    educator: this.educatorFilter(),
    beneficiary: this.beneficiaryFilter(),
    statuses: this.prestationStatusFilter(),
    bap: this.bapFilter(),
    nonBap: this.nonBapFilter()
  } as PrestationListFilter))

  pageIndex = signal(0);
  pageSize = signal(100);

  prestations: Signal<PrestationSummary[]> = toSignal(this.store.select(PrestationState.prestationSummaries)
    .pipe(
      map((prestations) => prestations.sort((a, b) => a.schedule.start().getTime() - b.schedule.start().getTime()))
    ));

  pagedPrestations = computed(() => this.prestations().slice(this.pageIndex() * this.pageSize(), (this.pageIndex() + 1) * this.pageSize()));

  constructor(public dialog: MatDialog) {
    const filterFromState = this.store.selectSnapshot(PrestationState.prestationFilter);
    this.startDate.set(filterFromState.dateRange.start);
    this.endDate.set(filterFromState.dateRange.end);
    this.educatorFilter.set(filterFromState.educator);
    this.beneficiaryFilter.set(filterFromState.beneficiary);
    this.prestationStatusFilter.set(filterFromState.statuses);
    this.bapFilter.set(filterFromState.bap);
    this.nonBapFilter.set(filterFromState.nonBap);

    toObservable(this.filter).subscribe(filter => {
      this.previousRange.set(filter.dateRange)
      this.store.dispatch(new FindPrestationSummaries(this.filter()));
    })
  }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.create) {
        this.openCreatePrestationDialog();
      }
    })
  }

  refreshPagination($event: PageEvent) {
    this.pageIndex.set($event.pageIndex);
    this.pageSize.set($event.pageSize);
  }

  toggleBapFilter() {
    this.bapFilter.update(bap => !bap);
  }

  toggleNonBapFilter() {
    this.nonBapFilter.update(nonBap => !nonBap);
  }

  openCreatePrestationDialog() {
    this.dialog.open(CreatePrestationFormComponent);
  }

  protected readonly PrestationStatusEnum = PrestationStatusEnum;
}
