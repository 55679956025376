<h1>Mon activité</h1>
<div class="vstack gap-2">

    <div class="d-flex gap-2 general-bg bg-white align-content-center align-items-center">
        <mat-form-field>
            <mat-label>Année</mat-label>
            <mat-select [(ngModel)]="yearFilter" (selectionChange)="resetReport()">
                <mat-option *ngFor="let year of years()" [value]="year">{{ year.value }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="box general-bg bg-white">
        <table mat-table [dataSource]="yearlyReportDataSource" matSort>

            <ng-container matColumnDef="weekNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
                <td mat-cell *matCellDef="let element"> {{ element.weekNumber }}</td>
            </ng-container>
            <ng-container matColumnDef="workedHours">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Travaillées</th>
                <td mat-cell *matCellDef="let element"> {{ element.workedHours }}</td>
            </ng-container>
            <ng-container matColumnDef="otherValuedHours">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Autres</th>
                <td mat-cell *matCellDef="let element"> {{ element.otherValuedHours }}</td>
            </ng-container>
            <ng-container matColumnDef="totalValuedHours">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Total valué</th>
                <td mat-cell *matCellDef="let element"> {{ element.totalValuedHours }}</td>
            </ng-container>
            <ng-container matColumnDef="expectedHours">
                <th mat-header-cell *matHeaderCellDef>Dûes</th>
                <td mat-cell *matCellDef="let element"> {{ element.expectedHours }}</td>
            </ng-container>
            <ng-container matColumnDef="weeklyDifference">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Différence hebdo</th>
                <td mat-cell *matCellDef="let element"> {{ element.weeklyDifference }}</td>
            </ng-container>
            <ng-container matColumnDef="yearlyDifference">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Compteur</th>
                <td mat-cell *matCellDef="let element"> {{ element.yearlyDifference }}</td>
            </ng-container>
            <ng-container matColumnDef="detail">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Détail</th>
                <td mat-cell *matCellDef="let element">
                    <button class="ms-auto" mat-icon-button (click)="selectWeek(element)">
                        <mat-icon>info</mat-icon>
                    </button>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="yearlyReportDisplayedColumns"></tr>
            <tr mat-row
                [class.selected-row]="isWeekSelected(row)"
                *matRowDef="let row; columns: yearlyReportDisplayedColumns;"></tr>
        </table>
    </div>

    <div class="repit-container-2">
        <div class="box general-bg bg-white" *ngIf="showWeekDetail()">
            <div class="d-flex gap-2 general-bg bg-white align-content-center align-items-center">
                <h2>Semaine {{ selectedWeek().weekNumber }} ({{ selectedWeek().weekStartDate | date:"dd/MM/YYYY" }}
                    - {{ selectedWeek().weekEndDate | date:"dd/MM/YYYY" }})</h2>
                <a (click)="closeWeek()" class="ms-auto">
                    <div class="btn btn-important">
                        <mat-icon>close</mat-icon>
                    </div>
                </a>
            </div>
            <table mat-table [dataSource]="weeklyReportDataSource" matSort>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                    <td mat-cell *matCellDef="let element"> {{ element.date | date:"dd/MM/YYYY" }}</td>
                </ng-container>
                <ng-container matColumnDef="workedHours">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Travaillées</th>
                    <td mat-cell *matCellDef="let element"> {{ element.workedHours | duration:"h" }}</td>
                </ng-container>
                <ng-container matColumnDef="otherValuedHours">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Autres</th>
                    <td mat-cell *matCellDef="let element"> {{ element.otherValuedHours | duration:"h" }}</td>
                </ng-container>
                <ng-container matColumnDef="totalValuedHours">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Total valué</th>
                    <td mat-cell *matCellDef="let element"> {{ element.totalValuedHours | duration:"h" }}</td>
                </ng-container>
                <ng-container matColumnDef="expectedHours">
                    <th mat-header-cell *matHeaderCellDef>Dûes</th>
                    <td mat-cell *matCellDef="let element"> {{ element.expectedHours | duration:"h" }}</td>
                </ng-container>
                <ng-container matColumnDef="dailyDifference">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Différence journa</th>
                    <td mat-cell *matCellDef="let element"> {{ element.dailyDifference | duration:"h" }}</td>
                </ng-container>
                <ng-container matColumnDef="detail">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Détail</th>
                    <td mat-cell *matCellDef="let element">
                        <button class="ms-auto" mat-icon-button (click)="selectDay(element)">
                            <mat-icon>info</mat-icon>
                        </button>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="weeklyReportDisplayedColumns"></tr>
                <tr mat-row
                    [class.selected-row]="isDaySelected(row)"
                    *matRowDef="let row; columns: weeklyReportDisplayedColumns;"></tr>
            </table>
        </div>
        <div class="box general-bg bg-white" *ngIf="showDayDetail()">
            <div class="d-flex gap-2 general-bg bg-white align-content-center align-items-center">
                <h2>{{ selectedDay().date | date:"EEEE dd/MM/YYYY" }}</h2>
                <a (click)="closeDay()" class="ms-auto">
                    <div class="btn btn-important">
                        <mat-icon>close</mat-icon>
                    </div>
                </a>
            </div>

            <div class="repit-field">
                <div>Travaillées</div>
                <div>{{ selectedDay().workedHours | duration:"h" }}</div>
            </div>

        </div>
    </div>

</div>