<div class="wrapper">
  <nav id="sidebar" [ngClass]="{'active': isSidebarExpanded}">

    <ul class="list-unstyled">
      <li class="menu-item-container menu-toggle">
        <button mat-icon-button (click)="toggleSidebar()">
          <mat-icon>menu</mat-icon>
        </button>
      </li>
      <li class="menu-item-container">
        <div class="menu-item">
          <a [routerLink]="['dashboard']">
          <img *ngIf="isSidebarExpanded" ngSrc="./assets/logo-wallopoly-header_black.png"
               style='height: 24px; width: auto;' alt="wallopoly-logo" height="189" width="792"/>
          <img *ngIf="!isSidebarExpanded" ngSrc="./assets/logo-wallopoly-icone.svg"
               alt="wallopoly-logo" height="32" width="32"/>
        </a>
        </div>
      </li>
      <li *ngFor="let menuItem of getMenuItems()" class="menu-item-container">
        <div class="menu-item">
          <a routerLinkActive="selected" [class.side-bar-expanded]="isSidebarExpanded" routerLink="{{menuItem.route}}">
            <mat-icon>{{menuItem.icon}}</mat-icon>
            <span class="menu-text" *ngIf="isSidebarExpanded">{{ menuItem.name }}</span>
          </a>
          <button mat-icon-button *ngIf="canShrinkMenuItem(menuItem)" (click)="toggleMenuExpansion(menuItem)">
            <mat-icon>expand_less</mat-icon>
          </button>
          <button mat-icon-button *ngIf="canExpandMenuItem(menuItem)" (click)="toggleMenuExpansion(menuItem)">
            <mat-icon>expand_more</mat-icon>
          </button>
        </div>
        <ul class="submenu" *ngIf="isSidebarExpanded && menuItem.expanded">
          <li *ngFor="let subMenuItem of menuItem.submenuItems" >
              <a routerLinkActive="selected" routerLink="{{subMenuItem.route}}" [queryParams]="subMenuItem.params"
                 class="submenu-item" [class.side-bar-expanded]="isSidebarExpanded">
              <span class="menu-text">{{ subMenuItem.name }}</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
  <div id="content">
    <!--    TODO RHA add notifications here-->
    <router-outlet></router-outlet>
  </div>
</div>

