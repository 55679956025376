import {Component, computed, inject, OnInit} from '@angular/core';
import {UserState} from "../../stores/user/user.state";
import {Store} from "@ngxs/store";
import {toSignal} from "@angular/core/rxjs-interop";


@Component({
  selector: 'app-user-main-interface',
  templateUrl: './dashboard.html',
  styleUrls: ['./dashboard.scss']
})
export class Dashboard implements OnInit {

  store = inject(Store);
  user = toSignal(this.store.select(UserState.getCurrentUser), {initialValue: null});

  constructor() {

  }

  ngOnInit(): void {
  }

  isUserLoaded = computed(() => {
    return this.user() !== null;
  })


}
