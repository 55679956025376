import {Component, computed, inject, Inject} from '@angular/core';
import {StaffActivityRequest} from "../../../models/staff/activity/StaffActivityRequest";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from "@angular/material/dialog";
import {ReactiveFormsModule} from "@angular/forms";
import {Store} from "@ngxs/store";
import {ConfirmTimeOffRequestAcceptance, ConfirmTimeOutRequestAcceptance} from "../../../stores/staff/staff.actions";
import {StaffActivityTypeEnum} from "../../../models/staff/activity/StaffActivityType";

export interface AcceptStaffActivityRequestData {
  request: StaffActivityRequest
}

@Component({
  selector: 'app-accept-staff-activity-request-dialog-component',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ReactiveFormsModule
  ],
  templateUrl: './accept-staff-activity-request-dialog-component.component.html',
  styleUrl: './accept-staff-activity-request-dialog-component.component.scss'
})
export class AcceptStaffActivityRequestDialogComponentComponent {

  store = inject(Store);

  constructor(@Inject(MAT_DIALOG_DATA) public input: AcceptStaffActivityRequestData) {

  }

  request = computed(() => this.input.request);

  confirm() {
    if (this.request().type === StaffActivityTypeEnum.TIME_OFF) {
      this.store.dispatch(new ConfirmTimeOffRequestAcceptance(this.input.request));
    } else if (this.request().type === StaffActivityTypeEnum.OUT) {
      this.store.dispatch(new ConfirmTimeOutRequestAcceptance(this.input.request));
    }
  }
}
