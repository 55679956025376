<div class="widget" *ngIf="hasPrestationsToClose()">
    <h2>
        <div class="title-text">Prestations à clôturer
            <span class="small" *ngIf="prestationsToClose().length > 1">
                ({{ prestationIndex() + 1 }}/{{ prestationsToClose().length }})
            </span>
        </div>
        <a mat-icon-button matTooltip="Voir la prestation"
           [routerLink]="['/prestation/detail/' + getPrestationToClose().id]">
            <mat-icon>info</mat-icon>
        </a>
        <a mat-icon-button routerLink="/prestation/list">
            <mat-icon>list</mat-icon>
        </a>
    </h2>
    <div>
        <app-prestation-to-close-form [prestation]="getPrestationToClose()"
                                      [showCancel]="prestationsToClose().length > 1"
                                      [cancelText]="'Plus tard'"
                                      (onCancel)="nextPrestation()"
                                      (confirmClosure)="closePrestation($event)"></app-prestation-to-close-form>
    </div>
</div>
