<h1 mat-dialog-title>Archiver le bénéficiaire</h1>
<div mat-dialog-content>
  <div *ngIf="data.canBeArchived">Souhaitez-vous archiver le bénéficiaire?</div>
  <div *ngIf="!data.canBeArchived">Ce bénéficiaire ne peut pas être archivé pour le moment. Veuillez vérifier s'il ne
    reste pas de prestation ouverte.
  </div>
  <div mat-dialog-actions class="d-flex gap-2">
    <button class="btn btn-secondary" mat-dialog-close="">Annuler</button>
    <button class="btn btn-danger" mat-dialog-close="" [disabled]="!data.canBeArchived" (click)="confirm()">Confirmer
    </button>
  </div>
</div>
