import {Component, effect, EventEmitter, inject, input, OnInit, Output, signal} from '@angular/core';
import {PrestationToClose} from "../../../models/prestation/PrestationToClose";
import {PrestationClosureRequest} from "../../../models/prestation/PrestationClosureRequest";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {SchedulerTimePipe} from "../../pipe/scheduler-time.pipe";
import {SchedulerDay} from "../../../models/scheduler/SchedulerDay";
import {SchedulerTime} from "../../../models/scheduler/SchedulerTime";
import {PersonInfoPipe} from "../../pipe/person-info.pipe";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {SchedulerDayPipe} from "../../pipe/scheduler-day.pipe";
import {DatePipe, NgIf} from "@angular/common";
import {SchedulerTimePeriodPipe} from "../../pipe/scheduler-time-period.pipe";
import {MatFormField, MatHint, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatIconButton} from "@angular/material/button";
import {
  MatExpansionPanel,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from "@angular/material/expansion";
import {InlineAddressPipe} from "../../pipe/inline-address.pipe";
import {CreateAddressFieldsComponent} from "../../address/create-address-fields/create-address-fields.component";
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {ClearableInputTextComponent} from "../../components/clearable-input-text/clearable-input-text.component";
import {UpdatePrestationTravelsComponent} from "../update-prestation-travels/update-prestation-travels.component";
import {TravelForm} from "../TravelForm";


@Component({
  selector: 'app-prestation-to-close-form',
  standalone: true,
  templateUrl: './prestation-to-close-form.component.html',
  imports: [
    MatLabel,
    MatHint,
    PersonInfoPipe,
    ReactiveFormsModule,
    MatIcon,
    MatTooltip,
    SchedulerDayPipe,
    DatePipe,
    SchedulerTimePeriodPipe,
    NgIf,
    MatFormField,
    MatInput,
    MatIconButton,
    MatExpansionPanel,
    MatExpansionPanelTitle,
    MatExpansionPanelHeader,
    InlineAddressPipe,
    CreateAddressFieldsComponent,
    MatExpansionPanelDescription,
    CdkTextareaAutosize,
    ClearableInputTextComponent,
    UpdatePrestationTravelsComponent
  ],
  styleUrl: './prestation-to-close-form.component.scss'
})
export class PrestationToCloseFormComponent implements OnInit {

  schedulerTimePipe = inject(SchedulerTimePipe);
  prestationId = 0;

  cancelText = input.required<string>();
  showCancel = input.required<boolean>();
  prestation = input.required<PrestationToClose>();
  updatingSchedule = signal(false);

  form: FormGroup = new FormGroup({
    startTime: new FormControl(null, Validators.required),
    endTime: new FormControl(null, Validators.required),
    travels: new FormGroup({
      addingTravelDuring: new FormControl(false),
      travelDescription: new FormControl(""),
      travelDistance: new FormControl(null),
      addingTravelBefore: new FormControl(false),
      travelBeforeDescription: new FormControl(""),
      addingTravelAfter: new FormControl(false),
      travelAfterDescription: new FormControl(""),
      addressBefore: new FormGroup({
        streetName: new FormControl(null),
        streetNumber: new FormControl(null),
        zipCode: new FormControl(null),
        city: new FormControl(null),
      }),
      addressAfter: new FormGroup({
        streetName: new FormControl(null),
        streetNumber: new FormControl(null),
        zipCode: new FormControl(null),
        city: new FormControl(null),
      })
    }),
  });
  travelForm: TravelForm = new TravelForm(this.form.get("travels") as FormGroup);

  @Output() confirmClosure = new EventEmitter<PrestationClosureRequest>();
  @Output() onCancel = new EventEmitter<void>();

  constructor() {
    effect(() => {
      if (this.prestation() && (this.prestationId === 0 || this.prestationId !== this.prestation().id)) {
        this.prestationId = this.prestation().id;
        this.resetForm();
      }
    });
  }

  ngOnInit(): void {

  }

  closePrestation() {
    if (!this.isValid()) {
      return;
    }
    const request = <PrestationClosureRequest>{
      prestationId: this.prestation().id,
      startDay: new SchedulerDay(this.prestation()?.schedule.start()),
      startTime: SchedulerTime.fromString(this.form.value.startTime),
      endDay: this.computeEndDay(),
      endTime: SchedulerTime.fromString(this.form.value.endTime),
      travelDuringDescription: this.form.get("travels.travelDescription").value,
      travelDuringDistance: this.form.get("travels.travelDistance").value,
      travelBefore: this.travelForm.travelBeforeRequest(),
      travelAfter: this.travelForm.travelAfterRequest(),
    }

    this.confirmClosure.emit(request);
    this.resetInteractions();
  }

  cancel() {
    this.onCancel.emit();
    this.resetInteractions();
  }

  private resetInteractions() {
    this.updatingSchedule.set(false);
    this.form.get("travels.addingTravelDuring").setValue(false)
    this.form.get("travels.addingTravelBefore").setValue(false)
    this.form.get("travels.addingTravelAfter").setValue(false)
  }

  private resetForm() {
    this.form.reset({
      startTime: this.schedulerTimePipe.transform(this.prestation()?.prestationValidation.schedule.timePeriod.startTime),
      endTime: this.schedulerTimePipe.transform(this.prestation()?.prestationValidation.schedule.timePeriod.endTime),
      travels: {
        travelDescription: "",
        travelDistance: null,
        addingTravelDuring: false,
        addingTravelBefore: false,
        addingTravelAfter: false,
        addressBefore: {
          streetName: "",
          streetNumber: "",
          zipCode: "",
          city: "",
        },
        addressAfter: {
          streetName: "",
          streetNumber: "",
          zipCode: "",
          city: "",
        }
      }
    });
  }

  computeEndDay() {
    if (this.isCoveringTwoDays()) {
      let nextDay = new Date(this.prestation()?.schedule.end());
      nextDay.setDate(nextDay.getDate() + 1);
      return new SchedulerDay(nextDay)
    }
    return new SchedulerDay(this.prestation()?.schedule.end());
  }

  isCoveringTwoDays() {
    return SchedulerTime.fromString(this.form.value.endTime).isBefore(SchedulerTime.fromString(this.form.value.startTime))
  }

  isOriginalScheduleCoveringTwoDays() {
    return this.prestation().schedule.isCoveringTwoDays();
  }

  isPrestationValidationCoveringTwoDays() {
    return this.prestation().prestationValidation.schedule.isCoveringTwoDays();
  }

  isValid(): boolean {
    return this.form.valid;
  }

}