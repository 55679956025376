import {Component, inject, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngxs/store";
import {AddressWithHistory} from "../../../models/address/AddressWithHistory";
import {UpdateStaffMemberAddress} from "../../../stores/staff/staff.actions";

export interface ModifyAddressDialogData {
  personId: number;
  address: AddressWithHistory;
}

@Component({
  selector: 'app-modify-address-dialog',
  templateUrl: './modify-address-dialog.component.html',
  styleUrls: ['./modify-address-dialog.component.scss']
})
export class ModifyAddressDialogComponent {

  store = inject(Store);
  address = this.data.address;

  form = new FormGroup({
    streetNumber: new FormControl(this.address.streetNumber, Validators.required),
    streetName: new FormControl(this.address.streetName, Validators.required),
    zipCode: new FormControl(this.address.zipCode, Validators.required),
    city: new FormControl(this.address.city, Validators.required)
  });

  constructor(public dialogRef: MatDialogRef<ModifyAddressDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ModifyAddressDialogData) {
  }

  confirm() {
    if (this.form.invalid) {
      return;
    }
    this.address.streetNumber = this.form.value.streetNumber;
    this.address.streetName = this.form.value.streetName;
    this.address.zipCode = this.form.value.zipCode;
    this.address.city = this.form.value.city;
    this.store.dispatch(new UpdateStaffMemberAddress(this.store.snapshot().staff.selectedStaffMember.id, this.address));
  }
}
