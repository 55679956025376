import {RepitEnum} from "../common/RepitEnum";

export namespace ActivityReportTypeEnum {
  export class ActivityReportType extends RepitEnum {
    constructor(value: string, label: string) {
      super(value, label);
    }
  }

  export const VALUES: ActivityReportType[] = [];


  const BY_VALUE = new Map<string, ActivityReportType>();
  VALUES.forEach(type => BY_VALUE.set(type.value, type));

  export function fromValue(value: string): ActivityReportType {
    return BY_VALUE.get(value);
  }
}