import {Pipe, PipeTransform} from '@angular/core';
import {GenderEnum} from "../../models/person/GenderEnum";


@Pipe({
  name: 'gender'
})
export class GenderPipe implements PipeTransform {

  constructor() {
  }

  transform(gender: string, ...args: unknown[]): string {
    if (gender == null) return GenderEnum.UNSPECIFIED.label;
    return GenderEnum.fromValue(gender).label;
  }

}
