import {Observable} from "rxjs";
import {StaffMemberSummary} from "../models/staff/StaffMemberSummary.model";
import {StaffMemberDetail} from "../models/staff/StaffMemberDetail";
import {AddressWithHistory} from "../models/address/AddressWithHistory";
import {TimeOutCompRequest} from "../models/staff/activity/TimeOutCompRequest";
import {TimeOffRequest} from "../models/staff/activity/TimeOffRequest";
import {StaffActivityRequest} from "../models/staff/activity/StaffActivityRequest";

export abstract class StaffGateway {
  abstract retrieveAll(): Observable<StaffMemberSummary[]>;

  abstract retrieve(id: number): Observable<StaffMemberDetail>;

  abstract updateCurrentAddress(staffMemberId: number, address: AddressWithHistory): Observable<StaffMemberDetail>;

  abstract retrieveActiveEducators(): Observable<StaffMemberSummary[]>;

  abstract findActivityRequests(staffMemberId: number): Observable<StaffActivityRequest[]>;

  abstract findActivityRequest(activityId: number): Observable<StaffActivityRequest>;

  abstract createTimeOutCompRequest(staffMemberId: number, request: TimeOutCompRequest): Observable<number>;

  abstract createTimeOffRequest(staffMemberId: number, request: TimeOffRequest): Observable<number>;

  abstract cancelActivityRequest(activityId: number): Observable<void>;

  abstract refuseActivityRequest(activityId: number, refusalNote: string): Observable<void>;
}
