import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {saveAs} from 'file-saver';
import _ from "lodash";
import {Month} from "../../calendar/Month";
import {
  InvoiceControllerService,
  InvoicedBapBeneficiaryDto,
  InvoicedRepitBeneficiaryDto
} from "../../../../generated/api";
import {Year} from "../../calendar/Year";

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private invoiceController: InvoiceControllerService) {
  }

  public fetchRepitInvoiceableBeneficiaries(month: Month, year: Year): Observable<InvoicedRepitBeneficiaryDto[]> {
    return this.invoiceController.findRepitBeneficiariesToInvoice(year.value, month.value);
  }

  public fetchBapInvoiceableBeneficiaries(month: Month, year: Year): Observable<InvoicedBapBeneficiaryDto[]> {
    return this.invoiceController.findBapBeneficiariesToInvoice(year.value, month.value);
  }

  generateRepitInvoice(beneficiary: InvoicedRepitBeneficiaryDto, month: Month, year: Year) {
    this.invoiceController.downloadRepitInvoice(beneficiary.contactId, year.value, month.value)
      .subscribe(this.downloadInvoice(beneficiary.firstName, beneficiary.lastName, month, year));
  }

  generateBapInvoice(beneficiary: InvoicedBapBeneficiaryDto, month: Month, year: Year) {
    this.invoiceController.downloadBapInvoice(beneficiary.id, year.value, month.value)
      .subscribe(this.downloadInvoice(beneficiary.firstName, beneficiary.lastName, month, year))
  }

  private downloadInvoice(firstName: string, lastName: string, month: Month, year: Year) {
    return doc => {
      let blob = new Blob([doc]);
      let monthAsString = '0' + month.value;
      monthAsString = monthAsString.substring(-2);
      let fileName = _.startCase(_.toLower(firstName)) + ' ' + _.toUpper(lastName) + ' - ' + monthAsString + '.' + year.value + '.pdf';
      saveAs(blob, fileName);
    };
  }
}
