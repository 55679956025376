import {RepitEnum} from "../common/RepitEnum";

export namespace PrestationRefusalMotiveEnum {
  export class PrestationRefusalMotive extends RepitEnum {
    constructor(value: string, label: string) {
      super(value, label);
    }
  }

  export const LATE_REQUEST = new PrestationRefusalMotive("LATE_REQUEST", "Demande tardive");
  export const LOW_PRIORITY = new PrestationRefusalMotive("LOW_PRIORITY", "Non prioritaire");
  export const LACK_OF_STAFF = new PrestationRefusalMotive("LACK_OF_STAFF", "Manque de personnel");
  export const OTHER = new PrestationRefusalMotive("OTHER", "Autre");


  export const VALUES: PrestationRefusalMotive[] = [
    LATE_REQUEST,
    LOW_PRIORITY,
    LACK_OF_STAFF,
    OTHER,
  ];

  const BY_VALUE = new Map<string, PrestationRefusalMotive>();
  VALUES.forEach(item => BY_VALUE.set(item.value, item));

  export function fromValue(value: string): PrestationRefusalMotive {
    return BY_VALUE.get(value);
  }
}