/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {SimpleBeneficiaryDto} from './simpleBeneficiaryDto';
import {DateTimeRangeDto} from './dateTimeRangeDto';


export interface SchedulablePrestationDto {
  id?: number;
  status?: SchedulablePrestationDto.StatusEnum;
  schedule?: DateTimeRangeDto;
  beneficiary?: SimpleBeneficiaryDto;
  mainEducatorId?: number;
  secondaryEducatorId?: number;
}

export namespace SchedulablePrestationDto {
  export type StatusEnum =
    'ACCEPTED'
    | 'PENDING'
    | 'CANCELLED'
    | 'REFUSED'
    | 'TO_VALIDATE_BY_EDUCATOR'
    | 'TO_CLOSE'
    | 'CLOSED';
  export const StatusEnum = {
    Accepted: 'ACCEPTED' as StatusEnum,
    Pending: 'PENDING' as StatusEnum,
    Cancelled: 'CANCELLED' as StatusEnum,
    Refused: 'REFUSED' as StatusEnum,
    ToValidateByEducator: 'TO_VALIDATE_BY_EDUCATOR' as StatusEnum,
    ToClose: 'TO_CLOSE' as StatusEnum,
    Closed: 'CLOSED' as StatusEnum
  };
}


