import {map} from "rxjs/operators";
import {WorkerFetcher} from "./WorkerFetcher";
import {EducatorService} from "../educator/educator.service";
import {Period} from "./Period";
import {Observable} from "rxjs";
import {RepitWorker} from "./RepitWorker";
import {EducatorWorker} from "./EducatorWorker";

export class EducatorFetcher implements WorkerFetcher {

  private readonly educatorService: EducatorService;


  constructor(educatorService: EducatorService) {
    this.educatorService = educatorService;
  }

  fetch(period: Period): Observable<RepitWorker[]> {
    return this.educatorService.activeDuring(period).pipe(map(educators => {
      return educators.map(educator => {
        return new EducatorWorker(educator.id, educator.personInfo.firstName, educator.personInfo.lastName)
      })
    }));
  }

  id(id: number): Observable<RepitWorker> {
    return this.educatorService.summary(id).pipe(map(educator => {
      return new EducatorWorker(educator.id, educator.personInfo.firstName, educator.personInfo.lastName)
    }));
  }


}
