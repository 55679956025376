import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PersonInfoPipe} from "../pipe/person-info.pipe";
import {Scheduler} from "../../models/scheduler/Scheduler";
import {NewPrestation} from "../../models/scheduler/NewPrestation";
import {SimpleBeneficiaryDto} from "../../../generated/api";
import {RepitWorker} from "../../models/scheduler/RepitWorker";
import {WorkerFetcher} from "../../models/scheduler/WorkerFetcher";
import {SchedulerEventFetcher} from "../../models/scheduler/SchedulerEventFetcher";
import {EducatorService} from "../../models/educator/educator.service";
import {PrestationService} from "../../models/prestation/prestation.service";
import {BeneficiaryService} from "../../models/beneficiary/beneficiary.service";
import {EducatorFetcher} from "../../models/scheduler/EducatorFetcher";
import {PrestationFetcher} from "../../models/scheduler/PrestationFetcher";
import {Week} from "../../models/scheduler/Week";
import {DndDropEvent} from "ngx-drag-drop";
import {SchedulerDay} from "../../models/scheduler/SchedulerDay";
import {PrestationEvent} from "../../models/scheduler/PrestationEvent";
import {SchedulerDateAndTime} from "../../models/scheduler/SchedulerDateAndTime";
import {SchedulerEvent} from "../../models/scheduler/SchedulerEvent";
import {SchedulerEventType} from "../../models/scheduler/SchedulerEventType";
import {SchedulerBeneficiary} from "../../models/scheduler/SchedulerBeneficiary";
import {sortItemWithPersonByFirstNameAndLastName} from "../../models/person/PersonUtils";

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss']
})
export class SchedulerComponent implements OnInit {
  scheduler: Scheduler;
  newPrestation: NewPrestation = new NewPrestation();
  activeBeneficiaries: SimpleBeneficiaryDto[] = [];
  activeWorkers: RepitWorker[] = []
  beneficiaryFilter: string = "";

  private workerFetcher: WorkerFetcher;
  private eventFetcher: SchedulerEventFetcher;

  constructor(private route: ActivatedRoute,
              private educatorService: EducatorService,
              private prestationService: PrestationService,
              private beneficiaryService: BeneficiaryService,
              private personInfoPipe: PersonInfoPipe) {
    // TODO handle the optional date from the route
  }

  ngOnInit(): void {
    this.workerFetcher = new EducatorFetcher(this.educatorService);
    this.eventFetcher = new PrestationFetcher(this.prestationService)
    this.scheduler = Scheduler.forCurrentWeek(this.workerFetcher, this.eventFetcher);
    this.workerFetcher.fetch(Week.current()).subscribe(workers => this.activeWorkers = workers)
    this.beneficiaryService.simpleBeneficiaries()
      .subscribe(simpleBeneficiaries => {
        return this.activeBeneficiaries = simpleBeneficiaries
          .filter(beneficiary => beneficiary.active)
          .sort(sortItemWithPersonByFirstNameAndLastName(beneficiary => beneficiary.personInfo));
      })
  }

  public nextWeek() {
    this.scheduler.nextPeriod()
  }

  public previousWeek() {
    this.scheduler.previousPeriod()
  }

  schedule($event: DndDropEvent, day: SchedulerDay, repitWorker: RepitWorker) {
    this.scheduler.schedule($event.data, day, repitWorker)
  }

  removeAssignee($event: DndDropEvent) {
    this.scheduler.removeAssignee($event.data)
  }

  createNewPrestation() {
    this.prestationService.createQuickPrestation(this.newPrestation).subscribe(createdPrestation => {
      let startTime = new Date(createdPrestation.schedule.start);
      let endTime = new Date(createdPrestation.schedule.end);
      let prestationEvent = PrestationEvent.atDateAndTime(createdPrestation.id, SchedulerBeneficiary.fromSimpleBeneficiaryDto(createdPrestation.beneficiary), SchedulerDateAndTime.between(startTime, endTime));
      this.scheduler.addEvent(prestationEvent)
      this.newPrestation = new NewPrestation();
    })
  }

  isPrestationEvent(event: SchedulerEvent): boolean {
    return event.type == SchedulerEventType.PRESTATION_TYPE;
  }

  castToPrestationEvent(event: SchedulerEvent): PrestationEvent {
    return event as PrestationEvent;
  }

  getFilteredBeneficiaries() {
    return this.activeBeneficiaries.filter(beneficiary => this.personInfoPipe
      .transform(beneficiary.personInfo)
      .toLowerCase()
      .includes(this.beneficiaryFilter.toLowerCase()));
  }

}
