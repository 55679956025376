import {Component, OnInit} from '@angular/core';
import {ChartConfiguration} from "chart.js";
import {Year} from "../../../models/calendar/Year";
import {BeneficiaryStatistics} from "../../../models/management-dashboard/beneficiary/beneficiary-statistics";
import {PrestationStatistics} from "../../../models/management-dashboard/prestation/prestation-statistics";
import {CalendarService} from "../../../models/calendar/calendar.service";
import {ManagementService} from "../../../models/management-dashboard/management.service";

@Component({
  selector: 'app-management-dashboard',
  templateUrl: './management-dashboard.component.html',
  styleUrls: ['./management-dashboard.component.scss']
})
export class ManagementDashboardComponent implements OnInit {
  selectedYear: Year;
  years: Year[] = [];
  beneficiaryStatistics: BeneficiaryStatistics = new BeneficiaryStatistics([], [], []);
  prestationStatistics: PrestationStatistics = new PrestationStatistics(0, [], []);

  barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: false,
    maintainAspectRatio: true,
  };
  doughnutAreaOptions: ChartConfiguration<'doughnut'>['options'] = {
    responsive: false,
  };
  beneficiariesByActivity: ChartConfiguration<'bar'>['data'] = {
    labels: [],
    datasets: []
  }
  beneficiariesByBrData: ChartConfiguration<'bar'>['data'] = {
    labels: [],
    datasets: []
  }
  refusedInscriptionsByMotiveData: ChartConfiguration<'bar'>['data'] = {
    labels: [],
    datasets: []
  }
  prestationsByMonthData: ChartConfiguration<'bar'>['data'] = {
    labels: [],
    datasets: []
  }

  constructor(private calendarService: CalendarService, private managementService: ManagementService) {
  }

  ngOnInit(): void {
    this.years = this.calendarService.getYears();
    this.selectedYear = this.calendarService.getCurrentYear();
    this.refreshData();
  }

  refreshData() {
    this.managementService.fetchBeneficiaryStatistics(this.selectedYear)
      .subscribe(stats => {
        this.beneficiaryStatistics = stats;
        this.refreshBeneficiariesByActivity();
        this.refreshBeneficiariesByBrData();
        this.refreshRefusedInscriptionsByMotiveData();
      });
    this.managementService.fetchPrestationStatistics(this.selectedYear)
      .subscribe(stats => {
        this.prestationStatistics = stats;
        this.refreshPrestationsByMonthData();
      });
  }

  private refreshBeneficiariesByBrData() {
    this.beneficiariesByBrData = {
      labels: this.beneficiaryStatistics.beneficiariesByBr.map(stat => stat.name),
      datasets: [{
        data: this.beneficiaryStatistics.beneficiariesByBr.map(stat => stat.number),
        label: 'Bénéficiaires par BR'
      }]
    }
  }

  private refreshRefusedInscriptionsByMotiveData() {
    this.refusedInscriptionsByMotiveData = {
      labels: this.beneficiaryStatistics.refusedInscriptionsByMotive.map(stat => stat.name),
      datasets: [{
        data: this.beneficiaryStatistics.refusedInscriptionsByMotive.map(stat => stat.number),
        label: 'Refus par motif'
      }]
    }
  }

  private refreshPrestationsByMonthData() {
    this.prestationsByMonthData = {
      labels: this.prestationStatistics.prestationsByMonth.map(stat => stat.name),
      datasets: [{
        data: this.prestationStatistics.prestationsByMonth.map(stat => stat.number),
        label: 'Prestations par mois'
      }, {
        data: this.prestationStatistics.prestationPointsByMonth.map(stat => stat.number * 1.1),
        label: 'Points par mois'
      }]
    }

  }

  private refreshBeneficiariesByActivity() {
    this.beneficiariesByActivity = {
      labels: this.beneficiaryStatistics.beneficiaries.map(stat => stat.name),
      datasets: [{
        data: this.beneficiaryStatistics.beneficiaries.map(stat => stat.number),
        label: 'Bénéficiaires'
      }]
    }
  }
}
