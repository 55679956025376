<div class="m-3" [formGroup]="form">
    <h1 mat-dialog-title>Modifier l'adresse et les contacts</h1>
    <mat-dialog-content>
        <div class="vstack" [formGroup]="form">
            <div class="d-flex gap-2">
                <mat-form-field appearance="fill">
                    <mat-label>Demande</mat-label>
                    <mat-select [compareWith]="byId" formControlName="requestContact">
                        <mat-option *ngFor="let contact of contacts()" [value]="contact">
                            {{ contact | personInfo }} ({{ contact.linkType }})
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Facturation</mat-label>
                    <mat-select [compareWith]="byId" formControlName="invoiceContact">
                        <mat-option *ngFor="let contact of contacts()" [value]="contact">
                            {{ contact | personInfo }} ({{ contact.linkType }})
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <app-create-address-fields formGroupName="address"></app-create-address-fields>
            <div class="vstack gap-2" *ngIf="isOutOfHome()">
                <h2>Prestation hors domicile
                    <mat-icon id="outOfHome" class="warning-icon"
                              matTooltip="Prestation hors domicile">
                        warning
                    </mat-icon>
                </h2>
                <button class="btn btn-secondary" id="setRequesterAddress" aria-label="Choisir l'adresse du demandeur"
                        (click)="setRequestContactAddress()">
                    <mat-icon>home</mat-icon>
                    <div class="vstack gap-2">
                        <div>Choisir l'adresse du demandeur</div>
                        <div>{{ getRequestContactAddress() | inlineAddress }}</div>
                    </div>
                </button>

            </div>

        </div>
    </mat-dialog-content>


    <mat-dialog-actions class="d-flex gap-2 justify-content-end">
        <button mat-dialog-close class="btn btn-secondary">Annuler</button>
        <button class="btn btn-primary" id="confirm" [disabled]="!isValid()" (click)="confirm()">Confirmer</button>
    </mat-dialog-actions>
</div>

