import {Component, computed, inject, OnInit, signal} from '@angular/core';
import {Store} from "@ngxs/store";
import {toSignal} from "@angular/core/rxjs-interop";
import {PrestationSelectors} from "../../../stores/prestation/prestation.selectors";
import {
  FindPrestationsToValidateForCurrentUser,
  ValidatePrestation
} from "../../../stores/prestation/prestation.actions";
import {PrestationValidationRequest} from "../../../models/prestation/PrestationValidationRequest";


@Component({
  selector: 'app-prestations-to-validate-widget',
  templateUrl: './prestations-to-validate-widget.component.html',
  styleUrls: ['./prestations-to-validate-widget.component.scss']
})
export class PrestationsToValidateWidgetComponent implements OnInit {
  store = inject(Store);
  prestationIndex = signal(0);
  prestationsToValidate = toSignal(this.store.select(PrestationSelectors.prestationsToValidate()), {initialValue: []});

  ngOnInit(): void {
    this.store.dispatch(new FindPrestationsToValidateForCurrentUser());
  }

  hasPrestationToValidate = computed(() => {
    return this.prestationsToValidate().length > 0
  });

  oldestPrestationsToValidate = computed(() => {
    return this.prestationsToValidate().sort((a, b) => a.schedule.start().getTime() - b.schedule.end().getTime());
  });

  getValidatingPrestation = computed(() => {
    return this.oldestPrestationsToValidate()[this.prestationIndex()];
  });

  nextPrestation() {
    this.prestationIndex.update((index) => index >= this.prestationsToValidate().length - 1 ? 0 : index + 1);
  }

  refreshIndexIfNeeded() {
    this.prestationIndex.update((index) => index >= this.prestationsToValidate().length - 1 ? 0 : index);
  }

  sendValidationRequest(prestationValidationRequest: PrestationValidationRequest) {
    this.store.dispatch(new ValidatePrestation(prestationValidationRequest)).subscribe(() => {
      this.refreshIndexIfNeeded();
    });
  }

}
