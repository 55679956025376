import {Pipe, PipeTransform} from '@angular/core';
import * as _ from "lodash";

@Pipe({
  standalone: true,
  name: 'amount'
})
export class AmountPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    if (value % 100 === 0) {
      return this.leftPart(value) + "€";
    } else {
      return this.leftPart(value) + "," + this.rightPart(value) + "€";
    }
  }

  private rightPart(value: number) {
    let cents = value % 100;
    if (cents < 10) {
      return _.padStart(cents, 2, '0');
    }
    return _.padEnd(cents, 2, '0');
  }

  private leftPart(value: number) {
    return Math.floor(value / 100);
  }
}
