<div *ngIf="items()?.length; else noItemsTemplate">
    <div class="card-container d-flex align-items-stretch">
        <ng-container *ngFor="let item of pagedItems()">
            <ng-container *ngTemplateOutlet="itemTemplate(); context: { $implicit: item }"></ng-container>
        </ng-container>
    </div>
    <mat-paginator
            [length]="items().length"
            [pageSize]="pageSize()"
            [pageIndex]="pageIndex()"
            [pageSizeOptions]="pageSizeOptions()"
            aria-label="Select page"
            (page)="onPageChange($event)">
    </mat-paginator>
</div>
<ng-template #noItemsTemplate>
    <div>Aucun élément ne correspond aux critères.</div>
</ng-template>