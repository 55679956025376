import {UserGateway} from "../../ports/UserGateway";
import {Observable} from "rxjs";
import {User} from "../../models/user/User";
import {UserControllerService} from "../../../generated/api";
import {map} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {KeycloakService} from "keycloak-angular";


@Injectable()
export class HttpUserGateway extends UserGateway {

  constructor(private userController: UserControllerService, private keycloak: KeycloakService) {
    super();
  }

  fetchCurrentUser(): Observable<User> {
    return this.userController.currentUser()
      .pipe(map(userResponse => {
        return new User(userResponse.id,
          userResponse.personInfo.id,
          userResponse.staffId,
          userResponse.educatorId,
          userResponse.personInfo.firstName,
          userResponse.personInfo.lastName,
          userResponse.email,
          this.keycloak.getUserRoles());
      }));
  }

}