export class DateRange {
  private readonly _start: Date;
  private readonly _end: Date;


  constructor(start: Date, end: Date) {
    this._start = start;
    this._end = end;
  }


  get start(): Date {
    return this._start;
  }

  get end(): Date {
    return this._end;
  }
}