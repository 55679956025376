import {RepitEnum} from "../../common/RepitEnum";

export namespace StaffActivityRequestStatusEnum {
  export class StaffActivityRequestStatus extends RepitEnum {
    constructor(value: string, label: string) {
      super(value, label);
    }
  }

  export const PENDING = new StaffActivityRequestStatus("PENDING", "En attente");
  export const REFUSED = new StaffActivityRequestStatus("REFUSED", "Refusée");
  export const CANCELLED = new StaffActivityRequestStatus("CANCELLED", "Annulée");
  export const ACCEPTED = new StaffActivityRequestStatus("ACCEPTED", "Acceptée");

  export const VALUES: StaffActivityRequestStatus[] = [
    PENDING,
    REFUSED,
    CANCELLED,
    ACCEPTED,
  ];

  const BY_VALUE = new Map<string, StaffActivityRequestStatus>();
  VALUES.forEach(item => BY_VALUE.set(item.value, item));

  export function fromValue(value: string): StaffActivityRequestStatus {
    return BY_VALUE.get(value);
  }
}