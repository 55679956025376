import {AbstractEvent} from "./AbstractEvent";
import {SimpleBeneficiaryDto} from "../../../generated/api";
import {SchedulerDay} from "./SchedulerDay";
import {SchedulerTimePeriod} from "./SchedulerTimePeriod";
import {SchedulerEventType} from "./SchedulerEventType";
import {SchedulerDateAndTime} from "./SchedulerDateAndTime";
import {CalendarEvent} from "angular-calendar";
import {SchedulerBeneficiary} from "./SchedulerBeneficiary";


export class PrestationEvent extends AbstractEvent {

  private readonly _beneficiary: SchedulerBeneficiary;

  private constructor(id: number, day: SchedulerDay, timePeriod: SchedulerTimePeriod, repitWorkerId: number, beneficiary: SchedulerBeneficiary) {
    super(id, day, timePeriod, repitWorkerId)
    this._beneficiary = beneficiary;
  }

  get type(): SchedulerEventType {
    return SchedulerEventType.PRESTATION_TYPE;
  }

  isAssignable(): boolean {
    return true;
  }

  get beneficiary(): SimpleBeneficiaryDto {
    return this._beneficiary;
  }

  get title(): string {
    return this._beneficiary.firstName + " " + this._beneficiary.lastName;
  }

  toCalendarEvent(): CalendarEvent {
    return {
      id: this.id,
      start: this.startDateAndTime,
      end: this.endDateAndTime,
      title: this.title,
      cssClass: 'prestation-event',
      color: {
        primary: 'black',
        secondary: 'white'
      }
    };
  }

  public static atDateAndTime(id: number, beneficiary: SchedulerBeneficiary, dateAndTime: SchedulerDateAndTime): PrestationEvent {
    return new PrestationEvent(id, dateAndTime.day, dateAndTime.timePeriod, null, beneficiary)
  }

  public static scheduled(id: number, beneficiary: SchedulerBeneficiary, dateAndTime: SchedulerDateAndTime, repitWorkerId: number): PrestationEvent {
    return new PrestationEvent(id, dateAndTime.day, dateAndTime.timePeriod, repitWorkerId, beneficiary)
  }


}
