import {SchedulerEvent} from "../../models/scheduler/SchedulerEvent";

export class FindWorkEventsForStaffMember {
  public static readonly type = '[Work events list] List work events for staff members';

  constructor(public staffMemberId: number) {
  }
}

export class WorkEventsFound {
  public static readonly type = '[Work events API] Work events found';

  constructor(public workEvents: SchedulerEvent[]) {
  }
}