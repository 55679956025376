<div class="vstack gap-2" [formGroup]="form">
    <div class="vstack gap-2" *ngIf="isUpdatingTravels(); else noTravelBlock">
        <div *ngIf="educatorDescription()">
            <div>
                <span style="font-weight: bold">Description des trajets : </span><span>{{ educatorDescription() }}</span>
            </div>
        </div>
        <mat-expansion-panel *ngIf="isAddingTravelBefore()" [(expanded)]="travelBeforeExpanded"
                             class="border border-2 rounded">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Trajet avant
                </mat-panel-title>
                <mat-panel-description *ngIf="!travelBeforeExpanded()">
                    <div>{{ travelBeforeAddress() | inlineAddress }}</div>
                </mat-panel-description>
                <div class="btn ms-auto" (click)="removeTravelBefore()">
                    <mat-icon>close</mat-icon>
                </div>
            </mat-expansion-panel-header>
            <app-create-address-fields class="vstack gap-2"
                                       formGroupName="addressBefore"></app-create-address-fields>
            <mat-form-field class="w-100">
                <mat-label>Description</mat-label>
                <textarea matInput cdkTextareaAutosize formControlName="travelBeforeDescription"></textarea>
            </mat-form-field>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="isAddingTravelDuring()" [(expanded)]="travelDuringExpanded"
                             class="border border-2 rounded">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Trajet pendant
                </mat-panel-title>
                <mat-panel-description *ngIf="!travelDuringExpanded()">
                    <div>{{ form.get("travelDescription").value }}
                        ({{ getDeclaredTravelDistanceOfTravelDuring() }}km)
                    </div>
                </mat-panel-description>
                <div class="btn ms-auto" (click)="removeTravelDuring()">
                    <mat-icon>close</mat-icon>
                </div>
            </mat-expansion-panel-header>
            <mat-form-field class="w-100">
                <mat-label>Description des trajets</mat-label>
                <textarea matInput cdkTextareaAutosize formControlName="travelDescription"></textarea>
            </mat-form-field>
            <mat-form-field class="small-input">
                <mat-label>Km</mat-label>
                <input matInput type="number" formControlName="travelDistance">
            </mat-form-field>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="isAddingTravelAfter()" [(expanded)]="travelAfterExpanded"
                             class="border border-2 rounded">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Trajet Après
                </mat-panel-title>
                <mat-panel-description *ngIf="!travelAfterExpanded()">
                    <div>{{ travelAfterAddress() | inlineAddress }}</div>
                </mat-panel-description>
                <div class="btn ms-auto" (click)="removeTravelAfter()">
                    <mat-icon>close</mat-icon>
                </div>
            </mat-expansion-panel-header>
            <app-create-address-fields class="vstack gap-2"
                                       formGroupName="addressAfter"></app-create-address-fields>
            <mat-form-field class="w-100">
                <mat-label>Description</mat-label>
                <textarea matInput cdkTextareaAutosize formControlName="travelAfterDescription"></textarea>
            </mat-form-field>
        </mat-expansion-panel>
    </div>
    <ng-template #noTravelBlock>
        Aucun déplacement associé à la prestation
    </ng-template>
    <div class="d-flex gap-2 justify-content-around">
        <div class="btn btn-secondary d-flex" *ngIf="!isAddingTravelBefore()"
             (click)="startAddingTravelBefore()">
            <mat-icon>add</mat-icon>
            Avant
        </div>
        <div class="btn btn-secondary d-flex" *ngIf="!isAddingTravelDuring()"
             (click)="startAddingTravelDuring()">
            <mat-icon>add</mat-icon>
            Pendant
        </div>
        <div class="btn btn-secondary d-flex" *ngIf="!isAddingTravelAfter()"
             (click)="startAddingTravelAfter()">
            <mat-icon>add</mat-icon>
            Après
        </div>
    </div>
</div>