<h1>Bénéficiaires</h1>
<div class="vstack gap-2">
    <div class="d-flex gap-2 general-bg bg-white align-content-center align-items-center">
        <mat-form-field class="height-0">
            <mat-label>Filtrer</mat-label>
            <input matInput type="text" [(ngModel)]="textFilter">
            <button *ngIf="textFilter" matSuffix mat-icon-button aria-label="Clear"
                    (click)="textFilter=''" tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <mat-chip-listbox multiple>
            <mat-chip-option (click)="toggleBap()"
                             [selected]="bapFilter">
                BAP
            </mat-chip-option>
            <mat-chip-option (click)="toggleNotBap()"
                             [selected]="notBapFilter">
                Non BAP
            </mat-chip-option>
        </mat-chip-listbox>

        <mat-checkbox [(ngModel)]="archivedFilter">Inclure les bénéficiaires archivés</mat-checkbox>

        <a routerLink="/beneficiary/create" class="ms-auto">
            <div class="btn btn-important">
                <mat-icon>add</mat-icon>
                Ajouter un bénéficiaire
            </div>
        </a>

    </div>

    <div *ngIf="filteredBeneficiaries().length > 0 ; else elseBlock">
        <div class="card-container d-flex align-items-stretch">
            <a class="card repit-card" *ngFor="let beneficiary of paginatedBeneficiaries()"
               [routerLink]="['/beneficiary/detail/' + beneficiary.id]">
                <div class="card-header">
                    {{ beneficiary.firstName }} {{ beneficiary.lastName }}
                </div>
                <div class="card-body">
                    <div class="card-text">
                        <div>
                            <span *ngIf="!beneficiary.active">Archivé - </span><span
                                *ngIf="beneficiary.bap">BAP - </span><span>{{ beneficiary.br.name }}</span>
                        </div>
                        <div *ngIf="hasLastPrestation(beneficiary)">
                            Dernière prestation
                            le {{ beneficiary.lastPrestation.schedule.startTime | date:"dd/MM/YYYY" }}
                        </div>
                        <div *ngIf="hasNextPrestation(beneficiary)">
                            Prochaine prestation
                            le {{ beneficiary.nextPrestation.schedule.endTime | date:"dd/MM/YYYY" }}
                        </div>
                    </div>
                </div>
            </a>
        </div>

        <mat-paginator [length]="filteredBeneficiaries().length"
                       [pageSize]="pageSize"
                       [pageIndex]="pageIndex"
                       [pageSizeOptions]="[10, 20, 50, 100]"
                       aria-label="Select page"
                       (page)="refreshPagination($event)"
        >
        </mat-paginator>
    </div>
    <ng-template #elseBlock>Aucun bénéficiaire ne correspond aux critères.</ng-template>

</div>