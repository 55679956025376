import {Period} from "./Period";
import {addDays, eachDayOfInterval} from "date-fns";
import {SchedulerDay} from "./SchedulerDay";
import {DateRange} from "../calendar/DateRange";

export class Week implements Period {
  private readonly endTime: Date;
  private readonly startTime: Date;
  private readonly days: SchedulerDay[];

  constructor(date: Date) {
    const dayOfWeek = date.getDay();
    const diff = (dayOfWeek + 6) % 7;
    const previousMonday = new Date(date);

    previousMonday.setDate(date.getDate() - diff);
    this.startTime = previousMonday;
    this.endTime = addDays(previousMonday, 6);
    this.days = [];
    for (let day of eachDayOfInterval({start: this.startTime, end: this.endTime})) {
      this.days.push(new SchedulerDay(day));
    }
  }

  getDaysOfPeriod(): SchedulerDay[] {
    return this.days;
  }

  getNextPeriod(): Period {
    return Week.ofDate(addDays(this.endTime, 1));
  }

  getPreviousPeriod(): Period {
    return Week.ofDate(addDays(this.startTime, -1));
  }

  getFirstDayOfPeriod(): SchedulerDay {
    return this.getDaysOfPeriod()[0];
  }

  getLastDayOfPeriod(): SchedulerDay {
    return this.getDaysOfPeriod().slice(-1)[0];
  }

  toRange(): DateRange {
    return new DateRange(this.startTime, this.endTime);
  }

  static current(): Week {
    return this.ofDate(new Date());
  }

  static ofDate(startDate: Date): Week {
    return new Week(startDate);
  }
}
