import {SchedulerTime} from "../../scheduler/SchedulerTime";

export class TimeOutCompRequest {
  staffMemberId: number;
  date: Date;
  startTime: SchedulerTime;
  endTime: SchedulerTime;
  note: string;

  constructor(staffMemberId: number, date: Date, startTime: SchedulerTime, endTime: SchedulerTime, note: string) {
    this.staffMemberId = staffMemberId;
    this.date = date;
    this.startTime = startTime;
    this.endTime = endTime;
    this.note = note;
  }
}