import {Observable} from "rxjs";
import {PrestationService} from "../prestation/prestation.service";
import {map} from "rxjs/operators";
import {SchedulerEventFetcher} from "./SchedulerEventFetcher";
import {Period} from "./Period";
import {SchedulerEvent} from "./SchedulerEvent";
import {PrestationEvent} from "./PrestationEvent";
import {SchedulerDateAndTime} from "./SchedulerDateAndTime";
import {SchedulerBeneficiary} from "./SchedulerBeneficiary";

export class PrestationFetcher implements SchedulerEventFetcher {

  private readonly prestationService: PrestationService;

  constructor(prestationService: PrestationService) {
    this.prestationService = prestationService;
  }

  fetch(period: Period): Observable<SchedulerEvent[]> {
    return this.prestationService.schedulablePrestations(period).pipe(map(prestations => {
      return prestations.map(prestation => {
        let startTime = new Date(prestation.schedule.start);
        let endTime = new Date(prestation.schedule.end);
        return PrestationEvent.scheduled(prestation.id,
          SchedulerBeneficiary.fromSimpleBeneficiaryDto(prestation.beneficiary),
          SchedulerDateAndTime.between(startTime, endTime),
          prestation.mainEducatorId
        );
      })
    }));
  }

}
