import {Component} from '@angular/core';

@Component({
  selector: 'app-list-activity-requests',
  standalone: true,
  imports: [],
  templateUrl: './list-activity-requests.component.html',
  styleUrl: './list-activity-requests.component.scss'
})
export class ListActivityRequestsComponent {

}
