import {UserGateway} from "../../ports/UserGateway";
import {Observable} from "rxjs";
import {User} from "../../models/user/User";
import {UserControllerService} from "../../../generated/api";
import {map} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {KeycloakService} from "keycloak-angular";


@Injectable()
export class HttpUserGateway extends UserGateway {

  constructor(private userController: UserControllerService, private keycloak: KeycloakService) {
    super();
  }

  fetchCurrentUser(): Observable<User> {
    return this.userController.currentUser()
      .pipe(map(userResponse => {
        const user = new User();
        user.id = userResponse.id;
        user.personId = userResponse.personInfo.id;
        user.staffId = userResponse.staffId;
        user.educatorId = userResponse.educatorId;
        user.firstName = userResponse.personInfo.firstName;
        user.lastName = userResponse.personInfo.lastName;
        user.email = userResponse.email;
        user.roles = this.keycloak.getUserRoles();
        return user;
      }));
  }

}