export namespace FirstContactApplicationStatusEnum {
  export class FirstContactApplicationStatus {
    private readonly _value: string;
    private readonly _label: string;
    private readonly _icon: string;

    constructor(value: string, label: string, icon: string) {
      this._value = value;
      this._label = label;
      this._icon = icon;
    }

    get value(): string {
      return this._value;
    }

    get label(): string {
      return this._label;
    }

    get icon(): string {
      return this._icon;
    }
  }

  export const PENDING = new FirstContactApplicationStatus("PENDING", "En attente", "keyboard_control");
  export const ACCEPTED = new FirstContactApplicationStatus("ACCEPTED", "Acceptée", "check");
  export const REFUSED = new FirstContactApplicationStatus("REFUSED", "Refusée", "clear");

  export const VALUES: FirstContactApplicationStatus[] = [
    PENDING,
    ACCEPTED,
    REFUSED
  ];

  const BY_VALUE = new Map<string, FirstContactApplicationStatus>();
  VALUES.forEach(status => BY_VALUE.set(status.value, status));

  export function fromValue(value: string): FirstContactApplicationStatus {
    return BY_VALUE.get(value);
  }
}




