import {Component} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {RefusalInformation} from "../../../models/first-contact/RefusalInformation";
import {FirstContactApplicationRefusalMotive} from "../../../models/first-contact/RefusalMotive";
import RefusalMotive = FirstContactApplicationRefusalMotive.RefusalMotive;

@Component({
  selector: 'app-refuse-first-contact',
  templateUrl: './refuse-first-contact.component.html',
  styleUrls: ['./refuse-first-contact.component.scss']
})
export class RefuseFirstContactComponent {

  refusal: RefusalInformation = new RefusalInformation()
  motives: RefusalMotive[] = FirstContactApplicationRefusalMotive.VALUES;


  constructor(public dialogRef: MatDialogRef<RefuseFirstContactComponent>) {
  }

  confirm() {
    this.dialogRef.close(this.refusal)
  }
}
