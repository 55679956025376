<h1>Inscription : {{ firstContact.status | firstContactApplicationStatus }}</h1>
<div class="vstack gap-2">
    <div class="general-bg bg-white">
        <h2>Informations à propos de la personne</h2>

        <div>
            <span style="font-weight: bold">Prénom : </span><span>{{ firstContact.firstName }}</span>
        </div>
        <div>
            <span style="font-weight: bold">Nom : </span><span>{{ firstContact.lastName }}</span>
        </div>
        <div>
            <span style="font-weight: bold">Age : </span><span>{{ firstContact.age }}</span>
        </div>
        <div>
            <span style="font-weight: bold">Genre : </span><span>{{ firstContact.gender | gender }}</span>
        </div>
        <div>
            <span style="font-weight: bold">Adresse : </span><span>{{ firstContact.address | inlineAddress }}</span>
        </div>
    </div>

    <div class="general-bg bg-white">
        <h2>Informations à propos de l'inscription</h2>

        <div>
    <span
            style="font-weight: bold">Date de contact : </span><span>{{ firstContact.contactedOn | date:"dd/MM/YYYY" }}</span>
        </div>
        <div>
            <span style="font-weight: bold">Note : </span><span>{{ firstContact.note }}</span>
        </div>
    </div>
    <div *ngIf="isRefused()" class="general-bg bg-white">
        <h2>Informations à propos du refus</h2>

        <div>
      <span
              style="font-weight: bold">Motif du refus : </span><span>{{ firstContact.refusalMotive | refusalMotive }}</span>
        </div>
        <div>
      <span
              style="font-weight: bold">Date du refus : </span><span>{{ firstContact.refusedOn | date:"dd/MM/YYYY" }}</span>
        </div>
        <div>
            <span style="font-weight: bold">Note : </span><span>{{ firstContact.refusalNote }}</span>
        </div>
    </div>
    <div *ngIf="isAccepted()" class="general-bg bg-white">
        <h2>Informations à propos de l'acceptation</h2>

        <div>
      <span
              style="font-weight: bold">Date de l'acceptation : </span><span>{{ firstContact.acceptedOn | date:"dd/MM/YYYY" }}</span>
        </div>
    </div>

    <div class="general-bg bg-white d-flex gap-2">
        <button class="btn btn-secondary" [routerLink]="['/first-contact']">Retour</button>
        <button class="btn btn-primary" [routerLink]="['/first-contact/edit/', firstContact.id]"
                *ngIf="firstContact.canBeEdited">
            Modifier
        </button>
        <button class="btn btn-danger" (click)="refuse()" *ngIf="firstContact.canBeEdited">
            Refuser
        </button>
        <button class="btn btn-success" (click)="accept()" *ngIf="firstContact.canBeEdited">
            Accepter
        </button>
        <button class="btn btn-primary" (click)="createBeneficiary()" *ngIf="canCreateBeneficiary()">
            Créer le bénéficiaire
        </button>
    </div>
</div>