import {SchedulerEvent} from "./SchedulerEvent";
import {SchedulerDay} from "./SchedulerDay";
import {RepitWorker} from "./RepitWorker";
import {SchedulerTimePeriod} from "./SchedulerTimePeriod";
import {SchedulerEventType} from "./SchedulerEventType";
import {SchedulerDateAndTime} from "./SchedulerDateAndTime";
import {CalendarEvent} from "angular-calendar";

export abstract class AbstractEvent implements SchedulerEvent {
  private readonly _id: number;
  private _day: SchedulerDay;
  private _timePeriod: SchedulerTimePeriod;
  private _repitWorkerId: number;

  protected constructor(id: number, day: SchedulerDay, timePeriod: SchedulerTimePeriod, repitWorkerId: number) {
    this._id = id;
    this._day = day;
    this._timePeriod = timePeriod;
    this._repitWorkerId = repitWorkerId;
  }

  get id(): number {
    return this._id;
  }

  abstract get type(): SchedulerEventType;

  abstract get title(): string;

  abstract toCalendarEvent(): CalendarEvent;

  get day(): SchedulerDay {
    return this._day;
  }

  get time(): SchedulerTimePeriod {
    return this._timePeriod;
  }

  get endDateAndTime(): Date {
    return this._day.dateTimeAtEndOfPeriod(this._timePeriod);
  }

  get isFullDay(): boolean {
    return false;
  }

  get startDateAndTime(): Date {
    return this._day.dateTimeAtStartOfPeriod(this._timePeriod);
  }

  private range(): SchedulerDateAndTime {
    return new SchedulerDateAndTime(this._day, this._timePeriod);
  }

  isConcerning(repitWorker: RepitWorker): boolean {
    return !this.isNotAssigned() && this._repitWorkerId === repitWorker.id;
  }

  isHappeningOn(day: SchedulerDay): boolean {
    return !this.isNotPlanned() && this.range().includesDay(day);
  }

  isNotScheduled(): boolean {
    return this.isNotPlanned() || this.isNotAssigned()
  }

  isNotAssigned(): boolean {
    return !this.isAssigned();
  }

  isAssigned(): boolean {
    return this._repitWorkerId != null;
  }

  isNotPlanned(): boolean {
    return this._day == null;
  }

  scheduleDay(day: SchedulerDay, worker: RepitWorker): void {
    this._day = day;
    this._repitWorkerId = worker.id;
  }

  removeAssignee(): void {
    this._repitWorkerId = null;
  }

  isAssignable(): boolean {
    return false;
  }


}
