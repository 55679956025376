/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {BeneficiaryContactTypeDto} from './beneficiaryContactTypeDto';
import {AddressWithPeriodDto} from './addressWithPeriodDto';
import {BeneficiaryContactLinkTypeDto} from './beneficiaryContactLinkTypeDto';
import {LegalRepresentativeDto} from './legalRepresentativeDto';


export interface BeneficiaryContactDto {
  id?: number;
  type?: BeneficiaryContactTypeDto;
  linkType?: BeneficiaryContactLinkTypeDto;
  legalRepresentative?: LegalRepresentativeDto;
  prestationPrimaryContact?: boolean;
  invoicePrimaryContact?: boolean;
  address?: AddressWithPeriodDto;
}

