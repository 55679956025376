import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {formatDate} from "@angular/common";
import {PrestationControllerService, SchedulablePrestationDto} from "../../../generated/api";
import {Period} from "../scheduler/Period";
import {NewPrestation} from "../scheduler/NewPrestation";

@Injectable({
  providedIn: 'root'
})
export class PrestationService {


  constructor(private prestationController: PrestationControllerService) {
  }

  public schedulablePrestations(period: Period): Observable<SchedulablePrestationDto[]> {
    return this.prestationController.schedulablePrestations(
      formatDate(period.getFirstDayOfPeriod().date, "YYYY-MM-dd", "fr-BE"),
      formatDate(period.getLastDayOfPeriod().date, "YYYY-MM-dd", "fr-BE"))
  }

  public createQuickPrestation(newPrestation: NewPrestation) {
    return this.prestationController.createPrestationWithDefault({
      beneficiaryId: newPrestation.beneficiary.id,
      startTime: formatDate(newPrestation.schedule.start(), "YYYY-MM-ddTHH:mm:ss", "fr-BE"),
      endTime: formatDate(newPrestation.schedule.end(), "YYYY-MM-ddTHH:mm:ss", "fr-BE")
    })
  }
}
