import {Component, Input, OnInit} from '@angular/core';
import {PrestationEvent} from "../../../models/scheduler/PrestationEvent";

@Component({
  selector: 'app-prestation-event-card',
  templateUrl: './prestation-event-card.component.html',
  styleUrls: ['./prestation-event-card.component.scss']
})
export class PrestationEventCardComponent implements OnInit {

  @Input() prestationEvent: PrestationEvent;


  constructor() {
  }

  ngOnInit(): void {

  }


}
