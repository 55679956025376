import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {PageEvent} from "@angular/material/paginator";
import {FirstContactApplicationStatusEnum} from "../../../models/first-contact/FirstContactApplicationStatusEnum";
import {FirstContactApplicationSummary} from "../../../../generated/api";
import {FirstContactService} from "../../../models/first-contact/first-contact.service";
import FirstContactApplicationStatus = FirstContactApplicationStatusEnum.FirstContactApplicationStatus;
import VALUES = FirstContactApplicationStatusEnum.VALUES;
import fromValue = FirstContactApplicationStatusEnum.fromValue;

@Component({
  selector: 'app-list-first-contact',
  templateUrl: './list-first-contact.component.html',
  styleUrls: ['./list-first-contact.component.scss']
})
export class ListFirstContactComponent implements OnInit {

  allFirstContacts: FirstContactApplicationSummary[] = [];

  textFilter: string = "";
  allStatuses: FirstContactApplicationStatus[] = VALUES;
  selectedStatuses: FirstContactApplicationStatus[] = [];

  pageIndex = 0;
  pageSize = 20;

  constructor(private firstContactService: FirstContactService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.firstContactService.fetchAll()
      .subscribe(all => {
        this.allFirstContacts = all;
        this.applyFilterFromRoute();
      });
  }

  private applyFilterFromRoute() {
    this.route.queryParamMap.subscribe(params => {
      let status = params.get("status");
      if (status) {
        this.toggleStatus(fromValue(status));
      }
    })
  }

  filteredFirstContacts(): FirstContactApplicationSummary[] {
    return this.allFirstContacts
      .filter(firstContact => this.filterName(firstContact))
      .filter(firstContact => this.filterStatus(firstContact));
  }

  private filterStatus(firstContact: FirstContactApplicationSummary) {
    return this.selectedStatuses.length === 0 || this.selectedStatuses.includes(fromValue(firstContact.status));
  }

  private filterName(firstContact: FirstContactApplicationSummary) {
    return (this.getName(firstContact)).toLowerCase()
      .includes(this.textFilter.toLowerCase());
  }

  toggleStatus(status: FirstContactApplicationStatus): void {
    const index = this.selectedStatuses.indexOf(status, 0);
    if (index > -1) {
      this.selectedStatuses.splice(index, 1);
    } else {
      this.selectedStatuses.push(status);
    }
  }

  getName(firstContact: FirstContactApplicationSummary): string {
    if (!firstContact.firstName && !firstContact.lastName) {
      return "Sans nom";
    } else {
      return firstContact.firstName + " " + firstContact.lastName;
    }
  }

  isStatusSelected(status: FirstContactApplicationStatus): boolean {
    return this.selectedStatuses.indexOf(status, 0) > -1;
  }

  determineIconFromStatus(status: string): string {
    return fromValue(status).icon;
  }

  paginatedFirstContacts(): FirstContactApplicationSummary[] {
    const end = (this.pageIndex + 1) * this.pageSize;
    const start = this.pageIndex * this.pageSize;
    return this.filteredFirstContacts().slice(start, end)
  }

  refreshPagination($event: PageEvent) {
    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
  }

  isRefused(firstContact: FirstContactApplicationSummary) {
    return FirstContactApplicationStatusEnum.fromValue(firstContact.status) === FirstContactApplicationStatusEnum.REFUSED
  }

  isAccepted(firstContact: FirstContactApplicationSummary) {
    return FirstContactApplicationStatusEnum.fromValue(firstContact.status) === FirstContactApplicationStatusEnum.ACCEPTED
  }
}
