import {StaffGateway} from "../../ports/StaffGateway";
import {map, Observable, of} from "rxjs";
import {StaffMemberSummary} from "../../models/staff/StaffMemberSummary.model";
import {StaffMemberDetail} from "../../models/staff/StaffMemberDetail";
import {AddressWithHistory} from "../../models/address/AddressWithHistory";
import {inject, Injectable} from "@angular/core";
import {
  EducatorControllerService,
  StaffActivityControllerService,
  StaffControllerService
} from "../../../generated/api";
import {StaffTypeEnum} from "../../models/staff/StaffType.enum";
import {TimeOutCompRequest} from "../../models/staff/activity/TimeOutCompRequest";
import {TimeOffRequest} from "../../models/staff/activity/TimeOffRequest";
import {fd} from "../../models/time/TimeUtils";
import {StaffActivityRequest} from "../../models/staff/activity/StaffActivityRequest";
import {StaffActivityTypeEnum} from "../../models/staff/activity/StaffActivityType";
import {StaffActivityRequestStatusEnum} from "../../models/staff/activity/StaffActivityRequestStatus";
import {SickLeaveRequest} from "../../models/staff/activity/SickLeaveRequest";

@Injectable()
export class HttpStaffGateway extends StaffGateway {
  // TODO move activity related methods to a separate gateway?

  educatorControllerService = inject(EducatorControllerService)
  staffControllerService = inject(StaffControllerService)
  staffActivityController = inject(StaffActivityControllerService)

  retrieveAll(): Observable<StaffMemberSummary[]> {
    return this.staffControllerService.staffMemberSummaries()
      .pipe(map(staffMembers => staffMembers.map(staffMember => {
        return {
          id: staffMember.id,
          firstName: staffMember.personInfo.firstName,
          lastName: staffMember.personInfo.lastName,
          active: staffMember.active,
          type: StaffTypeEnum.fromValue(staffMember.type.value)
        } as StaffMemberSummary
      })));
  }

  retrieve(id: number): Observable<StaffMemberDetail> {
    return of(null);
  }

  updateCurrentAddress(staffMemberId: number, address: AddressWithHistory): Observable<StaffMemberDetail> {
    return of(null);
  }

  retrieveActiveEducators(): Observable<StaffMemberSummary[]> {
    return this.educatorControllerService.activeEducators()
      .pipe(map(staffMembers => staffMembers.map(staffMember => {
        return {
          id: staffMember.id,
          firstName: staffMember.personInfo.firstName,
          lastName: staffMember.personInfo.lastName,
          active: true,
          type: StaffTypeEnum.EDUCATOR
        } as StaffMemberSummary;
      })));
  }

  findActivityRequestsForStaff(staffMemberId: number): Observable<StaffActivityRequest[]> {
    return this.staffActivityController.findRequestsByStaffId(staffMemberId)
      .pipe(map(requests => {
        return requests.map(this.mapRequest())
      }));
  }

  findActivityRequests(year: number): Observable<StaffActivityRequest[]> {
    return this.staffActivityController.findRequestsForYear(year)
      .pipe(map(requests => {
        return requests.map(this.mapRequest())
      }));
  }

  findActivityRequest(activityId: number): Observable<StaffActivityRequest> {
    return this.staffActivityController.findRequestById(activityId)
      .pipe(map(this.mapRequest()));
  }

  private mapRequest() {
    return request => {
      return {
        id: request.id,
        type: StaffActivityTypeEnum.fromValue(request.type),
        status: StaffActivityRequestStatusEnum.fromValue(request.status),
        staffMemberId: request.staffMemberId,
        staffMemberFullName: request.staffMemberFullName,
        requestedAt: new Date(request.requestedAt),
        requesterId: request.requesterId,
        requesterFullName: request.requesterFullName,
        startDate: new Date(request.period.startTime),
        endDate: new Date(request.period.endTime),
        validatorId: request.validatorId,
        validatorFullName: request.validatorFullName,
        refusalNote: request.refusalNote,
        refusedAt: request.refusedAt ? new Date(request.refusedAt) : null,
        cancelledAt: request.cancelledAt ? new Date(request.cancelledAt) : null
      } as StaffActivityRequest
    };
  }

  createSickLeaveRequest(staffMemberId: number, request: SickLeaveRequest): Observable<number> {
    return this.staffActivityController.createSickLeave({
      staffMemberId: staffMemberId,
      start: fd(request.startDate),
      end: fd(request.endDate)
    });
  }

  createTimeOutRequest(staffMemberId: number, request: TimeOutCompRequest): Observable<number> {
    return this.staffActivityController.requestTimeOut({
      staffMemberId: staffMemberId,
      start: fd(request.date),
      startTime: request.startTime.asString(),
      endTime: request.endTime.asString(),
    })
  }

  createTimeOffRequest(staffMemberId: number, request: TimeOffRequest): Observable<number> {
    return this.staffActivityController.requestTimeOff({
      staffMemberId: staffMemberId,
      start: fd(request.startDate),
      end: fd(request.endDate),
    }).pipe(
      map(staffMemberId => staffMemberId)
    )
  }

  acceptTimeOffRequest(activityId: number): Observable<void> {
    return this.staffActivityController.acceptTimeOff({activityRequestId: activityId, excludedDates: []})
  }

  acceptTimeOutRequest(activityId: number): Observable<void> {
    return this.staffActivityController.acceptTimeOut({activityRequestId: activityId})
  }

  cancelActivityRequest(activityId: number): Observable<void> {
    return this.staffActivityController.cancelActivityRequest({
      activityRequestId: activityId
    })
  }

  refuseActivityRequest(activityId: number, refusalNote: string): Observable<void> {
    return this.staffActivityController.refuseActivityRequest({
      activityId: activityId,
      comment: refusalNote
    })
  }
}
