<h1>Inscrire une personne</h1>
<form [formGroup]="form" class="vstack gap-2">
    <div class="general-bg bg-white">
        <h2>Informations à propos de la personne</h2>
        <app-clearable-input-text controlName="firstName" label="Prénom"></app-clearable-input-text>
        <app-clearable-input-text controlName="lastName" label="Nom"></app-clearable-input-text>

        <mat-form-field>
            <mat-label>Age</mat-label>
            <input matInput type="number" step="1" formControlName="age">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Genre</mat-label>
            <mat-select formControlName="gender">
                <mat-option *ngFor="let gender of genders" [value]="gender.value">
                    {{ gender.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="general-bg bg-white">
        <h2>Adresse</h2>
        <app-create-address-fields formGroupName="address"></app-create-address-fields>
    </div>

    <div class="general-bg bg-white">
        <h2>Autres informations</h2>
        <app-date-picker controlName="contactedOn" label="Date de contact"></app-date-picker>

        <mat-form-field class="input-note">
            <mat-label>Note</mat-label>
            <textarea matInput cdkTextareaAutosize formControlName="note"></textarea>
        </mat-form-field>
    </div>

    <div class="general-bg bg-white d-flex gap-2">
        <button [routerLink]="['/first-contact']" class="btn btn-secondary">Retour</button>
        <button type="submit" (click)="create()" class="btn btn-primary">
            Créer l'inscription
        </button>
    </div>
</form>
