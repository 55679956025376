import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {formatDate} from "@angular/common";
import {EducatorControllerService, EducatorSummaryDto, ReportedKmEducatorDto} from "../../../generated/api";
import {Period} from "../scheduler/Period";
import {Year} from "../calendar/Year";
import {Month} from "../calendar/Month";

@Injectable({
  providedIn: 'root'
})
export class EducatorService {

  constructor(private educatorController: EducatorControllerService) {
  }

  public allSummaries(): Observable<EducatorSummaryDto[]> {
    return this.educatorController.activeEducators();
  }

  public activeDuring(period: Period): Observable<EducatorSummaryDto[]> {
    return this.educatorController.activeEducators(
      formatDate(period.getFirstDayOfPeriod().date, "YYYY-MM-dd", "fr-BE"),
      formatDate(period.getLastDayOfPeriod().date, "YYYY-MM-dd", "fr-BE"))
  }

  public summary(id: number): Observable<EducatorSummaryDto> {
    return this.educatorController.educatorSummary(id);
  }

  public fetchEducatorsWithDocumentCapability(year: Year, month: Month): Observable<ReportedKmEducatorDto[]> {
    return this.educatorController.findEducatorsToReport(year.value, month.value);
  }
}
