import {Pipe, PipeTransform} from '@angular/core';
import {formatDate} from "@angular/common";
import {DateTimeRangeDto} from "../../../generated/api";

@Pipe({
  standalone: true,
  name: 'schedule'
})
export class SchedulePipe implements PipeTransform {


  constructor() {
  }

  transform(schedule: DateTimeRangeDto, ...args: unknown[]): string {
    let startDateTime = new Date(schedule.startTime);
    let endDateTime = new Date(schedule.endTime);
    if (this.isSameDay(startDateTime, endDateTime)) {
      return this.formatDate(schedule.startTime)
        + " " + this.formatTime(schedule.startTime)
        + " - " + this.formatTime(schedule.endTime)
    } else {
      return this.formatDate(schedule.startTime)
        + " " + this.formatTime(schedule.startTime)
        + " - " + this.formatDate(schedule.endTime)
        + " " + this.formatTime(schedule.endTime)
    }
  }

  private formatTime(date: string): string {
    return formatDate(date, "HH:mm", "fr-BE");
  }

  private formatDate(date: string): string {
    return formatDate(date, "dd/MM/YYYY", "fr-BE");
  }

  private isSameDay(d1: Date, d2: Date) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

}
