export abstract class RepitEnum {
  private readonly _value: string;
  private readonly _label: string;
  private readonly _style: EnumStyle;

  protected constructor(value: string, label: string, style: EnumStyle = null) {
    this._value = value;
    this._label = label;
    this._style = style;
  }

  get value(): string {
    return this._value;
  }

  get label(): string {
    return this._label;
  }

  get icon(): string {
    return this._style ? this._style.icon : null;
  }

  get color(): string {
    return this._style ? this._style.color : null;
  }
}

export class EnumStyle {
  private readonly _icon: string;
  private readonly _color: string;

  constructor(icon: string, color: string) {
    this._icon = icon;
    this._color = color;
  }

  get icon(): string {
    return this._icon;
  }

  get color(): string {
    return this._color;
  }
}