import {Pipe, PipeTransform} from '@angular/core';
import {fullName, WithFullName} from "../../models/person/PersonUtils";

@Pipe({
  standalone: true,
  name: 'personInfo'
})
export class PersonInfoPipe implements PipeTransform {

  transform(value: { firstName?: string; lastName?: string } | WithFullName, ...args: unknown[]): string {
    return fullName(value);
  }

}
