import {WorkEventGateway} from "../../ports/WorkEventGateway";
import {Observable, of} from "rxjs";
import {SchedulerEvent} from "../../models/scheduler/SchedulerEvent";

export class InMemoryWorkEventGateway extends WorkEventGateway {

  private workEvents: SchedulerEvent[] = [];

  findWorkEventsForStaffMember(staffMemberId: number): Observable<SchedulerEvent[]> {
    return of(this.workEvents);
  }

  setWorkEvents(workEvents: SchedulerEvent[]): InMemoryWorkEventGateway {
    this.workEvents = workEvents;
    return this;
  }

}