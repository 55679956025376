import {Component, computed, inject, signal} from '@angular/core';
import {MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {DatePickerComponent} from "../../components/date-picker/date-picker.component";
import {TimePickerComponent} from "../../components/time-picker/time-picker.component";
import {Store} from "@ngxs/store";
import {TimeOutCompRequest} from "../../../models/staff/activity/TimeOutCompRequest";
import {CreateTimeOffRequest, CreateTimeOutCompRequest} from "../../../stores/staff/staff.actions";
import {UserState} from "../../../stores/user/user.state";
import {SchedulerTime} from "../../../models/scheduler/SchedulerTime";
import {MatCheckbox} from "@angular/material/checkbox";
import {
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker,
  MatEndDate,
  MatStartDate
} from "@angular/material/datepicker";
import {MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {NgIf} from "@angular/common";
import {MatButtonToggle, MatButtonToggleGroup} from "@angular/material/button-toggle";
import {TimeOffRequest} from "../../../models/staff/activity/TimeOffRequest";

export enum TimeOffType {
  OUT = "OUT",
  TIME_OFF = "TIME_OFF"
}

@Component({
  selector: 'app-create-staff-activity-request-dialog',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogTitle,
    MatDialogActions,
    MatDialogClose,
    ReactiveFormsModule,
    DatePickerComponent,
    TimePickerComponent,
    MatCheckbox,
    FormsModule,
    MatDateRangeInput,
    MatDateRangePicker,
    MatDatepickerToggle,
    MatEndDate,
    MatFormField,
    MatHint,
    MatLabel,
    MatStartDate,
    MatSuffix,
    NgIf,
    MatButtonToggleGroup,
    MatButtonToggle
  ],
  templateUrl: './create-staff-activity-request-dialog.component.html',
  styleUrl: './create-staff-activity-request-dialog.component.scss'
})
export class CreateStaffActivityRequestDialogComponent {

  store = inject(Store);
  type = signal(TimeOffType.OUT)
  outCompForm: FormGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    startTime: new FormControl(null, [Validators.required]),
    endTime: new FormControl(null, [Validators.required]),
    note: new FormControl("")
  });
  timeOffForm: FormGroup = new FormGroup({
    startDate: new FormControl(null, [Validators.required]),
    endDate: new FormControl(null, [Validators.required]),
    note: new FormControl("")
  });

  isFormValid() {
    return this.form().valid;
  }

  create() {
    this.createAndClose(false)
  }

  createAndClose(close: boolean = true) {
    const staffId = this.store.selectSnapshot(UserState.getCurrentStaffId);
    if (this.isOut()) {
      this.store.dispatch(new CreateTimeOutCompRequest(new TimeOutCompRequest(
        staffId,
        this.form().get("date").value,
        SchedulerTime.fromString(this.form().get("startTime").value),
        SchedulerTime.fromString(this.form().get("endTime").value),
        this.form().get("note").value
      ), close));
    } else {
      this.store.dispatch(new CreateTimeOffRequest(new TimeOffRequest(
          staffId,
          this.form().get("startDate").value,
          this.form().get("endDate").value,
          this.form().get("note").value
        ), close
      ));
    }

    this.resetForms();
  }

  private resetForms() {
    this.outCompForm.reset({
      date: null,
      startTime: null,
      endTime: null,
      note: ""
    });
    this.timeOffForm.reset({
      startDate: null,
      endDate: null,
      note: ""
    });
  }

  form = computed(() => {
    return this.isOut() ? this.outCompForm : this.timeOffForm;
  });

  isOut = computed(() => {
    return this.type() === TimeOffType.OUT;
  });

  isTimeOff = computed(() => {
    return this.type() === TimeOffType.TIME_OFF;
  });
  protected readonly TimeOffType = TimeOffType;
}
