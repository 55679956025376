import {Component, computed, inject, OnInit, Signal, signal} from '@angular/core';
import {toObservable, toSignal} from "@angular/core/rxjs-interop";
import {combineLatest, map} from "rxjs";
import {StaffMemberSummary} from "../../../models/staff/StaffMemberSummary.model";
import {Store} from "@ngxs/store";
import {StaffSelectors} from "../../../stores/staff/staff.selectors";
import {RetrieveStaffMembers} from "../../../stores/staff/staff.actions";
import {fullName} from "../../../models/person/PersonUtils";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {FormsModule} from "@angular/forms";
import {MatInput} from "@angular/material/input";
import {MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {RouterLink} from "@angular/router";
import {NgForOf, NgIf} from "@angular/common";
import {PersonInfoPipe} from "../../pipe/person-info.pipe";


@Component({
  selector: 'app-list-staff',
  standalone: true,
  templateUrl: './list-staff.component.html',
  imports: [
    MatFormField,
    FormsModule,
    MatInput,
    MatIconButton,
    MatIcon,
    MatLabel,
    MatSlideToggle,
    RouterLink,
    NgIf,
    PersonInfoPipe,
    NgForOf
  ],
  styleUrls: ['./list-staff.component.scss']
})
export class ListStaffComponent implements OnInit {
  store = inject(Store);

  name = signal("");
  activeOnly = signal(true);

  filter = computed(() => ({
    name: this.name(),
    activeOnly: this.activeOnly()
  }))

  staff: Signal<StaffMemberSummary[]> = toSignal(combineLatest([this.store.select(StaffSelectors.summaries()), toObservable(this.filter)])
    .pipe(
      map(([staff, filter]) => staff
        .filter(staffMember => fullName(staffMember).toLowerCase().includes(filter.name.toLowerCase()))
        .filter(staffMember => !filter.activeOnly || staffMember.active)
      )
    ), {initialValue: []});

  ngOnInit(): void {
    this.store.dispatch(new RetrieveStaffMembers());
  }

  toggleActiveOnly() {
    this.activeOnly.update(value => !value)
  }
}
