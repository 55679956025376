import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {inject, Injectable} from "@angular/core";
import {StaffMemberSummary} from "../../models/staff/StaffMemberSummary.model";
import {
  ActiveEducatorsRetrieved,
  CancelStaffActivityRequest,
  ConfirmStaffActivityRequestCancellation,
  ConfirmStaffActivityRequestRefusal,
  CreateTimeOffRequest,
  CreateTimeOutCompRequest,
  FindActivityRequest,
  FindMyStaffActivityRequests,
  RefuseStaffActivityRequest,
  RetrieveActiveEducators,
  RetrieveStaffMember,
  RetrieveStaffMembers,
  StaffMemberRetrieved,
  StaffMembersRetrieved,
  UpdateStaffMemberAddress
} from "./staff.actions";
import {StaffGateway} from "../../ports/StaffGateway";
import {tap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {StaffMemberDetail} from "../../models/staff/StaffMemberDetail";
import {MatDialog} from "@angular/material/dialog";
import {ShowInfo} from "../notification/notification.state";
import {StaffActivityTypeEnum} from "../../models/staff/activity/StaffActivityType";
import {StaffActivityRequestStatusEnum} from "../../models/staff/activity/StaffActivityRequestStatus";
import {StaffActivityRequest} from "../../models/staff/activity/StaffActivityRequest";
import {UserState} from "../user/user.state";
import {
  CancelStaffActivityRequestDialogComponent
} from "../../views/my-work/cancel-staff-activity-request-dialog/cancel-staff-activity-request-dialog.component";
import {
  RefuseStaffActivityRequestDialogComponent
} from "../../views/my-work/refuse-staff-activity-request-dialog/refuse-staff-activity-request-dialog.component";
import StaffActivityType = StaffActivityTypeEnum.StaffActivityType;
import StaffActivityRequestStatus = StaffActivityRequestStatusEnum.StaffActivityRequestStatus;

export interface StaffStateModel {
  staffMembers: StaffMemberSummary[];
  activeEducators: StaffMemberSummary[];
  selectedStaffMember: StaffMemberDetail | null;
  selectedActivityRequest: StaffActivityRequest | null;
  myActivityRequests: StaffActivityRequest[];
  myActivityRequestFilter: StaffActivityRequestFilter;
}

export type StaffActivityRequestFilter = {
  type: StaffActivityType[],
  status: StaffActivityRequestStatus[]
}

@State<StaffStateModel>({
  name: 'staff',
  defaults: {
    staffMembers: [],
    activeEducators: [],
    selectedStaffMember: null,
    selectedActivityRequest: null,
    myActivityRequests: [],
    myActivityRequestFilter: {
      type: [],
      status: []
    }
  }
})
@Injectable()
export class StaffState {

  staffGateway = inject(StaffGateway);
  store = inject(Store);
  dialog = inject(MatDialog);

  @Action(RetrieveStaffMembers)
  public retrieveStaffMembers(ctx: StateContext<StaffStateModel>): Observable<StaffMemberSummary[]> {
    return this.staffGateway.retrieveAll().pipe(
      tap(staffMembers => {
        ctx.dispatch(new StaffMembersRetrieved(staffMembers));
      })
    )
  }

  @Action(StaffMembersRetrieved)
  public staffMembersRetrieved(ctx: StateContext<StaffStateModel>, action: StaffMembersRetrieved) {
    ctx.patchState({
      staffMembers: action.staffMembers
    });
  }

  @Action(RetrieveActiveEducators)
  public retrieveActiveEducators(ctx: StateContext<StaffStateModel>): Observable<StaffMemberSummary[]> {
    return this.staffGateway.retrieveActiveEducators().pipe(
      tap(activeEducators => {
        ctx.dispatch(new ActiveEducatorsRetrieved(activeEducators));
      })
    )
  }

  @Action(ActiveEducatorsRetrieved)
  public activeEducatorsRetrieved(ctx: StateContext<StaffStateModel>, action: ActiveEducatorsRetrieved) {
    ctx.patchState({
      activeEducators: action.educators
    });
  }

  @Action(RetrieveStaffMember)
  public retrieveStaffMember(ctx: StateContext<StaffStateModel>, action: RetrieveStaffMember) {
    return this.staffGateway.retrieve(action.staffMemberId).pipe(
      tap(staffMember => {
        ctx.dispatch(new StaffMemberRetrieved(staffMember));
      })
    )
  }

  @Action(StaffMemberRetrieved)
  public staffMemberRetrieved(ctx: StateContext<StaffStateModel>, action: StaffMemberRetrieved) {
    ctx.patchState({
      selectedStaffMember: action.staffMember
    });
  }

  @Action(UpdateStaffMemberAddress)
  public updateStaffMemberAddress(ctx: StateContext<StaffStateModel>, action: UpdateStaffMemberAddress) {
    return this.staffGateway.updateCurrentAddress(action.staffMemberId, action.addressToUpdate).pipe(
      tap((staffMemberDetail) => {
        ctx.patchState({
          selectedStaffMember: staffMemberDetail
        });
      })
    );
  }

  @Action(FindMyStaffActivityRequests)
  public findMyStaffActivityRequests(ctx: StateContext<StaffStateModel>, action: FindMyStaffActivityRequests) {
    const staffId = this.store.selectSnapshot(UserState.getCurrentStaffId)
    if (!staffId) {
      this.waitForUser(ctx, action);
      return of([])
    } else {
      if (action.filter) {
        ctx.patchState({
          myActivityRequestFilter: action.filter
        });
      }
      return this.staffGateway.findActivityRequests(staffId).pipe(
        tap((requests) => {
          ctx.patchState({
            myActivityRequests: requests
          });
        })
      );
    }
  }

  @Action(FindActivityRequest)
  public findActivityRequest(ctx: StateContext<StaffStateModel>, action: FindActivityRequest) {
    return this.staffGateway.findActivityRequest(action.activityRequestId).pipe(
      tap((request) => {
        ctx.patchState({
          selectedActivityRequest: request
        });
      })
    );
  }

  private waitForUser(ctx: StateContext<StaffStateModel>, action: FindMyStaffActivityRequests) {
    setTimeout(() => ctx.dispatch(action), 1000);
  }

  @Action(CreateTimeOutCompRequest)
  public createTimeOutCompRequest(ctx: StateContext<StaffStateModel>, action: CreateTimeOutCompRequest) {
    return this.staffGateway.createTimeOutCompRequest(action.request.staffMemberId, action.request).pipe(
      tap(() => {
        if (action.close) {
          this.dialog.closeAll();
        }
        ctx.dispatch(new ShowInfo("La demande a bien été créée"))
      })
    );
  }

  @Action(CreateTimeOffRequest)
  public createTimeOffRequest(ctx: StateContext<StaffStateModel>, action: CreateTimeOffRequest) {
    return this.staffGateway.createTimeOffRequest(action.request.staffMemberId, action.request).pipe(
      tap(() => {
        if (action.close) {
          this.dialog.closeAll();
        }
        ctx.dispatch(new ShowInfo("La demande a bien été créée"));
        ctx.dispatch(new FindMyStaffActivityRequests(ctx.getState().myActivityRequestFilter));
      })
    );
  }

  @Action(CancelStaffActivityRequest)
  public cancelStaffActivityRequest(ctx: StateContext<StaffStateModel>, action: CancelStaffActivityRequest) {
    return this.dialog.open(CancelStaffActivityRequestDialogComponent, {
      data: {
        request: action.request
      }
    });
  }

  @Action(ConfirmStaffActivityRequestCancellation)
  public confirmStaffActivityRequestCancellation(ctx: StateContext<StaffStateModel>, action: ConfirmStaffActivityRequestCancellation) {
    return this.staffGateway.cancelActivityRequest(action.request.id).pipe(
      tap(() => {
        this.dialog.closeAll();
        ctx.dispatch(new FindActivityRequest(action.request.id));
        ctx.dispatch(new ShowInfo("La demande a bien été annulée"));
      })
    );
  }

  @Action(RefuseStaffActivityRequest)
  public refuseStaffActivityRequest(ctx: StateContext<StaffStateModel>, action: RefuseStaffActivityRequest) {
    return this.dialog.open(RefuseStaffActivityRequestDialogComponent, {
      data: {
        request: action.request
      }
    });
  }

  @Action(ConfirmStaffActivityRequestRefusal)
  public confirmStaffActivityRequestRefusal(ctx: StateContext<StaffStateModel>, action: ConfirmStaffActivityRequestRefusal) {
    return this.staffGateway.refuseActivityRequest(action.request.id, action.note).pipe(
      tap(() => {
        this.dialog.closeAll();
        ctx.dispatch(new FindActivityRequest(action.request.id));
        ctx.dispatch(new ShowInfo("La demande a bien été refusée"));
      })
    );
  }

  @Selector()
  static myActivityRequestFilter(state: StaffStateModel) {
    return state.myActivityRequestFilter;
  }

  @Selector()
  static myActivityRequests(state: StaffStateModel) {
    const filter = state.myActivityRequestFilter;
    return state.myActivityRequests
      .filter(request => filter.type.length === 0 || filter.type.includes(request.type))
      .filter(request => filter.status.length === 0 || filter.status.includes(request.status));
  }

  @Selector()
  static selectedActivityRequest(state: StaffStateModel) {
    return state.selectedActivityRequest;
  }

}
