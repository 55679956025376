import {Pipe, PipeTransform} from '@angular/core';
import {SchedulerTime} from "../../models/scheduler/SchedulerTime";
import {padNumberWithLeadingZeros} from "../../models/time/TimeUtils";

@Pipe({
  standalone: true,
  name: 'schedulerTime'
})
export class SchedulerTimePipe implements PipeTransform {

  transform(value: SchedulerTime): string {
    return padNumberWithLeadingZeros(value.hour) + ":" + padNumberWithLeadingZeros(value.minute)
  }

}
