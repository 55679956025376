<div class="m-3" [formGroup]="form">
    <h1 mat-dialog-title>Annuler la prestation</h1>
    <mat-dialog-content>
        <div class="vstack">
            <mat-form-field appearance="fill">
                <mat-label>Motif</mat-label>
                <mat-select formControlName="motive">
                    <mat-option *ngFor="let motive of cancellationMotives()" [value]="motive">
                        {{ motive.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="input-note" *ngIf="isOtherMotiveSelected()">
                <mat-label>Justification</mat-label>
                <textarea matInput id="note" cdkTextareaAutosize formControlName="note"></textarea>
            </mat-form-field>
        </div>
    </mat-dialog-content>


    <mat-dialog-actions class="d-flex gap-2 justify-content-end">
        <button mat-dialog-close class="btn btn-secondary">Annuler</button>
        <button class="btn btn-primary" id="confirm" [disabled]="!isValid()" (click)="cancel()">Confirmer</button>
    </mat-dialog-actions>
</div>

