import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'duration',
  standalone: true
})
export class DurationPipe implements PipeTransform {

  transform(value: Duration, format: string = ""): unknown {
    if (format === "hours" || format === "h") {
      return (this.getSafeHours(value) + (this.getSafeMinutes(value) / 60)) + "h";
    }
    if (format === "minutes" || format === "m") {
      return (this.getSafeHours(value) * 60 + this.getSafeMinutes(value)) + "m";
    }
    const totalMinutes = this.getSafeHours(value) * 60 + this.getSafeMinutes(value);

    if (totalMinutes < 60) {
      return `${(totalMinutes)}m`;
    } else {
      return `${Math.floor(totalMinutes / 60)}h ${totalMinutes % 60}m`;
    }
  }

  private getSafeMinutes(value: Duration) {
    return value.minutes ?? 0;
  }

  private getSafeHours(value: Duration) {
    return value.hours ?? 0;
  }
}
