import {SimpleBeneficiaryDto} from "../../../generated/api";

export class SchedulerBeneficiary {
  private readonly _id: number;
  private readonly _firstName: string;
  private readonly _lastName: string;


  constructor(id: number, firstName: string, lastName: string) {
    this._id = id;
    this._firstName = firstName;
    this._lastName = lastName;
  }

  get id(): number {
    return this._id;
  }

  get firstName(): string {
    return this._firstName;
  }

  get lastName(): string {
    return this._lastName;
  }

  static fromSimpleBeneficiaryDto(simpleBeneficiaryDto: SimpleBeneficiaryDto): SchedulerBeneficiary {
    return new SchedulerBeneficiary(simpleBeneficiaryDto.id, simpleBeneficiaryDto.personInfo.firstName, simpleBeneficiaryDto.personInfo.lastName);
  }
}
