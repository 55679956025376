<h1>Facturation BAP</h1>

<div class="vstack gap-2">
    <div class="d-flex gap-2 general-bg bg-white align-content-center align-items-center">
        <mat-form-field appearance="fill">
            <mat-label>Année</mat-label>
            <mat-select [(ngModel)]="selectedYear" (ngModelChange)="updateBeneficiaries()">
                <mat-option *ngFor="let year of years" [value]="year">{{ year.value }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Mois</mat-label>
            <mat-select [(ngModel)]="selectedMonth" (ngModelChange)="updateBeneficiaries()">
                <mat-option *ngFor="let month of months" [value]="month">{{ month.name }}</mat-option>
            </mat-select>
        </mat-form-field>
        <button class="btn btn-primary">
            <mat-icon>email</mat-icon>
            Tout envoyer
        </button>
        <button class="btn btn-primary">
            <mat-icon>picture_as_pdf</mat-icon>
            Tout télécharger
        </button>
    </div>

    <div class="card-container" *ngIf="hasBeneficiaries() ; else elseBlock">
        <div class="card repit-card" *ngFor="let beneficiary of this.bapBeneficiaries">
            <div class="card-header">
                <div class="card-title">{{ beneficiary.lastName }} {{ beneficiary.firstName }}</div>
                <div class="card-subtitle">BAP</div>
            </div>
            <div class="card-body">
                <div class="card-text mb-3">
                    <b> {{ beneficiary.numberOfPrestations }} prestation(s)
                        : {{ beneficiary.totalAmountInCents | amount }}</b>
                </div>

                <div class="d-flex gap-2">
                    <button class="btn btn-primary">
                        <mat-icon>email</mat-icon>
                    </button>
                    <button class="btn btn-primary" (click)="generateAndDownloadBapInvoice(beneficiary)">
                        <mat-icon>picture_as_pdf</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <ng-template #elseBlock>Aucun bénéficiaire pour ce mois.</ng-template>

</div>