<div *ngIf="isBeneficiaryLoaded(); else loadingBlock">
    <h1>{{ beneficiary.personInfo.firstName }} {{ beneficiary.personInfo.lastName }}<span
            *ngIf="!beneficiary.otherInformation.active"> [archivé]</span></h1>


    <mat-tab-group>
        <mat-tab label="Informations générales">
            <h2>Informations générales</h2>
            <div class="d-flex content-tab">
                <div class="beneficiary beneficiary-face">
                    <img ngSrc="./assets/avatar.png" alt="picture" height="398" width="287">
                </div>

                <div class="beneficiary beneficiary-infos">
                    <div>
          <span
                  style="font-weight: bold">Date de naissance : </span><span>{{ beneficiary.personInfo.birthDate | date:"dd/MM/YYYY" }}</span>
                    </div>
                    <div>
                        <span style="font-weight: bold">Genre : </span><span>{{ beneficiary.personInfo.gender | gender }}</span>
                    </div>
                    <div>
                        <span style="font-weight: bold">Bureau régional : </span><span>{{ beneficiary.generalInfo.br.name }}</span>
                    </div>
                    <div>
                        <span style="font-weight: bold">Bap : </span><span><mat-checkbox disabled
                                                                                         [checked]="beneficiary.generalInfo.bap"></mat-checkbox></span>
                    </div>
                    <div>
          <span
                  style="font-weight: bold">Profil d'accompagnement : </span><span>{{ beneficiary.generalInfo.careProfile }}</span>
                    </div>
                    <br/>
                    <mat-divider></mat-divider>
                    <br/>
                    <h3>AVIQ</h3>
                    <div>
                        <span style="font-weight: bold">Numéro : </span><span>{{ beneficiary.aviqInfo.aviqNumber }}</span>
                    </div>
                    <div>
          <span
                  style="font-weight: bold">Classification : </span><span>{{ beneficiary.aviqInfo.aviqClassification.label }}</span>
                    </div>
                    <div>
          <span
                  style="font-weight: bold">Priorisation : </span><span>{{ beneficiary.aviqInfo.aviqPrioritization.label }}</span>
                    </div>
                </div>
                <div class="beneficiary beneficiary-portrait">
                    <h4>Portrait : </h4>
                    <div *ngIf="!isEditingSummary" style="white-space:pre-wrap">{{ beneficiary.generalInfo.summary }}
                    </div>

                </div>
            </div>


        </mat-tab>
        <mat-tab label="Contact">
            <h2>Contacts</h2>
            <div *ngIf="areContactsLoaded(); else loadingBlock">
                <div class="card-container">

                    <div class="card contact-card" *ngFor="let contact of sortedContacts()">
                        <div class="card-header">
                            <div class="card-title-with-icon card-title d-flex align-items-baseline">
                                {{ contact.legalRepresentative.personInfo | personInfo }}
                                <button class="ms-auto" mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu" class="no-max-width">
                                    <button mat-menu-item (click)="editContact(contact)">
                                        <mat-icon>edit</mat-icon>
                                        Modifier
                                    </button>
                                    <button mat-menu-item *ngIf="!contact.prestationPrimaryContact"
                                            (click)="makePrestationPrimary(contact)">
                                        <mat-icon>adjust</mat-icon>
                                        <div>Contact principal pour les prestations</div>
                                    </button>
                                    <button mat-menu-item *ngIf="!contact.invoicePrimaryContact"
                                            (click)="makeInvoicePrimary(contact)">
                                        <mat-icon>attach_money</mat-icon>
                                        Contact principal pour la facturation
                                    </button>
                                </mat-menu>
                            </div>
                            <div class="card-subtitle">
                                {{ contact.type.label }}
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="card-text">
                                <div *ngIf="contact.prestationPrimaryContact">
                                    <span style="font-weight: bold">Contact principal pour les prestations</span>
                                </div>
                                <div *ngIf="contact.invoicePrimaryContact">
                                    <span style="font-weight: bold">Contact principal pour la facturation</span>
                                </div>
                                <div>
                <span
                        style="font-weight: bold">Adresse : </span><span>{{ contact.legalRepresentative.currentAddress.addressWithCoordinates | inlineAddress }}</span>
                                    <br>
                                    <span
                                            style="font-weight: bold">Numéro de téléphone : </span><span>{{ contact.legalRepresentative.phoneNumber }}</span>
                                    <br>
                                    <span
                                            style="font-weight: bold">Email : </span><span>{{ contact.legalRepresentative.email }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Prestations">
            <h2>Prestations</h2>
            <div *ngIf="arePrestationsLoaded(); else loadingBlock">

                <div *ngIf="prestations.length > 0 ; else noPrestationBlock" class="card-container">
                    <div class="card inscription-card" *ngFor="let prestation of paginatedPrestations()"
                         [routerLink]="['/prestation/' + prestation.id]">
                        <div class="card-header">
                            <div class="card-title">
                                <span>{{ prestationScheduleStatus(prestation) }}</span>{{ prestation.schedule | schedule }}
                            </div>

                        </div>
                        <div class="card-body">
                            <div class="card-text">

                            </div>
                        </div>
                    </div>


                </div>
                <mat-paginator [length]="prestations.length"
                               [pageSize]="prestationsPageSize"
                               [pageIndex]="prestationsPageIndex"
                               [pageSizeOptions]="[10, 20, 50, 100]"
                               aria-label="Select page"
                               (page)="refreshPagination($event)"
                >
                </mat-paginator>
                <ng-template #noPrestationBlock>Aucune prestation.</ng-template>

            </div>
        </mat-tab>
        <mat-tab label="Autre">
            <h2>Autres informations</h2>
            <div>
          <span
                  style="font-weight: bold">Date d'inscription : </span><span>{{ beneficiary.otherInformation.registeredOn  | date:"dd/MM/YYYY" }}</span>
            </div>
            <div>
                <span style="font-weight: bold">Archivé : </span><span><mat-checkbox disabled
                                                                                     [checked]="!beneficiary.otherInformation.active"></mat-checkbox></span>
            </div>
        </mat-tab>
    </mat-tab-group>


    <div class="general-bg bg-white d-flex gap-2">
        <button [routerLink]="['/beneficiary']" class="btn btn-secondary">Retour</button>
        <button class="btn btn-danger" (click)="openArchivePopup()" *ngIf="beneficiary.otherInformation.active">
            Archiver
        </button>
        <button class="btn btn-primary" (click)="reactivate()" *ngIf="!beneficiary.otherInformation.active">
            Réactiver
        </button>
    </div>
</div>
<ng-template #loadingBlock>En cours de chargement</ng-template>
