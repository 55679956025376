<h1>Facturation répit</h1>
<div class="vstack gap-2">
    <div class="d-flex gap-2 general-bg bg-white align-content-center align-items-center">
        <mat-form-field appearance="fill">
            <mat-label>Année</mat-label>
            <mat-select [(ngModel)]="selectedYear" (ngModelChange)="updateBeneficiaries()">
                <mat-option *ngFor="let year of years" [value]="year">{{ year.value }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Mois</mat-label>
            <mat-select [(ngModel)]="selectedMonth" (ngModelChange)="updateBeneficiaries()">
                <mat-option *ngFor="let month of months" [value]="month">{{ month.name }}</mat-option>
            </mat-select>
        </mat-form-field>
        <button class="btn btn-primary">
            <mat-icon>email</mat-icon>
            Tout envoyer
        </button>
        <button class="btn btn-primary">
            <mat-icon>picture_as_pdf</mat-icon>
            Tout télécharger
        </button>
    </div>

    <div *ngIf="!isLoading; else loadingBlock">
        <div *ngIf="hasBeneficiaries() ; else elseBlock">
            <div class="card-container">
                <div class="card repit-card" *ngFor="let beneficiary of paginatedBeneficiaries()">
                    <div class="card-header">
                        <div class="card-title">{{ beneficiary.lastName }} {{ beneficiary.firstName }}</div>
                        <div class="card-subtitle">{{ beneficiary.contactLinkType.label }}</div>
                    </div>
                    <div class="card-body">
                        <div class="card-text d-flex flex-column mb-3">
                            <div *ngIf="beneficiary.numberOfPrestations > 0"><i>{{ beneficiary.numberOfPrestations }}
                                prestation(s)
                                : </i>{{ beneficiary.totalPrestationAmountInCents | amount }}
                            </div>
                            <div *ngIf="beneficiary.numberOfLeisures > 0"><i>{{ beneficiary.numberOfLeisures }}
                                loisir(s)
                                :</i> {{ beneficiary.totalLeisureAmountInCents | amount }}
                            </div>
                            <div *ngIf="beneficiary.totalTravelAmountInCents > 0"><i> Trajets
                                :</i> {{ beneficiary.totalTravelAmountInCents | amount }}
                            </div>
                            <div><b> Total : {{ beneficiary.totalAmountInCents | amount }}</b></div>
                        </div>
                        <div class="d-flex gap-2">
                            <button class="btn btn-primary">
                                <mat-icon>email</mat-icon>
                            </button>
                            <button class="btn btn-primary" (click)="generateAndDownloadRepitInvoice(beneficiary)">
                                <mat-icon>picture_as_pdf</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <mat-paginator [length]="repitBeneficiaries.length"
                           [pageSize]="pageSize"
                           [pageIndex]="pageIndex"
                           [pageSizeOptions]="[10, 20, 50, 100]"
                           aria-label="Select page"
                           (page)="refreshPagination($event)"
            >
            </mat-paginator>
        </div>

        <ng-template #elseBlock>Aucun bénéficiaire pour ce mois.</ng-template>
    </div>

    <ng-template #loadingBlock>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only"></span>
            </div>
        </div>
    </ng-template>
</div>

