<h1>Inscriptions</h1>
<div class="vstack gap-2">
    <div class="d-flex gap-2 general-bg bg-white align-content-center align-items-center">

        <mat-form-field>
            <mat-label>Filtrer</mat-label>
            <input matInput type="text" [(ngModel)]="textFilter">
            <button *ngIf="textFilter" matSuffix mat-icon-button aria-label="Clear"
                    (click)="textFilter=''" tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>


        <mat-chip-listbox multiple>
            <mat-chip-option *ngFor="let status of allStatuses"
                             (click)="toggleStatus(status)"
                             [selected]="isStatusSelected(status)"
            >{{ status.label }}
            </mat-chip-option>
        </mat-chip-listbox>


        <a routerLink="/first-contact/create" class="ms-auto">
            <div class="btn btn-important">
                <mat-icon>add</mat-icon>
                Créer une inscription
            </div>
        </a>

    </div>

    <div *ngIf="filteredFirstContacts().length > 0 ; else elseBlock">
        <div class="card-container">

            <div class="card repit-card" *ngFor="let firstContact of paginatedFirstContacts()"
                 [routerLink]="['/first-contact/detail/' + firstContact.id]">
                <div class="card-header">
                    <div class="card-title-with-icon card-title">
                        <mat-icon mat-card-avatar>{{ determineIconFromStatus(firstContact.status) }}</mat-icon>
                        {{ getName(firstContact) }}
                    </div>
                    <div class="card-subtitle">
                        {{ firstContact.contactedOn | date:"dd/MM/YYYY" }}
                        - {{ firstContact.status | firstContactApplicationStatus }}
                    </div>
                </div>
                <div class="card-body">
                    <div class="card-text">
                        <div *ngIf="isRefused(firstContact)">
                            Refusé le {{ firstContact.refusedOn | date:"dd/MM/YYYY" }}
                            : {{ firstContact.refusalMotive | refusalMotive }}
                        </div>
                        <div *ngIf="isAccepted(firstContact)">
                            Accepté le {{ firstContact.acceptedOn | date:"dd/MM/YYYY" }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <mat-paginator [length]="filteredFirstContacts().length"
                       [pageSize]="pageSize"
                       [pageIndex]="pageIndex"
                       [pageSizeOptions]="[10, 20, 50, 100]"
                       aria-label="Select page"
                       (page)="refreshPagination($event)"
        >
        </mat-paginator>
    </div>
    <ng-template #elseBlock>Aucune inscription ne correspond aux critères.</ng-template>
</div>