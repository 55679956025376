import {createPropertySelectors, createSelector} from "@ngxs/store";
import {PrestationState, PrestationStateModel} from "./prestation.state";

export class PrestationSelectors {
  static slices = createPropertySelectors<PrestationStateModel>(PrestationState);

  static prestationsToValidate() {
    return createSelector(
      [PrestationSelectors.slices.prestationsToValidate],
      prestations => prestations);
  }

  static prestationsToClose() {
    return createSelector(
      [PrestationSelectors.slices.prestationToClose],
      prestations => prestations);
  }

  static prestationDetail() {
    return createSelector(
      [PrestationSelectors.slices.prestationDetail],
      prestation => prestation);
  }
}