import {Component, computed, input, model, TemplateRef} from '@angular/core';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {NgForOf, NgIf, NgTemplateOutlet} from "@angular/common";

@Component({
  selector: 'app-repit-pager',
  standalone: true,
  imports: [
    MatPaginator,
    NgTemplateOutlet,
    NgForOf,
    NgIf
  ],
  templateUrl: './repit-pager.component.html',
  styleUrl: './repit-pager.component.scss'
})
export class RepitPagerComponent<T> {

  items = input.required<Array<T>>();
  pageSizeOptions = input<Array<number>>([5, 10, 20, 50]);
  pageSize = model(10);
  pageIndex = model(0);
  itemTemplate = input.required<TemplateRef<any>>();

  onPageChange(event: PageEvent): void {
    this.pageIndex.set(event.pageIndex);
    this.pageSize.set(event.pageSize);
  }

  pagedItems = computed(() => {
    return this.items().slice(this.pageIndex() * this.pageSize(), (this.pageIndex() + 1) * this.pageSize())
  })

}
