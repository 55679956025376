import {Component, OnInit} from '@angular/core';
import {Event, NavigationEnd, Params, Router} from '@angular/router';
import {KeycloakService} from "keycloak-angular";
import {ADMIN_AND_AS, ADMIN_ONLY} from "./core/stores/user/user.state";

interface MenuItem {
  name: string;
  icon: string;
  route: string;
  expanded: boolean;
  roles?: string[];
  submenuItems: SubMenuItem[];
}

interface SubMenuItem {
  name: string;
  route: string;
  params?: Params;
  roles?: string[];
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isSidebarExpanded: boolean = true;

  menuItems: MenuItem [] = [
    {
      name: "Tableau de bord",
      icon: "dashboard",
      route: "/dashboard",
      expanded: false,
      submenuItems: []
    },
    {
      name: "Activités",
      icon: "calendar_month",
      route: "/activity",
      expanded: false,
      roles: ADMIN_AND_AS,
      submenuItems: [
        {name: "Horaire", route: "activity/schedule"},
        {name: "Demandes", route: "activity/request"},]
    },
    {
      name: "Mon horaire",
      icon: "calendar_month",
      route: "/my-work",
      expanded: false,
      submenuItems: [
        {name: "Mon horaire", route: "/my-work/schedule"},
        {name: "Mes congés/absences", route: "my-work/request/list"},
      ]
    },
    {
      name: "Bénéficiaires",
      icon: "family_restroom",
      route: "/beneficiary",
      expanded: false,
      roles: ADMIN_AND_AS,
      submenuItems: [
        {name: "Liste", route: "/beneficiary/list"},
        {name: "Créer", route: "/beneficiary/create"}]
    },
    {
      name: "Inscriptions",
      icon: "person_add",
      route: "/first-contact",
      expanded: false,
      roles: ADMIN_AND_AS,
      submenuItems: [
        {name: "Liste", route: "/first-contact/list"},
        {name: "Créer", route: "/first-contact/create"}]
    },
    {
      name: "Prestations",
      icon: "house",
      route: "/prestation",
      expanded: false,
      roles: ADMIN_AND_AS,
      submenuItems: [
        {name: "Liste", route: "/prestation/list"},
        {name: "Créer", route: "/prestation/list", params: {create: true}}]
    },
    {
      name: "Personnel",
      icon: "people",
        route: "/staff",
      expanded: false,
      roles: ADMIN_AND_AS,
      submenuItems: [
          {name: "Liste", route: "/staff/list"},
          {name: "Bénévoles", route: "/staff/volunteer"},]
    },
    {
      name: "Documents",
      icon: "file_copy",
      route: "/document",
      expanded: false,
      roles: ADMIN_AND_AS,
      submenuItems: [
        {name: "KM", route: "/document/generate-report-km"},
        {name: "RA", route: "/document/ra"},
        {name: "Facturation répit", route: "/document/generate-repit-invoice"},
        {name: "Facturation BAP", route: "/document/generate-bap-invoice"},
        {name: "Congés et OUT", route: "/document/out"}]
    },
    {
      name: "Pilotage",
      icon: "show_chart",
      route: "/management",
      expanded: false,
      roles: ADMIN_ONLY,
      submenuItems: []
    },
    {
      name: "Ressources",
      icon: "folder",
      route: "/resource",
      expanded: false,
      submenuItems: []
    },
    {
      name: "Mon compte",
      icon: "account_circle",
      route: "/user",
      expanded: false,
      submenuItems: []
    }
  ]


  constructor(private router: Router, private keycloakService: KeycloakService) {

  }

  ngOnInit(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.expandMenuByRoute(event.url)
      }
    });
  }

  toggleSidebar() {
    this.isSidebarExpanded = !this.isSidebarExpanded;
  }

  toggleMenuExpansion(menuItem: MenuItem) {
    menuItem.expanded = !menuItem.expanded;
  }

  canShrinkMenuItem(menuItem: MenuItem): boolean {
    return this.isSidebarExpanded && menuItem.expanded && menuItem.submenuItems.length > 0;
  }

  canExpandMenuItem(menuItem: MenuItem): boolean {
    return this.isSidebarExpanded && !menuItem.expanded && menuItem.submenuItems.length > 0;
  }

  private expandMenuByRoute(url: string) {
    let matchingMenuItem = this.menuItems.find(menuItem => url.startsWith(menuItem.route));
    if (matchingMenuItem) {
      matchingMenuItem.expanded = true;
    }
  }

  getMenuItems(): MenuItem[] {
    return this.menuItems
      .filter(menuItem => this.keycloakService.getUserRoles(true)
        .some(role => menuItem.roles ? menuItem.roles.includes(role) : true));
  }
}
