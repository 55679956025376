/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {BureauRegionalDto} from './bureauRegionalDto';
import {DateTimeRangeDto} from './dateTimeRangeDto';
import {EducatorSummaryDto} from './educatorSummaryDto';
import {PrestationStatusDto} from './prestationStatusDto';
import {PersonInfoDto} from './personInfoDto';


export interface PrestationSummaryDto {
  id?: number;
  status?: PrestationStatusDto;
  schedule?: DateTimeRangeDto;
  educator?: EducatorSummaryDto;
  beneficiary?: PersonInfoDto;
  bureauRegional?: BureauRegionalDto;
  isBap?: boolean;
}

