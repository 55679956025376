<form [formGroup]="form">
    <div class="d-flex gap-2">
        <mat-form-field class="small-input-3">
            <mat-label>Numéro</mat-label>
            <input matInput type="text" formControlName="streetNumber">
            <button *ngIf="form.get('streetNumber').value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="form.patchValue({streetNumber:''})" tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field class="flex-grow-1">
            <mat-label>Rue</mat-label>
            <input matInput type="text" formControlName="streetName">
            <button *ngIf="form.get('streetName').value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="form.patchValue({streetName:''})" tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <div class="d-flex gap-2">
        <mat-form-field class="small-input-3">
            <mat-label>Code postal</mat-label>
            <input matInput type="text" formControlName="zipCode">
            <button *ngIf="form.get('zipCode').value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="form.patchValue({zipCode:''})" tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field class="flex-grow-1">
            <mat-label>Localité</mat-label>
            <input matInput type="text" formControlName="city">
            <button *ngIf="form.get('city').value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="form.patchValue({city:''})" tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </div>
</form>
