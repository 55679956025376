import {Injectable} from '@angular/core';
import {PrestationGateway} from "../../ports/PrestationGateway";
import {map, Observable} from "rxjs";
import {PrestationToValidate} from "../../models/prestation/PrestationToValidate";
import {SchedulerDateAndTime} from "../../models/scheduler/SchedulerDateAndTime";
import {formatDate} from "@angular/common";
import {PrestationValidationRequest} from "../../models/prestation/PrestationValidationRequest";
import {PrestationToClose} from "../../models/prestation/PrestationToClose";
import {PrestationClosureRequest} from "../../models/prestation/PrestationClosureRequest";
import {PrestationSummary} from "../../models/prestation/PrestationSummary";
import {fd} from "../../models/time/TimeUtils";
import {DateRange} from "../../models/calendar/DateRange";
import {PrestationStatusEnum} from "../../models/prestation/PrestationStatusEnum";
import {PrestationDetail} from "../../models/prestation/prestationDetail";
import {PrestationTypeEnum} from "../../models/prestation/PrestationType";
import {PrestationCreationRequest} from "../../models/prestation/PrestationCreationRequest";
import {PrestationRefusalMotiveEnum} from "../../models/prestation/PrestationRefusalMotive";
import {PrestationCancellationMotiveEnum} from "../../models/prestation/PrestationCancellationMotive";
import {
  AddressInfoDto,
  EducatorSummaryDto,
  LocationAndContactsDto,
  PrestationControllerService,
  PrestationTravelDto,
  PrestationTravelMutationRequestDto,
  UpdateScheduleDto
} from "../../../generated/api";
import {mapContact} from "../beneficiary/ContactMapper";
import {mapAddressWithCoordinates} from "../address/AddressMapper";
import {PrestationTravel} from "../../models/prestation/PrestationTravel";
import {PrestationTravelTypeEnum} from "../../models/prestation/PrestationTravelType";
import {PrestationTravelCreationRequest} from "../../models/prestation/PrestationTravelCreationRequest";
import {InlinableAddress} from "../../models/address/InlinableAddress";
import {SchedulerTime} from "../../models/scheduler/SchedulerTime";
import PrestationRefusalMotive = PrestationRefusalMotiveEnum.PrestationRefusalMotive;
import PrestationCancellationMotive = PrestationCancellationMotiveEnum.PrestationCancellationMotive;

@Injectable()
export class HttpPrestationGateway extends PrestationGateway {

  constructor(private prestationControllerService: PrestationControllerService) {
    super();
  }

  retrieveAllSummaries(dateRange: DateRange): Observable<PrestationSummary[]> {
    return this.prestationControllerService.summaries(
      fd(dateRange.start),
      fd(dateRange.end))
      .pipe(map(prestations => {
        return prestations.map(prestation => {
          return {
            id: prestation.id,
            status: PrestationStatusEnum.fromValue(prestation.status.value),
            schedule: SchedulerDateAndTime.between(new Date(prestation.schedule.start), new Date(prestation.schedule.end)),
            educator: this.getEducator(prestation.educator),
            beneficiary: {
              id: prestation.beneficiary.id,
              firstName: prestation.beneficiary.firstName,
              lastName: prestation.beneficiary.lastName
            },
            bureauRegional: prestation.bureauRegional,
            bap: prestation.isBap
          } as PrestationSummary;
        });
      }));
  }

  private getEducator(educator: EducatorSummaryDto) {
    if (!educator) {
      return null;
    }
    return {
      id: educator.id,
      firstName: educator.personInfo.firstName,
      lastName: educator.personInfo.lastName
    };
  }

  retrieveAllToValidate(): Observable<PrestationToValidate[]> {
    return this.prestationControllerService.prestationsToValidate()
      .pipe(map(prestations => {
        return prestations.map(prestation => {
          return {
            id: prestation.id,
            schedule: SchedulerDateAndTime.between(new Date(prestation.schedule.start), new Date(prestation.schedule.end)),
            educatorId: prestation.mainEducatorId,
            beneficiary: {
              id: prestation.beneficiary.id,
              firstName: prestation.beneficiary.personInfo.firstName,
              lastName: prestation.beneficiary.personInfo.lastName
            }
          } as PrestationToValidate;
        })
      }));
  }

  retrieveAllToClose(): Observable<PrestationToClose[]> {
    return this.prestationControllerService.prestationsToClose()
      .pipe(map(prestations => {
        return prestations.map(prestation => {
          return {
            id: prestation.id,
            schedule: SchedulerDateAndTime.between(new Date(prestation.schedule.start), new Date(prestation.schedule.end)),
            educator: {
              id: prestation.educator.id,
              firstName: prestation.educator.firstName,
              lastName: prestation.educator.lastName
            },
            beneficiary: {
              id: prestation.beneficiary.id,
              firstName: prestation.beneficiary.firstName,
              lastName: prestation.beneficiary.lastName
            },
            prestationValidation: {
              id: prestation.prestationValidation.id,
              hasCorrectSchedule: prestation.prestationValidation.hasCorrectSchedule,
              schedule: SchedulerDateAndTime.between(new Date(prestation.prestationValidation.startsAt), new Date(prestation.prestationValidation.endsAt)),
              hasTravelDuringPrestation: prestation.prestationValidation.hasTravelDuringPrestation,
              travelDescription: prestation.prestationValidation.travelDescription,
              note: prestation.prestationValidation.note,
              validatedAt: new Date(prestation.prestationValidation.validatedAt)
            }
          } as PrestationToClose;
        })
      }));
  }

  validatePrestation(request: PrestationValidationRequest): Observable<void> {
    return this.prestationControllerService.validate(request.prestationId, {
      hasCorrectSchedule: request.hasCorrectSchedule,
      startsAt: formatDate(request.startDay.dateTimeAt(request.startTime), "YYYY-MM-ddTHH:mm:ss", "fr-BE"),
      endsAt: formatDate(request.endDay.dateTimeAt(request.endTime), "YYYY-MM-ddTHH:mm:ss", "fr-BE"),
      hasTravelDuringPrestation: request.hasTravelDuringPrestation,
      travelDescription: request.travelDescription,
      note: request.note
    });
  }

  assignPrestation(prestationId: number, mainEducatorId: number, secondaryEducatorId?: number | null): Observable<void> {
    return this.prestationControllerService.assignPrestation(prestationId, {
      prestationId: prestationId,
      mainEducatorId: mainEducatorId,
      secondaryEducatorId: secondaryEducatorId
    }).pipe(map(() => null));
  }

  refusePrestation(prestationId: number, refusalMotive: PrestationRefusalMotive, note: string): Observable<void> {
    return this.prestationControllerService.refusePrestation(prestationId, {
      prestationId: prestationId,
      motive: refusalMotive.value,
      note: note
    });
  }

  cancelPrestation(prestationId: number, cancellationMotive: PrestationCancellationMotive, note: string): Observable<void> {
    return this.prestationControllerService.cancelPrestation(prestationId, {
      prestationId: prestationId,
      motive: cancellationMotive.value,
      note: note
    });
  }

  closePrestation(request: PrestationClosureRequest): Observable<void> {
    return this.prestationControllerService.close(request.prestationId, {
      startsAt: formatDate(request.startDay.dateTimeAt(request.startTime), "YYYY-MM-ddTHH:mm:ss", "fr-BE"),
      endsAt: formatDate(request.endDay.dateTimeAt(request.endTime), "YYYY-MM-ddTHH:mm:ss", "fr-BE"),
      travelDuringDescription: request.travelDuringDescription,
      travelDuringDistance: request.travelDuringDistance,
      travelBefore: this.mapTravelMutationRequest(request.travelBefore),
      travelAfter: this.mapTravelMutationRequest(request.travelAfter),
    });
  }

  createPrestation(request: PrestationCreationRequest): Observable<number> {
    return this.prestationControllerService.createPrestation({
      type: request.type.value,
      beneficiaryId: request.beneficiaryId,
      requestContactId: request.requestContactId,
      invoiceContactId: request.invoiceContactId,
      urgent: request.urgent,
      bap: request.bap,
      startTime: formatDate(request.startDay.dateTimeAt(request.startTime), "YYYY-MM-ddTHH:mm:ss", "fr-BE"),
      endTime: formatDate(request.endDay.dateTimeAt(request.endTime), "YYYY-MM-ddTHH:mm:ss", "fr-BE"),
      address: {
        streetNumber: request.address.streetNumber,
        streetName: request.address.streetName,
        zipCode: request.address.zipCode,
        city: request.address.city
      },
      mainEducatorId: request.mainEducatorId,
      secondaryEducatorId: request.secondaryEducatorId,
      note: request.note,
      accepted: request.accepted
    }).pipe(map(prestation => prestation.id));
  }

  retrieveDetail(prestationId: number): Observable<PrestationDetail> {
    return this.prestationControllerService.detail(prestationId)
      .pipe(map(prestation => {
        return {
          id: prestation.id,
          schedule: SchedulerDateAndTime.between(new Date(prestation.schedule.start), new Date(prestation.schedule.end)),
          status: PrestationStatusEnum.fromValue(prestation.status.value),
          type: PrestationTypeEnum.fromValue(prestation.type.value),
          urgent: prestation.urgent,
          bap: prestation.bap,
          mainEducator: this.getEducator(prestation.mainEducator),
          secondaryEducator: this.getEducator(prestation.secondaryEducator),
          beneficiary: {
            id: prestation.beneficiary.id,
            firstName: prestation.beneficiary.personInfo.firstName,
            lastName: prestation.beneficiary.personInfo.lastName,
            bap: prestation.beneficiary.bap
          },
          requestContact: mapContact(prestation.requestContact),
          invoiceContact: mapContact(prestation.invoiceContact),
          location: mapAddressWithCoordinates(prestation.location),
          note: prestation.note,
          refusalMotive: prestation.refusalMotive?.label,
          refusalNote: prestation.refusalNote,
          cancellationMotive: prestation.cancellationMotive?.label,
          cancellationNote: prestation.cancellationNote,
          // TODO RHA add prestation validation in detail
          travelDurings: this.mapTravelDurings(prestation.travelDurings),
          travelBefore: this.mapTravel(prestation.travelBefore),
          travelAfter: this.mapTravel(prestation.travelAfter),
        } as PrestationDetail;
      }));
  }

  updateTravels(prestationId: number, travelDuringDescription: string, travelDuringDistance: number, travelBefore: PrestationTravelCreationRequest | null, travelAfter: PrestationTravelCreationRequest | null): Observable<void> {
    return this.prestationControllerService.updateTravels(prestationId, {
      travelDuringDescription: travelDuringDescription,
      travelDuringDistance: travelDuringDistance,
      travelBefore: this.mapTravelMutationRequest(travelBefore),
      travelAfter: this.mapTravelMutationRequest(travelAfter)
    } as PrestationTravelMutationRequestDto);
  }

  updateLocationAndContacts(prestationId: number, requestContactId: number, invoiceContactId: number, location: InlinableAddress): Observable<void> {
    return this.prestationControllerService.updateLocationAndContacts(prestationId, {
      requestContactId: requestContactId,
      invoiceContactId: invoiceContactId,
      addressInfo: {
        streetNumber: location.streetNumber,
        streetName: location.streetName,
        zipCode: location.zipCode,
        city: location.city
      } as AddressInfoDto
    } as LocationAndContactsDto);
  }

  switchBap(prestationId: number): Observable<void> {
    return this.prestationControllerService.switchBap(prestationId);
  }

  switchUrgent(prestationId: number): Observable<void> {
    return this.prestationControllerService.switchUrgent(prestationId);
  }


  updateSchedule(prestationId: number, startTime: SchedulerTime, endTime: SchedulerTime): Observable<void> {

    return this.prestationControllerService.updatePrestationSchedule(prestationId, {
      startTime: formatDate(startTime.asDate(), "YYYY-MM-ddTHH:mm:ss", "fr-BE"),
      endTime: formatDate(endTime.asDate(), "YYYY-MM-ddTHH:mm:ss", "fr-BE")
    } as UpdateScheduleDto)
  }

  updateType(prestationId: number, type: PrestationTypeEnum.PrestationType): Observable<void> {
    return this.prestationControllerService.updatePrestationType(prestationId, type.value);
  }


  deletePrestation(prestationId: number): Observable<void> {
    return this.prestationControllerService.deletePrestation(prestationId);
  }

  private mapTravelMutationRequest(travel: PrestationTravelCreationRequest) {
    return travel ? {
      type: travel.type.value,
      address: {
        streetNumber: travel.address.streetNumber,
        streetName: travel.address.streetName,
        zipCode: travel.address.zipCode,
        city: travel.address.city,
      },
      description: travel.description,
    } : null;
  }

  private mapTravel(travel: PrestationTravelDto) {
    if (!travel) {
      return null;
    }
    return {
      id: travel.id,
      type: PrestationTravelTypeEnum.fromValue(travel.type.value),
      motive: travel.motive,
      from: mapAddressWithCoordinates(travel.from),
      to: mapAddressWithCoordinates(travel.to),
      distance: travel.distance
    } as PrestationTravel;
  }

  private mapTravelDurings(travelDurings: Array<PrestationTravelDto>) {
    return travelDurings.map(travel => {
      return {
        id: travel.id,
        type: PrestationTravelTypeEnum.fromValue(travel.type.value),
        motive: travel.motive,
        distance: travel.distance
      } as PrestationTravel;
    });
  }
}