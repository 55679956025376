import {BeneficiaryForPrestationCreation} from "../models/beneficiary/beneficiaryForPrestationCreation";
import {Observable} from "rxjs";
import {SimpleBeneficiaryContact} from "../models/beneficiary/simpleBeneficiaryContact";

export abstract class BeneficiaryGateway {

  abstract retrieveAllForPrestationCreation(): Observable<BeneficiaryForPrestationCreation[]>;

  abstract retrieveContactsForBeneficiary(beneficiaryId: number): Observable<SimpleBeneficiaryContact[]>;

}