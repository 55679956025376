import {Component, inject, Inject} from '@angular/core';
import {DeletePrestation} from "../../../stores/prestation/prestation.actions";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from "@angular/material/dialog";
import {Store} from "@ngxs/store";
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {NgForOf, NgIf} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {MatList, MatListItem} from "@angular/material/list";

export interface DeletePrestationData {
  prestationId: number;
}

@Component({
  selector: 'app-delete-prestation-dialog',
  standalone: true,
  imports: [
    CdkTextareaAutosize,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    MatList,
    MatListItem
  ],
  templateUrl: './delete-prestation-dialog.component.html',
  styleUrl: './delete-prestation-dialog.component.scss'
})
export class DeletePrestationDialogComponent {

  store = inject(Store);

  constructor(@Inject(MAT_DIALOG_DATA) public input: DeletePrestationData) {
  }

  confirmDeletion() {
    this.store.dispatch(new DeletePrestation(this.input.prestationId));
  }

}
