<div class="widget" *ngIf="hasPrestationToValidate()">
    <h2>
        <div class="title-text">Prestations à valider
            <span class="small" *ngIf="prestationsToValidate().length > 1">
                ({{ prestationIndex() + 1 }}/{{ prestationsToValidate().length }})
            </span>
        </div>
        <a mat-icon-button routerLink="/prestation/list">
            <mat-icon>list</mat-icon>
        </a>
    </h2>
    <div>
        <app-prestation-to-validate-form [prestation]="getValidatingPrestation()"
                                         [showCancel]="prestationsToValidate().length > 1"
                                         [cancelText]="'Plus tard'"
                                         (onCancel)="nextPrestation()"
                                         (confirmValidationRequest)="sendValidationRequest($event)"></app-prestation-to-validate-form>
    </div>
</div>
