import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {GenderEnum} from "../../../models/person/GenderEnum";
import {FirstContactService} from "../../../models/first-contact/first-contact.service";
import {FirstContactApplicationMutationRequest, FirstContactApplicationResponse} from "../../../../generated/api";
import Gender = GenderEnum.Gender;


@Component({
  selector: 'app-update-first-contact',
  templateUrl: './edit-first-contact.component.html',
  styleUrls: ['./edit-first-contact.component.scss']
})
export class EditFirstContactComponent implements OnInit {
  original: FirstContactApplicationResponse = {};
  firstContact: FirstContactApplicationMutationRequest = {
    address: {}
  };
  id: number;

  genders: Gender[] = GenderEnum.VALUES;

  constructor(private router: Router, private route: ActivatedRoute, private firstContactService: FirstContactService) {
  }

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.firstContactService.fetch(this.id)
      .subscribe(response => {
        this.original = response;
        this.firstContact.contactedOn = response.contactedOn;
        this.firstContact.firstName = response.firstName;
        this.firstContact.lastName = response.lastName;
        this.firstContact.age = response.age;
        this.firstContact.gender = response.gender;
        this.firstContact.note = response.note;
        this.firstContact.address = response.address ? response.address : {};
      });
  }

  save() {
    this.firstContactService.update(this.id, this.firstContact)
      .subscribe(response => this.router.navigate(["/first-contact/detail/", this.id]));
  }
}
