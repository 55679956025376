import {map, Observable} from "rxjs";
import {BeneficiaryForPrestationCreation} from "../../models/beneficiary/beneficiaryForPrestationCreation";
import {BeneficiaryGateway} from "../../ports/BeneficiaryGateway";
import {inject, Injectable} from "@angular/core";
import {BeneficiaryControllerService} from "../../../generated/api";
import {mapContact} from "./ContactMapper";
import {SimpleBeneficiaryContact} from "../../models/beneficiary/simpleBeneficiaryContact";

@Injectable()
export class HttpBeneficiaryGateway extends BeneficiaryGateway {

  beneficiaryControllerService: BeneficiaryControllerService = inject(BeneficiaryControllerService);

  retrieveAllForPrestationCreation(): Observable<BeneficiaryForPrestationCreation[]> {
    return this.beneficiaryControllerService.allForPrestationCreation()
      .pipe(map(beneficiaries => {
        return beneficiaries.map(beneficiary => {
          return {
            id: beneficiary.person.id,
            firstName: beneficiary.person.firstName,
            lastName: beneficiary.person.lastName,
            bap: beneficiary.bap,
            contacts: beneficiary.contacts.map(contact => {
              return mapContact(contact);
            })
          } as BeneficiaryForPrestationCreation;
        });
      }));
  }


  retrieveContactsForBeneficiary(beneficiaryId: number): Observable<SimpleBeneficiaryContact[]> {
    return this.beneficiaryControllerService.fetchContactsForBeneficiary(beneficiaryId)
      .pipe(map(contacts => {
        return contacts.map(contact => {
          return mapContact(contact);
        });
      }));
  }
}