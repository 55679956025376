export interface WithFullName {
  firstName: string;
  lastName: string;
}

export const sortByLastNameAndFirstName = (a: WithFullName, b: WithFullName) => (a.lastName + a.firstName).toLowerCase() > (b.lastName + b.firstName).toLowerCase() ? 1 : -1;


export const sortByFullName = (a: WithFullName, b: WithFullName) => fullName(a).toLowerCase() > fullName(b).toLowerCase() ? 1 : -1

export function sortItemWithPersonByFirstNameAndLastName(itemProvider: (a: any) => WithFullName) {
  return (a, b) => (itemProvider(a).firstName + itemProvider(a).lastName).toLowerCase() > (itemProvider(b).firstName + itemProvider(b).lastName).toLowerCase() ? 1 : -1;
}

export function fullName(person: { firstName?: string; lastName?: string } | WithFullName) {
  if (!person) {
    return '';
  }
  return `${person.firstName} ${person.lastName}`;
}

