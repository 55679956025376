import {Action, Selector, State, StateContext} from "@ngxs/store";
import {BeneficiaryForPrestationCreation} from "../../models/beneficiary/beneficiaryForPrestationCreation";
import {inject, Injectable} from "@angular/core";
import {BeneficiaryGateway} from "../../ports/BeneficiaryGateway";
import {tap} from "rxjs/operators";
import {SimpleBeneficiaryContact} from "../../models/beneficiary/simpleBeneficiaryContact";

export interface BeneficiaryStateModel {
  beneficiariesForPrestationCreation: BeneficiaryForPrestationCreation[],
  contacts: SimpleBeneficiaryContact[];
}

export class FindBeneficiariesForPrestationCreation {
  static readonly type = '[Beneficiary] Find Beneficiaries For Prestation Creation';
}

export class BeneficiariesForPrestationCreationFound {
  static readonly type = '[Beneficiary] Beneficiaries For Prestation Creation Found';

  constructor(public beneficiaries: BeneficiaryForPrestationCreation[]) {
  }
}

export class FindBeneficiaryContacts {
  static readonly type = '[Beneficiary] Find Beneficiary Contacts';

  constructor(public beneficiaryId: number) {
  }

}

@State<BeneficiaryStateModel>({
  name: 'beneficiary',
  defaults: {
    beneficiariesForPrestationCreation: [],
    contacts: []
  }
})
@Injectable()
export class BeneficiaryState {

  beneficiaryGateway = inject(BeneficiaryGateway);

  @Selector()
  static beneficiariesForPrestationCreation(state: BeneficiaryStateModel) {
    return state.beneficiariesForPrestationCreation;
  }

  @Selector()
  static contacts(state: BeneficiaryStateModel) {
    return state.contacts;
  }

  @Action(FindBeneficiariesForPrestationCreation)
  public findBeneficiariesForPrestationCreation(ctx: StateContext<BeneficiaryStateModel>) {
    return this.beneficiaryGateway.retrieveAllForPrestationCreation().pipe(
      tap(beneficiaries => {
        ctx.dispatch(new BeneficiariesForPrestationCreationFound(beneficiaries));
      })
    )
  }

  @Action(BeneficiariesForPrestationCreationFound)
  public beneficiariesForPrestationCreationFound(ctx: StateContext<BeneficiaryStateModel>, action: BeneficiariesForPrestationCreationFound) {
    ctx.patchState({
      beneficiariesForPrestationCreation: action.beneficiaries
    });
  }

  @Action(FindBeneficiaryContacts)
  public findBeneficiaryContacts(ctx: StateContext<BeneficiaryStateModel>, action: FindBeneficiaryContacts) {
    return this.beneficiaryGateway.retrieveContactsForBeneficiary(action.beneficiaryId).pipe(
      tap(contacts => {
        ctx.patchState({
          contacts: contacts
        });
      })
    )
  }

}