import {RepitEnum} from "../../common/RepitEnum";

export namespace StaffActivityTypeEnum {
  export class StaffActivityType extends RepitEnum {

    private readonly _dayOnly: boolean;

    constructor(value: string, label: string, dayOnly: boolean = false) {
      super(value, label);
      this._dayOnly = dayOnly;
    }


    get dayOnly(): boolean {
      return this._dayOnly;
    }
  }

  export const TIME_OFF = new StaffActivityType("TIME_OFF", "Congé", true);
  export const SICK_LEAVE = new StaffActivityType("SICK_LEAVE", "Maladie", true);
  export const OUT = new StaffActivityType("OUT", "Out", false);

  export const VALUES: StaffActivityType[] = [
    TIME_OFF,
    SICK_LEAVE,
    OUT,
  ];

  const BY_VALUE = new Map<string, StaffActivityType>();
  VALUES.forEach(item => BY_VALUE.set(item.value, item));

  export function fromValue(value: string): StaffActivityType {
    return BY_VALUE.get(value);
  }
}