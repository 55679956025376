<h1>Prestations</h1>
<div class="vstack gap-2">
    <div class="d-flex gap-2 general-bg bg-white align-content-center align-items-center">
        <mat-form-field>
            <mat-label>Période</mat-label>
            <mat-date-range-input [rangePicker]="datePicker">
                <input matStartDate placeholder="Début" [(ngModel)]="startDate">
                <input matEndDate placeholder="Fin" [(ngModel)]="endDate">
            </mat-date-range-input>
            <mat-hint>JJ/MM/AAAA - JJ/MM/AAAA</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-date-range-picker #datePicker></mat-date-range-picker>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Educateur</mat-label>
            <input matInput type="text" [(ngModel)]="educatorFilter">
            <button *ngIf="educatorFilter()" matSuffix mat-icon-button aria-label="Clear"
                    (click)="educatorFilter.set('')" tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Bénéficiaire</mat-label>
            <input matInput type="text" [(ngModel)]="beneficiaryFilter">
            <button *ngIf="beneficiaryFilter()" matSuffix mat-icon-button aria-label="Clear"
                    (click)="beneficiaryFilter.set('')" tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Statut</mat-label>
            <mat-select [(ngModel)]="prestationStatusFilter" name="prestationStatus" multiple>
                <mat-option *ngFor="let status of PrestationStatusEnum.VALUES" [value]="status">
                    <app-prestation-status-icon [status]="status"/>
                    {{ status.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-chip-listbox multiple>
            <mat-chip-option (click)="toggleBapFilter()"
                             [selected]="bapFilter()">
                BAP
            </mat-chip-option>
            <mat-chip-option (click)="toggleNonBapFilter()"
                             [selected]="nonBapFilter()">
                Non BAP
            </mat-chip-option>
        </mat-chip-listbox>

        <a (click)="openCreatePrestationDialog()" class="ms-auto">
            <div class="btn btn-important">
                <mat-icon>add</mat-icon>
                Ajouter
            </div>
        </a>
    </div>

    <app-repit-pager [items]="prestations()"
                     [pageSize]="50"
                     [itemTemplate]="cardTemplate">
        <ng-template #cardTemplate let-prestation>
            <a class="card repit-card" [routerLink]="['/prestation/detail/' + prestation.id]">
                <div class="card-header d-flex gap-2">
                    <div>
                        <app-prestation-status-icon [status]="prestation.status"/>
                    </div>
                    <div>
                        <div class="card-title">{{ prestation.beneficiary | personInfo }}</div>
                        <div class="card-title" *ngIf="prestation.educator">{{ prestation.educator | personInfo }}</div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="card-text">
                        <div>{{ prestation.status.label }}</div>
                        <div>{{ prestation.schedule.day | schedulerDay }}</div>
                        <div>{{ prestation.schedule.timePeriod | schedulerTimePeriod:true }}</div>
                        <div><span *ngIf="prestation.bap">BAP - </span>{{ prestation.bureauRegional.name }}</div>
                    </div>
                </div>
            </a>
        </ng-template>
    </app-repit-pager>

</div>
