import {Component, computed, inject, OnInit, signal} from '@angular/core';
import {Store} from "@ngxs/store";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {toSignal} from "@angular/core/rxjs-interop";
import {StaffActivityRequest} from "../../../models/staff/activity/StaffActivityRequest";
import {StaffState} from "../../../stores/staff/staff.state";
import {
  CancelStaffActivityRequest,
  FindActivityRequest,
  RefuseStaffActivityRequest
} from "../../../stores/staff/staff.actions";
import {DatePipe, NgIf} from "@angular/common";
import {PersonInfoPipe} from "../../pipe/person-info.pipe";
import {PrestationStatusIconComponent} from "../../prestation/prestation-status-icon/prestation-status-icon.component";
import {SchedulerDayPipe} from "../../pipe/scheduler-day.pipe";
import {StaffActivityRequestStatusEnum} from "../../../models/staff/activity/StaffActivityRequestStatus";
import {MatDialog} from "@angular/material/dialog";
import {UserState} from "../../../stores/user/user.state";

@Component({
  selector: 'app-detail-staff-activity-request',
  standalone: true,
  imports: [
    NgIf,
    PersonInfoPipe,
    PrestationStatusIconComponent,
    SchedulerDayPipe,
    DatePipe,
    RouterLink
  ],
  templateUrl: './detail-staff-activity-request.component.html',
  styleUrl: './detail-staff-activity-request.component.scss'
})
export class DetailStaffActivityRequestComponent implements OnInit {
  store = inject(Store);
  dialog = inject(MatDialog);

  id = signal(Number(inject(ActivatedRoute).snapshot.paramMap.get('id')));
  request = toSignal<StaffActivityRequest>(this.store.select(StaffState.selectedActivityRequest));

  ngOnInit(): void {
    this.store.dispatch(new FindActivityRequest(this.id()))
  }

  isRefused = computed(() => this.request().status === StaffActivityRequestStatusEnum.REFUSED);

  canBeRefused = computed(() => {
    return this.request().status === StaffActivityRequestStatusEnum.PENDING && !this.store.selectSnapshot(UserState.isEducator)
  });

  canBeCancelled = computed(() => this.request().status === StaffActivityRequestStatusEnum.PENDING);

  refuse() {
    this.store.dispatch(new RefuseStaffActivityRequest(this.request()));
  }

  cancel() {
    this.store.dispatch(new CancelStaffActivityRequest(this.request()));
  }
}
