import {Component, inject, Inject, OnInit} from '@angular/core';
import {SimpleBeneficiaryContact} from "../../../models/beneficiary/simpleBeneficiaryContact";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from "@angular/material/dialog";
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {MatFormField, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {NgForOf, NgIf} from "@angular/common";
import {PersonInfoPipe} from "../../pipe/person-info.pipe";
import {Store} from "@ngxs/store";
import {toSignal} from "@angular/core/rxjs-interop";
import {BeneficiaryState, FindBeneficiaryContacts} from "../../../stores/beneficiary/beneficiary.state";
import {byId} from "../../../models/common/compareUtils";
import {AddressWithCoordinates} from "../../../models/address/AddressWithCoordinates";
import {AddressForm} from "../../address/AddressForm";
import {CreateAddressFieldsComponent} from "../../address/create-address-fields/create-address-fields.component";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";
import {MatIconButton} from "@angular/material/button";
import {InlineAddressPipe} from "../../pipe/inline-address.pipe";
import {UpdatePrestationLocationAndContacts} from "../../../stores/prestation/prestation.actions";

export interface UpdatePrestationLocationAndContactsData {
  prestationId: number;
  beneficiaryId: number;
  requestContact: SimpleBeneficiaryContact;
  invoiceContact: SimpleBeneficiaryContact;
  address: AddressWithCoordinates;
}

@Component({
  selector: 'app-update-prestation-location-and-contacts',
  standalone: true,
  imports: [
    CdkTextareaAutosize,
    FormsModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    PersonInfoPipe,
    CreateAddressFieldsComponent,
    MatIcon,
    MatTooltip,
    MatIconButton,
    MatSuffix,
    InlineAddressPipe
  ],
  templateUrl: './update-prestation-location-and-contacts.component.html',
  styleUrl: './update-prestation-location-and-contacts.component.scss'
})
export class UpdatePrestationLocationAndContactsComponent implements OnInit {

  store = inject(Store);
  addressForm = AddressForm.initialize();
  form = new FormGroup({
    requestContact: new FormControl(null, Validators.required),
    invoiceContact: new FormControl(null, Validators.required),
    address: this.addressForm.formGroup(),
  });
  contacts = toSignal<SimpleBeneficiaryContact[]>(this.store.select(BeneficiaryState.contacts))

  constructor(@Inject(MAT_DIALOG_DATA) public input: UpdatePrestationLocationAndContactsData) {
    this.form.patchValue({
      requestContact: input.requestContact,
      invoiceContact: input.invoiceContact
    });
    this.addressForm.setValue(input.address);
  }

  ngOnInit(): void {
    this.store.dispatch(new FindBeneficiaryContacts(this.input.beneficiaryId));
  }

  confirm() {
    if (!this.isValid()) {
      return;
    }

    this.store.dispatch(new UpdatePrestationLocationAndContacts(
      this.input.prestationId,
      this.form.get("requestContact").value.id,
      this.form.get("invoiceContact").value.id,
      this.addressForm.address()
    ));
    this.resetForm();
  }

  isValid() {
    return this.form.valid;
  }

  isOutOfHome() {
    const requestContactAddress = this.getRequestContactAddress();
    if (requestContactAddress) {
      return !requestContactAddress.isSameAs(this.addressForm.address());
    }
    return true;
  }

  protected readonly byId = byId;

  setRequestContactAddress() {
    this.addressForm.setValue(this.form.get("requestContact").value?.address as AddressWithCoordinates);
  }

  getRequestContactAddress() {
    return this.form.get("requestContact").value?.address as AddressWithCoordinates;
  }

  private resetForm() {
    this.form.reset();
    this.addressForm.reset();
  }
}
