import {Component, inject, Inject} from '@angular/core';
import {StaffActivityRequest} from "../../../models/staff/activity/StaffActivityRequest";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from "@angular/material/dialog";
import {Store} from "@ngxs/store";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ConfirmStaffActivityRequestRefusal} from "../../../stores/staff/staff.actions";
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {NgIf} from "@angular/common";

export interface RefuseStaffActivityRequestDialogData {
  request: StaffActivityRequest
}


@Component({
  selector: 'app-refuse-staff-activity-request-dialog',
  standalone: true,
  imports: [
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    CdkTextareaAutosize,
    MatFormField,
    MatInput,
    MatLabel,
    NgIf,
    ReactiveFormsModule
  ],
  templateUrl: './refuse-staff-activity-request-dialog.component.html',
  styleUrl: './refuse-staff-activity-request-dialog.component.scss'
})
export class RefuseStaffActivityRequestDialogComponent {

  store = inject(Store);
  form = new FormGroup({
    note: new FormControl(null, Validators.required),
  })

  constructor(@Inject(MAT_DIALOG_DATA) public input: RefuseStaffActivityRequestDialogData) {
  }

  confirm() {
    if (this.isValid()) {
      this.store.dispatch(new ConfirmStaffActivityRequestRefusal(this.input.request, this.form.get('note').value));
    }
  }

  isValid() {
    return this.form.valid;
  }
}
