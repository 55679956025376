import {Component, Inject, inject, OnInit, signal} from '@angular/core';
import {MatCheckbox} from "@angular/material/checkbox";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {NgForOf, NgIf} from "@angular/common";
import {PersonInfoPipe} from "../../pipe/person-info.pipe";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {PrestationRefusalMotiveEnum} from "../../../models/prestation/PrestationRefusalMotive";
import {byLabel} from "../../../models/common/sortUtils";
import {Store} from "@ngxs/store";
import {RefusePrestation} from "../../../stores/prestation/prestation.actions";
import {conditionalValidation} from "../../formUtils";
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {MatInput} from "@angular/material/input";


export interface PrestationRefusalData {
  prestationId: number;
}


@Component({
  selector: 'app-refuse-prestation-dialog',
  standalone: true,
  imports: [
    MatCheckbox,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
    NgIf,
    PersonInfoPipe,
    ReactiveFormsModule,
    FormsModule,
    CdkTextareaAutosize,
    MatInput
  ],
  templateUrl: './refuse-prestation-dialog.component.html',
  styleUrl: './refuse-prestation-dialog.component.scss'
})
export class RefusePrestationDialogComponent implements OnInit {

  store = inject(Store);
  refusalMotives = signal(PrestationRefusalMotiveEnum.VALUES.sort(byLabel))
  form = new FormGroup({
    motive: new FormControl(null, Validators.required),
    note: new FormControl(null),
  })


  constructor(@Inject(MAT_DIALOG_DATA) public input: PrestationRefusalData) {
  }

  ngOnInit() {
    conditionalValidation(this.form, "motive", value => value === PrestationRefusalMotiveEnum.OTHER, [
      {field: "note", validators: [Validators.required]}])
  }

  isValid() {
    return this.form.valid;
  }

  refuse() {
    if (this.isValid()) {
      this.store.dispatch(new RefusePrestation(this.input.prestationId, this.form.value.motive, this.form.value.note));
    }
  }

  isOtherMotiveSelected() {
    return this.form.get("motive").value === PrestationRefusalMotiveEnum.OTHER;
  }
}
