import {User} from "../../models/user/User";

export class FetchCurrentUser {
  public static readonly type = '[User] Fetch current user';
}


export class CurrentUserFetched {
  public static readonly type = '[User API] Current user fetched';

  constructor(public user: User) {
  }
}