<div>
    <div>
        <span style="font-weight: bold">Bénéficiaire : </span><span>{{ prestation().beneficiary | personInfo }}</span>
    </div>
    <div>
        <span style="font-weight: bold">Date : </span><span>{{ prestation().schedule.day | schedulerDay }}</span>
    </div>
    <div>
        <span style="font-weight: bold">Horaire : </span><span>{{ prestation().schedule.timePeriod | schedulerTimePeriod:true }}</span>
    </div>
    <form [formGroup]="form">

        <div class="d-flex gap-2 align-items-center">
            <span style="font-weight: bold">Horaire correct : </span>
            <div class="d-flex gap-2">
                <div>
                    <input type="radio" value="true" formControlName="hasCorrectSchedule" class="btn-check"
                           id="schedule-correct" autocomplete="off">
                    <label class="btn btn-outline-info" for="schedule-correct">Oui</label>
                </div>
                <div>
                    <input type="radio" value="false" formControlName="hasCorrectSchedule" class="btn-check"
                           id="schedule-incorrect" autocomplete="off">
                    <label class="btn btn-outline-info" for="schedule-incorrect">Non</label>
                </div>
            </div>
        </div>

        <div *ngIf="isIncorrectSchedule()" class="border border-2 rounded m-2 p-2">
            <div class="d-flex gap-2 align-items-center">
                <div class="flex-grow-1 d-flex gap-2">
                    <span style="font-weight: bold">Début : </span><span>{{ prestation().schedule.day | schedulerDay }}</span>
                </div>
                <mat-form-field class="small-input">
                    <mat-label>Début</mat-label>
                    <input matInput required type="text" formControlName="startTime">
                    <mat-hint>HH:mm (10:00)</mat-hint>
                    <button *ngIf="form.value.startTime" matSuffix mat-icon-button aria-label="Clear"
                            (click)="form.patchValue({startTime:''})" tabindex="-1">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="d-flex gap-2 align-items-center">
                <div class="flex-grow-1 d-flex gap-2">
                    <span style="font-weight: bold">Fin : </span><span>{{ computeEndDay() | schedulerDay }}</span>
                    <mat-icon *ngIf="isCoveringTwoDays()" class="warning-icon"
                              matTooltip="La prestation couvre 2 jours">
                        warning
                    </mat-icon>
                </div>
                <mat-form-field class="small-input">
                    <mat-label>Fin</mat-label>
                    <input matInput required type="text" formControlName="endTime">
                    <mat-hint>HH:mm (13:00)</mat-hint>
                    <button *ngIf="form.value.endTime" matSuffix mat-icon-button aria-label="Clear"
                            (click)="form.patchValue({endTime:''})" tabindex="-1">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </div>

        <div class="d-flex gap-2 align-items-center m-2">
            <span style="font-weight: bold">Déplacements : </span>
            <div class="d-flex gap-2">
                <div>
                    <input type="radio" value="true" formControlName="hasTravelDuringPrestation" class="btn-check"
                           id="travel-yes" autocomplete="off">
                    <label class="btn btn-outline-info" for="travel-yes">Oui</label>
                </div>
                <div>
                    <input type="radio" value="false" formControlName="hasTravelDuringPrestation" class="btn-check"
                           id="travel-no" autocomplete="off">
                    <label class="btn btn-outline-info" for="travel-no">Non</label>
                </div>
            </div>
        </div>

        <div *ngIf="hasTravelDuringPrestation()">
            <mat-form-field class="input-note">
                <mat-label>Description des trajets</mat-label>
                <textarea matInput cdkTextareaAutosize formControlName="travelDescription"></textarea>
            </mat-form-field>
        </div>


        <mat-form-field class="input-note">
            <mat-label>Note</mat-label>
            <textarea matInput cdkTextareaAutosize formControlName="note"></textarea>
        </mat-form-field>

        <div class="d-flex gap-2 mt-lg-2 justify-content-end">
            <button class="btn btn-secondary" *ngIf="showCancel()" (click)="cancel()">{{ cancelText() }}</button>
            <button class="btn btn-primary" [disabled]="form.invalid" (click)="validate()">Valider</button>
        </div>
    </form>
</div>
