if (!window["env"]) {
  window["env"] = [];
}
export const environment = {
  production: false,
  baseHref: window["env"]["baseHref"] || "/",
  apiUrl: window["env"]["apiUrl"] || "//localhost:8880",
  keycloakUrl: window["env"]["keycloakUrl"] || "//localhost:8881/auth",
  keycloakRealm: window["env"]["keycloakRealm"] || "RepitRealm",
  keycloakClientId: window["env"]["keycloakClientId"] || "repit",
  debug: window["env"]["debug"] || false
};
