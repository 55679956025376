/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {AddressInfoDto} from './addressInfoDto';


export interface PrestationCreationRequestDto {
  type: string;
  beneficiaryId: number;
  requestContactId: number;
  invoiceContactId: number;
  address: AddressInfoDto;
  urgent?: boolean;
  bap?: boolean;
  startTime: string;
  endTime: string;
  accepted?: boolean;
  mainEducatorId?: number;
  secondaryEducatorId?: number;
  note?: string;
}

