import {Component, OnInit} from '@angular/core';
import {BureauRegionalDto, EducatorSummaryDto, ReportedKmEducatorDto} from "../../../../generated/api";
import {Year} from "../../../models/calendar/Year";
import {Month} from "../../../models/calendar/Month";
import {CalendarService} from "../../../models/calendar/calendar.service";
import {EducatorService} from "../../../models/educator/educator.service";
import {BrService} from "../../../models/br/br.service";
import {ReportService} from "../../../models/document/km/report.service";


@Component({
  selector: 'app-generate-report-km',
  templateUrl: './generate-report-km.component.html',
  styleUrls: ['./generate-report-km.component.scss']
})
export class GenerateReportKmComponent implements OnInit {
  educators: ReportedKmEducatorDto[] = [];
  selectedYear: Year;
  selectedMonth: Month;
  months: Month[] = [];
  years: Year[] = [];
  brs: BureauRegionalDto[] = [];
  selectedBrs: BureauRegionalDto[] = [];
  isLoading: boolean = true;
  constructor(private calendarService: CalendarService, private educatorService: EducatorService, private brService: BrService, private reportService: ReportService) {
  }

  ngOnInit(): void {
    this.months = this.calendarService.getMonths();
    this.years = this.calendarService.getYears();
    this.selectedYear = this.calendarService.getCurrentYear();
    this.selectedMonth = this.calendarService.getCurrentMonth();
    this.brService.allBr().subscribe(brs => this.brs = brs.sort((a, b) => a.name > b.name ? 1 : -1))
    this.updateEducators();
  }

  getName(reportedKmEducatorDto: ReportedKmEducatorDto) {
    return reportedKmEducatorDto.educator.personInfo.firstName + " " + reportedKmEducatorDto.educator.personInfo.lastName;
  }

  updateEducators() {
    this.isLoading = true;
    this.educatorService.fetchEducatorsWithDocumentCapability(this.selectedYear, this.selectedMonth).subscribe(educators => {
      this.educators = educators
        .sort((a, b) => this.getName(a) > this.getName(b) ? 1 : -1);
      this.isLoading = false;
    });
  }

  hasEducator(): boolean {
    return this.educators.length > 0;
  }

  generateAndDownloadFor(educator: EducatorSummaryDto) {
    this.reportService.generateKmReport(educator, this.selectedMonth, this.selectedYear);
  }

  isBrSelected(br: BureauRegionalDto) {
    return this.selectedBrs.indexOf(br, 0) > -1;
  }

  toggleBr(br: BureauRegionalDto) {
    const index = this.selectedBrs.indexOf(br, 0);
    if (index > -1) {
      this.selectedBrs.splice(index, 1);
    } else {
      this.selectedBrs.push(br);
    }
  }

  filteredEducators() {
    return this.educators
      .filter(educator => this.selectedBrs.length === 0 || this.selectedBrs.some(br => br.id === educator.educator.br.id))
  }
}
