import {Component, inject, Inject} from '@angular/core';
import {StaffActivityRequest} from "../../../models/staff/activity/StaffActivityRequest";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from "@angular/material/dialog";
import {Store} from "@ngxs/store";
import {ConfirmStaffActivityRequestCancellation} from "../../../stores/staff/staff.actions";

export interface CancelStaffActivityRequestDialogData {
  request: StaffActivityRequest
}

@Component({
  selector: 'app-cancel-staff-activity-request-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose
  ],
  templateUrl: './cancel-staff-activity-request-dialog.component.html',
  styleUrl: './cancel-staff-activity-request-dialog.component.scss'
})
export class CancelStaffActivityRequestDialogComponent {

  store = inject(Store);

  constructor(@Inject(MAT_DIALOG_DATA) public input: CancelStaffActivityRequestDialogData) {
  }

  confirm() {
    this.store.dispatch(new ConfirmStaffActivityRequestCancellation(this.input.request));
  }
}
