import {Component, computed, inject, OnInit, signal} from '@angular/core';
import {PrestationStatusEnum} from "../../../models/prestation/PrestationStatusEnum";
import {YesNoPipe} from "../../pipe/yes-no.pipe";
import {SchedulerDayPipe} from "../../pipe/scheduler-day.pipe";
import {SchedulerTimePeriodPipe} from "../../pipe/scheduler-time-period.pipe";
import {PersonInfoPipe} from "../../pipe/person-info.pipe";
import {NgForOf, NgIf} from "@angular/common";
import {Store} from "@ngxs/store";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {InlineAddressPipe} from "../../pipe/inline-address.pipe";
import {PrestationDetail} from "../../../models/prestation/prestationDetail";
import {PrestationSelectors} from "../../../stores/prestation/prestation.selectors";
import {toSignal} from "@angular/core/rxjs-interop";
import {FindPrestationDetail, SwitchBap, SwitchUrgent} from "../../../stores/prestation/prestation.actions";
import {MatDialog} from "@angular/material/dialog";
import {AssignPrestationDialogComponent} from "../assign-prestation-dialog/assign-prestation-dialog.component";
import {RefusePrestationDialogComponent} from "../refuse-prestation-dialog/refuse-prestation-dialog.component";
import {CancelPrestationDialogComponent} from "../cancel-prestation-dialog/cancel-prestation-dialog.component";
import {MatIcon} from "@angular/material/icon";
import {
  UpdatePrestationTravelsDialogComponent
} from "../update-prestation-travels-dialog/update-prestation-travels-dialog.component";
import {PrestationStatusIconComponent} from "../prestation-status-icon/prestation-status-icon.component";
import {
  UpdatePrestationLocationAndContactsComponent,
  UpdatePrestationLocationAndContactsData
} from "../update-prestation-location-and-contacts/update-prestation-location-and-contacts.component";
import {MatIconButton} from "@angular/material/button";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {
  PrestationUpdateTypeData,
  UpdatePrestationTypeDialogComponent
} from "../update-prestation-type-dialog/update-prestation-type-dialog.component";
import {
  UpdatePrestationScheduleDialogComponent
} from "../update-prestation-schedule-dialog/update-prestation-schedule-dialog.component";
import {UserState} from "../../../stores/user/user.state";
import {DeletePrestationDialogComponent} from "../delete-prestation-dialog/delete-prestation-dialog.component";


@Component({
  selector: 'app-detail-prestation',
  standalone: true,
  imports: [
    YesNoPipe,
    SchedulerDayPipe,
    SchedulerTimePeriodPipe,
    PersonInfoPipe,
    NgIf,
    InlineAddressPipe,
    RouterLink,
    MatIcon,
    NgForOf,
    PrestationStatusIconComponent,
    MatIconButton,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger
  ],
  templateUrl: './detail-prestation.component.html',
  styleUrl: './detail-prestation.component.scss'
})
export class DetailPrestationComponent implements OnInit {
  private readonly UPDATE_TRAVEL_ALLOWED_STATUSES = [PrestationStatusEnum.CLOSED];
  private readonly ASSIGNABLE_STATUSES = [PrestationStatusEnum.PENDING, PrestationStatusEnum.ACCEPTED];
  private readonly DELETABLE_STATUSES = [PrestationStatusEnum.PENDING, PrestationStatusEnum.ACCEPTED];
  private readonly CANCELLABLE_STATUSES = [PrestationStatusEnum.ACCEPTED, PrestationStatusEnum.TO_VALIDATE_BY_EDUCATOR];
  private readonly READONLY_STATUSES = [PrestationStatusEnum.REFUSED, PrestationStatusEnum.CANCELLED];

  store = inject(Store);
  dialog = inject(MatDialog);

  id = signal(Number(inject(ActivatedRoute).snapshot.paramMap.get('id')));
  prestation = toSignal<PrestationDetail>(this.store.select(PrestationSelectors.prestationDetail()))

  ngOnInit(): void {
    this.store.dispatch(new FindPrestationDetail(this.id()))
  }

  isRefused = computed(() => {
    return this.prestation().status === PrestationStatusEnum.REFUSED;
  })

  isCancelled = computed(() => {
    return this.prestation().status === PrestationStatusEnum.CANCELLED;
  })

  isClosed = computed(() => {
    return this.prestation().status === PrestationStatusEnum.CLOSED;
  })

  hasTravels = computed(() => {
    return this.prestation().travelDurings.length > 0
      || this.prestation().travelBefore
      || this.prestation().travelAfter;
  });

  canUpdateTravels() {
    return this.UPDATE_TRAVEL_ALLOWED_STATUSES.includes(this.prestation().status);
  };

  updateTravels() {
    this.dialog.open(UpdatePrestationTravelsDialogComponent, {
      data: {
        prestationId: this.prestation().id,
        educatorTravelDescription: this.prestation().educatorValidation?.travelDescription,
        travelBefore: this.prestation().travelBefore,
        travelDurings: this.prestation().travelDurings,
        travelAfter: this.prestation().travelAfter
      }
    })

  }

  assign() {
    this.dialog.open(AssignPrestationDialogComponent, {
      data: {
        prestationId: this.prestation().id,
        mainEducator: this.prestation().mainEducator,
        secondaryEducator: this.prestation().secondaryEducator
      }
    })
  };

  canBeAssigned = computed(() => {
    return this.ASSIGNABLE_STATUSES.includes(this.prestation().status)
  });

  refuse() {
    this.dialog.open(RefusePrestationDialogComponent, {
      data: {
        prestationId: this.prestation().id
      }
    })
  };

  canBeRefused = computed(() => {
    return this.prestation().status === PrestationStatusEnum.PENDING;
  });

  cancel() {
    this.dialog.open(CancelPrestationDialogComponent, {
      data: {
        prestationId: this.prestation().id
      }
    })
  };

  canBeCancelled = computed(() => {
    return this.CANCELLABLE_STATUSES.includes(this.prestation().status)
  });

  isLocationAtHome = computed(() => {
    return this.prestation().location.id === this.prestation().requestContact.address.id
  });

  canUpdateLocationAndContacts() {
    return !this.READONLY_STATUSES.includes(this.prestation().status);
  }

  updateLocationAndContacts() {
    this.dialog.open(UpdatePrestationLocationAndContactsComponent, {
      data: {
        prestationId: this.prestation().id,
        beneficiaryId: this.prestation().beneficiary.id,
        requestContact: this.prestation().requestContact,
        invoiceContact: this.prestation().invoiceContact,
        address: this.prestation().location
      } as UpdatePrestationLocationAndContactsData
    });
  }

  switchUrgent() {
    this.store.dispatch(new SwitchUrgent(this.prestation().id))
  }

  canSwitchBap() {
    return !this.prestation().beneficiary.bap;
  }

  switchBap() {
    this.store.dispatch(new SwitchBap(this.prestation().id))
  }

  editType() {
    this.dialog.open(UpdatePrestationTypeDialogComponent, {
      data: {
        prestationId: this.prestation().id,
        type: this.prestation().type
      } as PrestationUpdateTypeData
    });
  }

  editSchedule() {
    this.dialog.open(UpdatePrestationScheduleDialogComponent, {
      data: {
        prestationId: this.prestation().id,
        schedule: this.prestation().schedule
      }
    });

  }

  canBeDeleted() {
    return this.DELETABLE_STATUSES.includes(this.prestation().status)
      || this.store.selectSnapshot(UserState.isAdmin);
  }

  delete() {
    this.dialog.open(DeletePrestationDialogComponent, {
      data: {
        prestationId: this.prestation().id
      }
    });
  }
}
