import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {formatDate} from "@angular/common";
import {
  FirstContactApplicationControllerService,
  FirstContactApplicationMutationRequest,
  FirstContactApplicationResponse,
  FirstContactApplicationSummary
} from "../../../generated/api";
import {FirstContactApplicationRefusalMotive} from "./RefusalMotive";

@Injectable({
  providedIn: 'root'
})
export class FirstContactService {

  constructor(private firstContactController: FirstContactApplicationControllerService) {
  }

  create(firstContact: FirstContactApplicationMutationRequest): Observable<FirstContactApplicationResponse> {
    this.formatContactedOn(firstContact);
    return this.firstContactController.create(firstContact);
  }

  private formatContactedOn(firstContact: FirstContactApplicationMutationRequest) {
    firstContact.contactedOn = formatDate(firstContact.contactedOn, "YYYY-MM-dd", "fr-BE");
  }

  update(id: number, firstContact: FirstContactApplicationMutationRequest): Observable<FirstContactApplicationResponse> {
    this.formatContactedOn(firstContact);
    return this.firstContactController.update(id, firstContact);
  }

  fetch(id: number): Observable<FirstContactApplicationResponse> {
    return this.firstContactController.fetch(id);
  }

  fetchAll(): Observable<FirstContactApplicationSummary[]> {
    return this.firstContactController.all();
  }

  fetchPending(): Observable<FirstContactApplicationSummary[]> {
    return this.firstContactController.pending();
  }

  refuse(id: number, selectedMotive: FirstContactApplicationRefusalMotive.RefusalMotive, note: string): Observable<FirstContactApplicationResponse> {
    return this.firstContactController.refuse(id, {motive: selectedMotive.value, note: note});
  }

  accept(id: number): Observable<FirstContactApplicationResponse> {
    return this.firstContactController.accept(id);
  }
}
