export * from './beneficiaryController.service';
import {BeneficiaryControllerService} from './beneficiaryController.service';
import {BrControllerService} from './brController.service';
import {ContactControllerService} from './contactController.service';
import {EducatorControllerService} from './educatorController.service';
import {FirstContactApplicationControllerService} from './firstContactApplicationController.service';
import {InvoiceControllerService} from './invoiceController.service';
import {KmReportControllerService} from './kmReportController.service';
import {PrestationControllerService} from './prestationController.service';
import {StaffActivityControllerService} from './staffActivityController.service';
import {StaffControllerService} from './staffController.service';
import {UserControllerService} from './userController.service';

export * from './brController.service';

export * from './contactController.service';

export * from './educatorController.service';

export * from './firstContactApplicationController.service';

export * from './invoiceController.service';

export * from './kmReportController.service';

export * from './prestationController.service';

export * from './staffActivityController.service';

export * from './staffController.service';

export * from './userController.service';

export const APIS = [BeneficiaryControllerService, BrControllerService, ContactControllerService, EducatorControllerService, FirstContactApplicationControllerService, InvoiceControllerService, KmReportControllerService, PrestationControllerService, StaffActivityControllerService, StaffControllerService, UserControllerService];
