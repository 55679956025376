import {Injectable} from '@angular/core';
import {saveAs} from 'file-saver';
import _ from "lodash";
import {Month} from "../../calendar/Month";
import {Year} from "../../calendar/Year";
import {EducatorControllerService, EducatorSummaryDto} from "../../../../generated/api";

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private educatorController: EducatorControllerService) {
  }


  generateKmReport(educator: EducatorSummaryDto, month: Month, year: Year) {
    this.educatorController.downloadKmReport1(educator.id, Number(year.value), Number(month.value))
      .subscribe(this.downloadReport(educator.personInfo.lastName, educator.personInfo.firstName, month, year));
  }


  private downloadReport(firstName: string, lastName: string, month: Month, year: Year) {
    return doc => {
      let blob = new Blob([doc]);
      let monthAsString = '0' + month.value;
      monthAsString = monthAsString.substring(-2);
      let fileName = _.startCase(_.toLower(firstName)) + ' ' + _.toUpper(lastName) + ' - ' + monthAsString + '.' + year.value + '.pdf';
      saveAs(blob, fileName);
    };
  }


}
