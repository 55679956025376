<div class="m-3" [formGroup]="form">
    <h1 mat-dialog-title>Modifier le type</h1>
    <mat-dialog-content>
        <mat-form-field appearance="fill">
            <mat-label>Type</mat-label>
            <mat-select formControlName="type" required>
                <mat-option *ngFor="let type of PrestationTypeEnum.VALUES" [value]="type">
                    {{ type.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions class="d-flex gap-2 justify-content-end">
        <button mat-dialog-close class="btn btn-secondary">Annuler</button>
        <button class="btn btn-primary" id="confirm" [disabled]="!isValid()" (click)="update()">Modifier</button>
    </mat-dialog-actions>

</div>