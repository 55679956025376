<h1>Membres du personnel</h1>

<div class="vstack gap-2">
    <div class="d-flex gap-2 general-bg bg-white align-content-center align-items-center">
        <mat-form-field class="height-0">
            <mat-label>Filtrer</mat-label>
            <input matInput type="text" [ngModel]="name()" (keyup)="name.set($any($event.target).value)">
            <button *ngIf="name()" matSuffix mat-icon-button aria-label="Clear"
                    (click)="name.set('')" tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <mat-slide-toggle [ngModel]="activeOnly()"
                          (click)="toggleActiveOnly()">
            Actifs uniquement
        </mat-slide-toggle>

        <a routerLink="/staff/create" class="ms-auto">
            <div class="btn btn-important">
                <mat-icon>add</mat-icon>
                Ajouter
            </div>
        </a>

    </div>

    <div class="card-container d-flex align-items-stretch">
        <a class="card repit-card" *ngFor="let staffMember of staff()"
           [routerLink]="['/staff/detail/' + staffMember.id]">
            <div class="card-header">
                {{ staffMember | personInfo }}
            </div>
            <div class="card-body">
                <div class="card-text">
                    <div>{{ staffMember.type.label }}</div>
                    <div *ngIf="!staffMember.active">Inactif</div>
                </div>
            </div>
        </a>
    </div>

    <p *ngIf="staff().length === 0">Aucun membre du personnel ne correspond aux critères</p>
</div>