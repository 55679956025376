import {Year} from "../models/calendar/Year";
import {Observable} from "rxjs";
import {DailyReportDetail, WeeklyReportDetail, YearlyReport} from "../models/activity-report/activity-report";

export abstract class ActivityReportGateway {

  abstract findActivityReport(staffMemberId: number, year: Year): Observable<YearlyReport>;

  abstract findWeeklyReport(staffMemberId: number, weekNumber: number): Observable<WeeklyReportDetail>;

  abstract findDailyReport(staffMemberId: number, date: Date): Observable<DailyReportDetail>;

}