import {Action, State, StateContext} from "@ngxs/store";
import {inject, Injectable} from "@angular/core";
import {SchedulerEvent} from "../../models/scheduler/SchedulerEvent";
import {WorkEventGateway} from "../../ports/WorkEventGateway";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {FindWorkEventsForStaffMember, WorkEventsFound} from "./work-event.actions";


export interface WorkEventStateModel {
  workEvents: SchedulerEvent[];
}

@State<WorkEventStateModel>({
  name: 'workEvent',
  defaults: {
    workEvents: []
  }
})
@Injectable()
export class WorkEventState {

  workEventGateway = inject(WorkEventGateway);

  @Action(FindWorkEventsForStaffMember)
  public findWorkEventsForStaffMember(ctx: StateContext<WorkEventStateModel>, action: FindWorkEventsForStaffMember): Observable<SchedulerEvent[]> {
    return this.workEventGateway.findWorkEventsForStaffMember(action.staffMemberId).pipe(
      tap(workEvents => {
        ctx.dispatch(new WorkEventsFound(workEvents));
      })
    )
  }

  @Action(WorkEventsFound)
  public workEventsForStaffMemberFound(ctx: StateContext<WorkEventStateModel>, action: WorkEventsFound) {
    ctx.patchState({
      workEvents: action.workEvents
    });
  }

}