import {Component, inject, OnInit, signal, Signal} from '@angular/core';
import {toSignal} from "@angular/core/rxjs-interop";
import {ActivatedRoute} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {
  ViewAddressHistoryDialogComponent
} from "../../address/view-address-history-dialog/view-address-history-dialog.component";
import {ModifyAddressDialogComponent} from "../../address/modify-address-dialog/modify-address-dialog.component";
import {Store} from "@ngxs/store";
import {StaffMemberDetail} from "../../../models/staff/StaffMemberDetail";
import {StaffSelectors} from "../../../stores/staff/staff.selectors";
import {RetrieveStaffMember} from "../../../stores/staff/staff.actions";
import {StaffTypeEnum} from "../../../models/staff/StaffType.enum";

@Component({
  selector: 'app-detail-staff',
  templateUrl: './detail-staff.component.html',
  styleUrls: ['./detail-staff.component.scss']
})
export class DetailStaffComponent implements OnInit {
  store = inject(Store);

  id = signal(Number(this.route.snapshot.paramMap.get('id')));
  staffMember: Signal<StaffMemberDetail> = toSignal(this.store.select(StaffSelectors.detail()));

  constructor(private route: ActivatedRoute, public dialog: MatDialog) {

  }

  ngOnInit(): void {
    this.store.dispatch(new RetrieveStaffMember(this.id()))
  }

  isEducator() {
    return this.staffMember().type === StaffTypeEnum.EDUCATOR;
  }

  viewAddressHistory() {
    this.dialog.open(ViewAddressHistoryDialogComponent, {data: {id: this.staffMember().id}})
  }

  modifyCurrentAddress() {
    this.dialog.open(ModifyAddressDialogComponent, {
      data: {
        personId: this.staffMember().id,
        address: this.staffMember().address
      }
    });
  }
}
