import {Injectable} from '@angular/core';
import {FirstContactApplicationResponse} from "../../../generated/api";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class CreateBeneficiaryService {
  private from: FirstContactApplicationResponse;

  constructor(private router: Router) {
  }

  initiateFromFirstContact(firstContact: FirstContactApplicationResponse) {
    this.from = firstContact
    this.router.navigate(["/beneficiary/create"])
  }
}
