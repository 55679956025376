<h1>Rapport kilométrique</h1>
<div class="vstack gap-2">
    <div class="d-flex gap-2 align-items-baseline general-bg bg-white">
        <mat-form-field appearance="fill">
            <mat-label>Année</mat-label>
            <mat-select [(ngModel)]="selectedYear" (ngModelChange)="updateEducators()">
                <mat-option *ngFor="let year of years" [value]="year">{{ year.value }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Mois</mat-label>
            <mat-select [(ngModel)]="selectedMonth" (ngModelChange)="updateEducators()">
                <mat-option *ngFor="let month of months" [value]="month">{{ month.name }}</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="mt-3">
            <mat-chip-listbox multiple>
                <mat-chip-option *ngFor="let br of brs"
                                 (click)="toggleBr(br)"
                                 [selected]="isBrSelected(br)"
                >{{ br.name }}
                </mat-chip-option>
            </mat-chip-listbox>
        </div>

        <button class="btn btn-primary">
            <mat-icon>picture_as_pdf</mat-icon>
            Tout télécharger
        </button>
    </div>
    <div *ngIf="!isLoading; else loadingBlock">
        <div class="card-container" *ngIf="hasEducator() ; else elseBlock">

            <div class="card educator-card" *ngFor="let educator of this.filteredEducators()">
                <div class="card-header">
                    <div class="card-title">{{ getName(educator) }}</div>
                    <div class="card-subtitle">{{ educator.educator.br.name }}</div>
                </div>
                <div class="card-body ">
                    <div class="card-text d-flex flex-column mb-3">
                        <div *ngIf="educator.totalPrestationAmountInCents > 0"><i>Dépl.
                            prestations:</i> {{ educator.totalPrestationAmountInCents |amount }}
                        </div>
                        <div *ngIf="educator.totalLeisureAmountInCents > 0"><i>Dépl.
                            loisirs: </i>{{ educator.totalLeisureAmountInCents |amount }}
                        </div>
                        <div *ngIf="educator.totalMissionAmountInCents > 0"><i>Dépl.
                            missions: </i>{{ educator.totalMissionAmountInCents |amount }}
                        </div>
                        <div *ngIf="educator.totalWithBeneficiaryAmountInCents > 0"><i>Dépl.
                            bénéficiaires: </i>{{ educator.totalWithBeneficiaryAmountInCents |amount }}
                        </div>
                        <div *ngIf="educator.totalAmountInCents > 0"><b>Dépl.
                            total: {{ educator.totalAmountInCents |amount }} </b></div>

                    </div>
                    <div class="d-flex gap-2">
                        <button class="btn btn-primary" (click)="generateAndDownloadFor(educator.educator)">
                            <mat-icon>picture_as_pdf</mat-icon>
                        </button>
                    </div>

                </div>
            </div>
        </div>

        <ng-template #elseBlock>Aucun éducateur pour ce mois.</ng-template>
    </div>
    <ng-template #loadingBlock>
        <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="sr-only"></span>
            </div>
        </div>
    </ng-template>
</div>
