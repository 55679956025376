import {Component, inject, OnInit, signal, Signal} from '@angular/core';
import {toSignal} from "@angular/core/rxjs-interop";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {
  ViewAddressHistoryDialogComponent
} from "../../address/view-address-history-dialog/view-address-history-dialog.component";
import {ModifyAddressDialogComponent} from "../../address/modify-address-dialog/modify-address-dialog.component";
import {Store} from "@ngxs/store";
import {StaffMemberDetail} from "../../../models/staff/StaffMemberDetail";
import {StaffSelectors} from "../../../stores/staff/staff.selectors";
import {RetrieveStaffMember} from "../../../stores/staff/staff.actions";
import {StaffTypeEnum} from "../../../models/staff/StaffType.enum";
import {MatDivider} from "@angular/material/divider";
import {MatTab, MatTabGroup} from "@angular/material/tabs";
import {DatePipe, NgIf, NgOptimizedImage} from "@angular/common";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {PersonInfoPipe} from "../../pipe/person-info.pipe";
import {InlineAddressPipe} from "../../pipe/inline-address.pipe";

@Component({
  selector: 'app-detail-staff',
  standalone: true,
  templateUrl: './detail-staff.component.html',
  imports: [
    MatDivider,
    MatTabGroup,
    MatTab,
    NgOptimizedImage,
    MatMenuTrigger,
    MatIconButton,
    MatIcon,
    MatMenu,
    PersonInfoPipe,
    MatMenuItem,
    InlineAddressPipe,
    NgIf,
    DatePipe,
    RouterLink
  ],
  styleUrls: ['./detail-staff.component.scss']
})
export class DetailStaffComponent implements OnInit {
  store = inject(Store);

  id = signal(Number(this.route.snapshot.paramMap.get('id')));
  staffMember: Signal<StaffMemberDetail> = toSignal(this.store.select(StaffSelectors.detail()));

  constructor(private route: ActivatedRoute, public dialog: MatDialog) {

  }

  ngOnInit(): void {
    this.store.dispatch(new RetrieveStaffMember(this.id()))
  }

  isEducator() {
    return this.staffMember().type === StaffTypeEnum.EDUCATOR;
  }

  viewAddressHistory() {
    this.dialog.open(ViewAddressHistoryDialogComponent, {data: {id: this.staffMember().id}})
  }

  modifyCurrentAddress() {
    this.dialog.open(ModifyAddressDialogComponent, {
      data: {
        personId: this.staffMember().id,
        address: this.staffMember().address
      }
    });
  }
}
