import {EnumStyle, RepitEnum} from "../common/RepitEnum";

export namespace PrestationStatusEnum {
  export class PrestationStatus extends RepitEnum {
    constructor(value: string, label: string, style: EnumStyle) {
      super(value, label, style);
    }
  }

  export const ACCEPTED = new PrestationStatus("ACCEPTED", "Acceptée", new EnumStyle("done", "green"));
  export const PENDING = new PrestationStatus("PENDING", "En attente", new EnumStyle("pending", "default"));
  export const CANCELLED = new PrestationStatus("CANCELLED", "Annulée", new EnumStyle("cancel", "red"));
  export const REFUSED = new PrestationStatus("REFUSED", "Refusée", new EnumStyle("block", "red"));
  export const TO_VALIDATE_BY_EDUCATOR = new PrestationStatus("TO_VALIDATE_BY_EDUCATOR", "A valider par l'éducateur", new EnumStyle("question_mark", "orange"));
  export const TO_CLOSE = new PrestationStatus("TO_CLOSE", "A clôturer", new EnumStyle("pending", "orange"));
  export const CLOSED = new PrestationStatus("CLOSED", "Clôturée", new EnumStyle("done_all", "green"));


  export const VALUES: PrestationStatus[] = [
    ACCEPTED,
    PENDING,
    CANCELLED,
    REFUSED,
    TO_VALIDATE_BY_EDUCATOR,
    TO_CLOSE,
    CLOSED
  ];

  const BY_VALUE = new Map<string, PrestationStatus>();
  VALUES.forEach(item => BY_VALUE.set(item.value, item));

  export function fromValue(value: string): PrestationStatus {
    return BY_VALUE.get(value);
  }
}