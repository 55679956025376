import {createPropertySelectors, createSelector} from "@ngxs/store";
import {WorkEventState, WorkEventStateModel} from "./work-event.state";

export class WorkEventSelectors {
  static slices = createPropertySelectors<WorkEventStateModel>(WorkEventState);

  static workEvents() {
    return createSelector(
      [WorkEventSelectors.slices.workEvents],
      workEvents => workEvents.map(workEvent => (workEvent.toCalendarEvent())));
  }
}