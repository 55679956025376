import {Component, effect, EventEmitter, inject, input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SchedulerDay} from "../../../models/scheduler/SchedulerDay";
import {SchedulerTime} from "../../../models/scheduler/SchedulerTime";
import {SchedulerTimePipe} from "../../pipe/scheduler-time.pipe";
import {PrestationToValidate} from "../../../models/prestation/PrestationToValidate";
import {PrestationValidationRequest} from "../../../models/prestation/PrestationValidationRequest";

@Component({
  selector: 'app-prestation-to-validate-form',
  templateUrl: './prestation-to-validate-form.component.html',
  styleUrls: ['./prestation-to-validate-form.component.scss']
})
export class PrestationToValidateFormComponent implements OnInit {

  schedulerTimePipe = inject(SchedulerTimePipe);

  cancelText = input.required<string>();
  showCancel = input.required<boolean>();
  prestation = input.required<PrestationToValidate>();
  form: FormGroup;
  @Output() confirmValidationRequest = new EventEmitter<PrestationValidationRequest>();
  @Output() onCancel = new EventEmitter<void>();


  constructor() {
    effect(() => {
      if (this.prestation()) {
        this.resetForm();
      }
    });
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      hasCorrectSchedule: new FormControl(null, Validators.required),
      startTime: new FormControl(this.schedulerTimePipe.transform(this.prestation()?.schedule.timePeriod.startTime), Validators.required),
      endTime: new FormControl(this.schedulerTimePipe.transform(this.prestation()?.schedule.timePeriod.endTime)),
      hasTravelDuringPrestation: new FormControl(null, Validators.required),
      travelDescription: new FormControl(""),
      note: new FormControl("", Validators.maxLength(255)),
    });
    this.form.get("hasCorrectSchedule").valueChanges.subscribe(() => {
      this.updateValidatorsForSchedule();
    })
    this.form.get("hasTravelDuringPrestation").valueChanges.subscribe(() => {
      this.updateValidatorsForTravel();
    })
  }

  validate() {
    if (this.form.invalid) {
      return;
    }

    const request = <PrestationValidationRequest>{
      prestationId: this.prestation().id,
      hasCorrectSchedule: this.form.value.hasCorrectSchedule === "true",
      startDay: new SchedulerDay(this.prestation()?.schedule.start()),
      startTime: this.isIncorrectSchedule() ? SchedulerTime.fromString(this.form.value.startTime) : this.prestation()?.schedule.timePeriod.startTime,
      endDay: this.isIncorrectSchedule() ? this.computeEndDay() : new SchedulerDay(this.prestation()?.schedule.end()),
      endTime: this.isIncorrectSchedule() ? SchedulerTime.fromString(this.form.value.endTime) : this.prestation()?.schedule.timePeriod.endTime,
      hasTravelDuringPrestation: this.form.value.hasTravelDuringPrestation === "true",
      travelDescription: this.form.value.travelDescription,
      note: this.form.value.note
    }

    this.confirmValidationRequest.emit(request);
  }

  computeEndDay() {
    if (this.isCoveringTwoDays()) {
      let nextDay = new Date(this.prestation()?.schedule.end());
      nextDay.setDate(nextDay.getDate() + 1);
      return new SchedulerDay(nextDay)
    }
    return new SchedulerDay(this.prestation()?.schedule.end());
  }

  isCoveringTwoDays() {
    return SchedulerTime.fromString(this.form.value.endTime).isBefore(SchedulerTime.fromString(this.form.value.startTime))
  }

  isIncorrectSchedule() {
    return this.form.get("hasCorrectSchedule").value === "false";
  }

  hasTravelDuringPrestation() {
    return this.form.get("hasTravelDuringPrestation").value === "true";
  }

  cancel() {
    this.onCancel.emit();
  }

  private resetForm() {
    this.updateValidatorsForSchedule();
    this.updateValidatorsForTravel();

    this.form.reset({
      hasCorrectSchedule: null,
      startTime: this.schedulerTimePipe.transform(this.prestation()?.schedule.timePeriod.startTime),
      endTime: this.schedulerTimePipe.transform(this.prestation()?.schedule.timePeriod.endTime),
      hasTravelDuringPrestation: null,
      travelDescription: "",
      note: "",
    });
  }

  private updateValidatorsForTravel() {
    if (this.hasTravelDuringPrestation()) {
      this.form.get("travelDescription").setValidators(Validators.required);
    } else {
      this.form.get("travelDescription").clearValidators();
    }
    this.form.get("travelDescription").updateValueAndValidity();
  }

  private updateValidatorsForSchedule() {
    if (this.isIncorrectSchedule()) {
      this.form.get("startTime").setValidators(Validators.required);
      this.form.get("endTime").setValidators(Validators.required);
    } else {
      this.form.get("startTime").clearValidators();
      this.form.get("endTime").clearValidators();
    }
    this.form.get("startTime").updateValueAndValidity();
    this.form.get("endTime").updateValueAndValidity();

  }
}
