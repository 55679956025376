<div class="m-3">
    <h1 mat-dialog-title>Accepter la demande</h1>
    <mat-dialog-content>
        <div class="vstack">
            Confirmer l'acceptation de la demande.
        </div>
    </mat-dialog-content>

    <mat-dialog-actions class="d-flex gap-2 justify-content-end">
        <button mat-dialog-close class="btn btn-secondary">Annuler</button>
        <button class="btn btn-primary" id="confirm" (click)="confirm()">Confirmer</button>
    </mat-dialog-actions>
</div>
