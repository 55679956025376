import {Component, computed, inject, OnInit, signal} from '@angular/core';
import _ from "lodash";
import {Router} from "@angular/router";
import {FirstContactApplicationSummary} from "../../../../generated/api";
import {FirstContactService} from "../../../models/first-contact/first-contact.service";
import {FirstContactApplicationStatusEnum} from "../../../models/first-contact/FirstContactApplicationStatusEnum";
import {Store} from "@ngxs/store";
import {toSignal} from "@angular/core/rxjs-interop";
import {UserState} from "../../../stores/user/user.state";


@Component({
  selector: 'app-pending-first-contact-widget',
  templateUrl: './pending-first-contact-widget.component.html',
  styleUrls: ['./pending-first-contact-widget.component.scss']
})
export class PendingFirstContactWidgetComponent implements OnInit {
  store = inject(Store);
  isEducator = toSignal(this.store.select(UserState.isEducator), {initialValue: false});

  readonly maxNumberOfItemsDisplayedInWidget = 5;
  pendingFirstContacts = signal<FirstContactApplicationSummary[]>([]);

  constructor(private firstContactService: FirstContactService, private router: Router) {

  }

  ngOnInit(): void {
    if (!this.isEducator()) {
      this.firstContactService.fetchPending()
        .subscribe(pendings => this.pendingFirstContacts.set(pendings));
    }
  }

  shouldDisplayWidget = computed(() => {
    return !this.isEducator() && this.pendingFirstContacts().length > 0;
  });

  displayableName(firstContact: FirstContactApplicationSummary) {
    if (!firstContact.firstName && !firstContact.lastName) {
      return "Aucun nom renseigné";
    } else {
      return [firstContact.firstName, firstContact.lastName].join(" ")
    }
  }

  latestPendingFirstContactApplications(): FirstContactApplicationSummary[] {
    return _.take(this.pendingFirstContacts(), 5);
  }

  hasMoreThanMaxDisplayableItems() {
    return this.pendingFirstContacts().length > this.maxNumberOfItemsDisplayedInWidget;
  }

  navigateToList() {
    this.router.navigate(['/first-contact/'], {queryParams: {status: FirstContactApplicationStatusEnum.PENDING.value}});
  }
}
