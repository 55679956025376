import {Component, input, OnInit} from '@angular/core';
import {GenderEnum} from "../../../models/person/GenderEnum";
import {MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatIconButton} from "@angular/material/button";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {MatOption, MatSelect} from "@angular/material/select";
import {NgForOf, NgIf} from "@angular/common";
import Gender = GenderEnum.Gender;

@Component({
  selector: 'app-create-person-fields',
  standalone: true,
  templateUrl: './create-person-fields.component.html',
  imports: [
    MatFormField,
    MatLabel,
    MatHint,
    MatIcon,
    MatSuffix,
    MatInput,
    FormsModule,
    MatIconButton,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDatepicker,
    MatSelect,
    MatOption,
    NgForOf,
    NgIf,
    ReactiveFormsModule
  ],
  styleUrls: ['./create-person-fields.component.scss']
})
export class CreatePersonFieldsComponent implements OnInit {

  formGroupName = input.required<string>()
  form: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective) {
  }

  // can't use GenderEnum.VALUES because backend only handle M/F for now
  genders: Gender[] = [GenderEnum.FEMALE, GenderEnum.MALE];

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName()) as FormGroup;
  }

}
