/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {DateTimeRangeDto} from './dateTimeRangeDto';


export interface StaffActivityRequestDto {
  id?: number;
  type?: string;
  status?: string;
  staffMemberId?: number;
  staffMemberFullName?: string;
  requestedAt?: string;
  requesterId?: number;
  requesterFullName?: string;
  period?: DateTimeRangeDto;
  validationId?: number;
  validatorFullName?: string;
  refusalNote?: string;
  refusedAt?: string;
  cancelledAt?: string;
}

