export namespace FirstContactApplicationRefusalMotive {
  export class RefusalMotive {
    private readonly _value: string;
    private readonly _label: string;

    constructor(value: string, label: string) {
      this._value = value;
      this._label = label;
    }

    get value(): string {
      return this._value;
    }

    get label(): string {
      return this._label;
    }
  }

  export const OUT_OF_TARGET_AUDIENCE = new RefusalMotive("OUT_OF_TARGET_AUDIENCE", "Hors public cible");
  export const OUT_OF_TERRITORY = new RefusalMotive("OUT_OF_TERRITORY", "Hors territoire");
  export const OUT_OF_SERVICE_OFFER = new RefusalMotive("OUT_OF_SERVICE_OFFER", "Hors offre du service");
  export const OUT_OF_REPIT_AUDIENCE = new RefusalMotive("OUT_OF_REPIT_AUDIENCE", "Hors public du répit");

  export const VALUES: RefusalMotive[] = [
    OUT_OF_TARGET_AUDIENCE,
    OUT_OF_TERRITORY,
    OUT_OF_SERVICE_OFFER,
    OUT_OF_REPIT_AUDIENCE
  ];

  const BY_VALUE = new Map<string, RefusalMotive>();
  VALUES.forEach(motive => BY_VALUE.set(motive.value, motive));

  export function fromValue(value: string): RefusalMotive {
    return BY_VALUE.get(value);
  }
}




