<div class="widget" *ngIf="shouldDisplayWidget()">
  <h2>
    <div class="title-text">Inscriptions en attente
      <span *ngIf="hasMoreThanMaxDisplayableItems()">({{maxNumberOfItemsDisplayedInWidget}}
          /{{ pendingFirstContacts().length }})</span>
    </div>
    <button mat-icon-button (click)="navigateToList()">
      <mat-icon>list</mat-icon>
    </button>
  </h2>
  <ul class="list-unstyled content">
    <li *ngFor="let firstContact of latestPendingFirstContactApplications()" class="content-item">
      <div class="content-text">{{firstContact.contactedOn | date:"dd/MM/YYYY"}}
        - {{displayableName(firstContact)}}</div>
      <button mat-icon-button [routerLink]="['/first-contact/detail/' + firstContact.id]">
        <mat-icon>info</mat-icon>
      </button>
    </li>
  </ul>
</div>
