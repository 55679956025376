import {Component, computed, inject, OnInit, signal} from '@angular/core';
import {Store} from "@ngxs/store";
import {UserState} from "../../../stores/user/user.state";
import {toSignal} from "@angular/core/rxjs-interop";
import {PrestationSelectors} from "../../../stores/prestation/prestation.selectors";
import {ClosePrestation, FindPrestationsToClose} from "../../../stores/prestation/prestation.actions";
import {PrestationClosureRequest} from "../../../models/prestation/PrestationClosureRequest";
import {MatIconAnchor} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import {PrestationToCloseFormComponent} from "../prestation-to-close-form/prestation-to-close-form.component";
import {NgIf} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-prestations-to-close-widget',
  standalone: true,
  templateUrl: './prestations-to-close-widget.component.html',
  imports: [
    MatIcon,
    MatIconAnchor,
    RouterLink,
    PrestationToCloseFormComponent,
    NgIf,
    MatTooltip,
  ],
  styleUrl: './prestations-to-close-widget.component.scss'
})
export class PrestationsToCloseWidgetComponent implements OnInit {

  store = inject(Store);
  isEducator = toSignal(this.store.select(UserState.isEducator), {initialValue: false});
  prestationIndex = signal(0);
  prestationsToClose = toSignal(this.store.select(PrestationSelectors.prestationsToClose()), {initialValue: []});

  ngOnInit(): void {
    if (!this.isEducator()) {
      this.store.dispatch(new FindPrestationsToClose());
    }
  }

  hasPrestationsToClose = computed(() => {
    return !this.isEducator() && this.prestationsToClose().length > 0;
  });

  oldestPrestationsToClose = computed(() => {
    return this.prestationsToClose().sort((a, b) => a.schedule.start().getTime() - b.schedule.end().getTime());
  });

  getPrestationToClose = computed(() => {
    return this.oldestPrestationsToClose()[this.prestationIndex()];
  });

  nextPrestation() {
    this.prestationIndex.update((index) => index >= this.prestationsToClose().length - 1 ? 0 : index + 1);
  }

  refreshIndexIfNeeded() {
    this.prestationIndex.update((index) => index >= this.prestationsToClose().length - 1 ? 0 : index);
  }

  closePrestation(closureRequest: PrestationClosureRequest) {
    this.store.dispatch(new ClosePrestation(closureRequest)).subscribe(() => {
      this.refreshIndexIfNeeded();
    });
  }


}
