import {AddressGateway} from "../../ports/AddressGateway";
import {Observable, of} from "rxjs";
import {AddressWithHistory} from "../../models/address/AddressWithHistory";

export class InMemoryAddressGateway extends AddressGateway {

    addresses: AddressWithHistory[] = [];

    getAllForPerson(personId: number): Observable<AddressWithHistory[]> {
        return of(this.addresses);
    }

    addAddresses(addresses: AddressWithHistory[]): InMemoryAddressGateway {
        this.addresses = this.addresses.concat(addresses);
        return this;
    }

}