import {AbstractEvent} from "./AbstractEvent";
import {SchedulerEventType} from "./SchedulerEventType";
import {SchedulerDateAndTime} from "./SchedulerDateAndTime";
import {CalendarEvent} from "angular-calendar";

export class TimeOffEvent extends AbstractEvent {

  get type(): SchedulerEventType {
    return SchedulerEventType.TIME_OFF_TYPE;
  }

  get title(): string {
    return "Congé";
  }

  toCalendarEvent(): CalendarEvent {
    return {
      id: this.id,
      start: this.startDateAndTime,
      end: this.endDateAndTime,
      title: this.title,
      cssClass: 'time-off-event',
      color: {
        primary: 'grey',
        secondary: 'grey'
      }

    };
  }

  public static scheduled(id: number, dateAndTime: SchedulerDateAndTime, repitWorkerId: number): TimeOffEvent {
    return new TimeOffEvent(id, dateAndTime.day, dateAndTime.timePeriod, repitWorkerId)
  }

}
